// import D1 from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB7-U4-P54-E1",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page54/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Choose the best option.",
        color: "#4d3c97",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          border: "none",
          borderRadius: "49%",
          borderColor: "transparent",
          fontSize: 25,
          margin: "0 50px",
          // fontWeight: 600,
        },
        selectWordStyle: {
          padding: 3,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "a._boring | b._familiar | c._awful", //0
          "a._kind |  b._scary | c._confident", //1
          "a._interesting | b._rich | c._boring", //2
          "a._brave | b._terrible | c._sweet", //3
          "a._uncommon | b._familiar | c._usual", //4
          "a._old | b._brave | c._strong", //5
        ],
        answers: ['0-6', '1-0', '2-12', '3-6', '4-0', '5-12'],
        // initialValue: ['0-0', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
        <div><b>1</b> Bến Thành market is always a/an ................... destination for foreigner.</div>
        <div><input id='0' type='Circle' /></div>
        <div><b>2</b> I really like my mum because she’s always ................... to me</div>
        <div><input id='1' type='Circle' /></div> 
        <div><b>3</b> I’m mad about long films – they’re sometimes slow and  ...................</div>
        <div><input id='2' type='Circle' /></div> 
        <div><b>4</b> My sister doesn’t like very strong coffee. She thinks it’s ................... .</div>
        <div><input id='3' type='Circle' /></div> 
        <div><b>5</b> In Việt Nam, it is not ................... for kids as well as adults to celebrate Mid-Autumn festivals</div>
        <div><input id='4' type='Circle' /></div> 
        <div><b>6</b> Our grandfather isn’t scared of anything. He’s a very ................... man.</div>
        <div><input id='5' type='Circle' /></div> 
      `,
    },
  },
  2: {
    unit: "Unit 4",
    id: "SB7-U4-P54-E2",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 150,
    maxLength: 50,
    exerciseKey: "img/FriendsPlus/Page54/E2/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "2",
        title: "Complete the sentences with the words.",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='width:60%; margin: 10px 0 20px 150px; border-radius: 30px; border: 2px solid  rgb(77 60 151)'>
              <div style='text-align:center'><strike> museum</strike>&emsp;&emsp;buildings&emsp;&emsp;collection <br> exhibit&emsp;&emsp;exhibition </div>
          </div>  
          <div> The oldest <u style="text-decoration: dotted underline;color: grey">&emsp;<b>museum</b>&emsp;</u> in the world is the Ashmolean in Oxford.</div>
          <div style='margin-top :5mm'><b>1.</b>The National Gallery in London i s very famous. It’s got a # of more than 2,300 paintings!</div>
          <div style='margin-top :5mm'><b>2.</b>There’s an # of Pablo Picasso’s paintings at the art gallery. It’s brilliant!</div>
          <div style='margin-top :5mm'><b>3.</b>My favourite # at the War Remnants Museum in Hồ Chí Minh City is the Patton tank.</div>
          <div style='margin-top :5mm'><b>4.</b>I want to visit the Taj Mahal in India and the Colosseum in Rome – I love interesting old #.</div>
          `,
        answer: [
          "collection", "exhibition", "exhibit", "buildings ",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB7-U4-P54-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", fontSize: 22 },
    inputSize: 600,
    exerciseKey: "img/FriendsPlus/Page54/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Write sentences using the affirmative (✔), negative (✘) or question (?) form of was or were",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          
              <div><b>1.</b> the tour / very interesting ✔ <br>#</div>
              <div><b>2.</b> the tunnels / really old ✔<br>#</div>
              <div><b>3.</b> what / the name of the tour ?<br>#.</div>
              <div><b>4.</b> the visit to the old town / very long ✘<br> #.</div>
              <div><b>5.</b> the tour guide / good ?<br> #.</div>
              <div><b>6.</b> our teacher / with us on the trip ✔ <br>#.</div>
              <div><b>7.</b> the tourists / scared of the dark ✘ <br> #.</div>
              <div><b>8.</b> your friends / at the museum too ?<br> #.</div>
          `,
        answer: [
          "The tour was very interesting. ",
          "The tunnels were really old.",
          "What was the name of the tour ?",
          "The visit to the old town wasn't very long.",
          "Was the tour guide good ?",
          "Our teacher was with us on the trip.",
          "The tourists weren't scared of the dark.",
          "Were your friends at the museum too?",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB7-U4-P54-E4",
    audio: 'Audios/1-47 Friends Plus 7.mp3',
    video: "",
    component: MatchDots,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page54/E4/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='1.47'></audioimage>Listen to Lily and Ann talking about a travel programme.Match 1–6 with a–f.",
        color: "#4d3c97",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "260px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "153px",
              left: "260px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "153px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "260px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "268px",
              left: "260px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "268px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "325px",
              left: "260px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "325px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "385px",
              left: "260px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "385px",
              left: "380px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 11
        ],
        answers: ['0-3', '2-5', '11-4', '6-9', '1-8', '10-7'],
        initialValue: [],
      },
      Layout: `
        <div style="font-size: 26px;">
					<div style='display: flex; font-weight: bold; padding-top: 5mm'>
						<div style='flex-grow: 2; margin-left: 145px;'>A</div>
						<div style='flex-grow: 2; margin-left: 100px'>B</div>
					</div>

          <div style='display: flex;padding-top: 5mm'>
            <div ><b>1</b> He explored</div>
						<div><input id='0' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
						<div style='margin-left: 242px;'><b>a.</b> Some  animals.</div>
          </div>

					<div style='display: flex;padding-top: 5mm'>
            <div><b>2</b> He visited</div>
						<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
						<div style='margin-left: 268px;'><b>b.</b> South America</div>
          </div>
					
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>3</b>  He met</div>
						<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='5' type= 'boxMatch' /></div>
						<div style='margin-left: 300px;'><b>c.</b> his school friend.</div>
          </div>
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>4</b> He stayed in</div>
						<div><input id='6' type= 'boxMatch' /></div>
            <div><input id='7' type= 'boxMatch' /></div>
						<div style='margin-left: 239px;'><b>d.</b> an older man.</div>
          </div>
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>5</b> He took photos of</div>
						<div><input id='8' type= 'boxMatch' /></div>
            <div><input id='9' type= 'boxMatch' /></div>
						<div style='margin-left: 180px;'><b>e.</b> the mountains.</div>
          </div>
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>6</b> He helped</div>
						<div><input id='10' type= 'boxMatch' /></div>
            <div><input id='11' type= 'boxMatch' /></div>
						<div style='margin-left: 268px;'><b>f.</b> two other travellers.</div>
          </div>
        </div>
      `,
    },
  },
};
export default json;
