import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Reading',
		id: 'SB7-R-P84-E1',
		exerciseKey: 'img/FriendsPlus/Page84/E1/Key/answerKey.png',

		audio: '',
		video: '',
		// isHiddenCheck: true,
		component: T6,
		inputSize: 70,
		maxLength: 5,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},

		titleQuestion: [
			{
				num: '1',
				title: `
					Read the song lyrics and answer the questions 
					with <i>Laila</i> or <i>Sam</i>.
				`,
				color: '#d72e3b',
			},
		],

		questions: [
			{
				title: `
					<div>
						<div style="width: 75%; display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page84/E1/1.jpg" style="max-width: 100%;" />
						</div>

						<div style="padding-left: 10px; margin-bottom: 16px;">
							<div>
								<strong style="padding-right: 10px;">1</strong>
								Who has a positive view about Laila's future in music? #
								
							</div>
							<div>
								<strong style="padding-right: 10px;">2</strong>
								Who thinks that Laila wont do well? #
								</div>
							<div></div>
						</div>

					</div>
                `,
				// title: `
				// 	<div style="width: 80%; margin-top: 20px;">
				// 		<div style="display: flex; justify-content: center; align-items: center;">
				// 		<img src="img/FriendsPlus/Page84/E1/1.jpg" style="max-width: 100%;" />
				// 		</div>

				// 		<div style="padding-left: 10px; margin-bottom: 16px;">
				// 			<div>
				// 				<strong style="padding-right: 10px;">1</strong>
				// 				Who has a positive view about Laila's future in music? #

				// 			</div>
				// 			<div>
				// 				<strong style="padding-right: 10px;">2</strong>
				// 				Who thinks that Laila wont do well? #
				// 				</div>
				// 			<div></div>
				// 		</div>

				// 	</div>
				// `,
				// 	title: `
				// 		<div style="width: 80%; margin-top: 20px;">
				// <div style="display: flex; justify-content: center; align-items: center;">
				//   <img src="img/FriendsPlus/Page84/E1/1.jpg" style="max-width: 100%;" />
				// </div>

				// <ol style="margin-top: 20px;">
				//   <li>Who has a positive view about Laila's future in music? #</li>
				//   <li>Who thinks that Laila wont do well? #</li>
				// </ol>
				// 		</div>
				//     `,
				answer: ['Laila', 'Sam'],
			},
		],
	},

	2: {
		unit: 'Reading',
		id: 'SB7-R-P84-E2',
		exerciseKey: 'img/FriendsPlus/Page84/E2/Key/answerKey.png',

		audio: 'Audios/2-17 Friends Plus 7.mp3',

		component: T6,
		inputSize: 570,
		maxLength: 56,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 22,
		},

		titleQuestion: [
			{
				num: '2',
				title: `
                    <audioimage name='2.17'></audioimage>
                    Read and listen to the song and the comments again. 
					Write True or False. 
					Explain your answers with lines from the song.
                `,
				color: '#d72e3b',
			},
		],

		questions: [
			{
				title: `
					<div style="width: 80%; margin-top: 20px;">
						<div>
							<img src="img/FriendsPlus/Page84/E2/1.jpg" style="max-width: 100%;" />
						</div>  

						<div style="margin-top: 10px; display: flex; justify-content: center;">
							<img src="img/FriendsPlus/Page84/E2/2.jpg" style="width: 50%;" />
						</div>  

                        <ol>
                            <li>
								Laila thinks that fame is more important than other things.<br/> #
                            </li>
                            <li>
								She doesn't think that she's got much talent.<br/> #
                            </li>
                            <li>
								Sam thinks that Laila doesn't understand the music business.<br/> #
                            </li>
                            <li>
								The story in the song is the same as Sam's life.<br/> #
                            </li>
                            <li>
								Sam isn't going to write music for the song because it's a rap.<br/> #
                            </li>
                        </ol>
					</div>
                `,
				answer: [
					"True (I'm going to be famous - that's all I want to do.)",
					"False (I've got the look, the voice; I've got the face.)",
					'True (Wake up a second now and take a look at reality.)',
					'True (Jan says: You had dreams once Sam!)',
					"False (we're going to have cool keyboard and bass)",
				],
			},
		],
	},

	3: {
		unit: 'Reading',
		id: 'SB7-R-P84-E3',
		exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 120,
		maxLength: 8,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 23,
		},

		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')

		textareaStyle: { width: 610 },

		titleQuestion: [
			{
				num: '3',
				title: `
                    <b style="color: #d72e3b;">VOCABULARY PLUS</b> Use a dictionary to check the meaning 
                    of the words in <span style="color: #29b2df">blue</span> in the text.
                `,
				color: '#d72e3b',
			},
		],

		questions: [
			{
				title: `
                <div style="width: 700px; margin-top: 20px;">
                    <div style="width: 785px;">
                        <img src="img/FriendsPlus/Page84/E3/1.jpg" style="max-width: 100%;" />
                    </div>     
                    
                </div>
                `,
				answer: [],
			},
		],
	},

	4: {
		unit: 'Reading',
		id: 'SB7-R-P84-E4',
		exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		textareaStyle: { width: 750 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '4',
				title: `
					What lines from the song do you like or dislike? 
					Why? Can you think of a title for the song?
                `,
				color: '#d72e3b',
				prefix: 'default',
			},
		],

		questions: [
			{
				title: `
                    <div style="width: 600px; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                        <img src="img/FriendsPlus/Page84/E3/1.jpg" style="max-width: 100%;" />
						<textarea id="0" rows="3"></textarea>
                    </div>     
                `,
				answer: [],
			},
		],
	},
};

export default json;
