import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: 'Unit 4',
    id: 'SB7-U4-P52-E1',
    component: T6,
    selectStyle: { width: 155, textAlign: 'center', fontSize: 18 },
    selectOptionRandom: true,
    selectOptionValues: [
      "1",
      "2",
      "3",
      "4",
    ],
    hintBox: [
      {
        src: [
          "cel",
          "character",
          "backgrounds",
          "stick figure",
        ],
        borderColor: '#DA2B37',
        width: 550,
      },
    ],
    titleQuestion: [{
      num: '1',
      title: ' Check the meaning of the words in the box. Then match them with the pictures.',
      color: "#DA2B37"
    }],
    exerciseKey: 'img/FriendsPlus/Page52/E1/Key/answerKey.png',
    questionImage: [],
    questions: [
      {
        title: `
          <div style="display: flex; justify-content: center; width: 900px"><hintbox id='0'></hintbox></div>
					<div style=' position: relative; '>
            <div><img src='img/FriendsPlus/Page52/E1/1.jpg' /></div>
            <div style=' position: absolute; top: 65px; left: -65px; '><select id='0'></select></div>
            <div style=' position: absolute; top: 165px; left: 638px; '><select id='1'></select></div>
            <div style=' position: absolute; top: 125px; left: 308px; '><select id='2'></select></div>
            <div style=' position: absolute; top: 505px; left: 483px; '><select id='3'></select></div>
					</div>
				`,
        answer: [
          "stick figure",
          "character",
          "cel",
          "backgrounds",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB7-U4-P52-E2",
    audio: 'Audios/1-46 Friends Plus 7.mp3',
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 700,
    exerciseKey: "img/FriendsPlus/Page52/E2/Key/answerKey.png",
    checkUppercase: true,
    selectStyle: { width: 90, textAlign: 'center', fontSize: 18 },
    selectOptionRandom: true,
    selectOptionValues: [
      "1",
      "2",
      "3",
      "4",
    ],
    titleQuestion: [
      {
        num: "2",
        title: "<audioimage name='1.46'></audioimage>Read and listen to the text. Match headings a–d with paragraphs 1–4.",
        color: "#dc3545",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          
          <div style='display: flex; justify-content: space-between; background-color: rgb(212, 227, 233); padding: 30px; border-radius: 50px; margin-top: 20px;'>
              <div style="margin-right:50px">
                <div style='margin-top:1cm; width: 200%;display: flex;'><b>a.&emsp;</b> <div style="width: 300px">Stop-motion animation </div><div><select id='0'></select></div></div>
                <div style='margin-top:1cm; width: 200%;display: flex;'><b>b.&emsp;</b> <div style="width: 300px">Computer animation </div><div><select id='1'></select></div></div>
                <div style='margin-top:1cm; width: 200%;display: flex;'><b>c.&emsp;</b> <div style="width: 300px">Early animation </div><div><select id='2'></select></div></div>
                <div style='margin-top:1cm; width: 200%;display: flex;'><b>d.&emsp;</b> <div style="width: 300px">Hand-drawn animation </div><div><select id='3'></select></div></div>
              </div>
              <img src='img/FriendsPlus/Page52/E3/1.jpg' />
            </div>
          `,
        answer: [
          "2",
          "4",
          "1",
          "3",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB7-U4-P52-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", fontSize: 22 },
    inputSize: 700,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page52/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title: "Read the text again and answer the questions.",
        color: "#dc3545",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          
          <div style='display: flex;width: 110% ; justify-content: space-between; background-color: rgb(212, 227, 233); padding: 30px; border-radius: 50px; margin-top: 20px;'>
              <div>
                <div style='margin-top:1cm'><b>1. </b> What did the zoopraxiscope do? <br> # </div>
                <div style='margin-top:1cm'><b>2. </b>When did the Lumière brothers make the cinematograph?<br> #</div>
                <div style='margin-top:1cm'><b>3. </b>What technique did they use to make King Kong in 1933?<br> #</div>
                <div style='margin-top:1cm'><b>4. </b>Which studio made the first cartoon with sound?<br> #</div>
                <div style='margin-top:1cm'><b>5. </b>When did film-makers start using computers for special effects?<br> #</div>
                <div style='margin-top:1cm'><b>6. </b>What is the name of the first CGI feature film?<br> #</div>
              </div>
              <div><img src='img/FriendsPlus/Page52/E3/1.jpg' /></div>
            </div>  
          `,
        answer: [
          "The zoopraxiscope took photographs of moving things.",
          "The Lumière brothers invented the cinematograph in 1895.",
          "They used stop motion animation to make King Kong in 1933.",
          "Disney studios made the first cartoon with sound.",
          "Film-makers started using computers for special effects in the 1960s.",
          "The first CGI feature film was Toy Story.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB7-U4-P52-E4",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: 1050 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Research the making of your favourite animated film. Prepare a short presentation (60 – 80 words) on the film. Include this information:",
        color: "#dc3545",
        prefix: 'default'
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <ul>
            <li>Which film-makers and studios worked on the film?</li>
            <li>What animation techniques did they use?</li>
            <li>Was it a blockbuster?</li>
            <li>What do you like about it?</li>
          </ul>
          <b>Give your presentation to the class.</b>
          <div><textarea id="0" rows="8" ></textarea><div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
