import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Unit 8',
		id: 'SB7-U8-P92-E1',
		exerciseKey: 'img/FriendsPlus/Page92/E1/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		isHiddenCheck: true,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},

		titleQuestion: [
			{
				num: '1',
				title: `
          Read the descriptions and match them with six photos A–F. 
        `,
				color: '#006ac0',
			},
		],

		questions: [
			{
				title: `
					<div style="margin-top: 10px;">
						<div>
							<strong>Means of transport</strong>

							<div style="margin-top: 5px; display: flex;">
								<img src="img/FriendsPlus/Page92/E1/1.jpg" style="max-width: 100%;" />
								<img src="img/FriendsPlus/Page92/E1/2.jpg" style="max-width: 100%;" />
								<img src="img/FriendsPlus/Page92/E1/3.jpg" style="max-width: 100%;" />
							</div>
			
							<div style="display: flex;">
								<img src="img/FriendsPlus/Page92/E1/4.jpg" style="max-width: 100%;" />
								<img src="img/FriendsPlus/Page92/E1/5.jpg" style="max-width: 100%;" />
								<img src="img/FriendsPlus/Page92/E1/6.jpg" style="max-width: 100%;" />
							</div>
						</div>

						<div style="margin-top: 30px;">
							<div style="display: flex; justify-content: center; align-items: center;">
								<img src="img/FriendsPlus/Page93/E3/1.jpg" style="max-width: 100%;" />
							</div>
						</div>

						<div style="margin-top: 30px;">
						<strong>Answers:</strong> <br/>
						1 # &emsp;&emsp;2 # &emsp;&emsp;3 # &emsp;&emsp;4 # &emsp;&emsp;5 # &emsp;&emsp;6 #
						</div>
					</div>
                `,
				answer: ['C', 'D', 'A', 'F', 'E', 'B'],
			},
		],
	},
};

export default json;
