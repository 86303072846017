import TB2 from '../../components/ExcerciseTypes/Table/TB2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Vocabulary And Listening',
		id: 'SB7-V-P96-E1',
		exerciseKey: 'img/FriendsPlus/Page96/E1/Key/answerKey.png',
		audio: 'Audios/2-28 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 280,
		maxLength: 27,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 20,
		},
		hintBox: [
			{
				src: ['yellow sign', 'departure information board', 'gate', 'shuttle'],
				borderColor: '#f57f22',
				width: 525,
			},
		],
		titleQuestion: [
			{
				num: '1',
				title: `
                    <audioimage name='2.28'></audioimage>
                    Fill in the blanks with these words. Listen and check.
                `,
				color: '#f57f22',
			},
		],

		questions: [
			{
				title: `
					<div style="width: 650px; margin-top: 20px;">
                        <div style="display: flex; justify-content: center;">
                            <hintbox id='0'></hintbox>
                        </div>
						
                        <div style="margin-top: 30px;"> 
                            <div style="display: flex; justify-content: space-between;">
                                <div>
                                    <div style="text-align: center"><img src="img/FriendsPlus/Page96/E1/1.jpg" style="max-width: 100%;" /></div>
                                    <span>#</span>
                                </div>
                                <div>
                                    <div style="text-align: center"><img src="img/FriendsPlus/Page96/E1/2.jpg" style="max-width: 100%;" /></div>
                                    <span>#</span>
                                </div>
                            </div>

                            <div style="display: flex; justify-content: space-between; margin-top: 20px;">
                                <div>
                                    <div style="text-align: center"><img src="img/FriendsPlus/Page96/E1/3.jpg" style="max-width: 100%;" /></div>
                                    <span>#</span>
                                </div>
                                <div>
                                    <div style="text-align: center"><img src="img/FriendsPlus/Page96/E1/4.jpg" style="max-width: 100%;" /></div>
                                    <span>#</span>
                                </div>
                            </div>
                        </div>
					</div>
                    
                    
                `,
				answer: [
					'departure information board',
					'shuttle',
					'gate',
					'yellow sign',
				],
			},
		],
	},

	2: {
		unit: 'Vocabulary And Listening',
		id: 'SB7-V-P96-E2',
		exerciseKey: 'img/FriendsPlus/Page96/E2/Key/answerKey.png',
		audio: 'Audios/2-29 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 710,
		maxLength: 65,
		stylesTextInput: {
			fontSize: 22,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
                    <audioimage name='2.29'></audioimage>
                    Listen and answer these questions.
                `,
				color: '#f57f22',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 600px; margin-top: 20px;">
						<div style="width: 700px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgb(255,226,202)">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(214,94,41); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
								STUDY STRATEGY
							</div>
							
							<div style="margin-top: 10px; padding-left: 20px;">
								<div style="font-weight: bold; font-size: 1.6rem; color: rgb(208,89,42)">
									Gaining details
								</div>
	
								<ol>
									<li>
										Listen and write down the numbers you hear.
									</li>
									<li>
										Try to write down the nouns before the numbers you hear.
									</li>
								</ol>
							</div>
						</div>

						<div style="margin-top: 20px;">
							<ol>
								<li>
									Where do Mike and Jane go? <br/> #
								</li>
								<li>
									What does Mike want to visit? <br/> #
								</li>
							</ol>
						</div>
					</div>
                `,
				answer: [
					'They go to Ho Chi Minh City.',
					'Mike wants to visit Ben Thanh Market and the War Remnants Museum.',
				],
			},
		],
	},

	3: {
		unit: 'Vocabulary And Listening',
		id: 'SB7-V-P96-E3',
		exerciseKey: 'img/FriendsPlus/Page96/E3/Key/answerKey.png',
		audio: 'Audios/2-29 Friends Plus 7.mp3',
		video: '',
		component: TB2,
		titleQuestion: [
			{
				num: '3',
				title: `
                    <audioimage name='2.29'></audioimage> Read the Study Strategy. Listen again and complete the table below.
                `,
				color: '#f57f22',
			},
		],
		questions: [
			{
				title: `
						<div style="width: 700px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgb(255,226,202)">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(214,94,41); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
								STUDY STRATEGY
							</div>
							
							<div style="margin-top: 10px; padding-left: 20px;">
								<div style="font-weight: bold; font-size: 1.6rem; color: rgb(208,89,42)">
									Gaining details
								</div>
	
								<ol>
									<li>
										Listen and write down the numbers you hear.
									</li>
									<li>
										Try to write down the nouns before the numbers you hear.
									</li>
								</ol>
							</div>
						</div>
						`,
				answer: [],
			},
		],
		tb2Style: { width: 800 },
		type: 1,
		data: [
			{
				title: 'Flight number',
				content: ['#'],
				answers: ['VN-1080'],
				commonStyle: { backgroundColor: 'rgb(253,220,189)' },
				titleStyle: { backgroundColor: 'rgb(251,164,95)', fontSize: 23 },
				contentStyle: { fontSize: 23 },
			},
			{
				title: 'Departure time',
				content: ['#'],
				answers: ["10 o'clock"],
				commonStyle: { backgroundColor: 'rgb(253,220,189)' },
				titleStyle: { backgroundColor: 'rgb(251,164,95)', fontSize: 23 },
				contentStyle: { fontSize: 23 },
			},
			{
				title: 'Terminal',
				content: ['#'],
				answers: ['1'],
				commonStyle: { backgroundColor: 'rgb(253,220,189)' },
				titleStyle: { backgroundColor: 'rgb(251,164,95)', fontSize: 23 },
				contentStyle: { fontSize: 23 },
			},
			{
				title: 'Gate',
				content: ['#'],
				answers: ['34'],
				commonStyle: { backgroundColor: 'rgb(253,220,189)' },
				titleStyle: { backgroundColor: 'rgb(251,164,95)', fontSize: 23 },
				contentStyle: { fontSize: 23 },
			},
		],
	},

	4: {
		unit: 'Vocabulary And Listening',
		id: 'SB7-V-P96-E4',
		exerciseKey: 'img/FriendsPlus/Page96/E4/Key/answerKey.png',
		audio: 'Audios/2-29 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 190,
		maxLength: 16,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 23,
		},

		titleQuestion: [
			{
				num: '4',
				title: `
                    <audioimage name='2.29'></audioimage> Listen to the dialogue again and complete the sentences.
                `,
				color: '#f57f22',
			},
		],

		questions: [
			{
				title: `
            <div style="width: 650px; margin-top: 10px;">
            <ol>
							<li>Mike and Jane are going to #</li>
							<li>They are looking for their flight #</li>
							<li>They fly with #</li>
							<li>Their flight departs at #</li>
							<li>
								They are going to catch the airport # to Terminal 1 
								and walk to Gate 34.
							</li>
						</ol>
            </div>
                `,
				answer: [
					'Ho Chi Minh city',
					'information',
					'Vietnam Airlines',
					"12 o'clock",
					'shuttle',
				],
			},
		],
	},

	5: {
		unit: 'Vocabulary And Listening',
		id: 'SB7-V-P96-E5',
		exerciseKey: 'img/FriendsPlus/Page96/E5/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		hintBox: [
			{
				src: ['What’s the first thing you want to see when you get to Ha Noi?'],
				borderColor: '#f77e1d',
				arrow: true,
				position: 1,
				width: 400,
			},
			{
				src: ['Ho Chi Minh Mausoleum. I really like it.'],

				borderColor: '#f77e1d',
				arrow: true,
				position: 2,
				width: 320,
			},
		],
		textareaStyle: { width: 620 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
				Work in pairs. 
				Imagine you are at the airport and you are going to Ha Noi. 
				Make your own dialogue.
                `,
				color: '#f57f22',
				prefix: 'default',
				// prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
			},
		],

		questions: [
			{
				title: `
					<div style="margin-top: 20px; width: 600px;">
						<hintbox id='0'></hintbox>

						<div style="margin-top: 40px; margin-left: 280px">
							<hintbox id='1'></hintbox>
						</div>

						<div style="margin-top: 30px;">
							<textarea id="8" rows="10"></textarea>
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
