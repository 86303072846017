import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  8: {
    unit: "Unit 2",
    id: "SB7-U2-P32-E8",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
    titleQuestion: [
      {
        num: "8",
        title: "<b>Match the words from A with the clues from B.</b>",
        color: "#4d3c97",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "204px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "60px",
              left: "290px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "100px",
              left: "204px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "100px",
              left: "290px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "177px",
              left: "204px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "177px",
              left: "290px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "204px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "215px",
              left: "290px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "255px",
              left: "204px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "255px",
              left: "290px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "295px",
              left: "204px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "295px",
              left: "290px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "338px",
              left: "204px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "339px",
              left: "290px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "378px",
              left: "204px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "380px",
              left: "290px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #4d3c97",
              background: "white",
            },
          }, // 15
        ],
        answers: ["0-5", "11-2", "13-4", "15-6", "7-8", "10-3", "1-12", "14-9"],
        initialValue: [],
      },
      Layout: `
        <div style="font-size: 26px;">
					<div style='display: flex; font-weight: bold;'>
						<div style='flex-grow: 2; margin-left: 80px;'>A</div>
						<div style='flex-grow: 2;'>B</div>
					</div>

          <div style='display: flex;'>
            <div style='width: 200px;'><b>1</b> conversation</div>
						<div><input id='0' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>a.</b> A smiley face or a small picture you put in an email</div>
          </div>

					<div style='display: flex;'>
            <div style='width: 200px;'><b>2</b> card</div>
						<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
						<div style='margin-left: 145px;'><b>b.</b> When you talk to somebody on your phone or computer and you can see them</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>3</b> landline</div>
						<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='5' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>c.</b> When you talk to somebody face-to-face</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>4</b> letter</div>
						<div><input id='6' type= 'boxMatch' /></div>
            <div><input id='7' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>d.</b> A short message you send with your mobile</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>5</b> text message</div>
						<div><input id='8' type= 'boxMatch' /></div>
            <div><input id='9' type= 'boxMatch' /></div>
						<div style='margin-left: 120px;'><b>e.</b> A symbol like this :) or this :(</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>6</b> video chat</div>
						<div><input id='10' type= 'boxMatch' /></div>
            <div><input id='11' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>f.</b> Something you send to a person on their birthday or a special day</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>7</b> symbol</div>
						<div><input id='12' type= 'boxMatch' /></div>
            <div><input id='13' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>g.</b> A type of phone that isn’t a mobile</div>
          </div>
					
					<div style='display: flex;'>
            <div style='width: 200px;'><b>8</b> emoji</div>
						<div><input id='14' type= 'boxMatch' /></div>
            <div><input id='15' type= 'boxMatch' /></div>
						<div style='margin-left: 120px; margin-top: 4px;'><b>h.</b> A message you write on some paper and put in an envelope</div>
          </div>
          
        </div>
      `,
    },
  },
  9: {
    unit: "Unit 2",
    id: "SB7-U2-P32-E9",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { textAlign: "center" },
    inputSize: 160,
    maxLength: 13,
    exerciseKey: "img/FriendsPlus/Page32/E9/Key/answerKey.png",
    titleQuestion: [
      {
        num: "9",
        title: "<b>Fill in the blanks with the words in the box.</b>",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='border-width: 2px; border-style: solid; border-radius: 8px; border-color: rgb(136,137,139); text-align: center; padding: 0 20px; margin-top: 20px; width: 75%;'>international&emsp;&emsp;useful&emsp;&emsp;creative&emsp;&emsp;colorful&emsp;&emsp;funny</div>
          <div style='position: relative; margin-top: 20px;'>
						<div><b>1</b> Nick is a very # person. He likes writing, singing and drawing.</div>
						<div><b>2</b> Your brother is very # – I always laugh when I read his posts on social media.</div>
						<div><b>3</b> Emojis are an # language.</div>
						<div><b>4</b> I like # photos. I don’t like black and white picture.</div>
						<div><b>5</b> Emojis are #. People communicate different ideas with them.</div>  
          </div>
        `,
        answer: ["creative", "funny", "international", "colourful", "useful"],
      },
    ],
  },
  10: {
    unit: "Unit 2",
    id: "SB7-U2-P32-E10",
    audio: "",
    video: "",
    component: T6,
    inputSize: 600,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page32/E10/Key/answerKey.png",
    titleQuestion: [
      {
        num: "10",
        title:
          "<b>Write affirmative (✔) or negative (✘) sentences using the present continuous.</b>",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    stylesTextInput: { fontSize: 22 },
    questions: [
      {
        title: `
					<div><b>1</b> my cousin / study / English and German ✔</div>
					<div>#</div>
					<div><b>2</b> I / have / my lunch ✔</div>
					<div>#</div>
					<div><b>3</b> you / read / your book ✘</div>
					<div>#</div>
					<div><b>4</b> Quang and Mai / use / video chat ✔</div>
					<div>#</div>
					<div><b>5</b> my friend / do / that difficult homework ✘</div>
					<div>#</div>
					<div><b>6</b> we / swim / in the sea ✘</div>
					<div>#</div>
        `,
        answer: [
          "My cousin is studying English and German.",
          "I'm having my lunch.",
          "You aren't reading your book.",
          "Zeynep and Merve are using video chat.",
          "My friend isn't doing that difficult homework.",
          "We aren't swimming in the sea.",
        ],
      },
    ],
  },
  11: {
    unit: "Unit 2",
    id: "SB7-U2-P32-E11",
    audio: "Audios/1-26 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 39,
    stylesTextInput: {
      border: "1px solid",
      height: 35,
    },
    textAlign: "center",
    maxLength: 1,
    exerciseKey: "img/FriendsPlus/Page32/E11/Key/answerKey.png",
    titleQuestion: [
      {
        num: "11",
        title:
          "<audioimage name='1.26'></audioimage>Listen to Anna making some phone calls. Match calls 1–6 with situations a–f.",
        color: "#4d3c97",
      },
    ],
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
          <div style="display: flex; margin-top: 20px;">
						<div>
              <div><b>a.</b></div>
              <div><b>b.</b></div>
              <div><b>c.</b></div>
              <div><b>d.</b></div>
              <div><b>e.</b></div>
              <div><b>f.</b></div>
            </div>
            <div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>

            <div style='margin-left: 20px;'>
              <div>hasn’t got any credit and needs to top up.</div>
              <div>speaks to a different person and needs to call back.</div>
              <div>needs to speak up.</div>
              <div>can’t speak to her friend. Her phone is engaged.</div>
              <div>has got the wrong number.</div>
              <div>leaves voicemail.</div>
            </div>
          </div>
          `,
        answer: ["6", "4", "5", "3", "1", "2"],
      },
    ],
  },
};

export default json;
