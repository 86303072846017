import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB7-U2-P29-E1",
    audio: "",
    video: "",
    component: D1,
    padding: 0,
    textAlign: "center",
    styleInput: { textTransform: 'uppercase' },
    fontSize: 30,
    maxLength: 1,
    marginTop: 12,
    hideRulesRequired: true,
    exerciseKey: "img/FriendsPlus/Page29/E1/Key/answerKey.png",
    questionImage: [
      [{ url: "img/FriendsPlus/Page29/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page29/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page29/E1/3.jpg",
          input: true,
          answer: "o",
        },
        { url: "img/FriendsPlus/Page29/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page29/E1/5.jpg",
          input: true,
          answer: "i",
        },
        {
          url: "img/FriendsPlus/Page29/E1/6.jpg",
          input: true,
          answer: "c",
        },
        {
          url: "img/FriendsPlus/Page29/E1/7.jpg",
          input: true,
          answer: "o",
        },
        {
          url: "img/FriendsPlus/Page29/E1/8.jpg",
          input: true,
          answer: "n",
        },
        { url: "img/FriendsPlus/Page29/E1/9.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page29/E1/10.jpg",
        },
        {
          url: "img/FriendsPlus/Page29/E1/11.jpg",
          input: true,
          answer: "v",
        },
        {
          url: "img/FriendsPlus/Page29/E1/12.jpg",
          input: true,
          answer: "i",
        },
        {
          url: "img/FriendsPlus/Page29/E1/13.jpg",
          input: true,
          answer: "d",
        },
        {
          url: "img/FriendsPlus/Page29/E1/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page29/E1/15.jpg",
          input: true,
          answer: "h",
        },
        { url: "img/FriendsPlus/Page29/E1/16.jpg" },
        {
          url: "img/FriendsPlus/Page29/E1/17.jpg",
          input: true,
          answer: "t",
        },
        { url: "img/FriendsPlus/Page29/E1/18.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page29/E1/19.jpg",
        },
        {
          url: "img/FriendsPlus/Page29/E1/20.jpg",
          input: true,
          answer: "e",
        },
        {
          url: "img/FriendsPlus/Page29/E1/21.jpg",
        },
        {
          url: "img/FriendsPlus/Page29/E1/22.jpg",
          input: true,
          answer: "t",
        },
        {
          url: "img/FriendsPlus/Page29/E1/23.jpg",
          input: true,
          answer: "e",
        },
        {
          url: "img/FriendsPlus/Page29/E1/24.jpg",
          input: true,
          answer: "r",
        },
        { url: "img/FriendsPlus/Page29/E1/25.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page29/E1/26.jpg",
        },
        {
          url: "img/FriendsPlus/Page29/E1/27.jpg",
          input: true,
          answer: "o",
        },
        {
          url: "img/FriendsPlus/Page29/E1/28.jpg",
          input: true,
          answer: "c",
        },
        {
          url: "img/FriendsPlus/Page29/E1/29.jpg",
          input: true,
          answer: "i",
        },
        { url: "img/FriendsPlus/Page29/E1/30.jpg" },
        {
          url: "img/FriendsPlus/Page29/E1/31.jpg",
          input: true,
          answer: "l",
        },
        { url: "img/FriendsPlus/Page29/E1/32.jpg" },
        {
          url: "img/FriendsPlus/Page29/E1/33.jpg",
          input: true,
          answer: "m",
        },
        { url: "img/FriendsPlus/Page29/E1/34.jpg" },
        {
          url: "img/FriendsPlus/Page29/E1/35.jpg",
          input: true,
          answer: "d",
        },
        {
          url: "img/FriendsPlus/Page29/E1/36.jpg",
          input: true,
          answer: "i",
        },
        {
          url: "img/FriendsPlus/Page29/E1/37.jpg",
          input: true,
          answer: "a",
        },
        { url: "img/FriendsPlus/Page29/E1/38.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page29/E1/39.jpg",
        },
        {
          url: "img/FriendsPlus/Page29/E1/40.jpg",
          input: true,
          answer: "t",
        },
        {
          url: "img/FriendsPlus/Page29/E1/41.jpg",
          input: true,
          answer: "e",
        },
        {
          url: "img/FriendsPlus/Page29/E1/42.jpg",
        },
        { url: "img/FriendsPlus/Page29/E1/43.jpg", input: true, answer: "t" },
        {
          url: "img/FriendsPlus/Page29/E1/44.jpg",
        },
        { url: "img/FriendsPlus/Page29/E1/45.jpg", input: true, answer: "m" },
        {
          url: "img/FriendsPlus/Page29/E1/46.jpg",
          input: true,
          answer: "e",
        },
        { url: "img/FriendsPlus/Page29/E1/47.jpg" },
        {
          url: "img/FriendsPlus/Page29/E1/48.jpg",
          input: true,
          answer: "s",
        },
        {
          url: "img/FriendsPlus/Page29/E1/49.jpg",
          input: true,
          answer: "a",
        },
        {
          url: "img/FriendsPlus/Page29/E1/50.jpg",
          input: true,
          answer: "g",
        },
        { url: "img/FriendsPlus/Page29/E1/51.jpg", input: true, answer: "e" },
        { url: "img/FriendsPlus/Page29/E1/52.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page29/E1/53.jpg",
        },
        {
          url: "img/FriendsPlus/Page29/E1/54.jpg",
          input: true,
          answer: "o",
        },
        {
          url: "img/FriendsPlus/Page29/E1/55.jpg",
          input: true,
          answer: "b",
        },
        {
          url: "img/FriendsPlus/Page29/E1/56.jpg",
          input: true,
          answer: "i",
        },
        { url: "img/FriendsPlus/Page29/E1/57.jpg", input: true, answer: "l" },
        {
          url: "img/FriendsPlus/Page29/E1/58.jpg",
        },
        { url: "img/FriendsPlus/Page29/E1/59.jpg", input: true, answer: "p" },
        {
          url: "img/FriendsPlus/Page29/E1/60.jpg",
          input: true,
          answer: "h",
        },
        { url: "img/FriendsPlus/Page29/E1/61.jpg", input: true, answer: "o" },
        {
          url: "img/FriendsPlus/Page29/E1/62.jpg",
          input: true,
          answer: "n",
        },
        {
          url: "img/FriendsPlus/Page29/E1/63.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page29/E1/64.jpg",
        },
        { url: "img/FriendsPlus/Page29/E1/65.jpg", input: true, answer: "y" },
        { url: "img/FriendsPlus/Page29/E1/66.jpg", input: true, answer: "m" },
        { url: "img/FriendsPlus/Page29/E1/67.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page29/E1/68.jpg", input: true, answer: "o" },
        { url: "img/FriendsPlus/Page29/E1/69.jpg" },
        { url: "img/FriendsPlus/Page29/E1/70.jpg", input: true, answer: "s" },
        { url: "img/FriendsPlus/Page29/E1/71.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page29/E1/72.jpg" }],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB7-U2-P29-E2",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 650,
    maxLength: 5,
    exerciseKey: "img/FriendsPlus/Page29/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "SENTENCE RACE. Work in groups. Order the words to make present continuous sentences. Score three points for finishing first. Score one point for each correct sentence.",
        color: "black",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div>still / for / She's / bus / waiting / the / school</div>
          <div style='color: gray; font-style: italic;'>She's still waiting for the school bus.</div>
          <div><b>1</b> isn't / very / maths / Our / teacher / well / feeling / today</div>
          <div style='margin-left: 15px;'>#</div>
          <div><b>2</b> his / playing / Jake / and / park / brother / in / the / are / football</div>
          <div style='margin-left: 15px;'>#</div>
          <div><b>3</b> phone's / His / answering / ringing / he / but / isn't</div>
          <div style='margin-left: 15px;'>#</div>
          <div><b>4</b> living / My / aren't / us / now / grandparents / with</div>
          <div style='margin-left: 15px;'>#</div>
          <div><b>5</b> my / working / I'm / landline / afraid / moment / the / isn't / at</div>
          <div style='margin-left: 15px;'>#</div>
        `,
        answer: [
          "Our maths teacher isn't feeling very well today.",
          "Jake and his brother are playing football in the park.",
          "His phone's ringing, but he isn't answering.",
          "My grandparents aren't living with us now.",
          "I'm afraid my landline isn't working at the moment.",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB7-U2-P29-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 150,
    maxLength: 5,
    exerciseKey: "img/FriendsPlus/Page29/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "",
        title: "",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page29/E3/1.jpg' />
          <div style='display: flex; justify-content: space-between;'>
            <div>
              <div><b>1</b> #</div>
              <div><b>2</b> #</div>
              <div><b>3</b> #</div>
            </div>
            <div>
              <div><b>4</b> #</div>
              <div><b>5</b> #</div>
            </div>
            <div>
              <div><b>6</b> #</div>
              <div><b>7</b> #</div>
            </div>
          </div>
        `,
        answer: [
          "send",
          "top up",
          "download",
          "hang up",
          "put",
          "speak up",
          "leave",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB7-U2-P29-E4",
    audio: "",
    video: "",
    component: UI,
    imageStyle: { width: '64%' },
    // recorder: true,
    questionImage: [[{ url: "img/FriendsPlus/Page29/E4/1.jpg" }]],
  },
  5: {
    unit: "Unit 2",
    id: "SB7-U2-P29-E5",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    questionImage: [[{ url: "img/FriendsPlus/Page29/E5/1.jpg" }]],
  },
};

export default json;
