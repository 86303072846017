import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	3: {
		// Exercise num
		unit: 'Unit 6',
		id: 'SB7-U6-P69-E3',
		audio: '/Audios/2-04 Friends Plus 7.mp3',
		video: '/Videos/Talking Heads/U6-2.04-Trang 69.mp4',
		videoSub: '/Videos/Talking Heads/U6-2.04-Trang 69.vtt',
		padding: 0,
		textAlign: 'center',
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page69/E3/Key/answerKey.png',
		questionImage: [],
		titleQuestion: [
			{
				num: '3',
				title:
					'<videoimage></videoimage><audioimage name="2.04" ></audioimage>  Watch  or listen. Which survival skills in the game are the people discussing? ',
				color: '#5390c4',
			},
		],
		component: T6,
		inputSize: 240,
		checkUppercase: true,
		questions: [
			{
				title: `
          <div style='border: 3px solid rgb(83, 144, 196); border-radius: 10px; margin-top: 40px; margin-left: 300px;' >
            <div style='padding:20px;'>
              <div ><b style='color:rgb(83, 144, 196) ;'>ANSWERS</b></div>
              <div><b>Shri:</b>#</div>
              <div><b>May:</b>#</div>
              <div><b>Simon:</b>#</div>
              <div><b>Rebecca:</b>#</div>
              <div><b>Harry:</b>#</div>
            </div>
          </div>
        `,
				answer: ['lighting fires ', 'picking fruit', 'picking fruit  ', 'finding your way ', 'finding your way'],
			},
		],
	},

	4: {
		// Exercise num
		unit: 'Unit 6',
		id: 'SB7-U6-P69-E4',
		audio: '/Audios/2-04 Friends Plus 7.mp3',
		video: '/Videos/Talking Heads/U6-2.04-Trang 69.mp4',
		padding: 0,
		textAlign: 'center',
		// maxLength: 1,
		exerciseKey: 'img/FriendsPlus/Page69/E4/Key/answerKey.png',
		questionImage: [],
		titleQuestion: [
			{
				num: '4',
				title:
					'<videoimage></videoimage><audioimage name="2.04" ></audioimage> Watch or listen again and complete the Key Phrases.',
				color: '#5390c4',
			},
		],
		component: T6,
		inputSize: 360,
		checkUppercase: true,
		questions: [
			{
				title: `
          <div style='background-color: rgb(204, 219, 232); margin-top: 20px;  width:750px; border-radius: 15px'>
          <div style='background-color: rgb(83, 144, 196); border-radius: 15px;'><h1 style='color: rgb(255, 255, 255); padding: 5px 5px 5px 25px;'>KEY PHRASES</h1></div>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(83, 144, 196) ;'>Ability</b></div>
            <div> Are you good at <b><sup>1</sup></b> #?</div>
              <div> I'm (no) good at <b><sup>2</sup></b> #.</div>
              <div> Can you <b><sup>3</sup></b> #?</div>
              <div> How do you <b><sup>4</sup></b> #.</div>
              <div> Definitely not ! /I've no idea !</div>
              <div> I can (probably /definitely) <b><sup>5</sup></b> #.</div>
              <div> I (don't) think so. /I doubt it.</div>
          </div>
      </div>

        `,
				answer: [
					'lighting fires outdoors',
					'survival skills',
					'light a fire outdoors / tell what fruit is safe to eat / find your way without a phone or a map',
					"find your way if you 're lost ",
					'light a fire outdoors / find my way with a map / use the sun ',
				],
			},
		],
	},

	5: {
		unit: 'Unit 6',
		id: 'SB7-U6-P69-E5',
		audio: '',
		video: '',
		component: T6,
		titleQuestion: [
			{
				num: '5',
				title:
					'Study the Key Phrases. Then ask and answer the questions about your survivalskills with your partner. Use the phrases in exercise 1 and your own ideas.',
				color: '#0066bf',
				prefix: 'default',
			},
		],
		exerciseKey: '',
		textareaStyle: { width: 1140 },
		hideBtnFooter: true,
		questionImage: [],
		inputSize: 120,
		hintBox: [
			{
				src: ['Can you make a fire?'],
				borderColor: '#0066bf',
				arrow: true,
				position: 1,
				width: 300,
			},
			{
				src: ["No, I don't think so. How about you?"],
				borderColor: '#0066bf',
				arrow: true,
				position: 2,
				width: 460,
			},
			{
				src: ['I doubt it.'],
				borderColor: '#0066bf',
				arrow: true,
				position: 1,
				width: 160,
			},
		],
		questions: [
			{
				title: `
          <div style='background-color: rgb(204, 219, 232); margin: 20px 90px 20px 170px ; border-radius: 20px'>
            <div style=''><img src='img/FriendsPlus/Page11/E5/1.jpg' style='width: 600px; border-radius:20px;'/></div>
            <div style='margin-left: 20px;' >
              <div ><b style='color:rgb(83, 144, 196) ;'>Ability</b></div>
              <div> Are you good at <b><sup>1</sup></b> #?</div>
              <div> I'm (no) good at <b><sup>2</sup></b> #.</div>
              <div> Can you <b><sup>3</sup></b> #?</div>
              <div> How do you <b><sup>4</sup></b> #.</div>
              <div> Definitely not ! /I've no idea !</div>
              <div> I can (probably /definitely) <b><sup>5</sup></b> #.</div>
              <div> I (don't) think so. /I doubt it.</div>
            </div>
          </div>
					
          <div >
            <div style='margin-left:100px;font-weight:600;'><hintbox id= '0'></hintbox></div>
            <div style="margin: 40px 0px 40px 400px; font-weight:600; " ><hintbox id= '1'></hintbox></div>
            <div style='margin-left:100px;font-weight:600;'><hintbox id= '2'></hintbox></div>
          </div>

          <div style=' width: 100%; display: flex; justify-content: center ; gap: 100px; margin: 50px 0 30px 70px'>
            <div>
                <div><b>1</b> # all plants </div>
                <div><b>2</b> # still </div>
                <div><b>3</b> # a shelter </div>
                <div><b>4</b> # a tree </div>
                <div><b>5</b> # the sun </div>
                <div><b>6</b> # the river </div>
                <div><b>7</b> # cool </div>
            </div>
            <div>
                <div>&nbsp;&nbsp;<b>8</b> # a fire </div>
                <div>&nbsp;&nbsp;<b>9</b> # a noise </div>
                <div><b>10</b> # at night </div>
                <div><b>11</b> # fruit </div>
                <div><b>12</b> # away </div>
                <div><b>13</b> # where you are </div>
                <div><b>14</b> # drinking water </div>
            </div>
          </div>
        `,
				answer: [],
			},
		],
	},
	6: {
		unit: 'Unit 6',
		id: 'SB7-U6-P69-E6',
		audio: '',
		video: '',
		component: T6,
		questionImage: [],
		textareaStyle: { width: 750 },
		hideBtnFooter: true,
		inputSize: 700,
		finished: {
			text: 'Write a five-question survival quiz to ask the class.',
		},
		questions: [
			{
				title: `
        <finished style='color: red'></finished>
        <div>
            <div><b>1</b> # </div>
            <div><b>2</b> # </div>
            <div><b>3</b> # </div>
            <div><b>4</b> # </div>
            <div><b>5</b> # </div>
        </div>
        `,
				answer: [],
			},
		],
	},
}

export default json
