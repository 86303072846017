import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB7-U4-P42-E1",
    component: UI,
    questionImage: [[{ url: "img/FriendsPlus/Page46/E1/1.jpg" }]],
  },
  2: {
    unit: "Unit 4",
    id: "SB7-U4-P46-E2",
    audio: "",
    video: "",
    component: T6,
    inputSize: 1000,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Why are they doing it? Which countries are they in? Read the texts and check.",
        color: "#db2d37",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <img style="width: 80%" src='img/FriendsPlus/Page46/E2/1.jpg' />
          <div><b>1</b> What are the people doing?</div>
          <div>#</div>
          <div><b>2</b> Why are they doing it?</div>
          <div>#</div>
          <div><b>3</b> Which countries are they in?</div>
          <div>#</div>
        `,
        answer: ["", "", "",],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB7-U4-P46-E3",
    audio: "Audios/1-41 Friends Plus 7.mp3",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 120,
    maxLength: 5,
    exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "<audioimage name='1.41'></audioimage> Read and listen to the text. Write <i>True</i> or <i>False</i>. Explain your answers.",
        color: "#db2d37",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <img style="width: 72%" src='img/FriendsPlus/Page46/E2/1.jpg' />
          <div><b>1 &emsp;</b> The 6-year-old girl in Chiang Mai, Thailand made some toys with ice-cream sticks. #</div>
          <div><b>2 &emsp;</b> According to the photographer, the toy was good. #</div>
          <div><b>3 &emsp;</b> The café was empty. #</div>
          <div><b>4 &emsp;</b> Some people were relaxing with decorations. #</div>
          <div><b>5 &emsp;</b> The writer liked the café with recycled decorations. #</div>
        `,
        answer: [
          "False",
          "True",
          "False",
          "False",
          "True",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB7-U4-P46-E4",
    component: UI,
    imageStyle: { width: '74%' },
    titleQuestion: [
      {
        num: "4",
        title:
          "<b style='color: #db2d37'>VOCABULARY PLUS</b>: Use a dictionary to check the meaning of the words in <span style='color: #2eb6e1'>blue</span> in the text.",
        color: "#db2d37",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page46/E2/1.jpg" }]],

  },

  5: {
    unit: "Unit 4",
    id: "SB7-U4-P46-E5",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 120,
    maxLength: 5,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Find the word although in the second text. What is it used for?",
        color: "#db2d37",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style="font-weight: bold; color: grey">
          <div> Their business was different, but it was meaningful to the environment.</div>
          <div> → Although their business was different, it was meaningful to the environment.</div>
        </div>
        `,
        answer: [],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB7-U4-P46-E6",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "1px dotted", fontSize: 24 },
    inputSize: 900,
    exerciseKey: "img/FriendsPlus/Page46/E6/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "6",
        title: "Combine the two sentences using although and but",
        color: "#db2d37",
      },
    ],
    questions: [
      {
        title: `
          <div><b>1</b> She is a little girl. She is helpul. <br><b>But</b>  #<br><b>Although</b>  #  </div>
          <div><b>2</b> Many collectors want to own her toys. She does not want to sell them <br><b>But</b> #<br><b>Although</b>  #</div>
        `,
        answer: [
          "She is a little girl, but she is helpful. ",
          "Although she is a little girl, she is helpful.",
          "Many collectors want to own her toys, but she does not want to sell them. ",
          "Although many collectors want to own her toys, she does not want to sell them.",
        ],
      },
    ],
  },
  7: {
    unit: "Unit 4",
    id: "SB7-U4-P46-E7",
    audio: "",
    video: "",
    component: T6,
    textareaStyle: { width: 1050 },
    inputSize: 800,
    maxLength: 200,
    // exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    checkUppercase: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "7",
        title:
          "Work in pairs. Do you think that recycling can change the world? Can you think of an idea to make toys from rubbish?",
        color: "#db2d37",
        prefix: 'default'
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page46/E7/1.jpg" }]],
    questions: [
      {
        title: `
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
