import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import TB2 from "../../components/ExcerciseTypes/Table/TB2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Starter",
    id: "SB7-S-P8-E1",
    audio: "Audios/1-03 Friends Plus 7.mp3",
    video: "",
    component: TB2,
    exerciseKey: "img/FriendsPlus/Page8/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "<audioimage name='1.03'></audioimage> Read and listen to the dialogue. Then complete the table with the words in <span style='color: #00aeef;'>blue</span> in the dialogue. Add more words to the table.",
        color: "#f58024",
      },
    ],
    questions: [
      {
        title: `
          <div style="display: flex;">
            <div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Mark</b></div>
                <div style='flex: 5;'>Excuse me, is this the <span style='color: #00aeef;'>science lab*</span>?</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Jenny</b></div>
                <div style='flex: 5;'>Erm, no, there aren’t any science labs in this block. You’re the new boy, right? We haven’t got science this morning.</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Mark</b></div>
                <div style='flex: 5;'>Oh. What have we got now? I haven’t got my <span style='color: #00aeef;'>timetable</span>.</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Susan</b></div>
                <div style='flex: 5;'>Here, take this. I’ve got a spare copy.</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Mark</b></div>
                <div style='flex: 5;'>Thanks. Ah, OK. We’ve got <span style='color: #00aeef;'>maths</span> now.</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Jenny</b></div>
                <div style='flex: 5;'>Yeah. Who have we got for maths this </div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Susan</b></div>
                <div style='flex: 5;'>Mr Waldron. That means a lot of <span style='color: #00aeef;'>homework</span> and <span style='color: #00aeef;'>tests</span>. And there’s a new <span style='color: #00aeef;'>history</span> teacher, look.</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Mark</b></div>
                <div style='flex: 5;'>How many <span style='color: #00aeef;'>teachers</span> are there here?</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Jenny</b></div>
                <div style='flex: 5;'>I don’t know. A lot. There are a thousand <span style='color: #00aeef;'>students</span> here.</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Mark</b></div>
                <div style='flex: 5;'>A thousand and one, including me. Mmm – double history on Friday afternoon. That’s tough.</div>
              </div>
              <div style="display: flex;">
                <div style='flex: 1;'><b>Susan</b></div>
                <div style='flex: 5;'>Yes. Welcome to our world!</div>
              </div>
              <div>*lab (laboratory) = a special room in school where you learn science</div>
            </div>
            <div><img src='img/FriendsPlus/Page8/E1/1.png' width='500px'/></div>
          </div>
            
        `,
      },
    ],
    tb2Style: { width: 700 },
    type: 2,
    data: [
      {
        title: "Subjects",
        content: ["science", "#", "#"],
        answers: ["", "maths", "history"],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#f9a360",
          border: "1px solid #f58024",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#fedbbe",
          border: "1px solid #f58024",
          color: "#58585a",
        },
      },
      {
        title: "Other words",
        content: ["lab", "#", "#", "#", "#", "#"],
        answers: ["", "timetable", "homework", "tests", "teachers", "students"],
        commonStyle: {},
        titleStyle: {
          backgroundColor: "#f9a360",
          border: "1px solid #f58024",
          color: "#fff",
        },
        contentStyle: {
          backgroundColor: "#fedbbe",
          border: "1px solid #f58024",
          color: "#58585a",
        },
      },
    ],
  },
  2: {
    unit: "Starter",
    id: "SB7-S-P8-E2",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page8/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Check the meaning of the words in <span style='color: #00aeef;'>blue</span> and choose the correct words. Then write sentences about your school.",
        color: "#f58024",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "#00aeef",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          "a._a_personal_blog &emsp; b._an_advertisement_for_a_TV_programme &emsp;", //1
          "notebook / teacher", //2
          "exams / rooms", //3
          "teacher / homework",
          "book / class",
          "notes / timetables",
        ],
        answers: ["0-0", "1-4", "2-0", "3-4", "4-0", "5-0"],
        initialValue: [],
      },
      Layout: `
        <div><b>1</b> The music <b><input id='0' type='Circle' /></b> is always cold.</div>
        <div><b>2</b> Miss Atkins is our new English <b><input id='1' type='Circle' /></b>.</div>
        <div><b>3</b> Geography <b><input id='2' type='Circle' /></b> are always difficult.</div>
        <div><b>4</b> I haven’t got French <b><input id='3' type='Circle' /></b> today.</div>
        <div><b>5</b> It’s on page 36 of the maths <b><input id='4' type='Circle' /></b>.</div>
        <div><b>6</b> Your history <b><input id='5' type='Circle' /></b> are very neat.</div>
      `,
    },
  },
  3: {
    unit: "Starter",
    id: "SB7-S-P8-E3",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Talk about your timetable using the prepositions of time.",
        color: "#f58024",
        prefix: "default",
      },
    ],
    hideBtnFooter: true,
    hintBox: [
      {
        src: [
          "I’ve got biology at 9:45 on Tuesday.",
          "I’ve got a break at 11:00 every day.",
        ],
        borderColor: "#f58024",
        arrow: true,
        position: 1,
        width: 500,
      },
    ],
    questions: [
      {
        title: `
          <div style='margin-bottom: 50px;'>
            <hintbox id='0'></hintbox>
          </div>
          <img src='img/FriendsPlus/Page8/E3/1.png' width='500px' />
          `,
        answer: [],
      },
    ],
  },
};

export default json;
