import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Option 1",
    id: "SB7-O1-P114-E1",
    exerciseKey: "img/FriendsPlus/Page114/E1/Key/answerKey.png",

    audio: "Audios/3-25 Friends Plus 7.mp3",
    video: "",

    component: T6,
    inputSize: 200,
    maxLength: 14,
    // checkDuplicated: true,

    titleQuestion: [
      {
        num: "1",
        title: `
              <audioimage name='3.25'></audioimage>
              Look at the activities in the pictures. Listen to the song. 
              Which activity is not in the song?
            `,
        color: "#dd2d38",
      },
    ],

    questions: [
      {
        title: `
                <div style="width: 550px; margin-left: 20px; margin-top: 20px;">
                    <img src='img/FriendsPlus/Page114/E1/2.jpg'/>
                    <p style="margin-top: 20px">Answer: #</p>
                </div>
            `,

        answer: ['going shopping / g'],
      },
    ],
  },

  2: {
    unit: "Option 1",
    id: "SB7-O1-P114-E2",
    exerciseKey: "img/FriendsPlus/Page114/E2/Key/answerKey.png",

    audio: "Audios/3-25 Friends Plus 7.mp3",
    video: "",

    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
            <audioimage name='3.25'></audioimage>
            Look at the song lyrics and choose the correct words. 
            Then listen again and check.
          `,
        color: "#dd2d38",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "#00aeef",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          'asleep / awake',
          'TV / computer',
          'restaurant / café',
          'month / week',
          'late / early',
          'afternoon / morning',
          'car / bus',
          'night / day',
        ],
        answers: ["0-0", "1-0", "2-4", "3-4", "4-4", "5-0", "6-0", "7-0"],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative; margin-top: 20px'>
            <div>
                <img src='img/FriendsPlus/Page115/E1/songFrame.jpg'/>
            </div>

            <div style='position: absolute; top: 80px; left: 40px; font-size: 0.9rem'>
                  <strong style='color:rgba(70, 138, 204, 1); font-size: 1.5rem'>A good idea</strong> <br /> 

                  You’re <strong><input id='0' type='Circle' /></strong> in bed, and your mum says <br/>
                  ‘Why don’t you get up and go to school?’ <br/>
                  You go on the bus – your friends talk a lot <br/>
                  So you listen to music on your phone. <br/>
                  In class, at the back you’re talking to your friends <br/>
                  And your teacher tells you off again <br/>
                  <br/>

                  You think: <br/>
                  ‘I often just want to go home <br/>
                  I always want to stay in bed <br/>
                  And be alone. <br/>
                  Sitting on the sofa, <br/>
                  In front of the <strong><input id='1' type='Circle' /></strong> <br/>
                  Is a good idea for me <br/>
                  Sounds like a great idea to me.’ <br/>
                  <br/>

                  You see your dad in town, and he says to you <br/>
                  ‘How about going for a walk?’ <br/>
                  Then you go out to a <strong><input id='2' type='Circle' /></strong> and eat fast food <br/>
                  And he says ‘Let’s finish your homework’ <br/>
                  But you don’t really feel like studying now – <br/>
                  You prefer playing video games. <br/>
                  <br/>

                  Then next <strong><input id='3' type='Circle' /></strong> you’re on holiday.. <br/>
                  You always love being on holiday.. <br/>
                  What will you do on holiday?. <br/>
                  You feel like doing everything!. <br/>
                  You get up <strong><input id='4' type='Circle' /></strong> and your mum says,. <br/>
                  ‘Why don’t you go out to the park?’. <br/>
                  So you get your things, and you call your friends. <br/>
                  And you play sports all <strong><input id='5' type='Circle' /></strong>. <br/>
                  Your dad wants to go out to the cinema. <br/>
                  So you run home from the park, and you go in the <strong><input id='6' type='Circle' /></strong>.. <br/>
                  <br/>

                  And you think: <br/>
                  ‘I always want to stay awake! <br/>
                  I love to be with friends <br/>
                  And my family! <br/>
                  I want to play all <strong><input id='7' type='Circle' /></strong> <br/>
                  I never want to sleep. <br/>
                  Sounds like a good idea to me <br/>
                  Yeah, that’s a great idea for me’ <br/>
            </div>
        </div>
        `,

    },
  },

  3: {
    unit: "Option 1",
    id: "SB7-O1-P114-E3",
    exerciseKey: "img/FriendsPlus/Page114/E3/Key/answerKey.png",

    audio: "Audios/3-25 Friends Plus 7.mp3",
    video: "",

    component: MatchDots,
    titleQuestion: [{ num: "3", title: "Match 1–5 with a–e to make sentences about the person in the song.", color: "#dd2d38", }],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "345px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "75px",
              left: "415px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // b

          {
            boxMatchStyle: {
              position: "absolute",
              top: "75px",
              left: "345px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "153px",
              left: "415px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // d

          {
            boxMatchStyle: {
              position: "absolute",
              top: "110px",
              left: "345px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "40px",
              left: "415px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // a

          {
            boxMatchStyle: {
              position: "absolute",
              top: "152px",
              left: "345px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "194px",
              left: "415px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // e

          {
            boxMatchStyle: {
              position: "absolute",
              top: "194px",
              left: "345px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "112px",
              left: "415px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #F58024",
              background: "white",
            },
          }, // c

          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "194px",
          //     left: "435px",
          //     width: "30px",
          //     height: "30px",
          //     borderRadius: "50%",
          //     border: "1px solid #F58024",
          //     background: "white",
          //   },
          // }, // 6
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "275px",
          //     left: "530px",
          //     width: "30px",
          //     height: "30px",
          //     borderRadius: "50%",
          //     border: "1px solid #F58024",
          //     background: "white",
          //   },
          // }, // f
        ],
        // boxMatch: [
        //   {
        //     boxMatchStyle: {
        //       position: "absolute",
        //       top: "75px",
        //       left: "435px",
        //       width: "30px",
        //       height: "30px",
        //       borderRadius: "50%",
        //       border: "1px solid #F58024",
        //       background: "white",
        //     },
        //   }, // 1
        //   {
        //     boxMatchStyle: {
        //       position: "absolute",
        //       top: "117px",
        //       left: "530px",
        //       width: "30px",
        //       height: "30px",
        //       borderRadius: "50%",
        //       border: "1px solid #F58024",
        //       background: "white",
        //     },
        //   }, // b

        //   {
        //     boxMatchStyle: {
        //       position: "absolute",
        //       top: "117px",
        //       left: "435px",
        //       width: "30px",
        //       height: "30px",
        //       borderRadius: "50%",
        //       border: "1px solid #F58024",
        //       background: "white",
        //     },
        //   }, // 2
        //   {
        //     boxMatchStyle: {
        //       position: "absolute",
        //       top: "197px",
        //       left: "530px",
        //       width: "30px",
        //       height: "30px",
        //       borderRadius: "50%",
        //       border: "1px solid #F58024",
        //       background: "white",
        //     },
        //   }, // d

        //   {
        //     boxMatchStyle: {
        //       position: "absolute",
        //       top: "158px",
        //       left: "435px",
        //       width: "30px",
        //       height: "30px",
        //       borderRadius: "50%",
        //       border: "1px solid #F58024",
        //       background: "white",
        //     },
        //   }, // 3
        //   {
        //     boxMatchStyle: {
        //       position: "absolute",
        //       top: "75px",
        //       left: "530px",
        //       width: "30px",
        //       height: "30px",
        //       borderRadius: "50%",
        //       border: "1px solid #F58024",
        //       background: "white",
        //     },
        //   }, // a

        //   {
        //     boxMatchStyle: {
        //       position: "absolute",
        //       top: "197px",
        //       left: "435px",
        //       width: "30px",
        //       height: "30px",
        //       borderRadius: "50%",
        //       border: "1px solid #F58024",
        //       background: "white",
        //     },
        //   }, // 4
        //   {
        //     boxMatchStyle: {
        //       position: "absolute",
        //       top: "237px",
        //       left: "530px",
        //       width: "30px",
        //       height: "30px",
        //       borderRadius: "50%",
        //       border: "1px solid #F58024",
        //       background: "white",
        //     },
        //   }, // e

        //   {
        //     boxMatchStyle: {
        //       position: "absolute",
        //       top: "237px",
        //       left: "435px",
        //       width: "30px",
        //       height: "30px",
        //       borderRadius: "50%",
        //       border: "1px solid #F58024",
        //       background: "white",
        //     },
        //   }, // 5
        //   {
        //     boxMatchStyle: {
        //       position: "absolute",
        //       top: "158px",
        //       left: "530px",
        //       width: "30px",
        //       height: "30px",
        //       borderRadius: "50%",
        //       border: "1px solid #F58024",
        //       background: "white",
        //     },
        //   }, // c

        //   // {
        //   //   boxMatchStyle: {
        //   //     position: "absolute",
        //   //     top: "275px",
        //   //     left: "435px",
        //   //     width: "30px",
        //   //     height: "30px",
        //   //     borderRadius: "50%",
        //   //     border: "1px solid #F58024",
        //   //     background: "white",
        //   //   },
        //   // }, // 6
        //   // {
        //   //   boxMatchStyle: {
        //   //     position: "absolute",
        //   //     top: "275px",
        //   //     left: "530px",
        //   //     width: "30px",
        //   //     height: "30px",
        //   //     borderRadius: "50%",
        //   //     border: "1px solid #F58024",
        //   //     background: "white",
        //   //   },
        //   // }, // f
        // ],
        answers: ["0-1", "2-3", "4-5", "6-7", "8-9"],
        initialValue: ["0-1"],
        // answers: ["0-1", "2-3", "4-5", "6-7", "8-9", "10-11"],
        // initialValue: ["0-1"],
      },
      Layout: `
          <div style="width: 700px;">

            <div style="font-size: 26px; margin-top: 20px; display: flex;">
              <div>
                <div><strong>1</strong> He listens to music</div>
                <div><strong>2</strong> He eats fast food</div>
                <div><strong>3</strong> On holiday, he wants to</div>
                <div><strong>4</strong> He plays sports in the park</div>
                <div><strong>5</strong> On school days, he wants</div>
              </div>

              <div>
                <input id='0' type= 'boxMatch' />
                <input id='1' type= 'boxMatch' />
                <input id='2' type= 'boxMatch' />
                <input id='3' type= 'boxMatch' />
                <input id='4' type= 'boxMatch' />
              </div>

              <div style="margin-left: 104px">
                <div><strong>a.</strong> stay awake all night.</div>
                <div><strong>b.</strong> on the bus.</div>
                <div><strong>c.</strong> to stay in bed.</div>
                <div><strong>d.</strong> in a café.</div>
                <div><strong>e.</strong> with his friends.</div>
              </div>

              <div>
                <input id='5' type= 'boxMatch' />
                <input id='6' type= 'boxMatch' />
                <input id='7' type= 'boxMatch' />
                <input id='8' type= 'boxMatch' />
                <input id='9' type= 'boxMatch' />
              </div>
            </div>
          </div>
        `,

    },
  },

  4: {
    unit: "Option 3",
    id: "SB7-O3-P114-E4",
    exerciseKey: "img/FriendsPlus/Page114/E4/Key/answerKey.png",

    audio: "",
    video: "",

    component: T6,
    inputSize: 90,
    maxLength: 5,
    titleQuestion: [
      {
        num: "4",
        title: "Look at the sentences from the song. Write <i>True</i> or <i>False</i>. Correct the false sentences.",
        color: "#dd2d38",
      },
    ],
    questions: [
      {
        title: `
          <div style='display:flex'>
              <img style="width: 80%" src='img/FriendsPlus/Page114/E1/1.jpg'/>

              <div>
                <p style='margin-left: 35px;'>
                  I go to school by car.<br/>
                  <i style='color: rgba(120,115,118,1);'>False. He goes to school by bus.</i>
                </p>
                <ol style='margin-left: 20px'>
                 <li>I sit at the front in class. #</li>  
                 <li>My mum wakes me up on school days. #</li>  
                 <li>The holidays start tomorrow. #</li>  
                 <li>I like playing video games. #</li>  
                 <li>I get up late on holiday. #</li>
              </ol>
              </div>
              
          </div>
          `,
        answer: ['false', 'true', 'false', 'true', 'false'],
      },
    ],
  },

  5: {
    unit: "Option 3",
    id: "SB7-O3-P114-E5",
    exerciseKey: "img/FriendsPlus/Page114/E5/Key/answerKey.png",

    audio: "",
    video: "",

    component: T6,
    inputSize: 550,
    maxLength: 100,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')

    hintBox: [{
      src: [
        'How do you … ?',
        'Where do … ?',
        'Does your mum / dad … ?',
        'Do you … ?',
        'How often do … ?',
        'When do … ?'],
      borderColor: "#e3828a",
      width: 525,
    },],

    titleQuestion: [
      {
        num: "5",
        title: "Work in pairs. Find out which sentences in exercise 4 are true for your partner. Use the question words in the box. How many things about your partner are the same as the singer?",
        color: "#dd2d38",
        prefix: "default",
      },
    ],
    questions: [
      {
        title: `
            <div style="width: 500px">
              <hintbox id='0'></hintbox>
              <div style="margin-top: 20px"># <br/> # <br/> # <br/> # <br/> # <br/> # <br/> </div>
            </div>
            
          `,
        answer: [],
      },
    ],
  },
};

export default json;