import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
// import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB7-U1-P14-E1",
    audio: "Audios/1-09 Friends Plus 7.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page14/E1/Key/answerKey.png",

    component: D1,
    isHiddenCheck: true,
    // recorder: true,
    // fontSize: 28,
    maxLength: 7,
    textAlign: "center",
    titleQuestion: [
      {
        num: "1",
        title:
          "<audioimage name='1-09'></audioimage> Complete the phrases in the questionnaire with the verbs in the box. Then listen and check.",
        color: "#e8a360",
        // prefix: "default",
        // prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page14/E1/30.jpg" }],
      [{ url: "img/FriendsPlus/Page14/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page14/E1/2.jpg" },
        { url: "img/FriendsPlus/Page14/E1/3.jpg", input: true, answer: "make" },
        { url: "img/FriendsPlus/Page14/E1/4.jpg" },
        { url: "img/FriendsPlus/Page14/E1/5.jpg", input: true, answer: "meet" },
        { url: "img/FriendsPlus/Page14/E1/6.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page14/E1/7.jpg" },
        { url: "img/FriendsPlus/Page14/E1/8.jpg", input: true, answer: "draw" },
        { url: "img/FriendsPlus/Page14/E1/9.jpg" },
        { url: "img/FriendsPlus/Page14/E1/10.jpg", input: true, answer: "go" },
        { url: "img/FriendsPlus/Page14/E1/11.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page14/E1/12.jpg", input: true, answer: "go" },
        { url: "img/FriendsPlus/Page14/E1/13.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/14.jpg",
          input: true,
          answer: "play",
        },
        { url: "img/FriendsPlus/Page14/E1/15.jpg" },
        { url: "img/FriendsPlus/Page14/E1/16.jpg", input: true, answer: "go" },
        { url: "img/FriendsPlus/Page14/E1/17.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page14/E1/18.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/19.jpg",
          input: true,
          answer: "blog",
        },
        { url: "img/FriendsPlus/Page14/E1/20.jpg" },
        { url: "img/FriendsPlus/Page14/E1/21.jpg", input: true, answer: "do" },
        { url: "img/FriendsPlus/Page14/E1/22.jpg" },
      ],
      [
        {
          url: "img/FriendsPlus/Page14/E1/23.jpg",
          input: true,
          answer: "collect",
        },
        { url: "img/FriendsPlus/Page14/E1/24.jpg" },
        {
          url: "img/FriendsPlus/Page14/E1/25.jpg",
          input: true,
          answer: "bake",
        },
        { url: "img/FriendsPlus/Page14/E1/26.jpg" },
        { url: "img/FriendsPlus/Page14/E1/27.jpg", input: true, answer: "go" },
        { url: "img/FriendsPlus/Page14/E1/28.jpg" },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB7-U1-P14-E1",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "2",
        title:
          "Do the questionnaire. Do you like being alone, being creative, or going out? Compare your answers with your partner's.",
        color: "#e8a360",
      },
    ],
    questions: [
      {
        title: ``,
        answer: [],
      },
    ],
  },

  3: {
    unit: "Unit 1",
    id: "SB7-U1-P14-E3",
    audio: "Audios/1-10 Friends Plus 7.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page14/E3/Key/answerKey.png",
    inputSize: 320,
    checkUppercase: true,
    maxLength: 26,
    // recorder: true,

    // hideBtnFooter: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "<audioimage name='1-10'></audioimage> Look at the photos of Abbie and Niall. What are their hobbies? Which hobby is relaxing?.",
        color: "#e8a360",
        // prefix: "default",
        // prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
      },
    ],
    questions: [
      {
        title: `
         <div style='display: flex;margin: 0px 100px'>
            <div>
              <img src='img/FriendsPlus/Page14/E3/1.jpg'>
              <div><b>1.</b> What are their hobbies?<br>#</div>
            </div>
            <div style='margin-left: 50px'>
              <img src='img/FriendsPlus/Page14/E3/2.jpg'>
              <div><b>2.</b> Which hobby is relaxing?<br>#<br>#</div>
            </div>
         </div>
        `,
        answer: [
          "Abbie paints trainers.",
          "Niall makes videos.",
          "Abbie’s hobby is relaxing.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SB7-U1-P14-E4",
    audio: "Audios/1-10 Friends Plus 7.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page14/E4/Key/answerKey.png",
    inputSize: 570,
    checkUppercase: true,
    maxLength: 46,
    // recorder: true,

    // hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='1-10'></audioimage> Listen again and answer the questions.",
        color: "#e8a360",
        // prefix: "default",
        // prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
      },
    ],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page14/E4/1.jpg'>
         <div>
            <div><b>1.</b>Where does Abbie buy the trainers and paints?<br>#</div>
            <div><b>2.</b>Why does Abbie like painting?<br>#</div>
            <div><b>3.</b>What do Abbie’s friends think of trainers?<br>#</div>
            <div><b>4.</b>Does Niall write the stories for his videos?<br>#</div>
            <div><b>5.</b>How do his friends hlep with the videos?<br>#</div>
            <div><b>6.</b>How often does he make the videos?<br>#</div>
         </div>
        `,
        answer: [
          "She buys them online.",
          "Because it’s a very relaxing hobby",
          "They like them.",
          "Yes, he does.",
          "They act in the videos.",
          "He makes the videos two to three times a year.",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB7-U1-P14-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    // fontSize: 28,
    maxLength: 7,
    titleQuestion: [
      {
        num: "5",
        title:
          "Work in pairs. Which hoppy do you prefer. Abbies’s or Niall’s? Why?.",
        color: "#e8a360",
        prefix: "default",
        // prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
      },
    ],
    questionImage: [],
  },
};

export default json;
