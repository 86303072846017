import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Puzzles And Games',
		id: 'SB7-PG-P101-E1',
		exerciseKey: 'img/FriendsPlus/Page101/E1/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 85,
		maxLength: 6,
		stylesTextInput: {
			fontSize: 23,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '#2e2919',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px; background-color: rgba(255, 216, 111, 1); padding: 10px; border-radius: 20px;">
						<h1 style="display: flex;">
							<span>1</span>
							<div style="margin-left: 10px;">
								A BOARD GAME. Work in groups. Follow the instructions.
							</div>
						</h1>

                      <div style="margin-bottom: 10px; margin-left: 30px;">
                        <img style="max-width: 10%" src="img/FriendsPlus/Page101/E1/1.jpg" />
                        <img style="max-width: 10%" src="img/FriendsPlus/Page101/E1/2.jpg" style="margin-left: 20px;" />
                      </div>

                        <div style="background-color: rgba(162, 220, 231, 1); padding: 10px; border-radius: 10px;">
                          <ul>
                            <li>Each player chooses a different colour to start on</li>
                            <li>
                              Take turns tossing a coin. If the coin lands on 'heads',
                              move two squares clockwise. If the coin lands on 'tails',
                              move two squares.
                            </li>
                            <li>
                              Match your means of transport with a verb, then make a 
                              true sentence, for exampale: A car runs on the street.
                            </li>
                            <li>
                              The winner is the first player to reach their start colour.
                            </li>
                          </ul>
                        </div>

                        <div style="margin-top: 10px; background-color: white; padding: 10px;">
                          <div style="display: flex;">
                            <div style="background-color: rgba(99, 175, 225, 1); flex: 1 1 0; height: 127px; display: flex; justify-content: center; align-items: center;">car</div>
                            <div style="background-color: rgba(252, 195, 124, 1); flex: 1 1 0; height: 127px; display: flex; justify-content: center; align-items: center; margin-left: 10px;">helicopter</div>
                            <div style="background-color: rgba(111, 196, 137, 1); flex: 1 1 0; height: 127px; display: flex; justify-content: center; align-items: center; margin-left: 10px;">boat</div>
                            <div style="background-color: rgba(234, 151, 133, 1); flex: 1 1 0; height: 127px; display: flex; justify-content: center; align-items: center; margin-left: 10px;">bus</div>
                          </div>

                          <div style="display: flex;  margin-top: 10px;">
                            <div style="background-color: rgba(165, 152, 118, 1); flex: 1 1 0; height: 127px; display: flex; justify-content: center; align-items: center;">bicycle</div>
                            <div style="background-color: rgba(255, 255, 255, 1); flex: 2 1 0; height: 127px; display: flex; justify-content: center; align-items: center; flex-direction: column; margin-left: 10px;">
                              <span>to run</span>
                              <span>to fly</span>
                              <span>to sall</span>
                            </div>
                            <div style="background-color: rgba(188, 182, 218, 1); flex: 1 1 0; height: 127px; display: flex; justify-content: center; align-items: center; margin-left: 20px;">taxi</div>
                          </div>

                          <div style="display: flex; margin-top: 10px;">
                            <div style="background-color: rgba(226, 115, 148, 1); flex: 1 1 0; height: 127px; display: flex; justify-content: center; align-items: center;">plane</div>
                            <div style="background-color: rgba(198, 218, 93, 1); flex: 1 1 0; height: 127px; display: flex; justify-content: center; align-items: center; margin-left: 10px;">balloon</div>
                            <div style="background-color: rgba(255, 223, 110, 1); flex: 1 1 0; height: 127px; display: flex; justify-content: center; align-items: center; margin-left: 10px;">ship</div>
                            <div style="background-color: rgba(130, 174, 185, 1); flex: 1 1 0; height: 127px; display: flex; justify-content: center; align-items: center; margin-left: 10px;">ferry</div>
                          </div>
                        </div>

                        
                    </div>
                `,
				answer: [],
			},
		],
	},

	2: {
		unit: 'Puzzles And Games',
		id: 'SB7-PG-P101-E2',
		exerciseKey: 'img/FriendsPlus/Page101/E2/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 490,
		maxLength: 45,
		stylesTextInput: {
			fontSize: 23,
		},

		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '#2e2919',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                      <div style="background-color: rgba(195,185,219,1); padding: 20px; border-radius: 20px;">
						<h1 style="display: flex;">
							<span>2</span>
							<div style="margin-left: 10px;">
								Order the blocks of letters to complete the sentences.
							</div>
					  	</h1>
						
                        <ol>
                          <li>(My br) (s to b) (new c) (other w) (uy a) (ant) (ar.)</li>
                          <li>(He sto) (o bu) (coffee.) (y a c) (pped t)</li>
                          <li>(She i) (o p) (ination.) (s st) (ass t) (udying h) (he exam) (ard t)</li>
                        <ol>
                      </div>

                      <div style="background-color: rgb(234 232 240); padding: 20px; border-radius: 20px; margin-top: 30px;">
                          <h2 style="color: rgb(50, 50, 93); font-size: 24px;">Answer:</h2>
                          <ol>
                            <li>#</li>
                            <li>#</li>
                            <li>#</li>
                          </ol>
                        </div>
                    </div>
                `,
				answer: [
					'My brother wants to buy a new car.',
					'He stopped to buy a coffee.',
					'She is studying hard to pass the examination.',
				],
			},
		],
	},

	3: {
		unit: 'Puzzles And Games',
		id: 'SB7-PG-P101-E3',
		exerciseKey: 'img/FriendsPlus/Page101/E3/Key/answerKey.png',
		audio: '',
		video: '',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '',
				title: `
				`,
				color: '#2e2919',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					borderRadius: '50%',
					borderColor: 'transparent',
					color: 'black',
				},
				selectWordStyle: {
					padding: 5,
					border: 'solid 2px',
					borderColor: '#00aeef',
					color: 'black',
				},
				limitSelect: 1,
				listWords: [
					`
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a._coal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; b.power <br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; c._hydro_power &nbsp;&nbsp;&nbsp; d.solar_energy
					`,
					`
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a._coal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; b.petrol <br/>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; c._wind_power &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; d.natural_gas
					`,
					`
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a._wind_power &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; b._hydro_power <br/> 
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; c._solar_energy &nbsp;&nbsp;&nbsp;&nbsp; d._nuclear_power
					`,
					`
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a._wind_power &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; b._hydro_power <br/> 
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; c._solar_energy &nbsp;&nbsp;&nbsp;&nbsp; d._nuclear_power
					`,
				],

				answers: ['2-40', '3-40', '0-16', '1-38'],
				initialValue: [],
			},
			Layout: `
				<div style="background-color: rgba(237,235,112,1); padding: 20px; border-radius: 20px;">
					<h1 style="display: flex;">
						<span>3</span>
						<div style="margin-left: 10px;">
						Circle the correct answer.
						</div>
					</h1>

					<div style="margin-left: 25px;">
						1. What causes pollution? <br/>
						<span style="margin-left:20px;"></span><input id='0' type='Circle' />
					</div>

					<div style="margin-left: 25px; margin-top: 15px;">
						2. What lasts forever? <br/>
						<span style="margin-left:20px;"></span><input id='1' type='Circle' />
					</div>

					<div style="margin-left: 25px; margin-top: 15px;">
						3. What is from the sun? <br/>
						<span style="margin-left:20px;"></span><input id='2' type='Circle' />
					</div>

					<div style="margin-left: 25px; margin-top: 15px;">
						4. What does not need tubines? <br/>
						<span style="margin-left:20px;"></span><input id='3' type='Circle' />
					</div>
				</div>
          `,
		},
	},

	4: {
		unit: 'Puzzles And Games',
		id: 'SB7-PG-P101-E4',
		exerciseKey: 'img/FriendsPlus/Page101/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 85,
		maxLength: 6,
		stylesTextInput: {
			fontSize: 23,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title: `
					
        		`,
				color: '#2e2919',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 600px; background-color: rgba(206,224,116,1); padding: 15px; border-radius: 20px; margin-top: 20px;">
						<h1 style="display: flex;">
							<span>4</span>
							<div style="margin-left: 10px;">
								FIND SOMEONE WHO … Walk around the classroom and ask questions.
								Write a different name for each question.<br/>
								<i>Find someone who wants to ...</i>
							</div>
						</h1>

						<div style="display: flex;">
							<div style="flex: 1 1 0; background-color: white; padding: 10px;">
								<span>
									win a prize.
								</span>
								<div>
									<img src="img/FriendsPlus/Page101/E4/1.jpg" style="max-width: 100%" />
								</div>
							</div>

							<div style="flex: 1 1 0; background-color: white; padding: 10px; margin-left: 10px;">
								<span>
									feel better.
								</span>
								<div>
									<img src="img/FriendsPlus/Page101/E4/2.jpg" style="max-width: 100%" />
								</div>
							</div>
						</div>

						<div style="display: flex; margin-top: 10px;">
							<div style="flex: 1 1 0; background-color: white; padding: 10px;">
								<span>
									stay up all night.
								</span>
								<div>
									<img src="img/FriendsPlus/Page101/E4/3.jpg" style="max-width: 100%" />
								</div>
							</div>

							<div style="flex: 1 1 0; background-color: white; padding: 10px; margin-left: 10px;">
								<span>
									try horse riding.
								</span>
								<div>
									<img src="img/FriendsPlus/Page101/E4/4.jpg" style="max-width: 100%" />
								</div>
							</div>
						</div>

						<div style="display: flex; margin-top: 10px;">
							<div style="flex: 1 1 0; background-color: white; padding: 10px;">
								<span>
									try an unusual food.
								</span>
								<div>
									<img src="img/FriendsPlus/Page101/E4/5.jpg" style="max-width: 100%" />
								</div>
							</div>

							<div style="flex: 1 1 0; background-color: white; padding: 10px; margin-left: 10px;">
								<span>
									ride on a roller coaster.
								</span>
								<div>
									<img src="img/FriendsPlus/Page101/E4/6.jpg" style="max-width: 100%" />
								</div>
							</div>
						</div>
					</div>
                `,
				answer: [],
			},
		],
	},

	5: {
		unit: 'Puzzles And Games',
		id: 'SB7-PG-P101-E5',
		exerciseKey: 'img/FriendsPlus/Page101/E5/Key/answerKey.png',
		audio: 'Audios/2-32 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 90,
		maxLength: 5,
		stylesTextInput: {
			fontSize: 23,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title: `
					
                `,
				color: '#2e2919',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 650px; background-color: rgba(2,181,201,1); padding: 30px; border-radius: 20px;">
						<h1 style="display: flex; color: white;">
							<span>5</span>
							<div style="margin-left: 10px;">
								GUESS WHO? Work in groups. Follow the instructions.
							</div>
						</h1>

						<div style="background-color: white; border-radius: 20px; padding: 10px; box-shadow: 10px 10px 20px rgb(89,92,92); transform: skew(2deg, -2deg); margin-top: 20px;">
							<div style="background-color: rgba(251, 174, 22, 1); border-radius: 20px; padding: 10px;">
								<ul>
									<li>
										On a piece of paper, each student completes 
										the sentence below and folds it in two.
									</li>
									<li>
										Mix up the group's pieces of paper and each 
										student takes one.
									</li>
									<li>
										Takes turns reading out the sentences and 
										guessing who wrote them.
									</li>
								</ul>

								<div style="display: flex; justify-content: center; align-items: center;">
									<img src="img/FriendsPlus/Page101/E5/1.jpg" style="max-width: 90%" />
								</div>
							</div>
						</div>
						
					</div>
                `,
				answer: [],
			},
		],
	},
};

export default json;
