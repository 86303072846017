import TB2 from '../../components/ExcerciseTypes/Table/TB2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Culture',
		id: 'SB7-C-P100-E1',
		exerciseKey: 'img/FriendsPlus/Page100/E1/Key/answerKey.png',
		audio: 'Audios/2-31 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 250,
		maxLength: 19,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '1',
				title: `
                    <audioimage name='2.31'></audioimage>
                    Read and listen to the text. 
                    Write dates before the following statements.
                `,
				color: '#33bed2',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <p style="display: flex;">
                            <span># </span>
                            <span style="margin-left: 10px;">
                                The world celebrated the 100<sup>th</sup> anniversary of the first heavier-than-air craft.
                            </span>
                        </p>

                        <p style="display: flex;">
                            <span># </span>
                            <span style="margin-left: 10px;">
                                The first airplane took off.
                            </span>
                        </p>

                        <p style="display: flex;">
                            <span># </span>
                            <span style="margin-left: 10px;">
                                Léon Delagrange became the first flight passenger.
                            </span>
                        </p>

                        <p style="display: flex;">
                            <span># </span>
                            <span style="margin-left: 10px;">
                                The first scheduled air service began.
                            </span>
                        </p>
                    </div>
                    
                `,
				answer: [
					'December 17th, 2003',
					'December 17th, 1903',
					'1908',
					'January 1st, 1914',
				],
			},
		],
	},

	2: {
		unit: 'Culture',
		id: 'SB7-C-P100-E2',
		exerciseKey: 'img/FriendsPlus/Page100/E2/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 250,
		maxLength: 21,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
                    Match the words in <span style="color: blue;">blue</span> 
                    in the text with definitions.
                `,
				color: '#33bed2',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <div style="background-color: rgba(254,218,123,1); padding: 10px;">
                            <div style="background-color: white; padding: 10px;">
                                <div>
                                    <img src="img/FriendsPlus/Page100/E2/1.jpg" style="max-width: 100%" />
                                </div>

                                <h2 style="text-transform: uppercase; color: rgba(243,155,38,1); text-shadow: 2px 2px 5px grey; text-align: center; margin: 20px 0; font-size: 36px; transform: skew(0deg, -5deg);">
                                    High Flyers
                                </h2>

                                <div>
                                    <p>
                                        In 2003, the world celebrated the 100<sup>th</sup> 
                                        anniversary of the first aircraft. Orville and 
                                        Wilbur Wright in the USA <span style="color: blue;">came up with</span> the first 
                                        <span style="color: blue;">heavier-than-air</span> plane. It took them more than 
                                        4 years to make their idea come true. Its historic 
                                        12-second flight on December 17<sup>th</sup>, 1903 was 
                                        the starting point of the world aviation.
                                    </p>
                                    
                                    <p>
                                        In 1908, Léon Delagrange became the first air
                                        passenger when he flew with French pilot Henri
                                        Farman in Paris. Six years later, on January 1<sup>st</sup>,
                                        the <span style="color: blue;">first scheduled air</span> service operated between
                                        St. Petersburg in Russia and Tampa in Florida,
                                        the USA. From here on aviation started to
                                        develop very fast.
                                    </p>
                                    
                                    <p>
                                        Aviation becomes a popular means of transport.
                                        <span style="color: blue;">Innovations</span> in material and engine-making
                                        technology help make lighter, stronger and
                                        safer kinds of planes. Travelling by air is more
                                        <span style="color: blue;">affordable</span>. People can fly anywhere like a bird.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div style="margin-top: 20px; background-color: rgba(227,242,249,1); padding: 20px; border-radius: 10px;">
                            <ol>
                                <li>Weighing more than the air<br/> #</li>
                                <li>Becoming cheaper and cheaper<br/> #</li>
                                <li>Produce something<br/> #</li>
                                <li>New ideas or methods<br/> #</li>
                                <li>A routine air transport service according to a timetable<br/> #</li>
                            </ol>
                        </div>
                        
                    </div>
                `,
				answer: [
					'Heavier-than-air',
					'Affordable',
					'Came up with',
					'Innovations',
					'Scheduled air service',
				],
			},
		],
	},

	3: {
		unit: 'Culture',
		id: 'SB7-C-P100-E3',
		exerciseKey: 'img/FriendsPlus/Page100/E3/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 470,
		maxLength: 115,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '3',
				title: `
                    Read the text again and answer the questions.
                `,
				color: '#33bed2',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <div style="background-color: rgba(254,218,123,1); padding: 10px;">
                            <div style="background-color: white; padding: 10px;">
                                <div>
                                    <img src="img/FriendsPlus/Page100/E2/1.jpg" style="max-width: 100%" />
                                </div>

                                <h2 style="text-transform: uppercase; color: rgba(243,155,38,1); text-shadow: 2px 2px 5px grey; text-align: center; margin: 20px 0; font-size: 36px; transform: skew(0deg, -5deg);">
                                    High Flyers
                                </h2>

                                <div>
                                    <p>
                                        In 2003, the world celebrated the 100<sup>th</sup> 
                                        anniversary of the first aircraft. Orville and 
                                        Wilbur Wright in the USA <span style="color: blue;">came up with</span> the first 
                                        <span style="color: blue;">heavier-than-air</span> plane. It took them more than 
                                        4 years to make their idea come true. Its historic 
                                        12-second flight on December 17<sup>th</sup>, 1903 was 
                                        the starting point of the world aviation.
                                    </p>
                                    
                                    <p>
                                        In 1908, Léon Delagrange became the first air
                                        passenger when he flew with French pilot Henri
                                        Farman in Paris. Six years later, on January 1<sup>st</sup>,
                                        the <span style="color: blue;">first scheduled air</span> service operated between
                                        St. Petersburg in Russia and Tampa in Florida,
                                        the USA. From here on aviation started to
                                        develop very fast.
                                    </p>
                                    
                                    <p>
                                        Aviation becomes a popular means of transport.
                                        <span style="color: blue;">Innovations</span> in material and engine-making
                                        technology help make lighter, stronger and
                                        safer kinds of planes. Travelling by air is more
                                        <span style="color: blue;">affordable</span>. People can fly anywhere like a bird.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div style="margin-top: 20px; background-color: rgba(227,242,249,1); padding: 20px; border-radius: 10px;">
                            <ol>
                                <li>Who made the first airplane?<br/> #</li>
                                <li>Who was the first air passenger?<br/> #</li>
                                <li>How long did the first flight on December 17<sup>th</sup>, 1903 last?<br/> #</li>
                                <li>What was the first airway?<br/> #</li>
                                <li>Why is travelling by air cheaper now?<br/> #</li>
                            </ol>
                        </div>
                        
                    </div>
                `,
				answer: [
					'Orville and Wilbur Wright',
					'Leon Delagrange',
					'12 seconds',
					'St. Petersburg in Russia and Tampa in Florida, the USA',
					'Because innovations in material and engine-making technology help make lighter, stronger and safer kinds of planes.',
				],

				// answer: [
				// 	'Orville and Wilbur Wright',
				// 	'Leon Delagrange',
				// 	'12 seconds',
				// 	'St. Petersburg in Russia and ',
				// 	'Tampa in Florida, the USA',
				// 	'Because innovations in material and ',
				// 	'engine-making technology help make ',
				// 	'lighter, stronger and safer kinds of planes.',
				// ],
			},
		],
	},

	4: {
		unit: 'Culture',
		id: 'SB7-C-P100-E4',
		exerciseKey: 'img/FriendsPlus/Page100/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: TB2,
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '4',
				title: `

                    Work in groups to complete the table. 
                    Use the following questions to help you.
                `,
				color: '#33bed2',
				prefix: { icons: ['far fa-comment'], text: 'YOUR CULTURE' },
			},
		],
		questions: [
			{
				title: `
					<div style="width: 600px; margin-top: 20px;">
                        <div>
                            <p>
                                Where do you often go on your holidays?
                            </p>
                            
                            <p>
                                How do you go there?
                            </p>
                            
                            <p>
                                How long does it take?
                            </p>
                            
                            <p>
                                How much do you spend on the fares?
                            </p>
                        </div>
                    </div>
                `,
				answer: [],
			},
		],
		tb2Style: { width: 700 },
		type: 1,
		data: [
			{
				title: 'Destination',
				content: ['#', '#', '#', '#', '#', '#'],
				answers: [],
				commonStyle: { backgroundColor: 'white' },
				titleStyle: { backgroundColor: '#7acddf' },
				contentStyle: {},
			},
			{
				title: 'Means of transport',
				content: ['#', '#', '#', '#', '#', '#'],
				answers: [],
				commonStyle: { backgroundColor: 'white' },
				titleStyle: { backgroundColor: '#7acddf' },
				contentStyle: {},
			},
			{
				title: 'Duration',
				content: ['#', '#', '#', '#', '#', '#'],
				answers: [],
				commonStyle: { backgroundColor: 'white' },
				titleStyle: { backgroundColor: '#7acddf' },
				contentStyle: {},
			},
			{
				title: 'Fares',
				content: ['#', '#', '#', '#', '#', '#'],
				answers: [],
				commonStyle: { backgroundColor: 'white' },
				titleStyle: { backgroundColor: '#7acddf' },
				contentStyle: {},
			},
		],
	},

	5: {
		unit: 'Culture',
		id: 'SB7-C-P100-E5',
		exerciseKey: 'img/FriendsPlus/Page100/E5/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 470,
		maxLength: 115,
		textareaStyle: { width: 1000 },

		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
                    Work in pairs. Plan a flight for
                    your holiday. Use the following phrases.
                `,
				color: '#33bed2',
				prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' },
			},
		],

		questions: [
			{
				title: `
                    <div style="width: 530px; margin-top: 20px; background-color: rgba(227,242,249,1); padding: 20px; border-radius: 10px;">
                        <p>
                            ... and I are going to spend our holiday in ...
                        </p>
                        
                        <p>
                            We will fly ...
                        </p>
                        
                        <p>
                            The plane takes off at ... and lands at ...
                        </p>
                        
                        <p>
                            It takes us ...
                        </p>
                        
                        <p>
                            We hope ...
                        </p>
                    </div>
										<div style="margin-top: 20px;"><textarea id="0" rows="5" ></textarea></div>

                `,
				answer: [],
			},
		],
	},
};

export default json;
