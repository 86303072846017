import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  13: { // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P81-E13',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page81/E13/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '13',
        title:
          'Complete the sentences with <i>must, mustn’t, should</i> or <i>shouldn’t</i>.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 140,
    //hiện icon đáp án đúng
    // isHiddenCheck:true,
    // chữ hoa khác chữ thường
    // checkUppercase: true,

    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>We <b>#</b> swim in the sea today. The weather is very bad and there’s a red flag.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>The exams are finished now. You <b>#</b> relax and have a nice time with your friends.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>You <b>#</b> eat in the library. That’s the rule.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>We <b>#</b> arrive on time for lessons every day. It’s very important.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>You <b>#</b> go to bed late. It isn’t a good idea.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>We <b>#</b> cycle to school every day. It’s cheaper than the bus.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 7 </b></div>
          <div>You <b>#</b> buy that book. It isn’t very good.</div>
        </div>
        `,
        answer: [
          "mustn’t",
          "should",
          "mustn’t",
          "must",
          "shouldn’t",
          "should",
          "shouldn’t",
        ],
      },
    ],
  },
  14: { // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P81-E14',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page81/E14/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '14',
        title:
          'Complete the dialogue with the phrases. There is one extra phrase.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 240,
    checkUppercase: true,
    hintBox: [
      {
        src: [
          "make sure that",
          "I need",
          "You’ll be fine",
          "it’s important to",
          "Try not to",
          "Remember to",
        ],
        borderColor: "#333",
        width: 600,
      },
    ],
    questions: [
      {
        title: `
        <div style='display: flex; justify-content: center;'><hintbox id= '0' ></hintbox></div>
        <div style='display: flex;'>
          <div style='flex: 1;'><b> Instructor </b></div>
          <div style='flex: 5'> Hi! Welcome to the climbing wall. There are a few important things to say before you start. Firstly<b><sup>1</sup>#</b> watch some climbing videos on YouTube. They’re useful.</div>
        </div>
        <div style='display: flex;'>
          <div style='flex: 1;'><b> Ethan </b></div>
          <div style='flex: 5'>Cool. <b><sup>2</sup>#</b> to do that.</div>
        </div>
        <div style='display: flex;'>
          <div style='flex: 1;'><b> Instructor </b></div>
          <div style='flex: 5'>Then, <b><sup>3</sup>#</b> you always wear a helmet when you’re here.</div>
        </div>
        <div style='display: flex;'>
          <div style='flex: 1;'><b> Ethan </b></div>
          <div style='flex: 5'>OK. I’ll put my helmet on now</div>
        </div>
        <div style='display: flex;'>
          <div style='flex: 1;'><b> Instructor </b></div>
          <div style='flex: 5'>And keep away from other climbers below you on the wall. <b><sup>4</sup>#</b>  kick their hands.</div>
        </div>
        <div style='display: flex;'>
          <div style='flex: 1;'><b> Ethan </b></div>
          <div style='flex: 5'>OK, but what do you think will happen if I fall?</div>
        </div>
        <div style='display: flex;'>
          <div style='flex: 1;'><b> Instructor </b></div>
          <div style='flex: 5'>Don’t worry, you won’t fall. We use ropes. <b><sup>5</sup>#</b> Don’t worry, you won’t fall. We use ropes.</div>
        </div>
        `,
        answer: [
          "it's important to",
          "I need",
          "make sure that",
          "Try not to",
          "You'll be fine",
        ],
      },
    ],
  },
  15: { // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P81-E15',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page81/E15/key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '15',
        title:
          'Put the paragraphs in the suitable order.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 60,
    checkUppercase: true,
    questions: [
      {
        title: `
          <div style='width: 100%;'><img src='img/FriendsPlus/Page81/E15/1.jpg' alt='' style='border-radius: 30px;'/></div>
          <div style='display: flex; gap:30px; justify-content: space-around; margin-top:20px;'>
            <div><b>1</b> #</div>
            <div><b>2</b> #</div>
            <div><b>3</b> #</div>
            <div><b>4</b> #</div>
          </div>
        `,
        answer: [
          "C",
          "D",
          "A",
          "B",
        ],
      },
    ],
  },

}

export default json;