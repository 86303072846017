import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	14: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P105-E14',
		exerciseKey: 'img/FriendsPlus/Page105/E14/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 50,
		maxLength: 2,
		stylesTextInput: {
			fontSize: 24,
		},
		titleQuestion: [
			{
				num: '14',
				title: `
                    Write <i>to</i> or <i>x</i> before the verbs. 
              `,
				color: '#4c3d9b',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <ol>
                            <li>I really want # go out.</li>
                            <li>She must # try her best.</li>
                            <li>Do you need # stay?</li>
                            <li>We don't have # pay in cash.</li>
                            <li>Would you like # dance?</li>
                            <li>You cannot # do the test.</li>
                            <li>They shouldn't # eat much sugar.</li>
                            <li>Did you want # find your pen?</li>
                        </ol>
                    </div>
                `,
				answer: ['to', 'x', 'to', 'to', 'to', 'x', 'x', 'to'],
			},
		],
	},

	15: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P105-E15',
		exerciseKey: 'img/FriendsPlus/Page105/E15/Key/answerKey.png',

		audio: '',
		video: '',

		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 24,
		},

		titleQuestion: [
			{
				num: '15',
				title: `
                    Use <i>some, any</i> or <i>a lot of / lots of</i> to complete the sentences.
              `,
				color: '#4c3d9b',
			},
		],

		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <ol>
                            <li>Jane doesn't have # cash for that expensive dress.</li>
                            <li>Are there # solar panels on the roof of this building?</li>
                            <li>I would like # hot chocolate milk and an orange please.</li>
                            <li>He's got # money in his wallet because he won a lottery.</li>
                            <li># of the windmills are broken, so they are replacing them.</li>
                            <li>There are # skyscrapers in Dubai.</li>
                        </ol>

                        <div style="margin-top: 30px; display: flex; justify-content: center;">
                            <img src="img/FriendsPlus/Page105/E15/1.jpg" style="max-width: 100%" />
                        </div>
                    </div>
                `,
				answer: [
					'any',
					'any',
					'some',
					'a lot of / lots of',
					'Some',
					'a lot of / lots of',
				],
			},
		],
	},

	16: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P105-E16',
		exerciseKey: 'img/FriendsPlus/Page105/E16/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 140,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 24,
		},
		titleQuestion: [
			{
				num: '16',
				title: `
                    Choose the correct words.
                `,
				color: '#4c3d9b',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px;">
                        <div style="background-color: rgba(228, 226, 239, 1); padding: 5px; border-radius: 20px; margin-bottom: 20px;">
                            <ol>
                                <li>
                                <ol type="a" style="display: flex;">
                                        <li style="width: 200px;">Where</li>
                                        <li style="width: 200px;">What</li>
                                        <li style="width: 200px;">How</li>
                                    </ol>
                                </li>
                                
                                <li>
                                <ol type="a" style="display: flex;">
                                        <li style="width: 200px;">making</li>
                                        <li style="width: 200px;">doing</li>
                                        <li style="width: 200px;">checking</li>
                                    </ol>
                                </li>
                                
                                <li>
                                <ol type="a" style="display: flex;">
                                        <li style="width: 200px;">You've</li>
                                        <li style="width: 200px;">You're</li>
                                        <li style="width: 200px;">You'll</li>
                                    </ol>
                                </li>
                                
                                <li>
                                <ol type="a" style="display: flex;">
                                        <li style="width: 200px;">How much</li>
                                        <li style="width: 200px;">How many</li>
                                        <li style="width: 200px;">How long</li>
                                    </ol>
                                </li>
                                
                                <li>
                                <ol type="a" style="display: flex;">
                                        <li style="width: 200px;">in</li>
                                        <li style="width: 200px;">at</li>
                                        <li style="width: 200px;">by</li>
                                    </ol>
                                </li>
                                
                                <li>
                                <ol type="a" style="display: flex;">
                                        <li style="width: 200px;">seat</li>
                                        <li style="width: 200px;">flight</li>
                                        <li style="width: 200px;">gift</li>
                                    </ol>
                                </li>
                                
                            </ol>
                        </div>

						<div>
							<div style="display: flex;">
								<span style="margin-right: 20px">Agent</span>
								<span>Good afternoon! <sup>1</sup># are you flying to today?</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 34px">Danh</span>
								<span>I'm flying to Nha Trang.</span>
							</div>

							<div style="margin-top: 5px; display: flex;">
								<span style="margin-right: 20px">Agent</span>
								<span>May I see your passport please?</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 34px">Danh</span>
								<span>Yes, sure. Here you are.</span>
							</div>

							<div style="margin-top: 5px; display: flex;">
								<span style="margin-right: 20px">Agent</span>
								<span>Are you <sup>2</sup># any bags?</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 34px">Danh</span>
								<span>Just this one. Would you please mark this bag as 'fragile'?</span>
							</div>

							<div style="margin-top: 5px; display: flex;">
								<span style="margin-right: 20px">Agent</span>
								<span>OK, no problem. Well, this bag is overweight. <br/><sup>3</sup># pay extra for this.</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 34px">Danh</span>
								<span><sup>4</sup># is this fee?</span>
							</div>

							<div style="margin-top: 5px; display: flex;">
								<span style="margin-right: 20px">Agent</span>
								<span>320,000 VND.</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 34px">Danh</span>
								<span>OK. Here you are.</span>
							</div>

							<div style="margin-top: 5px; display: flex;">
								<span style="margin-right: 20px">Agent</span>
								<span>Here is your boarding pass. Your seat number is 26E. Your flight leaves from Gate 15A and begins boarding <sup>5</sup># 15:20. Have a nice <sup>6</sup>#.</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 34px">Danh</span>
								<span>Thanks.</span>
							</div>
						</div>

                        <div style="margin-top: 30px; display: flex; justify-content: center;">
                            <img src="img/FriendsPlus/Page105/E16/1.jpg" style="max-width: 100%" />
                        </div>
                        
                    </div>
                `,
				answer: ['Where', 'checking', "You've", 'How much', 'at', 'flight'],
			},
		],
	},

	17: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P105-E17',
		exerciseKey: 'img/FriendsPlus/Page105/E17/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 420,
		maxLength: 35,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 24,
		},
		hintBox: [
			{
				src: [
					'because (x2)',
					'Dear Maria',
					'Hi Maria',
					"I've had some bad luck.",
					"I've had some good luck.",
					"I've sprained my ankle.",
					'Write back soon.',
					'so (x2)',
					'Thanks for your email.',
					'Have you done anything interesting?',
				],
				borderColor: '#4c3d9b',
				width: 600,
			},
		],

		titleQuestion: [
			{
				num: '17',
				title: `
					Complete the text with the phrases. 
					There are two extra phrases.
              `,
				color: '#4c3d9b',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 650px; margin-top: 20px;">
						<div style="display: flex; justify-content: center;"><hintbox id='0'></hintbox></div>
						
						<div style="margin-top: 30px;"></div>
                        <p><sup>1</sup>#</p>
						<p><sup>2</sup># How's it going?</p>
						<p><sup>3</sup># Guess what?</p>
						<p>
							<sup>4</sup># I'm really upset. 
						</p>
						<p>
							<sup>5</sup># It happened yesterday 
							when I was running through the park with John. 
							It was raining <sup>6</sup># I couldn't see very 
							well and I fell over a branch on the path. 
							John called my dad <br/><sup>7</sup># my ankle 
							really hurt. He arrived quickly and took me home. 
							It's a bad sprain, <sup>8</sup>#. I can't walk very  
							far today. I'm really bored <br/><sup>9</sup>#. 
							can't go out at the moment. 
						</p>
						<p><sup>10</sup>#</p>
						<p>Ethan</p>
                        <div style="margin-top: 30px; display: flex; justify-content: center;">
                            <img src="img/FriendsPlus/Page105/E17/1.jpg" style="max-width: 100%" />
                        </div>
                    </div>  
                `,

				answer: [
					'Hi Maria,',
					'Thanks for your email.',
					'Have you done anything interesting?',
					"I've had some bad luck.",
					"I've sprained my ankle.",
					'so',
					'because',
					'so',
					'because',
					'Write back soon,',
				],
			},
		],
	},
};

export default json;
