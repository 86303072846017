import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB7SB-U1-P17-E1',
    audio: '',
    video: '',
    component: T6,
    // hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page17/E1/Key/answerKey.png',
    inputSize: 330,
    checkDuplicated: true,
    maxLength: 24,
    stylesTextInput: { borderBottom: 'dotted 1px' },
    // titleImage: 'img/FriendsPlus/Page10/E1/title.jpg',
    titleQuestion: [
      {
        num: '1',
        title: 'Read the profile and find three things which Quỳnh Anh likes.',
        color: '#12a15f',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page17/E1/1.jpg'>
          <div style='margin: 20px;line-height: 60px'>
            <b>1</b>#<br><b>2</b>#<br><b>3</b>#
          </div>
          `,
        answer: [
          'meeting friends downtown / badminton / athletics / piano / watching music videos / funny programmes / listening to most music',
          'meeting friends downtown / badminton / athletics / piano / watching music videos / funny programmes / listening to most music',
          'meeting friends downtown / badminton / athletics / piano / watching music videos / funny programmes / listening to most music',
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB7SB-U1-P17-E2',
    audio: '',
    video: '',
    component: T6,
    // hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page17/E2/Key/answerKey.png',
    inputSize: 180,
    maxLength: 36,
    isHiddenCheck: true,
    stylesTextInput: {
      // border: "2px solid",
      // height: 45,
      // width: 45,
      borderBottom: 'dotted 1px',
      // backgroundColor: "transparent",
    },
    // titleImage: 'img/FriendsPlus/Page10/E1/title.jpg',
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the Key Phrases with words from Quỳnh Anh’s profile.',
        color: '#12a15f',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='background: rgb(207 232 211); border-radius: 15px; padding: 0px;margin:20px 20px'>
          <b style='color:white;border-radius:15px;background:rgb(18 161 95);padding:10px 400px 10px 10px; font-size: 27px'>KEY PHRASES</b>
          <div style='line-height:45px;padding:10px'>
              <b style='color: rgb(79 188 121)'>Expressing likes and preferences</b> <br>
                <b>1.</b> I like<input id='0' width='300px'>.<br>
                <b>2.</b> I enjoy<input id='1' width='300px'>.<br>
                <b>3.</b> I prefer<input id='2' width='460px'>.<br>
                <b>4.</b> I hate#.<br>
                <b>5.</b> I’m not really bothered about<input id='4' width='180px'>.<br>
                <b>6.</b> I’m (not) a big#fan.<br>
                <b>7.</b> I’m not mad about#.<br>
                <b>8.</b> I’m also into#.<br>
            </div>
        </div>
          `,
        answer: [
          'meeting friends downtown / listening to most music',
          'watching music videos',
          'watching funny programmes on YouTube',
          'listening to him',
          'TV',
          'video games',
          'Justin Bieber',
          'athletics',
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB7SB-U1-P17-E3',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    // hideBtnFooter: true,
    exerciseKey: '',
    inputSize: 100,
    // maxLength: 36,
    textareaStyle: { width: 400 },
    stylesTextInput: {
      // border: "2px solid",
      // height: 45,
      // width: 45,
      borderBottom: 'dotted 1px',
      // backgroundColor: "transparent",
    },
    // titleImage: 'img/FriendsPlus/Page10/E1/title.jpg',
    titleQuestion: [
      {
        num: '3',
        title: 'Write true sentences about yourself using the key phrases in exercise 2.',
        color: '#12a15f',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div>
            <b style='color:white;border-radius:15px;background:rgb(18 161 95);padding:10px 200px 10px 10px; font-size: 27px'>KEY PHRASES</b>
            <div style='background: rgb(207 232 211); border-radius: 15px; padding: 10px;margin:-10px 0px'>
              <b style='color: rgb(79 188 121)'>Expressing likes and preferences</b>
                <div>
                    <b>1.</b> I like ........<br>
                    <b>2.</b> I enjoy.........<br>
                    <b>3.</b> I prefer.........<br>
                    <b>4.</b> I hate.........<br>
                    <b>5.</b> I’m not really bothered about<br>.........<br>
                    <b>6.</b> I’m (not) a big........fan.<br>
                    <b>7.</b> I’m not mad about.........<br>
                    <b>8.</b> I’m also into.........<br>
                </div>
            </div>
          </div>
          <textarea id="8" rows="10"></textarea>
        </div>
          `,
        answer: [''],
      },
    ],
  },
  4: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB7SB-U1-P17-E4',
    audio: '',
    video: '',
    component: T6,
    // hideBtnFooter: true,
    exerciseKey: 'img/FriendsPlus/Page17/E4/Key/answerKey.png',
    inputSize: 1000,
    checkDuplicated: true,
    // maxLength: 24,
    stylesTextInput: { borderBottom: 'dotted 1px' },
    // titleImage: 'img/FriendsPlus/Page10/E1/title.jpg',
    titleQuestion: [
      {
        num: '4',
        title:
          'Find and, also and too in the profile. Are they in affirmative or negative sentences? Where is each word in the sentence?.',
        color: '#12a15f',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page17/E1/1.jpg'>
          <div style='margin: 20px;line-height:50px'>
            <b>1</b> Are they in affirmative or negative sentences?<br>
              #<br>
            <b>2</b> Where is each word in the sentence? <br> 
              #<br>
              #<br>
              #<br>
              #<br>
          </div>
          `,
        answer: [
          "They're in affirmative sentences.",
          'And and also are in the middle of a sentence.',
          'And comes between two full clauses.',
          'Also comes before a main verb (I also like …) or after the verb be (I’m also into …).',
          'Too comes at the end of a phrase, and is preceded by a comma.',
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB7SB-U1-P17-E5',
    audio: '',
    video: '',
    component: T6,
    // hideBtnFooter: true,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page17/E5/Key/answerKey.png',
    inputSize: 70,
    maxLength: 4,
    // textareaStyle: { width: 400 },
    stylesTextInput: {
      // border: "2px solid",
      // height: 45,
      // width: 45,
      borderBottom: 'dotted 1px',
      // backgroundColor: "transparent",
    },
    // titleImage: 'img/FriendsPlus/Page10/E1/title.jpg',
    titleQuestion: [
      {
        num: '5',
        title: 'Complete the sentences using <i>and, also</i> and <i>too</i>.',
        color: '#12a15f',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1</b> I like swimming#. I like playing tennis.<br>
            <b>2</b> I eat meat#. I eat fish#.<br>
            <b>3</b> I play the piano. I#play the violin.<br>
            <b>4</b> I love being in the countryside#. I love being in the park#.<br>
            <b>5</b> I meet friends at the weekend#. I#listen to music.<br>
        </div>
          
        `,
        answer: ['and', 'and', 'too', 'also', 'and', 'too', 'and', 'also'],
      },
    ],
  },
  6: {
    unit: 'Unit 1',
    id: 'SB7-U1-P17-E6',
    audio: '',
    video: '',
    component: T6,
    recorder: true,
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: '6',
        title: ' Follow the steps in the Writing Guide. Ask and answer the questions for part B with your partner.',
        color: '#12a15f',
        prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display:flex'>
            <img src='img/FriendsPlus/Page17/E6/1.jpg'>
            <div><textarea id="0" rows="7" ></textarea><div>
          </div>
        `,
        answer: [''],
      },
    ],
  },
  7: {
    unit: 'Unit 2',
    id: 'SB7-U2-P17-E7',
    audio: '',
    video: '',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700 },
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page21/E7/1.jpg' />
          <div style='margin-left: 100px;'><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
