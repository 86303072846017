import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	3: {
		unit: 'Unit 7',
		id: 'SB7-U7-P83-E3',
		exerciseKey: 'img/FriendsPlus/Page83/E3/Key/answerKey.png',
		audio: 'Audios/2-15 Friends Plus 7.mp3',
		video: 'Videos/Talking Heads/U7-2.15-Trang 83.mp4',
		videoSub: 'Videos/Talking Heads/U7-2.15-Trang 83.vtt',
		component: T6,
		inputSize: 180,
		maxLength: 15,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 30,
		},
		selectStyle: {
			// width: 300,
			// backgroundColor: '#FAF1DE',
		},
		selectOptionValues: ['☺', '☹'],
		checkUppercase: true,
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '3',
				title: `
					<videoimage></videoimage><audioimage name='2.15'></audioimage>
					Watch or listen. What type of music does each person listen to, 
					and what do they think of the music they hear? 
					Complete the ‘Type of music’ column and 
					write 'like' or 'dislike' for each name in the ‘Opinion’ column.
				`,
				color: '#006ac0',
			},
		],

		questions: [
			{
				title: `
					<div style="margin-top: 20px; background-color: rgb(253, 220, 189); padding: 20px; border-radius: 20px;">
						<div style="display: flex; font-weight: bold; background-color: rgb(251,164,95);">
							<div style="text-align: center; width: 150px;">Name</div>
							<div style="text-align: center; width: 200px; border-left: 1px solid black;">Type of music</div>
							<div style="text-align: center; width: 150px; border-left: 1px solid black;">Opinion</div>
						</div>

						<div style="display: flex; border-top: 1px solid black">
							<div style="text-align: center; width: 150px;">Elijah</div>
							<div style="text-align: center; width: 200px; border-left: 1px solid black;">#</div>
							<div style="text-align: center; width: 150px; border-left: 1px solid black;"><select id='5'></select></div>
						</div>
						<div style="display: flex; border-top: 1px solid black">
							<div style="text-align: center; width: 150px;">Rebecca</div>
							<div style="text-align: center; width: 200px; border-left: 1px solid black;">#</div>
							<div style="text-align: center; width: 150px; border-left: 1px solid black;"><select id='6'></select></div>
						</div>
						<div style="display: flex; border-top: 1px solid black">
							<div style="text-align: center; width: 150px;">Lily</div>
							<div style="text-align: center; width: 200px; border-left: 1px solid black;">#</div>
							<div style="text-align: center; width: 150px; border-left: 1px solid black;"><select id='7'></select></div>
						</div>
						<div style="display: flex; border-top: 1px solid black">
							<div style="text-align: center; width: 150px;">Harry</div>
							<div style="text-align: center; width: 200px; border-left: 1px solid black;">#</div>
							<div style="text-align: center; width: 150px; border-left: 1px solid black;"><select id='8'></select></div>
						</div>
						<div style="display: flex; border-top: 1px solid black">
							<div style="text-align: center; width: 150px;">Renee</div>
							<div style="text-align: center; width: 200px; border-left: 1px solid black;">#</div>
							<div style="text-align: center; width: 150px; border-left: 1px solid black;"><select id='9'></select></div>
						</div>
					</div>
                `,
				answer: ['reggaeton', 'classical music', 'samba', 'heavy metal', 'folk music', '☺', '☹', '☺', '☹', '☹'],
			},
		],
	},
	4: {
		unit: 'Unit 7',
		id: 'SB7-U7-P83-E4',
		audio: 'Audios/2-15 Friends Plus 7.mp3',
		video: 'Videos/Talking Heads/U7-2.15-Trang 83.mp4',
		exerciseKey: 'img/FriendsPlus/Page83/E4/Key/answerKey.png',
		component: T6,
		inputSize: 320,
		maxLength: 50,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		// isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		titleQuestion: [
			{
				num: '4',
				title: `
					<videoimage></videoimage><audioimage name='2.15'></audioimage>
					Watch or listen again. Which of the key phrases do you hear? Fill in the ‘Words and phrases’ column in exercise 3.
				`,
				color: '#006ac0',
			},
		],

		questions: [
			{
				title: `
					<div style="display:flex; gap:50px;">
						<div style="margin-top: 20px; width: 500px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgb(216,224,243);">
							<div style="font-weight: bold;  background-color: rgb(108,159,214); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
							Key Phrases
							</div>
		
							<div style="margin-top: 10px; padding: 0 20px">
								<div style="color:rgb(108,159,214); font-weight: bold;">
									Talk about personal interests
								</div>
			
								<div style="">
									What do you think of this song? <br/>
									It sounds (like) …<br/>
									It’s / It isn’t very catchy.<br/>
									I’m (not) into heavy metal.<br/>
									It’s got a good beat / rhythm.<br/>
									It’s / It isn’t a good one for dancing to.<br/>
									It’s a bit too heavy / slow / weird for me.<br/>
									I like / don’t like the vocals / lyrics / guitar.<br/>
									There’s a really cool video for this / that.<br/>
								</div>
							</div>
						</div>

						<div>
						<div style="margin-top: 20px; background-color: rgb(253, 220, 189); padding: 20px; border-radius: 20px;">
							<div style="display: flex; font-weight: bold; background-color: rgb(251,164,95);">
								<div style="text-align: center; width: 150px;">Name</div>
								<div style="text-align: center; width: 330px; border-left: 1px solid black;">Words and phrases</div>
							</div>

							<div style="display: flex; border-top: 1px solid black">
								<div style="text-align: center; width: 150px;">Elijah</div>
								<div style="text-align: center; border-left: 1px solid black;">#</div>
								
							</div>
							<div style="display: flex; border-top: 1px solid black">
								<div style="text-align: center; width: 150px;">Rebecca</div>
								<div style="text-align: center; border-left: 1px solid black;">#</div>
							</div>
							<div style="display: flex; border-top: 1px solid black">
								<div style="text-align: center; width: 150px;">Lily</div>
								<div style="text-align: center; border-left: 1px solid black;">#</div>
							</div>
							<div style="display: flex; border-top: 1px solid black">
								<div style="text-align: center; width: 150px;">Darius</div>
								<div style="text-align: center; border-left: 1px solid black;">#</div>
							</div>
							<div style="display: flex; border-top: 1px solid black">
								<div style="text-align: center; width: 150px;">Renee</div>
								<div style="text-align: center; border-left: 1px solid black;">#</div>
							</div>
						</div>
						</div>
					</div>
						
                `,
				answer: [
					"It's a good one for dancing to. / It sounds like ...",
					"I'm not into ... / It's a bit too slow for me.",
					"It sounds like ... / It's very catchy. / It's got a good beat. / It's a good one for dancing to.",
					"It's too heavy for me. / It's weird. / I'm not into heavy metal.",
					"I don't like the beat. / It isn't a good one for dancing to. / I don't like it.",
				],
				// answer: [
				// 	"It's a good one for dancing to.",
				// 	"It's a bit too slow for me.",
				// 	"It's very catchy.",
				// 	"It's too heavy for me.",
				// 	"I don't like the beat.",
				// ],
			},
		],
	},

	5: {
		unit: 'Unit 7',
		id: 'SB7-U7-P83-E5',
		exerciseKey: 'img/FriendsPlus/Page83/E5/Key/answerKey.png',
		audio: 'Audios/2-16 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		stylesTextInput: {
			fontSize: 23,
		},

		textareaStyle: { width: 900 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
          <audioimage name='2.16'></audioimage> <b style="color: #006ac0">USE IT!</b>
          Listen to three pieces of music. Answer questions 1-3 for each piece.
        `,
				color: '#006ac0',
			},
		],

		questions: [
			{
				title: `
          <div style="width: 700px; margin-top: 30px;">
            <ol>
              <li>What type of music is it?</li>
              <li>What instruments do you hear?</li>
              <li>What do you think of the music?</li>
            </ol>

           

            <textarea id="0" rows="8"></textarea>
          </div>
        `,
				answer: [],
			},
		],
	},
	6: {
		unit: 'Unit 7',
		id: 'SB7-U7-P83-E6',
		exerciseKey: 'img/FriendsPlus/Page83/E5/Key/answerKey.png',
		audio: 'Audios/2-16 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		stylesTextInput: {
			fontSize: 23,
		},
		finished: {
			text: `
        Write (40 - 60 words) about your favourite piece of music. 
        What type of music is it? How does it sound? Why do you like it?
      `,
		},
		textareaStyle: { width: 750 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title: `
		        `,
				color: '#006ac0',
			},
		],

		questions: [
			{
				title: `
          <div style="width: 700px; margin-top: 30px;">
            <div style="margin-top: 30px;">
              <finished></finished>
            </div>

            <textarea id="0" rows="10"></textarea>
          </div>
        `,
				answer: [],
			},
		],
	},
}

export default json
