import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB7-U4-P50-E1",
    audio: "Audios/1-45 Friends Plus 7.mp3",
    video: "Videos/Speaking/U4-1.45- Trang 50.mp4",
    exerciseKey: "img/FriendsPlus/Page50/E1/Key/answerKey.png",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 180,
    titleQuestion: [
      {
        num: "1",
        title:
          "<videoimage></videoimage><audioimage name='1.45'></audioimage> Complete the dialogue with the phrases in the box. Then watch or listen and check. What happen in Julie'photo? Where was Julie when she took the photo?",
        color: "#da1f73",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='border-width: 2px; border-style: solid; border-radius: 30px; border-color: rgb(218,31,115); text-align: center; padding: 0 20px; margin-top: 20px;'>do you thing&emsp;&emsp;fell of&emsp;&emsp;was cycling&emsp;&emsp;was sitting&emsp;&emsp;were you</div>
          <div style='display: flex;  justify-content: space-between; background-color: rgb(212, 227, 233); padding: 30px; border-radius: 50px; margin-top: 20px;'>
            <div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Hey, these are good photos, Julie. Did you take them?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>Yes. My new phone takes really good photos. What <sup>1</sup># of this one?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>I like it. That one’s brilliant.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>Yeah, i’m pleased with it. I was very lucky.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>But the poor guy wasn’t lucky! Where <sup>2</sup># when you took it?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>I <sup>3</sup># on a wall by the road. He <sup>4</sup># while he <sup>5</sup># past me.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Well, it’s an amazing photo. Was it near here?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>No, it was a cycling race near Bristol. I went with my brother</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Really? Did he take part in the race?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>You’re kidding! He doesn’t even ride a bike.  </div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Well, it’s an amazing photo. I’m impressed!</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>That’s really kind of you. Thanks .</div>
              </div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page50/E1/2.jpg' />
            </div>
          </div>
          </br />
          <div>What happen in Julie's photo?<br> <input id="7" text-align='left' width='600px' background-color='transparent' /></div>
          <div>Where was Julie when she took the photo?<br> <input id="8" text-align='left' width='800px' background-color='transparent' /></div>
        `,
        answer: [
          "do you think",
          "were you",
          "was sitting",
          "fell off",
          "was cycling",
          "A man fell off his bike.",
          "Julie was watching a cycling race when she took the photo."
        ],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB7-U4-P50-E2",
    audio: "Audios/1-45 Friends Plus 7.mp3",
    video: "Videos/Speaking/U4-1.45- Trang 50.mp4",
    exerciseKey: "img/FriendsPlus/Page50/E2/Key/answerKey.png",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 100,
    maxLength: 50,
    checkUppercase: true,
    selectStyle: { width: 110, textAlign: 'center', fontSize: 17 },
    selectOptionRandom: true,
    selectOptionValues: [
      "Amelia",
      "Julie",
    ],
    titleQuestion: [
      {
        num: "2",
        title:
          "<videoimage></videoimage><audioimage name='1.45'></audioimage> Look at the Key Phrases. Cover the dialogue and try to remember who says the phrases, Amelia or Julie. Watch or listen again and check.",
        color: "#da1f73",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='padding:8mm  3mm;width: 70%; margin-top: 50px; border-radius: 30px; background:rgb(246 211 218)'>
            
            <div style='color: white; background:rgb(226 115 148); padding: 20px 20px 20px 20px; margin: -1cm -11px 5mm -11px; border-radius: 25px'><b> KEY PHRASES </b> </div>
            <b style='color:rgb(220 45 119)'>Expressing interest</b>
            <div  style='display: flex; margin: 20px 0 20px 0'>
              <div style="display: flex;"><select id='0'></select> that one’s brilliant.</div>
              <div style=' display: flex; margin-left: 100px'><select id='1'></select> You're kidding!</div>
            </div>
            <div  style='display: flex; margin: 20px 0 20px 0'>
              <div style="display: flex;"><select id='2'></select> It’s an amazing photo.</div>
              <div style=' display: flex; margin-left: 60px'><select id='3'></select> I’m impressed!</div>
              
            </div>
            <div  style='display: flex; margin: 20px 0 20px 0'>
              <div style="display: flex;"><select id='4'></select> Really</div>
              <div style=' display: flex; margin-left: 230px'><select id='5'></select> That’s really kind of you.</div>
            </div>
          </div>
          <div style='display: flex; margin-top: 20px; justify-content: space-between; background-color: rgb(212, 227, 233); padding: 50px; border-radius: 50px; margin-top: 20px;'>
            <div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Hey, these are good photos, Julie. Did you take them?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>Yes. My new phone takes really good photos. What <sup>1</sup>.............. of this one?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>I like it. That one’s really good.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>Yeah, i’m pleased with it. I was very lucky.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>But the poor guy wasn’t lucky! Where <sup>2</sup>.............. when you took it?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>I <sup>3</sup>.............. on a wall by the road. He <sup>4</sup>.............. while he <sup>5</sup>.............. past me.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Well, it’s an amazing photo. Was it near here?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>No, it was a cycling race near Bristol. I went with my brother</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Really? Did he take part in the race?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>You’re kidding! He doesn’t even ride a bike.  </div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Well, it’s an amazing photo. I’m impressed!</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>That’s really kind of you. Thanks .</div>
              </div>
            </div>
            <div>
              <img src='img/FriendsPlus/Page50/E1/2.jpg' />
            </div>


          `,
        answer: [
          "Amelia",
          "Julie",
          "Amelia",
          "Amelia",
          "Amelia",
          "Julie",
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB7-U4-P50-E3",
    audio: "",
    video: "",
    component: T6,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Work in pairs. Practise the dialogue.",
        color: "#da1f73",
        prefix: 'default'
      },
    ],
    questions: [
      {
        title: `
            <div style='display: flex; margin-top: 20px; justify-content: space-between; background-color: rgb(212, 227, 233); padding: 50px; border-radius: 50px; margin-top: 20px;'>
            <div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Hey, these are good photos, Julie. Did you take them?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>Yes. My new phone takes really good photos. What <sup>1</sup>.............. of this one?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>I like it. That one’s really good.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>Yeah, i’m pleased with it. I was very lucky.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>But the poor guy wasn’t lucky! Where <sup>2</sup>.............. when you took it?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>I <sup>3</sup>.............. on a wall by the road. He <sup>4</sup>.............. while he <sup>5</sup>.............. past me.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Well, it’s an amazing photo. Was it near here?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>No, it was a cycling race near Bristol. I went with my brother</div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Really? Did he take part in the race?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>You’re kidding! He doesn’t even ride a bike.  </div>
              </div>
              <div style='display: flex;'>
                <div><b>Amelia</b></div>
                <div style='margin-left: 15px;'>Well, it’s an amazing photo. I’m impressed!</div>
              </div>
              <div style='display: flex;'>
                <div><b>Julie</b></div>
                <div style='margin-left: 40px;'>That’s really kind of you. Thanks .</div>
              </div>
            </div>
          `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB7-U4-P50-E4",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 800,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Imagine that you took this photo. Think about your answers to these questions.",
        color: "#da1f73",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
      <div>  
        <div style='padding-top: 6mm'>
        <div style='padding-top: 1cm'><b>1.</b> Where were you?<br> #</div>
        <div style='padding-top: 1cm'><b>2.</b> Why were you there? <br> #</div>
        <div style='padding-top: 1cm'><b>3.</b> What were the people doing?<br> #</div>
      </div>
            <img src='img/FriendsPlus/Page50/E4/1.jpg' style='margin: 3cm; width: 74%'>
          `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 4",
    id: "SB7-U4-P50-E5",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    hintBox: [
      {
        src: ["Did you take this photo?"],
        borderColor: "#D91A72",
        arrow: true,
        position: 1,
        width: 380,
      },
      {
        src: ["Yes. What do you think of it?"],
        borderColor: "#E685A0",
        arrow: true,
        position: 2,
        width: 350,
      },
    ],
    titleQuestion: [
      {
        num: "5",
        title:
          "Work in pairs. Prepare a new dialogue. Use the key phrases and your ideas from exercise 4. Then practise your dialogue.",
        color: "#da1f73",
        prefix: 'default'
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page47/E5/2.jpg" }]],
    questions: [
      {
        title: `
          <div style='display: flex; justify-content: space-between; width: 600px; margin-top: 40px; text-align: center;'>
            <div><hintbox id='0'></hintbox></div>
            <div style=" margin: 30px 0 0 40px"><hintbox id='1'></hintbox></div>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
