import { Input } from 'antd';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';

const CaseTextArea = ({ content, dataIndex, handleTextAreaChange, resultTextArea }) => {
  const renderColor = () => {
    // Duyệt qua tất cả các mảng.
    return resultTextArea.map((x) => {
      if (!x.some((k) => k.index === dataIndex)) return null;
      // Duyệt qua mảng con đúng với index.
      return x.map((k, i) => (
        <div key={i} style={{ color: k.isCorrect ? 'green' : 'red' }}>
          {k.value}
        </div>
      ));
    });
  };

  return (
    <Fragment>
      <div>{ReactHtmlParser(content)}</div>
      {resultTextArea.length !== 0 ? (
        <Fragment>{renderColor()}</Fragment>
      ) : (
        <Input.TextArea allowClear autoSize onChange={(e) => handleTextAreaChange(e.target.value, dataIndex)} />
      )}
    </Fragment>
  );
};

CaseTextArea.propTypes = {
  content: PropTypes.string.isRequired,
  dataIndex: PropTypes.number,
  handleTextAreaChange: PropTypes.func,
  resultTextArea: PropTypes.array,
};

export default CaseTextArea;
