import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB7-U5-P63-E1",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 100,
    // textAlign: "center",
    maxLength: 5,
    exerciseKey: "img/FriendsPlus/Page63/E1/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Change the words bold from affirmative to negative, or to affirmative, to make sentences.",
        color: "#2eb6e1",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
              
        <div style='margin-top:5mm'><b>1.</b> Most children (<b>can</b>)# read until they’re read until they’re six.</div>
        <div style='margin-top:5mm'><b>2.</b> Mozart (<b>couldn’t</b>)# compose music when he was five.</div>
              `,
        answer: ["can't", "could"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P63-E2",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page63/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Look at the sentences in exercise 1 again and choose the correct words in the Rules.",
        color: "#2eb6e1",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "0 5px",
          fontWeight: 600,
          color: "",
          fontSize: 24,
          borderRadius: "50%",
        },
        selectWordStyle: { border: "3px solid #2eb6e1" },
        limitSelect: 1,
        listWords: [
          "present /  past",
          "present / past",
          "with / without",
          "can / cans",
          "don’t_can_and_didn’t_can / can’t_and_couldn’t",
          "use / don’t_use.",
        ],
        answers: ["0-0", "1-4", "2-4", "3-0", "4-4", "5-4"],
        initialValue: [],
      },
      Layout: `
        <div style='margin-top:5mm'><b>1.</b> Most children <b>can</b> read until they’re read until they’re six.</div>
        <div style='margin-top:5mm'><b>2.</b> Mozart <b>couldn’t</b> compose music when he was five.</div>

      <div style='margin:10px;border-radius:20px; border:4px solid rgb(93 200 220);padding: 5px; width: 18cm'>
          <div style=' font-weight:900;color:white;background:rgb(93 200 220);padding-left:20px;border-radius:15px'>
            RULES
            <img style='margin-left:360px ;width:35mm;height:14mm' src='img/FriendsPlus/Page63/E2/1.jpg'>
          </div>
          <div style='display:flex'>
            <b>1</b>
            <div style='margin-left:20px'>
              We use can to talk about the <input id='0'  type='Circle' /> and
              could to talk about the <input id='1'  type='Circle' />.
            </div>
          </div>
          <div style='display:flex'>
            <b>2</b>
            <div style='margin-left:20px'>We can use base form <input id='2'  type='Circle' /> to after
            can and could.</div>
          </div>
          <div style='display:flex'>
            <b>3</b>
            <div style='margin-left:20px'>The he / she / it form of can is <input id='3'  type='Circle' />.</div>
          </div>
          <div style='display:flex'>
            <b>4</b>
            <div style='margin-left:20px'>The negative forms are <br> <input id='4'  type='Circle' />.</div>
          </div>
          <div style='display:flex'>
            <b>5</b>
            <div style='margin-left:20px'>We <input id='5'  type='Circle' /> do and did to make
            questions with can and could. For example:<br> <b style='font-style: italic;color:rgb(138 138 138)'>At what age can most people talk?<br>When could you first speak English?</b>.</div>
          </div>
    </div>
        `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P63-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page63/E3/Key/answerKey.png",
    component: D1,
    maxLength: 8,
    textAlign: "center",
    // inputHeight: 0,
    fontSize: 26,
    titleQuestion: [
      {
        num: "3",
        title:
          "Complete the text with affirmative and negative forms of <i>can</i> and <i>could</i>.",
        color: "#2eb6e1",
      },
    ],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page63/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page63/E3/2.jpg" },
        {
          url: "img/FriendsPlus/Page63/E3/3.jpg",
          input: true,
          answer: "can't",
        },
        { url: "img/FriendsPlus/Page63/E3/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E3/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page63/E3/6.jpg" },
        {
          url: "img/FriendsPlus/Page63/E3/7.jpg",
          input: true,
          answer: "could",
        },
        { url: "img/FriendsPlus/Page63/E3/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E3/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page63/E3/10.jpg" },
        {
          url: "img/FriendsPlus/Page63/E3/11.jpg",
          input: true,
          answer: "could",
        },
        { url: "img/FriendsPlus/Page63/E3/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E3/13.jpg" }],
      [
        { url: "img/FriendsPlus/Page63/E3/14.jpg" },
        {
          url: "img/FriendsPlus/Page63/E3/15.jpg",
          input: true,
          answer: "couldn't",
        },
        { url: "img/FriendsPlus/Page63/E3/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E3/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page63/E3/18.jpg" },
        { url: "img/FriendsPlus/Page63/E3/19.jpg", input: true, answer: "can" },
        { url: "img/FriendsPlus/Page63/E3/20.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page63/E3/21.jpg" },
        {
          url: "img/FriendsPlus/Page63/E3/22.jpg",
          input: true,
          answer: "can't",
        },
        { url: "img/FriendsPlus/Page63/E3/23.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page63/E3/24.jpg" }],
    ],
  },

  4: {
    unit: "Unit 5",
    id: "SB7-U5-P63-E4",
    audio: "",
    video: "",
    component: MatchDots,

    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page63/E4/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "Match 1-6 with a-f to make question. Can you remember the answers? Ask and answer the questions with your partner.",
        color: "#2eb6e1",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "180px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "95px",
              left: "330px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "153px",
              left: "180px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "153px",
              left: "330px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "180px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "330px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "268px",
              left: "180px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "268px",
              left: "330px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "327px",
              left: "180px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "327px",
              left: "330px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "384px",
              left: "180px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "384px",
              left: "330px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 11
        ],
        answers: ["0-3", "11-2", "4-9", "1-6", "5-8", "10-7"],
        initialValue: [],
      },
      Layout: `
        <div style="font-size: 26px;">
					<div style='display: flex; font-weight: bold; padding-top: 5mm'>
						<div style='flex-grow: 2; margin-left: 100px;'>A</div>
						<div style='flex-grow: 2; margin-left: 80px'>B</div>
					</div>

          <div style='display: flex;padding-top: 5mm'>
            <div ><b>1</b> How much</div>
						<div><input id='0' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
						<div style='margin-left: 217px;'><b>a.</b> should a bodybuilder have eggs per day?</div>
          </div>

					<div style='display: flex;padding-top: 5mm'>
            <div><b>2</b> How fast</div>
						<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
						<div style='margin-left: 238px;'><b>b.</b> potassium does a banana contain?</div>
          </div>
					
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>3</b>  How high</div>
						<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='5' type= 'boxMatch' /></div>
						<div style='margin-left: 231px;'><b>c.</b> words can Twista rap in a minute?</div>
          </div>
					
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>4</b> How often</div>
						<div><input id='6' type= 'boxMatch' /></div>
            <div><input id='7' type= 'boxMatch' /></div>
						<div style='margin-left: 221px;'><b>d.</b> was William Sidis when he started reading?</div>
          </div>
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>5</b> How many</div>
						<div><input id='8' type= 'boxMatch' /></div>
            <div><input id='9' type= 'boxMatch' /></div>
						<div style='margin-left: 216px;'><b>e.</b> is Mount Everest?</div>
          </div>
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>6</b> How old</div>
						<div><input id='10' type= 'boxMatch' /></div>
            <div><input id='11' type= 'boxMatch' /></div>
						<div style='margin-left: 247px;'><b>f.</b> could Carl Lewis run?</div>
          </div>
            
          
        </div>
      `,
    },
  },
  5: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P63-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Make questions with <i>How … ?</i> and the words in the table. Then ask and answer the questions with your partner.",
        color: "#2eb6e1",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page63/E5/2.jpg" }],
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB7-U6-P63-E6",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 650 },
    // hideBtnFooter: true,
    finished: {
      text: "Write sentences about things you couldn’t do in the past, but that you can do now.",
    },

    questions: [
      {
        title: `
          <finished></finished>
          <div style='padding-top: 40px'><textarea id="0" rows="6" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
