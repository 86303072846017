import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB7-U4-P53-E1",
    component: T6,
    titleQuestion: [
      {
        num: "1",
        title: "ONE-MINUTE RACE. Work in pairs. Follow thegit instructions.",
        color: "#2c3332",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <img style='margin: 20px;' src='img/FriendsPlus/Page53/E1/1.jpg' />
        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB7-U4-P53-E2",
    exerciseKey: "img/FriendsPlus/Page53/E2/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    inputSize: 440,
    titleQuestion: [
      {
        num: "2",
        title:
          "WHO DID IT? Someone took a famous painting from the National Gallery in London yesterday. Work in pairs and read the notes.",
        color: "#2c3332",
      },
    ],
    questions: [
      {
        title: `
         Which person is not in the pictures? This is the person who took the painting. #
        <img style='margin-left:5cm; width: 74%' src='img/FriendsPlus/Page53/E2/2.jpg' />
        
        `,
        answer: [
          "Mr Jacobs/Mr Jacobs isn't in the pictures."
        ],
      },
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB7-U4-P53-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 700,
    exerciseKey: "img/FriendsPlus/Page53/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "WORK IN PAIRS. Find the past continuous questions about the people in exercise 2. Think of some answers to the questions.",
        color: "#2c3332",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div>
          <div><b >1.</b> What were Jill and Lucy talking about? # </div>
          <div style='margin-top: 20px; display: flex'><b>2.</b> #</div><div> # </div>
          <div style='margin-top: 20px; display: flex'><b>3.</b> #</div><div> # </div>
          <div style='margin-top: 20px; display: flex'><b>4.</b> #</div><div> # </div>
          </div>
          <img style='margin-right: 100px;' src='img/FriendsPlus/Page53/E3/2.jpg' />
        </div>
          `,
        answer: [
          "",
          "How was Stephen feeling yesterday ?",
          "",
          "Where was Joe driving his taxi to ?",
          "",
          "Why was Jenny calling America ?",
          "",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 4",
    id: "SB7-U4-P53-E4",
    exerciseKey: "img/FriendsPlus/Page53/E4/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    inputSize: 150,
    titleQuestion: [
      {
        num: "4",
        title:
          "Find nine more adverbs.",
        color: "#2c3332",
      },
    ],
    questions: [
      {
        title: `
        <img style='margin:20px; width: 50%' src='img/FriendsPlus/Page53/E4/1.jpg' />
        <br><b style="color: grey">fast</b>
        <br> # # # # # # # # #
        `,
        answer: [
          "easily",
          "politely",
          "quietly",
          "carefully",
          "happily",
          "well",
          "badly",
          "comfortably",
          "bravely",
        ],
      },
    ],
  },

  5: {
    unit: "Unit 4",
    id: "SB7-U4-P53-E5",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page53/E5/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 180,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Find five verbs in the grid. Each verb is in a different colour. Change them to past simple and past continuous to complete the sentences.",
        color: "#2c3332",
      },
    ],
    questions: [
      {
        title: `
          <img style='margin: 0 0 40px 100px; width: 50%'  src='img/FriendsPlus/Page53/E5/1.jpg' />
        <div><b>1.</b> As soon as the accident # , we called the police.</div>
        <div style='margin-top: 20px'><b>2.</b> We met a lot of interesting people while we # </div>
        <div style='margin-top: 20px'><b>3.</b> The two men were fishing when they # the shark.</div>
        <div style='margin-top: 20px'><b>4.</b> Someone stole my MP3 player while I # a shower.</div>
        <div style='margin-top: 20px'><b>5.</b> Tom was playing computer games and his sister # TV</div>
          `,
        answer: [
          "happened",
          "were travelling",
          "saw",
          "was having",
          "was watching",
        ],
      },
    ],
  },
};

export default json;
