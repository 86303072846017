import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: {
    unit: "Option 8",
    id: "SB7-O8-P113-E1",
    exerciseKey: "img/FriendsPlus/Page113/E1/Key/answerKey.png",
    audio: "Audios/3-22 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 125,
    maxLength: 9,
    stylesTextInput: {
      fontSize: 24,
    },

    hintBox: [{
      src: [
        'boarding',
        'area',
        'office',
        'clearance',
        'floor',
        'counter'
      ],
      borderColor: "#f77e1d",
      width: 525,
    },],

    titleQuestion: [
      {
        num: "1",
        title: "<audioimage name='3.22'></audioimage> Match words 1-6 with the words in the box to form compound nouns. Then listen and check.",
        color: "#f77e1d",
      },
    ],

    questions: [
      {
        title: `
                <hintbox id='0'></hintbox>
                <ol style='margin-top: 40px'>
                    <li>customs #</li>
                    <li># gates</li>
                    <li>first / second #</li>
                    <li>immigration #</li>
                    <li>transfer #</li>
                    <li>baggage claim #</li>
                </ol>
            `,
        answer: ['office', 'boarding', 'floor', 'clearance', 'counter', 'area'],
      },
    ],
  },

  2: {
    unit: "Option 8",
    id: "SB7-O8-P113-E2",
    exerciseKey: "img/FriendsPlus/Page113/E2/Key/answerKey.png",
    audio: "Audios/3-23 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 240,
    maxLength: 19,
    stylesTextInput: { textAlign: 'center' },
    titleQuestion: [
      {
        num: "2",
        title: "<audioimage name='3.23'></audioimage> Look at the map of an airport and answer the following questions. Then listen and check.",
        color: "#f77e1d",
      },
    ],
    questions: [
      {
        title: `
                <div style="width: 550px">
                  <img src='img/FriendsPlus/Page113/E2/1.jpg' style="width: 110%"/>

                  <ol style='margin-top: 40px'>
                    <li>On what floor is the immigration clearance? #</li>
                    <li>On what floor is the baggage claim area? #</li>
                    <li>On what floor is the transfer counter? #</li>
                    <li>On what floor is the boarding gates? #</li>
                    <li>On what floor is the customs office? #</li>
                </ol>
                </div>  
              `,
        answer: [
          'on the second floor',
          'on the first floor',
          'on the second floor',
          'on the second floor',
          'on the first floor'
        ],
      },
    ],

  },

  3: {
    unit: "Option 7",
    id: "SB7-O7-P113-E3",
    exerciseKey: "img/FriendsPlus/Page113/E3/Key/answerKey.png",

    audio: "Audios/3-24 Friends Plus 7.wav",
    video: "",

    component: T6,
    inputSize: 110,
    maxLength: 7,
    // checkDuplicated: true,
    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 23,
    },

    hintBox: [{
      src: [
        'where',
        'on',
        'turn',
        'between',
      ],
      borderColor: "#f77e1d",
      width: 525,
    },],

    titleQuestion: [
      {
        num: "3",
        title: `
              <audioimage name='3.24'></audioimage>
              Complete the Key Phrases with the
              words in the box. Then listen to the dialogue
              and check.
          `,
        color: "#f77e1d",
      },
    ],

    questions: [
      {
        title: `
                  <div style="margin-top: 20px; width: 450px;"><hintbox id='0'></hintbox></div>

                  <div style="margin-top: 20px; width: 450px; padding-bottom: 20px;border: 1px solid rgb(247, 126, 29); border-radius: 10px; overflow: hidden">
                      <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(247, 126, 29); color: white; padding: 5px; border-radius: 0px 0px 10px 10px; text-transform: uppercase;">
                          Key Phrases
                      </div>
                      
                      <div style="margin-top: 10px; padding-left: 20px;">
                          <div style="color: rgb(247, 126, 29); font-weight: bold; font-size: 1.6rem">
                              Giving directions
                          </div>

                          <div>
                              Do you know <sup>1</sup> # the... is?<br/> 
                              <sup>2</sup># the left...<br/>
                              <sup>3</sup># Gate 17 and Gate 18.<br/>
                              <sup>4</sup># left...<br/>
                          </div>
                      </div>
                  </div>
                  
              `,
        answer: [
          'where',
          'on',
          'between',
          'turn',
        ],
      },
    ],

  },

  4: {
    unit: "Option 8",
    id: "SB7-O8-P113-E4",
    exerciseKey: "img/FriendsPlus/Page113/E4/Key/answerKey.png",

    audio: "Audios/3-24 Friends Plus 7.wav",
    video: "",

    component: Circle_Write,
    titleQuestion: [
      {
        num: "4",
        title: `
            <audioimage name='3.24'></audioimage>
            Listen to the dialogue between Alex and the immigration officer again. 
            Choose the correct words in the dialogue. Then practise it.`,
        // title: "<audioimage name='3.24'></audioimage> Listen to the dialogue between Alex and the immigration officer again. Choose the correct words in the dialogue. Then practise it.",
        color: "#f77e1d",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "#00aeef",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          'the_UK / the_USA',
          'Penang / Bintan',
          'clean / clear',
          'left / right'
        ],
        answers: ["0-0", "1-0", "2-4", "3-0",],
        initialValue: [],
      },

      Layout: `
          <div style="width: 600px">
            <div style="display: flex;">
                <span style="margin-right: 27px">I.O.</span>
                <span>Good morning. Where are you from?</span>
            </div>

            <div style="display: flex;">
                <span style="margin-right: 20px">Alex</span>
                <span>
                  Good morning. I’m from <input id='0' type='Circle' />. 
                  Do you know where the transfer counter is? 
                  I’m flying to <input id='1' type='Circle' />, Malaysia. 
                  This is my first flight.
                </span>
            </div>

            <div style="display: flex;">
              <span style="margin-right: 27px">I.O.</span>
                <span>
                  It’s on this floor, on the left, between Gate 17 and Gate 18.
                </span>
            </div>
            
            <div style="display: flex;">
                <span style="margin-right: 20px">Alex</span>
                <span>
                  Can I go to the baggage claim area? I would like to take my baggage.
                </span>
            </div>

            <div style="display: flex;">
              <span style="margin-right: 27px">I.O.</span>  
                <span>
                  Oh, no. You mustn’t <input id='2' type='Circle' /> the immigration. 
                  Your baggage is following you to Malaysia.
                  </span>
            </div>
            
            <div style="display: flex;">
                <span style="margin-right: 20px">Alex</span>
                <span>Thank you. Where can I stay now?</span>
            </div>
            
            <div style="display: flex;">
              <span style="margin-right: 27px">I.O.</span>
                <span>
                  You are welcome. Please, turn <input id='3' type='Circle' /> over there and you’ll see some benches. 
                  Here is your passport. Enjoy your flight.
                </span>
            </div>
            
            <div style="display: flex;">
                <span style="margin-right: 20px">Alex</span>
                <span>Thanks a lot. Bye.</span>
            </div>
          </div>
        `,
    },

  },

  5: {
    unit: "Option 8",
    id: "SB7-O8-P113-E5",
    exerciseKey: "",
    audio: "",
    video: "",
    component: T6,
    inputSize: 550,
    maxLength: 100,
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    textareaStyle: { width: 1000 },
    titleQuestion: [
      {
        num: "5",
        title: "Work in pairs. Imagine that you are at Gate 19 and you want to take your baggage. Ask for and give directions using the Key Phrases.",
        color: "#f77e1d",
        prefix: "default",
      },
    ],
    questions: [
      {
        title: `
              <div style="width: 500px; margin-top: 20px;">
                  <div style="margin-block: 10px">Ask for directions: <textarea id='0' row='3'></textarea></div>
                  <div style="margin-block: 10px">Give the directions: <textarea id='1' row='3'></textarea></div>
              </div>
              
            `,
        answer: [],
      },
    ],
  },

};

export default json;
