// TEMPLATE
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P102-E1',
		exerciseKey: 'img/FriendsPlus/Page102/E1/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 85,
		maxLength: 6,
		stylesTextInput: {
			fontSize: 23,
		},

		hintBox: [
			{
				src: [
					'band',
					'drums',
					'fans',
					'hit',
					'lyrics',
					'views',
					'violin',
					'rap',
				],
				borderColor: '#4c3d9b',
				width: 450,
			},
		],

		titleQuestion: [
			{
				num: '1',
				title: `
                Complete the sentences with the words in the box. 
                There are two extra words.
              `,
				color: '#4c3d9b',
			},
		],

		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <div style="display: flex; justify-content: center;">
                            <hintbox id='0'></hintbox>
                        </div>
                        
                        <ol style="margin-top: 30px;">
                            <li>Can you play the # or the piano?</li>
                            <li>Is this music by your favourite pop #?</li>
                            <li>How many # were at the concert?</li>
                            <li>Do you know the # to this song?</li>
                            <li>Is Tim playing the # or the keyboard?</li>
                            <li>How many # did the online video have?</li>
                        <ol>

                        <div style="margin-top: 30px;">
                            <img src="img/FriendsPlus/Page102/E1/1.jpg" style="max-width: 100%" />
                        </div>
                    </div>
                `,
				answer: ['violin', 'band', 'fans', 'lyrics', 'drums', 'views'],
			},
		],
	},

	2: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P102-E2',
		exerciseKey: 'img/FriendsPlus/Page102/E2/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 75,
		maxLength: 6,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
                    Complete the words.
              `,
				color: '#4c3d9b',
			},
		],

		questions: [
			{
				title: `
                    <div style="width: 700px; margin-top: 20px;">
                        <ol>
                            <li>Everybody loves this song. It's going to be a big <strong>h</strong># this summer.</li>
                            <li>I'd like to be a pop star. I want to see my name up there in the <strong>b</strong># lights.</li>
                            <li>Tom thinks he'll be famous soon. He needs to wake up and take a look at the <strong>r</strong>#!</li>
                            <li>That young singer has an amazing <strong>v</strong># and she can dance well. She's going to be a <strong>s</strong>#!</li>
                        <ol>

                        <div style="margin-top: 30px;">
                            <img src="img/FriendsPlus/Page102/E2/1.jpg" style="max-width: 100%" />
                        </div>
                    </div>
                `,
				answer: ['it', 'right', 'eality', 'oice', 'tar'],
			},
		],
	},

	3: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P102-E3',
		exerciseKey: 'img/FriendsPlus/Page102/E3/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 510,
		maxLength: 46,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 24,
		},
		titleQuestion: [
			{
				num: '3',
				title: `
                    Write sentences with <i>be going to.</i>
              `,
				color: '#4c3d9b',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 680px">
                        <ol style='margin-top: 30px'>
                            <li>I / write / a new song #</li>
                            <li>we / learn / a musical instrument #</li>
                            <li>Lana Del Rey / not sing / her big hits #</li>
                            <li>they / watch / a One Direction video #</li>
                        </ol>
                    </div>  
                `,

				answer: [
					"I'm going to write a new song.",
					"We're going to learn a musical instrument.",
					"Lana Del Rey isn't going to sing her big hits.",
					"They're going to watch a One Direction video.",
				],
			},
		],
	},

	4: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P102-E4',
		exerciseKey: 'img/FriendsPlus/Page102/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '4',
				title: `
                    Choose the correct words.
                `,
				color: '#4c3d9b',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					borderRadius: '50%',
					borderColor: 'transparent',
					color: '#00aeef',
				},
				selectWordStyle: {
					padding: 5,
					border: 'solid 2px',
					borderColor: '#00aeef',
				},
				limitSelect: 1,
				listWords: [
					"We're_going_to / We'll",
					"you're_going_to / you'll",
					'will / is_going_to',
					"there'll / there's_going_to",
					"won't/ isn't_going_to",
					"she's_going_to / she'll",
				],
				answers: ['0-0', '1-4', '2-4', '3-0', '4-0', '5-0'],
				initialValue: [],
			},
			Layout: `
            <div style="width: 600px">
                <ol>
                    <li><strong><input id='0' type='Circle' /></strong> see Taylor Swift inconcert. I bought the tickets on Tuesday.</li>
                    <li>I'm sure <strong><input id='1' type='Circle' /></strong> like the show.</li>
                    <li>David <strong><input id='2' type='Circle' /></strong> play some new music later. He wrote it for this concert.</li>
                    <li>I imagine <strong><input id='3' type='Circle' /></strong> be a lot of people at the festival.</li>
                    <li>We like that singer, but our prediction is that he <strong><input id='4' type='Circle' /></strong> be famous.</li>
                    <li>My sister has got a new guitar and <strong><input id='5' type='Circle' /></strong> learn to play it.</li>
                </ol>

                <div style="margin-top: 30px; display: flex; justify-content: center;">
                    <img src="img/FriendsPlus/Page102/E2/1.jpg" style="max-width: 100%" />
                </div>
            </div>
          `,
		},
	},

	5: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P102-E5',
		exerciseKey: 'img/FriendsPlus/Page102/E5/Key/answerKey.png',
		audio: 'Audios/2-32 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 90,
		maxLength: 5,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '5',
				title: `
                    <audioimage name='2.32'></audioimage>
                    Listen to <i>Young Musician of the Year.</i>
                    One of the judges is talking about the three
                    contestants: Ibrahim, Hannah and Nathan.
                    Write <i>True</i> or <i></i>False.
                `,
				color: '#4c3d9b',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px;">
                        <ol>
                            <strong style="margin-left: -25px;">Ibrahim …</strong>
                            <li>needs to be a lot more confident. #</li>
                            <li>plays the guitar with energy. #</li>
                            <li>has only got one weakness. #</li>
                            <strong style="margin-left: -25px;">Hannah …</strong>
                            <li>isn't very ambitious. #</li>
                            <li>had success at the end of last weeks programme. #</li>
                            <strong style="margin-left: -25px;">Nathan …</strong>
                            <li>is very charming. #</li>
                            <li>has got one important strength. #</li>
                            <li>will be famous. #</li>
                        </ol>

                        <div style="margin-top: 30px; display: flex; justify-content: center;">
                            <img src="img/FriendsPlus/Page102/E2/1.jpg" style="max-width: 100%" />
                        </div>
                    </div>
                `,
				answer: [
					'False',
					'True',
					'True',
					'False',
					'True',
					'True',
					'False',
					'True',
				],
			},
		],
	},
};

export default json;
