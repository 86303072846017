import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {},
  2: {
    unit: 'Unit 3',
    id: 'SB7-U3-P43-E2',
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style="text-align: center"><img style="width: 74%" src="img/FriendsPlus/Page43/E2/1.jpg"/></div>
      `,
        answer: [],
      },
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB7-U3-P43-E3",
    audio: "",
    video: "",
    component: D1,
    padding: 0,
    textAlign: "center",
    fontSize: 26,
    maxLength: 1,
    marginTop: 4,
    hideRulesRequired: true,
    exerciseKey: "img/FriendsPlus/Page43/E3/Key/answerKey.png",
    questionImage: [
      [{ url: "img/FriendsPlus/Page43/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page43/E3/2.jpg" },
        { url: "img/FriendsPlus/Page43/E3/3.jpg", input: true, answer: "t" }, // 1 ngang
        { url: "img/FriendsPlus/Page43/E3/4.jpg" },
        { url: "img/FriendsPlus/Page43/E3/5.jpg", input: true, answer: "a" }, // 1 ngang
        { url: "img/FriendsPlus/Page43/E3/6.jpg", input: true, answer: "v" }, // 1 ngang, 2 dọc
        { url: "img/FriendsPlus/Page43/E3/7.jpg", input: true, answer: "e" }, // 1 ngang
        { url: "img/FriendsPlus/Page43/E3/8.jpg", input: true, answer: "l" }, // 1 ngang
        { url: "img/FriendsPlus/Page43/E3/9.jpg", input: true, answer: "l" }, // 1 ngang
        { url: "img/FriendsPlus/Page43/E3/10.jpg" },
        { url: "img/FriendsPlus/Page43/E3/11.jpg", input: true, answer: "d" }, // 1 ngang
        { url: "img/FriendsPlus/Page43/E3/12.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page43/E3/13.jpg" },
        { url: "img/FriendsPlus/Page43/E3/14.jpg", input: true, answer: "o" }, // 1 dọc
        { url: "img/FriendsPlus/Page43/E3/15.jpg" },
        { url: "img/FriendsPlus/Page43/E3/16.jpg", input: true, answer: "i" }, // 2 dọc
        { url: "img/FriendsPlus/Page43/E3/17.jpg" },
        { url: "img/FriendsPlus/Page43/E3/18.jpg", input: true, answer: "s" }, // 4 dọc
        { url: "img/FriendsPlus/Page43/E3/19.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page43/E3/20.jpg" },
        { url: "img/FriendsPlus/Page43/E3/21.jpg", input: true, answer: "o" }, // 1 dọc
        { url: "img/FriendsPlus/Page43/E3/22.jpg" },
        { url: "img/FriendsPlus/Page43/E3/23.jpg", input: true, answer: "f" }, // 5 ngang
        { url: "img/FriendsPlus/Page43/E3/24.jpg", input: true, answer: "e" }, // 5 ngang
        { url: "img/FriendsPlus/Page43/E3/25.jpg", input: true, answer: "l" }, // 5 ngang
        { url: "img/FriendsPlus/Page43/E3/26.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page43/E3/27.jpg" },
        { url: "img/FriendsPlus/Page43/E3/28.jpg", input: true, answer: "i" }, // 2 dọc
        { url: "img/FriendsPlus/Page43/E3/29.jpg" },
        { url: "img/FriendsPlus/Page43/E3/30.jpg", input: true, answer: "t" }, // 3 dọc
        { url: "img/FriendsPlus/Page43/E3/31.jpg" },
        { url: "img/FriendsPlus/Page43/E3/32.jpg", input: true, answer: "a" }, // 4 dọc, // 6 ngang
        { url: "img/FriendsPlus/Page43/E3/33.jpg", input: true, answer: "v" }, // 6 ngang
        { url: "img/FriendsPlus/Page43/E3/34.jpg", input: true, answer: "e" }, // 6 ngang
        { url: "img/FriendsPlus/Page43/E3/35.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page43/E3/36.jpg" },
        { url: "img/FriendsPlus/Page43/E3/37.jpg", input: true, answer: "m" }, // 7 ngang
        { url: "img/FriendsPlus/Page43/E3/38.jpg" },
        { url: "img/FriendsPlus/Page43/E3/39.jpg", input: true, answer: "t" }, // 2 dọc, 7 ngang
        { url: "img/FriendsPlus/Page43/E3/40.jpg" },
        { url: "img/FriendsPlus/Page43/E3/41.jpg", input: true, answer: "f" }, // 8 dọc
        { url: "img/FriendsPlus/Page43/E3/42.jpg" },
        { url: "img/FriendsPlus/Page43/E3/43.jpg", input: true, answer: "y" }, // 4 dọc
        { url: "img/FriendsPlus/Page43/E3/44.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page43/E3/45.jpg" },
        { url: "img/FriendsPlus/Page43/E3/46.jpg", input: true, answer: "e" }, // 2 dọc
        { url: "img/FriendsPlus/Page43/E3/47.jpg", input: true, answer: "x" }, // 9 ngang
        { url: "img/FriendsPlus/Page43/E3/48.jpg", input: true, answer: "p" }, // 9 ngang
        { url: "img/FriendsPlus/Page43/E3/49.jpg", input: true, answer: "l" }, // 9 ngang
        { url: "img/FriendsPlus/Page43/E3/50.jpg", input: true, answer: "o" }, // 9 ngang
        { url: "img/FriendsPlus/Page43/E3/51.jpg", input: true, answer: "r" }, // 9 ngang
        { url: "img/FriendsPlus/Page43/E3/52.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page43/E3/53.jpg" },
        { url: "img/FriendsPlus/Page43/E3/54.jpg", input: true, answer: "d" }, // 2 dọc
        { url: "img/FriendsPlus/Page43/E3/55.jpg" },
        { url: "img/FriendsPlus/Page43/E3/56.jpg", input: true, answer: "d" }, // 4 dọc
        { url: "img/FriendsPlus/Page43/E3/57.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page43/E3/58.jpg" },
        { url: "img/FriendsPlus/Page43/E3/59.jpg", input: true, answer: "n" }, // 8 dọc
        { url: "img/FriendsPlus/Page43/E3/60.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page43/E3/61.jpg" },
        { url: "img/FriendsPlus/Page43/E3/62.jpg", input: true, answer: "d" }, // 8 dọc
        { url: "img/FriendsPlus/Page43/E3/63.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page43/E3/64.jpg" }],
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'SB7-U3-P43-E4',
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style="text-align: center"><img style="width: 71%" src="img/FriendsPlus/Page43/E4/1.jpg"/></div>
      `,
        answer: [],
      },
    ],
  },
};

export default json;
