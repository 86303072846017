import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB7-U2-P22-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 40,
    stylesTextInput: {
      border: "2px solid",
      borderBottom: "dash",
      height: 40,
      // fontSize: 30,
    },
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page22/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Read the article. Which of topics A-E are in the text? Put the topics in order.",
        color: "#dd2c3b",
        // prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    maxLength: 1,
    questions: [
      {
        title: `
          <div style='line-height:50px'>
            <b>A</b> Emojis to communicate
            <span style='margin-left:10px'>#</span><br> 
            <b>B</b> Fashion in Japan
            <span style='margin-left:75px'>#</span><br> 
            <b>C</b> Emojis in everyday life
            <span style='margin-left:19px'>#</span><br> 
            <b>D</b> Emojis and emotions
            <span style='margin-left:35px'>#</span><br> 
            <b>E</b> Where emojis are from
            <span style='margin-left:17px'>#</span><br> 
          </div>
          <img  src='img/FriendsPlus/Page22/E1/1.jpg'>
        `,
        answer: ["1", "2", "2", "4", "3"],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB7-U2-P22-E2",
    audio: "Audios/1-16 Friends Plus 7.mp3",
    video: "",
    component: T6,
    maxLength: 5,
    inputSize: 80,
    textAlign: "center",
    // selectStyle: {
    //   // color: "#da1f73",
    //   width: 150,
    //   textAlign: "center",
    //   fontWeight: "bold",
    // },
    // selectOptionRandom: true,
    // selectOptionValues: ["Mike", "Anna", "Not used"],
    exerciseKey: "img/FriendsPlus/Page22/E2/Key/answerKey.png",
    titleImage: "",
    questionImage: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "<audioimage name='1.16'></audioimage>Read and listen to the article. Write <i>True</i> or <i>False</i>.",
        color: "#dd2c3b",
      },
    ],
    questions: [
      {
        title: `
      <div style='line-height:50px'>
        <b>1</b> Lucy is reading a message made of emoticons
        #<br> 
        <b>2</b> There are emojis on clothes.
        #<br> 
        <b>3</b> Emojis show only Japanese culture
        #<br> 
        <b>4</b> The people and faces in the new emojis use a lot more colours.
        #<br> 
        <b>5</b> Emojis don’t help with communication.
        #<br> 
      </div>
        `,
        answer: ["false", "true", "false", "true", "false"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB7-U2-P22-E3",
    video: "",
    audio: "",
    component: UI,
    titleQuestion: [
      {
        num: "3",
        title:
          "Use a dictionary to check the meaning of the words in <span style='color:#1dbfd7'>blue</span> in the text.",
        color: "#dd2c3b",
        prefix: {
          icons: [],
          text: "VOCABULARY PLUS",
        },
      },
    ],
    recorder: true,

    questionImage: [[{ url: "img/FriendsPlus/Page22/E1/1.jpg" }]],
  },
  4: {
    unit: "Unit 2",
    id: "SB7-U2-P22-E4",
    video: "",
    audio: "",
    component: UI,
    titleQuestion: [
      {
        num: "4",
        title: "Work in pairs. Do you like emojis? Why / Why not?",
        color: "#dd2c3b",
        prefix: {
          icons: ["far fa-comment"],
          text: "USE IT!",
        },
      },
    ],
    recorder: true,

    questionImage: [[{ url: "img/FriendsPlus/Page22/E1/1.jpg" }]],
  },
  5: {
    unit: "Unit 2",
    id: "SB7-U2-P22-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "",
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "<img src='img/FriendsPlus/Page26/E5/1.jpg' style='margin-bottom: 5px;' /> Work in pairs. Read the situation. Practise a new dialogue using the key phrases and the dialogue in exercise 1 to help you.",
        color: "#da1f73",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='background-color: rgb(246,211,219); padding: 20px; border-radius: 20px;'>
            <div style='border-width: 2px; border-style: solid; border-color: rgb(218,31,115); padding: 10px;'>
              <b>Student A:</b> Phone student B and ask if he / she’s interested in going shopping.
            </div>
            <div style='border-width: 2px; border-style: solid; border-color: rgb(218,31,115); padding: 10px; margin-top: 10px;'>
              <b>Student B:</b> Ask student A for details and 
              say that you aren’t sure: you’re playing 
              video games at the moment, and you’re 
              winning. Ask if you can go another time.
            </div>
          </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
