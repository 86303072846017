import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: {
    unit: "Unit 5",
    id: "SB7-U5-P61-E1",
    audio: "",
    video: "",
    component: T6,
    // recorder: true,
    questionImage: [],
    maxLength: 14,
    textAlign: "center",
    // textareaStyle: { width: 700 },
    // hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page61/E1/Key/answerKey.png",
    stylesTextInput: {
      borderBottom: "dotted 1px",
      width: 176,
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Complete the sentences with the words from the reading text on page 60.",
        color: "#5dc8dc",
        // prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questions: [
      {
        title: `
        
          <div style='border-radius:20px; padding:10px;width:25cm; background:rgb(254, 243, 195)'>
                  Yes, you can. You should practise bodybuilding regularly and have a good diet 
                  like this: <br>
                  <b>• Breakfast:</b> scrambled eggs, and vegetables<br>
                  <b>• Snack:</b> whey protein shake, soft-boiled eggs<br>
                  <b>• Lunch:</b> grilled chicken breast, mixed greens, and baked
                  sweet potato<br>
                  <b>• Snack:</b> hard-boiled eggs and carrot sticks<br>
                  <b>• Dinner:</b> fish, green beans with brown rice<br>
                  Just follow our instructions and you won’t look different from a famous bodybuilder.
          </div>
          <div style='border-radius:20px; padding:10px;width:25cm; background:rgb(214 238 248)'>
                  No, you cannot if you do not eat as many calories
                  as them. A basketball player needs between
                  2,500 and 5,000 calories per day. Bananas contain
                  helpful <span style='color:rgb(87 194 217)'>nutrients</span> that make the body feel full of
                  energy. Each banana, on average, contains 30g
                  of carbohydrates, and about 0.01g of vitamin C.
                  Especially, bananas are a rich source of potassium,
                  about 0.4g of each. Potassium <span style='color:rgb(87 194 217)'>reduces </span>  sudden
                  pain in muscles. That is why a wise basketball
                  player often enjoys a banana before or during the
                  game.
          </div>
          <div >
            <b>1</b> You should practise bodybuilding regularly and have a good diet # this <br>
            <b>2</b> You won’t look # a famous bodybuilder. <br>
            <b>3</b> Can I jump # NBA stars? <br>
            <b>4</b> You cannot if you do not eat # calories # them.
             <br>
          </div>

        `,
        answer: ["like", "different from", "as high as", "as many", "as"],
      },
    ],
  },

  2: {
    unit: "Unit 5",
    id: "SB7-U5-P61-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page61/E2/Key/answerKey.png",
    inputSize: 175,
    stylesTextInput: { borderBottom: "dotted 1px" },
    maxLength: 14,
    textAlign: "center",
    checkUppercase: true,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Look again at exercise 1, then complete the rules with <i>like, different from, as … as</i>.",
        color: "#1ec0d9",
      },
    ],
    // hintBox: [
    //   {
    //     src: ["doesn't", "don't", "end", "habits", "routines", "start"],
    //     borderColor: "#1bb7d0",

    //     width: 350,
    //   },
    // ],
    questions: [
      {
        title: `
        
          <div style='border-radius:20px; padding:10px;width:25cm; background:rgb(254, 243, 195)'>
                  Yes, you can. You should practise bodybuilding regularly and have a good diet 
                  like this: <br>
                  <b>• Breakfast:</b> scrambled eggs, and vegetables<br>
                  <b>• Snack:</b> whey protein shake, soft-boiled eggs<br>
                  <b>• Lunch:</b> grilled chicken breast, mixed greens, and baked
                  sweet potato<br>
                  <b>• Snack:</b> hard-boiled eggs and carrot sticks<br>
                  <b>• Dinner:</b> fish, green beans with brown rice<br>
                  Just follow our instructions and you won’t look different from a famous bodybuilder.
          </div>
          <div style='border-radius:20px; padding:10px;width:25cm; background:rgb(214 238 248)'>
                  No, you cannot if you do not eat as many calories
                  as them. A basketball player needs between
                  2,500 and 5,000 calories per day. Bananas contain
                  helpful <span style='color:rgb(87 194 217)'>nutrients</span> that make the body feel full of
                  energy. Each banana, on average, contains 30g
                  of carbohydrates, and about 0.01g of vitamin C.
                  Especially, bananas are a rich source of potassium,
                  about 0.4g of each. Potassium <span style='color:rgb(87 194 217)'>reduces </span>  sudden
                  pain in muscles. That is why a wise basketball
                  player often enjoys a banana before or during the
                  game.
          </div>
          <div >
            <b>1</b> You should practise bodybuilding regularly and have a good diet ...... this <br>
            <b>2</b> You won’t look ...... a famous bodybuilder. <br>
            <b>3</b> Can I jump ...... NBA stars? <br>
            <b>4</b> You cannot if you do not eat ...... calories ...... them.
             <br>
          </div>


      <div style='margin:10px;border-radius:20px; border:4px solid rgb(93 200 220);padding: 5px; width: 15cm'>
        <div style='font-weight:900;color:white;background:rgb(93 200 220);padding-left:20px;border-radius:15px'>RULES</div>
        <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'>We can use # to show something that is <b>similar</b> to something else.</div>
        </div>
        <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'>We can use # to show something that is <b>not similar</b> to something else.</div>
        </div>
        <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:20px'>We can use # to express comparison of
          equality.</div>
        </div>
        <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:20px'>We also use # before and after many /
          much + noun.</div>
        </div>
        
      </div>
        `,
        answer: ["like", "different from", "as...as", "as...as"],
      },
    ],
  },

  3: {
    unit: "Unit 5",
    id: "SB7-U5-P61-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page61/E3/Key/answerKey.png",
    inputSize: 475,
    stylesTextInput: { borderBottom: "dotted 1px" },
    // maxLength: 14,
    // textAlign: "center",
    checkUppercase: true,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Look at the information about the athletes. Then complete sentences 1–5 using comparative forms of the adjectives.",
        color: "#1ec0d9",
      },
    ],
    // hintBox: [
    //   {
    //     src: ["doesn't", "don't", "end", "habits", "routines", "start"],
    //     borderColor: "#1bb7d0",

    //     width: 350,
    //   },
    // ],
    questions: [
      {
        title: `
          Florence / Archie (fast)
          <div style='color:gray'>Florence was faster than Archie.</div>
          <div style=''>
            <b>1</b>&emsp;Archie / Carl (fast)<br>#<br>
            <b>2</b>&emsp;Carl / Florence (heavy)<br>#<br>
            <b>3</b>&emsp;Carl / Archie (good)<br>#<br>
            <b>4</b>&emsp;Archie / Florence (tall)<br>#<br>
            <b>5</b>&emsp;Archie / Carl (short)<br>#<br>
          </div>
      
        `,
        answer: [
          "Archie wasn't as fast as Carl.",
          "Carl was heavier than Florence.",
          "Carl was better than Archie.",
          "Archie wasn't as tall as Florence",
          "Archie was shorter than Carl.",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB7-U5-P61-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page61/E4/Key/answerKey.png",
    inputSize: 175,
    stylesTextInput: { borderBottom: "dotted 1px" },
    maxLength: 14,
    textAlign: "center",
    checkUppercase: true,
    // checkDuplicated: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the sentences with <i>like, different from, as … as.</i>",
        color: "#1ec0d9",
      },
    ],
    // hintBox: [
    //   {
    //     src: ["doesn't", "don't", "end", "habits", "routines", "start"],
    //     borderColor: "#1bb7d0",

    //     width: 350,
    //   },
    // ],
    questions: [
      {
        title: `
          <div style='display:flex;'>
            <b>1</b>
            <div style='margin-left:20px'>Mandy doesn’t play tennis # well #
            her sister.</div>
          </div>
          <div style='display:flex;'>
            <b>2</b>
            <div style='margin-left:20px'>This art museum is definitely #
            the history museum.</div>
          </div>
          <div style='display:flex;'>
            <b>3</b>
            <div style='margin-left:20px'>Do you think learning Spanish is #
            difficult # learning German?</div>
          </div>
          <div style='display:flex;'>
            <b>4</b>
            <div style='margin-left:20px'>That big animal over there looks #
              an elephant.</div>
          </div>
          <div style='display:flex;'>
            <b>5</b>
            <div style='margin-left:20px'>Lan is 1.62 metres tall, but I’m 1.70 metres
            tall. Her height is # mine.</div>
          </div>
      
        `,
        answer: [
          "as",
          "as",
          "different from",
          "as",
          "as",
          "like",
          "different from",
        ],
      },
    ],
  },

  //
  5: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P61-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Work in pairs. Compare opinions about the things in box B using comparative and superlative forms of the adjectives in box A.",
        color: "#5dc8dc",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page61/E5/1.jpg" }],
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB7-U5-P61-E6",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700 },
    hideBtnFooter: true,
    finished: {
      text: "Make comparisons between two famous athletes you know well. Then compare with a partner.",
    },
    questions: [
      {
        title: `
        <finished></finished>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};
// 4: {
//   // Exercise num
//   unit: "Unit 8",
//   id: "SB5-U8-P61-E4",
//   audio: "",
//   video: "",
//   component: DrawColor,
//   exerciseKey: "img/FriendsPlus/Page61/E4/Key/answerKey.png",
//   titleQuestion: [
//     {
//       num: "4",
//       title:
//         "Circle letter <i>c</i> that says <i>s</i> and underline letter <i>g</i> that says <i>j</i>.",
//       color: "#394893",
//     },
//   ],
//   question: {
//     Write: {
//       underlineStyle: {},
//       inputStyle: {},
//       answers: [],
//       initialValue: [],
//     },
//     Circle: {
//       listStyle: {
//         normal: {},
//         circle: {
//           padding: 0.5,
//           border: "solid 0.5px",
//           borderRadius: "50%",
//           borderColor: "#4285F4",
//         },
//         underline: { borderBottom: "1px solid #4285F4" },
//         // square: { padding: 3, border: 'solid 2px', borderColor: '#4285F4' },
//       },
//       // limitSelect: 1,
//       listWords: [
//         "c|u|c|u|m|b|e|r", //0

//         "r|a", //1
//         "e", //1

//         "c|r|a|y|o|n", //2
//         "c|e|r|e|a|l", //3
//         "c|i|t|y", //4
//         "c|a|m|e|r|a", //5
//         "s|t|a|g|e", //6
//         "g|u|i|t|a|r", //7
//         "g|o|a|l", //8
//         "g|i|r|a|f|f|e", //9
//         "p|a|g|e", //10
//         "f|i|n|g|e|r", //11
//       ],
//       answers: {
//         "4-0": "circle",
//         "5-0": "circle",
//         "10-0": "underline",
//         "11-4": "underline",
//         "7-6": "underline",
//       },

//       initialValue: {},
//     },
//     Layout: `
//       <div style=" position: relative; ">
//           <div> <img src='img/FriendsPlus/Page61/E4/1.jpg' /> </div>

//           <div style="position: absolute;top: 130px;left: 35px;font-size: 20px;line-height: 1.35;text-align: center;">
//             <b><input id='0' type='Circle' /></b>
//           </div>

//           <div style="position: absolute;top: 130px;left: 200px;font-size: 20px;line-height: 1.35;text-align: center;">

//             <b><input id='1' type='Circle' /><span style="padding: 0.5px; border: 0.5px solid rgb(66, 133, 244); border-radius: 50%;">c</span><input id='2' type='Circle' /></b>
//           </div>

//           <div style="position: absolute;top: 130px;left: 400px;font-size: 20px;line-height: 1.35;text-align: center;">
//             <b><input id='3' type='Circle' /></b>
//           </div>

//           <div style="position: absolute;top: 130px;left: 550px;font-size: 20px;line-height: 1.35;text-align: center;">
//             <b><input id='4' type='Circle' /></b>
//           </div>

//           <div style="position: absolute;top: 297px;left: 70px;font-size: 20px;line-height: 1.35;text-align: center;">
//             <b><input id='5' type='Circle' /></b>
//           </div>

//           <div style="position: absolute;top: 297px;left:190px;font-size: 20px;line-height: 1.35;text-align: center;">
//             <b><input id='6' type='Circle' /></b>
//           </div>

//           <div style="position: absolute;top: 297px;left: 400px;font-size: 20px;line-height: 1.35;text-align: center;">
//             <b><input id='7' type='Circle' /></b>
//           </div>

//           <div style="position: absolute;top: 297px;left: 550px;font-size: 20px;line-height: 1.35;text-align: center;">
//             <b><input id='8' type='Circle' /></b>
//           </div>

//           <div style="position: absolute;top: 465px;left: 70px;font-size: 20px;line-height: 1.35;text-align: center;">
//             <b><input id='9' type='Circle' /></b>
//           </div>

//           <div style="position: absolute;top: 465px;left:190px;font-size: 20px;line-height: 1.35;text-align: center;">
//             <b><input id='10' type='Circle' /></b>
//           </div>

//           <div style="position: absolute;top: 465px;left: 400px;font-size: 20px;line-height: 1.35;text-align: center;">
//             <b><input id='11' type='Circle' /></b>
//           </div>

//           <div style="position: absolute;top: 465px;left: 550px;font-size: 20px;line-height: 1.35;text-align: center;">
//             <b><input id='12' type='Circle' /></b>
//           </div>

//       </div>
//     `,
//   },
// },

export default json;
