import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Writing',
		id: 'SB7-W-P89-E1',
		exerciseKey: 'img/FriendsPlus/Page89/E1/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 590,
		maxLength: 70,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '1',
				title: `
				Read the reviews. Which song has great
				singers? Which song belongs to Viet Nam?
				`,
				color: '#05a951',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 600px; margin-top: 20px;">
            			<div style="display: flex; justify-content: center;"><img src="img/FriendsPlus/Page89/E1/1.jpg" style="max-width: 100%;" /></div>      
						
						<div style="margin-top: 20px; padding-left: 10px; margin-bottom: 16px;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>
									Which song has great singers? <br/> #
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>
									Which song belongs to Viet Nam? <br/> #
								</span>
							</div>
						</div>
					</div>
                `,
				answer: [
					'We are the world by Michael Jackson and Lionel Richie',
					'Trống cơm (a Vietnamese folk song)',
				],
			},
		],
	},
	2: {
		unit: 'Writing',
		id: 'SB7-W-P89-E2',
		exerciseKey: 'img/FriendsPlus/Page89/E2/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 170,
		// inputSize: 330,
		maxLength: 29,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
          Complete the key phrases with words from the text.
        `,
				color: '#05a951',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 20px; font-size: 20px; display: flex; align-items: flex-start;">
						<img src="img/FriendsPlus/Page89/E1/1.jpg" style="width: 40%;" />
						<div style="margin-left: 20px; margin-top: 30px; width: 520px; padding-bottom: 20px;border: 1px solid rgb(79, 188, 121); border-radius: 20px; overflow: hidden; background-color: rgba(207,232,211,1);">
							<div style="font-weight: bold; font-size: 1.4rem; background-color: rgb(79, 188, 121); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
								Key Phrases
							</div>
							
							<div style="margin-top: 10px; padding: 0 10px 0 20px">
								<div style="color: rgb(79, 188, 121); font-weight: bold; font-size: 1.4rem">
								Reviewing songs
								</div>

								<div>
								I like <sup>1</sup><input id='0' width='290px' /> .<br/>
								His songs have usually got <sup>2</sup># .<br/>
								The lyrics are <sup>3</sup># .<br/>
								This song is <sup>4</sup># .<br/>
								If you like dancing, <sup>5</sup># .<br/>
								I often dance <sup>6</sup># .<br/>
								It sounds <sup>7</sup><input id='6' width='90px' /> .<br/>
								</div>
							</div>
						</div>
					</div>
        		`,
				answer: [
					"Michael Jackson's songs a lot",
					'good melodies',
					'really interesting',
					'the best of his',
					'you will love this',
					'to it cheerfully',
					'happy',
				],
			},
		],
	},
	3: {
		unit: 'Writing',
		id: 'SB7-W-P89-E3',
		exerciseKey: 'img/FriendsPlus/Page89/E3/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 140,
		maxLength: 11,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '3',
				title: `
          Find the <span style="color: #03b2dd">blue</span> words in the reviews and say what they refer to.
        `,
				color: '#05a951',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 20px; display: flex; align-items: flex-start;">
						<img src="img/FriendsPlus/Page89/E1/1.jpg" style="max-width: 100%;" />

						<div style="margin-left: 30px; width: 300px; padding: 20px;border: 1px solid rgb(79, 188, 121); border-radius: 20px; overflow: hidden; background-color: rgba(207,232,211,1); display: flex;">
							<div style="padding-left: 10px; margin-bottom: 16px;">
								<div style="display: flex;">
									<span style="padding-right: 78px;">it</span>
									<span style="font-style: italic; color: rgb(88, 88, 90);">the song</span>
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">1</strong>
									<span style="padding-right: 10px;">they</span>#
									<span></span>
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">2</strong>
									<span style="padding-right: 22px;">me</span>#
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">3</strong>
									<span style="padding-right: 25px;">his</span>#
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">4</strong>
									<span style="padding-right: 18px;">you</span>#
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">5</strong>
									<span style="padding-right: 18px;">this</span>#
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">6</strong>
									<span style="padding-right: 48px;">I</span>#
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">7</strong>
									<span style="padding-right: 42px;">It</span>#
								</div>
							</div>
						</div>
					</div>
        		`,
				answer: [
					'the singers',
					'reviewer',
					'the songs',
					'listeners',
					'the song',
					'reviewer',
					'The song',
				],
			},
		],
	},
	4: {
		unit: 'Writing',
		id: 'SB7-W-P89-E4',
		exerciseKey: 'img/FriendsPlus/Page89/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 71,
		maxLength: 4,
		stylesTextInput: {
			fontSize: 23,
			textAlign: 'center',
		},
		hintBox: [
			{
				src: ['he', 'her', 'hers', 'it', 'my', 'she', 'their', 'them', 'they'],
				borderColor: '#05a951',
				width: 600,
			},
		],
		titleQuestion: [
			{
				num: '4',
				title: `
          Replace the <span style="color: #03b2dd">blue</span> phrases with five pronouns from the box.
        `,
				color: '#05a951',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 20px; width: 720px;">
						<hintbox id='0'></hintbox>

						<div style="margin-top: 30px; padding-left: 10px;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>The song is OK, but <span style="color: rgb(23,174,217)">the song</span> # isn't fantastic</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>One Direction? I really can't listen to <span style="color: rgb(23,174,217)">One Direction</span> #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>Taylor Swift has got a lot of fans and <span style="color: rgb(23,174,217)">her fans</span> # are cool</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>John Newman sings on Calvin Harris's song. <span style="color: rgb(23,174,217)">John Newman and Calvin Harris</span> # are brilliant</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">5</strong>
								<span>My brother is really into Calvin Harris. <span style="color: v">My brother</span> # is a big fan</span>				
							</div>
						</div>
					</div>
				`,
				answer: ['it', 'them', 'hers', 'They', 'He'],
			},
		],
	},
	5: {
		unit: 'Writing',
		id: 'SB7-W-P89-E5',
		exerciseKey: '',
		audio: '',
		video: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 600,
		maxLength: 80,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 20,
		},

		textareaStyle: { width: 690 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
          Follow the steps in the Writing Guide.
				`,
				color: '#08a64f',
				prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' },
			},
		],
		questions: [
			{
				title: `
					<div>
						<div style="width: 670px; border: 1px solid rgb(79, 188, 121); border-radius: 20px; overflow: hidden; background-color:white; font-size: 20px;">
							<div style="font-weight: bold; background-color: rgb(79, 188, 121); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
								WRITING GUIDE
							</div>
							<div style="margin-top: 5px; padding: 0 20px">
								<div style="color: rgb(79, 188, 121); font-weight: bold;">
									<span style="text-transform: uppercase;">A</span> <span style="color: rgba(13,104,73,1);">TASK</span>
								</div>
								<span>Think of two songs you like and write short reviews (60 - 80 words) of them.</span>
							</div>

							<div style="margin-top: 5px; padding: 0 20px">
								<div style="color: rgb(79, 188, 121); font-weight: bold;">
									<span style="text-transform: uppercase;">B</span> <span style="color: rgba(13,104,73,1); text-transform: uppercase">THINK AND PLAN</span>
								</div>
								<div style="padding-left: 10px; margin-bottom: 16px;">
									<div style="display: flex;">
										<strong style="padding-right: 10px;">1</strong>
										<span>Who wrote the song?</span>				
									</div>
									<div style="display: flex;">
										<strong style="padding-right: 10px;">2</strong>
										<span>What kind of music is it?</span>				
									</div>
									<div style="display: flex;">
										<strong style="padding-right: 10px;">3</strong>
										<span>What do you like about the artist, music and lyrics?</span>				
									</div>
									<div style="display: flex;">
										<strong style="padding-right: 10px;">4</strong>
										<span>What will fans and other people think about the song?</span>				
									</div>
									<div style="display: flex;">
										<strong style="padding-right: 10px;">5</strong>
										<span>Will it be a hit, in your opinion?</span>				
									</div>
								</div>
							</div>

							<div style="margin-top: 5px; padding: 0 20px">
								<div style="color: rgb(79, 188, 121); font-weight: bold;">
									<span style="text-transform: uppercase;">C</span> <span style="color: rgba(13,104,73,1);">WRITE</span>
								</div>
								<span style="margin-left: 20px;">
									Read the model text again and look at your notes. 
									Then write your review and include some of the key phrases. 
									Exchange your reviews with other people.
                				</span>
							</div>

							<div style="margin-top: 5px; padding: 0 20px">
								<div style="color: rgb(79, 188, 121); font-weight: bold;">
									<span style="text-transform: uppercase;">D</span> <span style="color: rgba(13,104,73,1); text-transform: uppercase">CHECK</span>
								</div>
								<div style="display: flex;">
									<ul>
										<li>References</li>
										<li>Capital letters</li>
									</ul>
									<ul style="margin-left: 50px;">
										<li><i>will</i> for prediction</li>
										<li>Pronouns</li>
									</ul>
								</div>
							</div>
						</div>

						<textarea id="8" rows="3"></textarea>
					</div>
                `,
				answer: [
				],
			},
		],
	},
};

export default json;
