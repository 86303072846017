import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 3',
    id: 'SB7-U3-P39-E1',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page39/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title: 'Choose the correct option a or b. Then check your answers in the text on page 38.',
        color: '#1cbed7',
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
          margin: '0 50px',
        },
        selectWordStyle: {
          padding: 3,
          border: 'solid 2px',
          borderColor: '#1dbfd7',
        },
        limitSelect: 1,
        listWords: [
          'a._In_Egypt | b.In_Rome ', //0
          'a._friendly. | b._unfriendly. ', //1
          "a._had | b._didn't_have ", //2
          "a._Yes,_she_did. | b._No,_she_didn't. ", //3
        ],
        answers: ['0-0', '1-6', '2-0', '3-0'],
        initialValue: [],
      },
      Layout: `
        <div><b>1</b> Where did Jade see the Pyramids?</div>
        <div><input id='0' type='Circle' /></div>
        <div><b>2</b> The dinosaurs looked ...</div>
        <div><input id='1' type='Circle' /></div> 
        <div><b>3</b> She ... dinner with Queen Victoria in London.</div>
        <div><input id='2' type='Circle' /></div> 
        <div><b>4</b> Did Jade watch a football match in Brazil?</div>
        <div><input id='3' type='Circle' /></div> 
      `,
    },
  },
  2: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB7-U3-P39-E2',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page39/E2/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: '0 5px', fontWeight: 600, color: '#20c1d8', fontSize: 24 },
        selectWordStyle: { border: '2px solid #20c1d8' },
        limitSelect: 1,
        listWords: ['the_same / different', 'base / past simple', 'base / past simple'],
        answers: ['0-0', '1-0', '2-0'],
        initialValue: [],
      },
      Layout: `
        <div>
          <b>
            <div style="display: flex; align-items: center; max-height: 150px">
              <div style="color: rgb(28, 190, 215); font-size: 80px;" >2 &nbsp</div>
              <div style="width: 900px">Study the sentences in exercise 1. Then choose
              the correct words in the Rules.</div>
            </div>
          </b>
        </div>
        <img  style="width: 74%" src='img/FriendsPlus/Page39/E2/1.jpg'/>
        <div><b>1.</b>  The past simple form of a verb is <input id='0' type='Circle' /> for all persons.</div>
        <div><b>2.</b> In negative sentences, we use didn't + the <input id='1' type='Circle' /> form of the verb.</div>
        <div><b>3.</b> In questions, we use (question word) did + subject + the <input id='2' type='Circle' /> form of the verb.</div>
      `,
    },
  },
  3: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB7-U3-P39-E3',
    component: T6,
    inputSize: 500,
    exerciseKey: 'img/FriendsPlus/Page39/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Order the words to make sentences and questions.', color: '#1dbfd7' }],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=" position: relative; line-height: 50px ">
             <div><b>1.</b> her grandparents / talk / Jade / did / to / ?</div>
             <div>#</div>
             <div><b>2.</b> you / rice / do / noodles / or / like / ?</div>
             <div>#</div>
             <div><b>3.</b> visited / Jim / summer / Nha Trang / last </div>
             <div>#</div>
             <div><b>4.</b> meet / your / where / parents / did / ?</div>
             <div>#</div>
             <div><b>5.</b> we / yesterday / play / didn't / football </div>
             <div>#</div>
          </div>
          `,
        answer: [
          'Did Jade talk to her grandparents?',
          'Do you like rice or noodles?',
          'Jim visited Nha Trang last summer.',
          'Where did your parents meet?',
          "We didn't play football yesterday.",
        ],
      },
    ],
  },
  4: {
    unit: 'Unit 3',
    id: 'SB7-U3-P39-E4',
    audio: 'Audios/1-35 Friends Plus 7.mp3',
    component: T6,
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style="text-align: center"><img style="width: 74%" src="img/FriendsPlus/Page39/E4/1.jpg"/></div>
      `,
        answer: [],
      },
    ],
  },
  5: {
    unit: 'Unit 3',
    id: 'SB7-U3-P39-E5',
    component: UI,
    questionImage: [[{ url: 'img/FriendsPlus/Page39/E5/1.jpg' }]],
  },
  6: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB7-U3-P39-E6',
    component: T6,
    stylesTextInput: { borderBottom: 'none', fontSize: 20 },
    inputSize: '162px',
    questionImage: [],
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page39/E6/Key/answerKey.png',
    questions: [
      {
        title: `
        <div style='position: relative;'>
          <img style="width: 65%" src='img/FriendsPlus/Page39/E6/1.jpg' />
          <div style=" position: absolute; top: 145px; left: 270px; ">#</div>
          <div style=" position: absolute; top: 197px; left: 75px; ">#</div>
          <div style=" position: absolute; top: 224px; left: 65px; ">#</div>
          <div style=" position: absolute; top: 251px; left: 55px; ">#</div>
          <div style=" position: absolute; top: 277px; left: 255px; ">#</div>
          <div style=" position: absolute; top: 303px; left: 140px; ">#</div>
          <div style=" position: absolute; top: 330px; left: 125px; ">#</div>
          <div style=" position: absolute; top: 357px; left: 110px; ">#</div>
          <div style=" position: absolute; top: 382px; left: 75px; ">#</div>
          <div style=" position: absolute; top: 409px; left: 95px; ">#</div>
        </div>
        `,
        answer: [
          'travelled',
          'took',
          'arrived',
          "didn't have",
          'started',
          "didn't have",
          'got',
          "didn't play",
          'lost',
          "wasn't",
        ],
      },
    ],
  },
  7: {
    unit: 'Unit 3',
    id: 'SB7-U3-P39-E7',
    component: T6,
    inputSize: '160px',
    exerciseKey: 'img/FriendsPlus/Page37/E7/Key/answerKey.png',
    checkUppercase: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '7',
        title: '  Work in pairs. Ask and answer about your recent past events.',
        color: '#20c1d8',
        prefix: 'default',
      },
    ],
    hintBox: [
      {
        src: ['Did you have pizza last night ? '],
        borderColor: '#20c1d8',
        arrow: true,
        position: 1,
        width: 280,
      },
      {
        src: ['No, I didn’t. I had phở.'],
        borderColor: '#20c1d8',
        arrow: true,
        position: 2,
        width: 250,
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex; justify-content: space-between; width: 600px; margin-top: 40px; text-align: center;'>
            <div><hintbox id='0'></hintbox></div>
            <div style=" margin: 30px 0 0 40px"><hintbox id='1'></hintbox></div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  8: {
    unit: 'Unit 3',
    id: 'SB7-U3-P39-E8',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1050 },
    hideBtnFooter: true,
    finished: {
      text: 'Imagine you went back in time. Write a description of where you went, what you did, and who and what you saw',
    },
    questions: [
      {
        title: `
          <finished></finished>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
