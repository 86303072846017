import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import MC2 from "../../components/ExcerciseTypes/MultipleChoice/MC2"


const json = {
  1: {
    unit: 'Unit 6',
    id: 'SB7-U6-P72-E1',
    audio: 'Audios/2-06 Friends Plus 7.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page72/E1/Key/answerKey.png',
    stylesTextInput: { borderBottom: "1px dashed", textAlign: "center" },
    inputSize: 190,
    questionImage: [],
    questions: [
      {
        title: `
            <div>
                <div><img src='img/FriendsPlus/Page72/E1/2.png' style='width:500px'/></div>
                <div><img src='img/FriendsPlus/Page72/E1/1.png' /></div>
                <div style='display:flex; justify-content: space-around;'>
                    <div style='display:flex; flex-direction: column; align-items: flex-end; '>
                        <div><b>1</b> #</div>
                        <div><b>2</b> #</div>
                        <div><b>3</b> #</div>
                        <div><b>4</b> #</div>
                    </div>
                    <div style='display:flex; flex-direction: column; align-items: flex-end;'>
                        <div><b>5</b> #</div>
                        <div><b>6</b> #</div>
                        <div><b>7</b> #</div>
                        <div><b>8</b> #</div>
                    </div>
                    <div style='display:flex; flex-direction: column; align-items: flex-end;'>
                        <div><b> 9</b> #</div>
                        <div><b> 10</b> #</div>
                        <div><b> 11</b> #</div>
                    </div>
                </div>
            </div>
                    `,
        answer: [
          'tent',
          'sleeping bag',
          'rope',
          'map',
          'water bottle',
          'mirror',
          'lighter',
          'compass',
          'first-aid kit',
          'torch',
          'knife',
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB7-U6-P72-E2",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "1px dashed", textAlign: "left" },
    textareaStyle: { width: 500 },
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "2",
        title:
          'Read the introduction to <i>Desert Challenge</i>. Which objects in exercise 1 will people probably need on a desert trek?',
        color: "#f58023",
      },
    ],
    titleImage: "",
    hintBox: [
      {
        src: [
          'tent',
          'sleeping bag',
          'rope',
          'map',
          'water bottle',
          'mirror',
          'lighter',
          'compass',
          'first-aid kit',
          'torch',
          'knife',
        ],
        borderColor: '#f58023',
        width: 500,
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style='display:flex;'>
              <div>
               <hintbox id='0'></hintbox>
               <textarea id="0" rows="7" ></textarea>
              </div>
              <div style='margin-left: 10px; width:500px;'><img src='/img/FriendsPlus/Page72/E2/3.png'/></div>
            </div>
                    `,
        answer: [],
      },
    ],
  },
  3:
  {
    unit: "Unit 6",
    id: "SB7-U6-P72-E3",
    audio: 'Audios/2-07 Friends Plus 7.mp3',
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page72/E3/Key/answerKey.png",
    stylesTextInput: { borderBottom: "1px dashed", textAlign: "center" },
    inputSize: 175,
    titleQuestion: [
      {
        num: "3",
        title:
          '<audioimage name= "2.07"></audioimage> Listen to Kay Freeman talking about the survival course she went on. Which equipment from exercise 1 does she say is useful?',
        color: "#f58023",
      },
    ],
    titleImage: "",
    questionImage: [],
    // checkDuplicated: true,
    hintBox: [
      {
        src: [
          'tent',
          'sleeping bag',
          'rope',
          'map',
          'water bottle',
          'mirror',
          'lighter',
          'compass',
          'first-aid kit',
          'torch',
          'knife',
        ],
        borderColor: '#f58023',
        width: 700,
      },
    ],
    questions: [
      {
        title: `
                      <img src='/img/FriendsPlus/Page72/E2/1.png' style=''/>
                      <div style='margin-left: 100px;'><hintbox id='0'></hintbox></div>
                      <div style='display:flex; flex-direction: row; justify-content: space-around;'>
                        <div style='display:flex; flex-direction: column; align-items: flex-end; '>
                            <div><b>1</b> #</div>
                            <div><b>4</b> #</div>
                        </div>
                        <div style='display:flex; flex-direction: column; align-items: flex-end;'>
                            <div><b>2</b> #</div>
                            <div><b>5</b> #</div>
                        </div>
                        <div style='display:flex; flex-direction: column; align-items: flex-end;'>
                            <div><b>3</b> #</div>
                            <div><b>6</b> #</div>
                        </div>
                        </div>
                      `,
        answer: [
          'tent/knife/torch/sleeping bag/water bottle/mirror',
          'tent/knife/torch/sleeping bag/water bottle/mirror',
          'tent/knife/torch/sleeping bag/water bottle/mirror',
          'tent/knife/torch/sleeping bag/water bottle/mirror',
          'tent/knife/torch/sleeping bag/water bottle/mirror',
          'tent/knife/torch/sleeping bag/water bottle/mirror',
        ],
      },
    ],
  },
  4: { // check lại types question
    unit: "Unit 6",
    id: "SB7-U6-P72-E4",
    audio: 'Audios/2-07 Friends Plus 7.mp3',
    video: "",
    component: MC2,
    exerciseKey: "img/FriendsPlus/Page72/E4/Key/answerKey.png",
    isQuestionVerticalToImage: true,
    isQuestionVertical: true,
    titleQuestion: [
      {
        num: "4",
        title:
          '<audioimage name= "2.07"></audioimage> Read and listen to the text and complete the sentences with the name of a team member',
        color: "#f58023",
      },
    ],
    titleImage_2: "",
    questions: [
      {
        title: ' To go on the Desert Challenge course, … ',
        answers: [
          { text: " you must be under thirteen. ", isCorrect: false },
          { text: " you must see a doctor. ", isCorrect: true },
          { text: " you should come with a friend.", isCorrect: false },
        ],
        // selectedItem: 0, // Lựa chọn mặc định
      },
      {
        title: ' You must take a lot of water because … ',
        answers: [
          { text: " you’ll need it for cooking. ", isCorrect: false },
          { text: " you’ll get very thirsty. ", isCorrect: false },
          { text: " you won’t find any in the desert.", isCorrect: true },
        ],
        // selectedItem: 0, // Lựa chọn mặc định
      },
      {
        title: 'When it gets hot, … ',
        answers: [
          { text: " you mustn’t take your clothes off. ", isCorrect: true },
          { text: " you should wear shorts and a T-shirt.", isCorrect: false },
          { text: " you should drink a lot of water.", isCorrect: false },
        ],
        // selectedItem: 0, // Lựa chọn mặc định
      },
      {
        title: ' If you get lost, … ',
        answers: [
          { text: " you should wait for help to arrive. ", isCorrect: false },
          { text: " you should use your mirror to signal for help. ", isCorrect: true },
          { text: " you should use your map to get out of the desert.", isCorrect: false },
        ],
        // selectedItem: 0, // Lựa chọn mặc định
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB7-U6-P72-E5",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "5",
        title:
          "Work in pairs. Say which objects from exercise 1 are the most useful for survival in this situation and why. Agree on five objects.",
        color: "#f58023",
        prefix: "default",
      },
    ],
    exerciseKey: "",
    textareaStyle: { width: 1140 },
    hideBtnFooter: true,
    questionImage: [],
    hintBox: [
      {
        src: ["Our first priority is a water bottle."],
        borderColor: "#f58023",
        arrow: true,
        position: 1,
        width: 400,
      },
      {
        src: ["Yes, I agree. And we’ll also need a tent."],
        borderColor: "#f58023",
        arrow: true,
        position: 2,
        width: 460,
      },
    ],
    questions: [
      {
        title: `
            <div style="margin-top: 10px; color: #333">
                You are planning a two-day trek in Cát Tiên National Park. You are going to camp in the forest.
              </div>
              <img src='/img/FriendsPlus/Page72/E5/2.png' style='width:500px; margin: 10px 200px 30px 200px;'>
                <div >
                <div style='font-weight: 600;'><hintbox id= '0'></hintbox></div>
                <div style="margin: -40px 0px 40px 430px; font-weight: 600;" ><hintbox id= '1'></hintbox></div>
              </div>
              <textarea id="1" rows="5"></textarea>
              
            `,
        answer: [],
      },
    ],
  },

}

export default json;