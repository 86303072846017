import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  5: {
    // Exercise num
    unit: "Unit 5",
    id: "SB5-U5-P59-E5",
    audio: "Audios/1-53 Friends Plus 7.mp3",
    video: "Videos/Talking Heads/U5-1.53-Trang 59.mp4",
    exerciseKey: "img/FriendsPlus/Page59/E5/Key/answerKey.png",
    selectStyle: {
      width: 70,
      textAlign: "center",
      fontSize: 26,
      fontWeight: "bold",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✔", "✘"],
    checkUppercase: true,
    component: T6,
    titleQuestion: [
      {
        num: "5",
        title:
          "<videoimage></videoimage><audioimage name='1.53'></audioimage> Look at the list of questions. Then watch or listen to people guessing the answers. Which of the questions do they answer?",
        color: "#2E479B",
      },
    ],
    questions: [
      {
        title: `
        <div style="display: flex; width: 900px ">
          <div style="flex: 2"><img  src='img/FriendsPlus/Page59/E5/1.jpg'></div>
          <div style="flex: 1">
            <div style='display: flex;'><b>1</b>&nbsp;<select id='0'></select></div>
            <div style='display: flex;'><b>2</b>&nbsp;<select id='1'></select></div>
            <div style='display: flex;'><b>3</b>&nbsp;<select id='2'></select></div>
            <div style='display: flex;'><b>4</b>&nbsp;<select id='3'></select></div>
            <div style='display: flex;'><b>5</b>&nbsp;<select id='4'></select></div>
            <div style='display: flex;'><b>6</b>&nbsp;<select id='5'></select></div>
            <div style='display: flex;'><b>7</b>&nbsp;<select id='6'></select></div>
          </div>
        </div>
        `,
        answer: ["✔", "✘", "✔", "✔", "✘", "✔", "✘",],
      },
    ],

  },
  6: {
    unit: "Unit 5",
    id: "SB7-U5-P59-E6",
    audio: "Audios/1-53 Friends Plus 7.mp3",
    video: "Videos/Talking Heads/U5-1.53-Trang 59.mp4",
    exerciseKey: "img/FriendsPlus/Page59/E6/Key/answerKey.png",
    component: T6,
    inputSize: 100,
    maxLength: 50,
    checkUppercase: true,
    selectStyle: { width: 110, textAlign: 'center', fontSize: 17 },
    selectOptionRandom: true,
    selectOptionValues: [
      "1",
      "1, 2",
      "2",
      "2, 4",
      "3",
      "4",
    ],
    titleQuestion: [
      {
        num: "6",
        title:
          "<videoimage></videoimage> <audioimage name='1.53'></audioimage> Watch or listen again. Read the Key Phrases. Which of the phrases do you hear each person says?",
        color: "#2E479B",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='padding:8mm  3mm; margin-top: 50px; border-radius: 30px; background:rgb(214, 226, 242)'>
            <div style='color: white; background:rgb(108, 157, 211); padding: 20px 20px 20px 20px; margin: -1cm -11px 5mm -11px; border-radius: 25px'><b> KEY PHRASES </b> </div>
            <b style='color:rgb(68, 139, 202)'>Expressing interest</b>
            <div  style='display: flex; margin: 20px 0 20px 0'>
              <div style="display: flex;width: 400px"><select id='0'></select> Have a guess!</div>
              <div style=' display: flex;'><select id='1'></select> ’ve no idea.</div>
            </div>
            <div  style='display: flex; margin: 20px 0 20px 0'>
              <div style="display: flex;width: 400px"><select id='2'></select> Any ideas?</div>
              <div style=' display: flex;'><select id='3'></select> I guess / I reckon …</div>
            </div>
            <div  style='display: flex; margin: 20px 0 20px 0'>
              <div style="display: flex;width: 400px"><select id='4'></select>What do you reckon?</div>
              <div style=' display: flex;'><select id='5'></select> Probably / Maybe.</div>
            </div>
            <div  style='display: flex; margin: 20px 0 20px 0'>
              <div style="display: flex;width: 400px"><select id='6'></select>I know that one.</div>
              <div style=' display: flex;'><select id='7'></select> Around / About …</div>
            </div>
            <div  style='display: flex; margin: 20px 0 20px 0'>
              <div style="display: flex;width: 400px"><select id='8'></select>I don’t know.</div>
            </div>
          </div>
          `,
        answer: [
          "3",
          "2",
          "1",
          "2, 4",
          "2",
          "1, 2",
          "3",
          "3",
          "1",
        ],
      },
    ],
  },
  7: {
    // Exercise num
    unit: "Unit 5",
    id: "SB5-U5-P59-E7",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "7",
        title:
          " Work in groups. Guess the answers to some of the questions in exercise 5. Use the key phrases.",
        color: "#2E479B",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page59/E7/1.jpg",
        },
        {
          url: "img/FriendsPlus/Page59/E7/2.jpg",
        },
      ],
    ],
  },
  8: {
    unit: "Unit 5",
    id: "SB7-U5-P59-E8",
    audio: "",
    video: "",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700 },
    hideBtnFooter: true,
    finished: {
      text: "Write more questions like the ones in exercise 5. Ask the group your questions to see if anyone knows the answers.",
    },
    questions: [
      {
        title: `
        <finished></finished>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
