import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P58-E1",
    audio: "Audios/1-49 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 740,
    // maxLength: 1,
    checkUppercase: true,
    // textAlign: "center",
    hintBox: [
      {
        src: [
          "billion",
          "century",
          "day",
          "decade",
          "fraction",
          "half",
          "hour",
          "hundred",
          "kilo",
          "kilometre",
          "metre",
          "millennium",
          "million",
          "minute",
          "week",
          "month",
          "quarter",
          "second",
          "thousand",
          "ton",
          "year",
        ],
        borderColor: "#1378b8",
        width: "640px",
      },
    ],
    stylesTextInput: {
      borderBottom: "dotted 1px",
      fontSize: 28,
      color: "black",
    },
    exerciseKey: "img/FriendsPlus/Page58/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "<audioimage name='1.49'></audioimage> Complete lists 1–3 with the words in the box. Put the time and number words in order. Then listen and check",
        color: "#1378b8",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <hintBox id='0'></hintBox>
        <div style='margin:10px;line-height:50px'>
          <b>1</b>&emsp; Time: <span style='color:gray'>millennium</span>(longest),#<br>
          <b>2</b>&emsp; Numbers: <span style='color:gray'>billion</span>(biggest),#<br>
          <b>3</b>&emsp; Measurements: <span style='color:gray'>ton</span>,#<br>
        </div>
        `,
        answer: [
          "century, decade, year, month, day, hour, minute, second",
          "million, thousand, hundred, half, quarter, fraction",
          "kilo, kilometre, metre",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P58-E2",
    audio: "Audios/1-50 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 140,
    maxLength: 10,
    checkUppercase: true,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dotted 1px",
      // fontSize: 28,
      // width: 43,
      color: "black",
    },
    exerciseKey: "img/FriendsPlus/Page58/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "<audioimage name='1.50'></audioimage> Complete the <i>Amazing Achievements</i> text with words from exercise 1. Then listen and check.",
        color: "#1378b8",
      },
    ],
    hintBox: [
      {
        src: [
          "billion",
          "century",
          "day",
          "decade",
          "fraction",
          "half",
          "hour",
          "hundred",
          "kilo",
          "kilometre",
          "metre",
          "millennium",
          "million",
          "minute",
          "week",
          "month",
          "quarter",
          "second",
          "thousand",
          "ton",
          "year",
        ],
        borderColor: "#1378b8",
        width: "640px",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='margin: 0 100px'><hintBox id='0' ></hintBox></div>
          <div style='display: flex'>
              <div style='width: 15cm'>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>AGE</b>
                  <div>
                    Jeanne Calment (1875–1997) lived 122 years and 164 <sup>1</sup>#.
                  </div>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>HEIGHT</b>
                  <div>
                    Thirteen-year-old Malavath Poorna from India climbed
                    Mount Everest (8,848 <sup>2</sup>#) in 2014.
                  </div>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>DISTANCE</b>
                  <div>
                    In 1970 three American astronauts travelled more than
                    four hundred <sup>3</sup># kilometres from Earth, around the
                    moon, and back to Earth again.
                  </div>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>RAP</b>
                  <div>
                    Chicago rapper Twista can rap an incredible 280 words
                    a <sup>4</sup>#. That’s almost five words every <sup>5</sup>#.
                  </div>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>FOOD</b>
                  <div>
                    Takeru Kobayashi once ate 110 hot dogs in ten <sup>6</sup>#.
                  </div>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>BOOKS</b>
                  <div>
                     Agatha Christie was one of the most popular writers in
                     history, selling more than two <sup>7</sup># books.
                  </div>
              </div>
              <div style='width: 15cm'>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>FILMS</b>
                  <div>
                      Jennifer Lawrence is the most successful film actress of
                      the last <sup>8</sup>#. The first <b style='font-size:28px'>Hunger Games</b> film made more thane 864
                      <sup>9</sup># dollars.
                  </div>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>SPORT</b>
                  <div>
                      Paula Radcliffe finished a 42.195-<sup>10</sup># marathon in
                      two hours, 15 minutes and 25 <sup>11</sup>#.
                  </div>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>MONEY</b>
                  <div>
                      One of the world's richest people is Warren Buffett. His
                      wealth is approximately 96 <sup>12</sup># dollars.
                  </div>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>SKILL</b>
                  <div>
                      Nancy Siefker shot an arrow 6.09 <sup>13</sup>#– using her feet!.
                  </div>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>STRENGTH</b>
                  <div>
                     Kevin Fast pulled a 55-<sup>14</sup>#aeroplane.
                  </div>
                  <b style='color:rgb(237 99 47) ; font-weight:800; font-size:30px '>ENDURANCE</b>
                  <div>
                    Wimm Hoff spent 1 <sup>15</sup>#, 42 minutes and
                    22 seconds covered in
                    snow. Brrrr!
                  </div>
              </div>
          </div>
        `,
        answer: [
          "days",
          "metres",
          "thousand",
          "minute",
          "second",
          "minute",
          "billion",
          "decade",
          "million",
          "kilometre",
          "seconds",
          "billion",
          "metres",
          "ton",
          "hour",
        ],
      },
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P58-E3",
    audio: "Audios/1-51 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 140,
    maxLength: 10,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dotted 1px",
      // fontSize: 28,
      // width: 43,
      color: "black",
    },
    titleQuestion: [
      {
        num: "3",
        title:
          "<audioimage name='1.51'></audioimage> Listen and repeat numbers 1–5.",
        color: "#1378b8",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='font-size:30px'>
            <b>1</b> 1.6 &emsp;&emsp; <b>3</b> 1,005 &emsp;&emsp; <b>5</b> 1,253,871 &emsp;&emsp;
            <br>
            <b>2</b> 235 <span style='margin-left:59px'> <b>4</b> 23,608 </span>
          </div>
        `,
        answer: [""],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P58-E4",
    audio: "Audios/1-52 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 140,
    maxLength: 10,
    checkUppercase: true,
    recorder: true,
    hideBtnFooter: true,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dotted 1px",
      // fontSize: 28,
      // width: 43,
      color: "black",
    },
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='1.52'></audioimage> Say numbers 6–10. Listen and check. ",
        color: "#1378b8",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='font-size:30px'>
            <b>6</b> 9.25 &emsp;&emsp; <b>7</b> 555 &emsp;&emsp; <b>8</b> 9,001 &emsp;&emsp;
            <br>
            <b>9</b> 82,359 <span style='margin-left:59px'> <b>10</b> 9,999,999 </span>
          </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
