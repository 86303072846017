import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {
	1: {
		unit: "Unit 3",
		id: "SB7-U3-P42-E1",
		component: Circle_Write,
		inputSize: 180,
		exerciseKey: "img/FriendsPlus/Page42/E1/Key/answerKey.png",
		titleQuestion: [
			{
				num: "1",
				title:
					" Check the meaning of the words in the box. Then look at the pictures and answer the questions. ",
				color: "#2ec3d8",
			},
		],
		hintBox: [
			{
				src: [
					"turkey",
					"pumpkin",
					"corn",
					"harvest",
					"hunt",
					"settler",
				],
				borderColor: "#2ec3d8",
				width: '70%'
			},
		],
		questionImage: [],
		question: {
			Write: {
				inputStyle: { width: 1211, color: 'black' },
				answers: [
					" People are eating a traditional Thanksgiving meal together. ",
				],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: { padding: '0 5px', fontWeight: 600, color: 'rgb(46, 195, 216)', fontSize: 24 },
				selectWordStyle: { border: '2px solid rgb(46, 195, 216)' },
				limitSelect: 1,
				listWords: [
					"A._1500  B._1621  C._1756 ",
				],
				answers: ['0-4'],
				initialValue: [],
			},

			Layout: `
				<div style="text-align: center;">
					<div style="display:flex; justify-content: center; margin-bottom: 10px"><hintbox id='0'></hintbox></div>
					<img style="width: 40%" src='img/FriendsPlus/Page42/E1/1.jpg'/>
					<img style="width: 40%" src='img/FriendsPlus/Page42/E1/3.jpg'/>
					<div style="display:flex">
						<div><b>1</b></div>
						<div style="margin-left: 20px">What is happening in picture A? What are people eating?</div>
					</div>
					<div><input id='0'/></div>
					<div style="display:flex">
						<div><b>2</b></div>
						<div style="margin-left: 20px">When did the event in picture B take place?</div>
					</div>
					<div style="text-align: left;"><input type="Circle" id='0'/></div>
				</div>
			`,
		}
	},

	2: {
		unit: 'Unit 3',
		id: 'SB7-U3-P42-E2',
		component: UI,
		audio: "Audios/1-38 Friends Plus 7.mp3",
		questionImage: [
			[
				{ url: 'img/FriendsPlus/Page42/E2/1.jpg' },
			],
		],
	},
	3: {
		unit: 'Unit 3',
		id: 'SB7-U3-P42-E3',
		component: T6,
		titleImage: 'img/FriendsPlus/Page42/E3/1.jpg',
		inputSize: '1200px',
		exerciseKey: 'img/FriendsPlus/Page42/E3/Key/answerKey.png',
		questions: [
			{
				title: `
					<div>
						<div><b>1.</b> What do people eat on Thanksgiving Day?</div>
						<div>#</div>
						<div><b>2.</b> Where did the Pilgrims arrive in 1620?</div>
						<div>#</div>
						<div><b>3.</b> Why did many settlers die in the first winter?</div>
						<div>#</div>
						<div><b>4.</b> How did they learn to grow food?</div>
						<div>#</div>
						<div><b>5.</b> Who went to the first Thanksgiving meal?</div>
						<div>#</div>
					</div>`,
				answer: [
					'Turkey, corn and potatoes.',
					'America.',
					"They were very cold and they didn't have enough food.",
					'The Native Americans showed them..',
					'The Pilgrims and the Native Americans .',
				]
			}
		]
	},
	4: {
		unit: 'Unit 3',
		id: 'SB7-U3-P42-E4',
		component: UI,
		questionImage: [
			[
				{ url: 'img/FriendsPlus/Page42/E4/1.jpg' },
			],
		],
	},
	5: {
		unit: 'Unit 3',
		id: 'SB7-U3-P42-E5',
		component: T6,
		questionImage: [],
		textareaStyle: { width: 1080 },
		hideBtnFooter: true,
		titleQuestion: [
			{
				num: "5",
				title:
					" Write a short description (35 – 50 words) of a festival in Việt Nam. Use your answers in exercise 4 to help you.",
				color: "#20c1d8",
				prefix: {
					icons: ['fas fa-pencil-alt'], text: "USE IT!"
				},
			},
		],
		questions: [
			{
				title: `
			  		<div><textarea id="0" rows="7" ></textarea></div>
			`,
				answer: []
			},
		]
	},
}

export default json;
