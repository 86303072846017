import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import TB2 from '../../components/ExcerciseTypes/Table/TB2';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';

const json = {
	1: {
		unit: 'Culture',
		id: 'SB7-C-P90-E1',
		exerciseKey: 'img/FriendsPlus/Page90/E1/Key/answerKey.png',
		audio: 'Audios/2-23 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 920,
		maxLength: 100,
		titleQuestion: [
			{
				num: '1',
				title: `
					<audioimage name='2.23'></audioimage> Read the text. Who is Jamal Edwards and why is he famous today? <br/>Read and listen to the text and check your answers.
				`,
				color: '#1dc1d2',
			},
		],
		questions: [
			{
				title: `
					<div style=" margin-top: 20px;">
							<img src="img/FriendsPlus/Page90/E1/1.jpg" style="width: 900px;" />

						<div style="margin-top: 30px;">
							<strong>Answer:</strong> <br/> #
						</div>
					</div>
                `,
				answer: [
					'Jamal Edwards is a young entrepreneur who has started his own TV station, SBTV.',
				],
			},
		],
	},
	2: {
		unit: 'Culture',
		id: 'SB7-C-P90-E2',
		exerciseKey: 'img/FriendsPlus/Page90/E2/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 130,
		maxLength: 10,
		// checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
          Match the <span style="color: #03b2dd">blue</span> words in the text with definitions 1-5.
        `,
				color: '#1dc1d2',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 700px; margin-top: 20px;">
						<div style="width: 650px; display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page90/E1/1.jpg" style="max-width: 100%;" />
						</div>

						<div style="margin-top: 20px; padding-left: 10px; margin-bottom: 16px;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>people who watch videos or TV #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>playing music and/or singing for people #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>wild animals that are similar to dogs #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>a plan #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">5</strong>
								<span>a prize or medal for an important achievement #</span>				
							</div>
						</div>
					</div>
        `,
				answer: ['viewers', 'performing', 'foxes', 'strategy', 'award'],
			},
		],
	},
	3: {
		unit: 'Culture',
		id: 'SB7-C-P90-E3',
		exerciseKey: 'img/FriendsPlus/Page90/E3/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 870,
		maxLength: 82,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		textareaStyle: { width: 610 },
		titleQuestion: [
			{
				num: '3',
				title: `
          Read the text again and answer the  questions.
        `,
				color: '#1dc1d2',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 700px; margin-top: 20px;">
						<div style="width: 650px; display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page90/E1/1.jpg" style="max-width: 100%;" />
						</div>

						<div style="margin-top: 20px; padding-left: 10px; margin-bottom: 16px;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>Why did Jamal receive an award? <br/> #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>What were Jamal's two hobbies when he was sixteen? <br/> #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>Why were Jamal's grime videos successful? <br/> #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>Why did Jamal decide to create his own TV station? <br/> #</span>				
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">5</strong>
								<span>What did Jamal do to increase the number of SBTV viewers? <br/> #</span>				
							</div>
						</div>
					</div>
        `,
				answer: [
					'He received an award for his services to music.',
					"Jamal's hobbies were making videos and grime music.",
					'Because he added live concerts and interviews with musicians.',
					'Because he wanted to combine his love of filming with his passion for grime music.',
					'He started including different types of music.',
				],
			},
		],
	},
	4: {
		unit: 'Culture',
		id: 'SB7-C-P90-E4',
		exerciseKey: 'img/FriendsPlus/Page90/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 130,
		maxLength: 10,
		stylesTextInput: {
			fontSize: 23,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '4',
				title: `
          Write about a young entrepreneur in Viet Nam. 
          Complete the table and use the internet to help you.
        `,
				color: '#1dc1d2',
				prefix: { icons: ['fas fa-pencil-alt'], text: 'YOUR CULTURE' },
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 20px; width: 700px;">
            <div style="display: flex;">
              <div style="width: 150px; border: 1px solid rgb(82,145,153); background-color: rgb(119,206,223); color: white; padding-left: 10px;">Name:</div>
              <div style="width: 500px;  background-color: rgb(207,236,244); border: 1px solid rgb(82,145,153); border-left: 0;"></div>
            </div>

            <div style="display: flex;">
              <div style="width: 150px; border: 1px solid rgb(82,145,153); background-color: rgb(119,206,223); color: white; padding-left: 10px;">From:</div>
              <div style="width: 500px;  background-color: rgb(207,236,244); border: 1px solid rgb(82,145,153); border-left: 0;"></div>
            </div>
            
            <div style="display: flex;">
              <div style="width: 150px; border: 1px solid rgb(82,145,153); background-color: rgb(119,206,223); color: white; padding-left: 10px;">Born:</div>
              <div style="width: 500px;  background-color: rgb(207,236,244); border: 1px solid rgb(82,145,153); border-left: 0;"></div>
            </div>
            
            <div style="display: flex;">
              <div style="width: 150px; border: 1px solid rgb(82,145,153); background-color: rgb(119,206,223); color: white; padding-left: 10px;">Business:</div>
              <div style="width: 500px;  background-color: rgb(207,236,244); border: 1px solid rgb(82,145,153); border-left: 0;"></div>
            </div>

            <div style="display: flex;">
              <div style="width: 150px; border: 1px solid rgb(82,145,153); background-color: rgb(119,206,223); color: white; padding-left: 10px;">How he / she became successful:</div>
              <div style="width: 500px;  background-color: rgb(207,236,244); border: 1px solid rgb(82,145,153); border-left: 0;"></div>
            </div>
					</div>
				`,
				answer: ['Flying car'],
			},
		],
	},
	5: {
		unit: 'Culture',
		id: 'SB7-C-P90-E5',
		exerciseKey: 'img/FriendsPlus/Page90/E5/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		textareaStyle: { width: 700 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
          Work in pairs. Tell your partner about your young entrepreneur in exercise 4.
        `,
				color: '#1dc1d2',
				prefix: 'default',
			},
		],
		questions: [
			{
				title: `<textarea id="0" rows="10"></textarea>`,
				answer: [],
			},
		],
	},
};

export default json;
