import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  5: {
    unit: "Unit 2",
    id: "SB7-U2-P31-E5",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", marginLeft: 15 },
    inputSize: 500,
    exerciseKey: "img/FriendsPlus/Page31/E5/Key/answerKey.png",
    titleQuestion: [
      {
        num: "5",
        title: "<b>Write questions using the present simple.</b>",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='margin-top: 20px;'>
            <div><b>1</b> where / you / go / at the weekend ?</div>
            <div>#</div>
            <div><b>2</b> you / meet / your friends in town ?</div>
            <div>#</div>
            <div><b>3</b> your brother / make / videos ?</div>
            <div>#</div>
            <div><b>4</b> your parents / listen / to music ?</div>
            <div>#</div>
            <div><b>5</b> your cousin / do / martial arts ?</div>
            <div>#</div>
            <div><b>6</b> when / your friends / watch / TV ?</div>
            <div>#</div>
            <div><b>7</b> where / your sister / go / to the cinema ?</div>
            <div>#</div>
            <div><b>8</b> how often / you / stay / in bed late ?</div>
            <div>#</div>
          </div>
        `,
        answer: [
          "Where do you go at the weekend?",
          "Do you meet your friends in town?",
          "Does your brother make videos?",
          "Do your parents listen to music?",
          "Does you cousin do martial arts?",
          "When do your friends watch TV?",
          "Where does your sister go to the cinema?",
          "How often do you stay in bed late?",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 2",
    id: "SB7-U2-P31-E6",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page31/E6/Key/answerKey.png",
    titleQuestion: [
      {
        num: "6",
        title: "<b>Choose the correct words.</b>",
        color: "#4d3c97",
      },
    ],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 5,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",
        },
        selectWordStyle: {
          padding: 3,
          border: "solid 2px",
          borderColor: "#4d3c97",
        },
        limitSelect: 1,
        listWords: [
          "Will / Shall / Do", //0
          "don’t / doesn’t / aren’t", //1
          "go / know / want", //2
          "don’t / doesn’t / not", //3
          "know / want / feel", //4
          "baking / bake / bakes", //5
          "as / like / of", //6
          "Let / Let’s / Let us", //7
        ],
        answers: ["0-4", "1-0", "2-8", "3-0", "4-8", "5-0", "6-4", "7-4"],
        // initialValue: ['0-0', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
        <div style='display: flex;'>
          <div style='width: 100px;'><b>Maya</b></div>
          <div style='flex: 1;'>Hey, Grace. What are you doing this afternoon?</div>
        </div>
        <div style='display: flex;'>
          <div style='width: 100px;'><b>Grace</b></div>
          <div style='flex: 1;'>Nothing really. <sup>1</sup><input id='0' type='Circle' /> do something together?</div>
        </div>
        <div style='display: flex;'>
          <div style='width: 100px;'><b>Maya</b></div>
          <div style='flex: 1;'>Yeah. I <sup>2</sup><input id='1' type='Circle' /> want to watch TV all afternoon. It’s boring!</div> 
        </div>
        <div style='display: flex;'>
          <div style='width: 100px;'><b>Grace</b></div>
          <div style='flex: 1;'>What do you <sup>3</sup><input id='2' type='Circle' /> to do?</div> 
        </div>
        <div style='display: flex;'>
          <div style='width: 100px;'><b>Maya</b></div>
          <div style='flex: 1;'>I don’t know.</div> 
        </div>
        <div style='display: flex;'>
          <div style='width: 100px;'><b>Grace</b></div>
          <div style='flex: 1;'>Why <sup>4</sup><input id='3' type='Circle' /> we go swimming?</div>
        </div>
        <div style='display: flex;'>
          <div style='width: 100px;'><b>Maya</b></div>
          <div style='flex: 1;'>I don’t <sup>5</sup><input id='4' type='Circle' /> like going swimming. It’s cold today.</div> 
        </div>

        <div style='display: flex;'>
          <div style='width: 100px;'><b>Grace</b></div>
          <div style='flex: 1;'>I know! How about <sup>6</sup><input id='5' type='Circle' /> a cake?</div> 
        </div>

        <div style='display: flex;'>
          <div style='width: 100px;'><b>Maya</b></div>
          <div style='flex: 1;'>That sounds <sup>7</sup><input id='6' type='Circle' /> a good idea!</div> 
        </div>
        <div style='display: flex;'>
          <div style='width: 100px;'><b>Grace</b></div>
          <div style='flex: 1;'><sup>8</sup><input id='7' type='Circle' /> start now.</div> 
        </div>
      `,
    },
  },
  7: {
    unit: "Unit 2",
    id: "SB7-U2-P31-E7",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { textAlign: "center", border: "none" },
    inputSize: 240,
    exerciseKey: "img/FriendsPlus/Page31/E7/Key/answerKey.png",
    titleQuestion: [
      {
        num: "7",
        title: "<b>Complete the text with the words and phrases.</b>",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    isHiddenCheck: true,
    questions: [
      {
        title: `
          <div style='border-width: 2px; border-style: solid; border-radius: 8px; border-color: rgb(136,137,139); text-align: center; padding: 0 20px; margin-top: 20px; width: 75%;'>about watching&emsp;&emsp;a lot of time&emsp;&emsp;also&emsp;&emsp;don’t enjoy&emsp;&emsp;not a big&emsp;&emsp;really bothered&emsp;&emsp;spending my time&emsp;&emsp;too</div>
          <div style='position: relative; margin-top: 20px;'>
            <img style='width:53%' src='img/FriendsPlus/Page31/E7/1.jpg' />
            <div style=" position: absolute; top: 175px; left: 58px; "><input id='0' background-color='transparent' /></div>
            <div style=" position: absolute; top: 376px; left: 107px; "><input id='1' /></div>
            <div style=" position: absolute; top: 405px; left: 201px; "><input id='2' /></div>
            <div style=" position: absolute; top: 577px; left: 202px; "><input id='3' /></div>
            <div style=" position: absolute; top: 606px; left: 35px; "><input id='4' /></div>
            <div style=" position: absolute; top: 725px; left: 106px; "><input id='5' /></div>
            <div style=" position: absolute; top: 834px; left: 160px; "><input id='6' /></div>
            <div style=" position: absolute; top: 986px; left: 19px; "><input id='7' width='150px' /></div>            
          </div>
        `,
        answer: [
          "spending my time",
          "a lot of time",
          "also",
          "too",
          "don't enjoy",
          "really bothered",
          "about watching",
          "not a big",
        ],
      },
    ],
  },
};

export default json;
