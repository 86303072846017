import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 1',
    id: 'SB7-U1-P12-E1',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page12/E1/Key/answerKey.png',
    titleQuestion: [
      {
        num: '1',
        title:
          'Read the Study Strategy. Then read the forum quickly. Which two people are not happy with the rules at home?',
        color: '#d82e3b',
      },
    ],
    inputSize: 200,
    questionImage: [],
    checkUppercase: true,
    questions: [
      {
        title: `
          <img style="width:34%" src='img/FriendsPlus/Page12/E1/2.jpg' />
          <img style="width:74%" src='img/FriendsPlus/Page12/E1/1.jpg' />
          <div style='margin-left: 100px;'>
            <div>Which two people are not happy with the rules at home?</div>
            <div>1. #</div>
            <div>2. #</div>
          </div>
        `,
        answer: ['Serzh98', 'Typho'],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: 'SB7-U1-P12-E2',
    audio: '',
    video: '',
    component: UI,
    recorder: true,
    imageStyle: {
      width: '80%'
    },
    titleQuestion: [
      {
        num: '2',
        title:
          'Use a dictionary to check the meaning of the words in <span style="color: #00aaec;">blue</span> in the text.',
        color: '#e5283c',
        prefix: { icons: [], text: 'VOCABULARY PLUS' },
      },
    ],
    questionImage: [[{ url: 'img/FriendsPlus/Page12/E1/1.jpg' }]],

  },
  3: {
    unit: 'Unit 1',
    id: 'SB7-U1-P12-E3',
    audio: 'Audios/1-06 Friends Plus 7.mp3',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '3',
        title: '<AudioImage name="1.06"></AudioImage> Read and listen to the forum and answer the questions.',
        color: '#d03837',
      },
    ],
    exerciseKey: 'img/FriendsPlus/Page12/E3/Key/answerKey.png',
    checkUppercase: true,
    inputSize: 250,
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
          <img style="width: 74%" src='img/FriendsPlus/Page12/E1/1.jpg' />
          <div>Who…</div>
          <div>1 … doesn’t allow his family to use phones during family meals?</div> #
          <div>2 … allows their daughter to have a computer in her room?</div> #
          <div>3 … doesn’t let her daughter watch TV in her bedroom?</div> #
          <div>4 … spends a lot of time watching TV?</div> #
          <div>5 … allows their son to play video games after he finishes his homework?</div> #
          <div>6 … lets her son play video games because he plays outside, too?</div> #
          `,
        answer: ["Typho's dad", "Serzh98's parents", "Sam 15's mum", "Maya's nan", "Typho's parents", "Messifan's mum"],
      },
    ],
  },
  4: {
    unit: 'Unit 1',
    id: 'SB7-U1-P12-E4',
    audio: '',
    video: '',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000 },
    titleQuestion: [
      {
        num: '4',
        title:
          'Work in pairs. Do you think Typho’s dad is right to ban phones at meal times? When do your parents let you use the computer?',
        color: '#e12d38',
        prefix: 'default',
      },
    ],
    questions: [
      {
        title: `
          <div style='margin-left: 100px;'><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: [''],
      },
    ],
  },
};

export default json;
