import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB7-U2-P26-E1",
    audio: "Audios/1-22 Friends Plus 7.mp3",
    video: "Videos/Speaking/U2-1.32-Trang 26.mp4",
    component: T6,
    inputSize: 200,
    maxLength: 36,
    checkUppercase: true,
    stylesTextInput: {
      // border: "2px solid",
      // height: 45,
      borderBottom: "dotted 1px",
      // backgroundColor: "transparent",
    },
    hintBox: [
      {
        src: [
          "are you doing",
          "at home",
          "bus",
          "cinema",
          "cousin",
          "film",
          "having coffee",
        ],
        borderColor: "#cf2370",
        width: 500,
      },
    ],
    textAlign: "center",
    // maxLength: 13,
    exerciseKey: "img/FriendsPlus/Page26/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [],
    titleQuestion: [
      {
        num: "1",
        title:
          `<videoimage></videoimage><audioimage name='1-22'></audioimage> Complete the dialogue with the phrases in the box.then watch or listen and check.What does Mike want to do? Why can’t Anna meet Mike now?`,
        color: "#cf2370",
      },
    ],
    questions: [
      {
        title: `
          <div style='margin:3px 140px'><hintBox id='0'></hintBox></div>
          <div style='display: flex; background:rgb(218 229 233); padding:10px; border-radius:10px'>
							<div style='width: 18cm'>
								<div style='display:flex'>
                  <b>Anna</b>
                  <div style='margin-left:20px'>Hello?</div>
								</div>
								<div style='display:flex'>
                   <b>Mike</b>
                   <div style='margin-left:27px'>Hi, Anna. It’s Mike.</div>
								</div>
								<div style='display:flex'>
                  <b>Anna</b>
                  <div style='margin-left:20px'>Hi there. How are things?</div>
								</div>
								<div style='display:flex'>
                   <b>Mike</b>
                   <div style='margin-left:27px'>Good. Are you <sup>1</sup>#?</div>
								</div>
								<div style='display:flex'>
                  <b>Anna</b>
                  <div style='margin-left:20px'>Yes. Why?</div>
								</div>
								<div style='display:flex'>
                   <b>Mike</b>
                   <div style='margin-left:27px'>Well, I’m  <sup>2</sup>#with Sally
                   in town. We’re thinking of going to the <sup>3</sup>#. Are you interested?</div>
								</div>
								<div style='display:flex'>
                  <b>Anna</b>
                  <div style='margin-left:20px'>I’d like to come, but I can’t right now.</div>
								</div>
								<div style='display:flex'>
                   <b>Mike</b>
                   <div style='margin-left:27px'>What <sup>4</sup>#?</div>
								</div>
								<div style='display:flex'>
                  <b>Anna</b>
                  <div style='margin-left:20px'>I’m waiting to Skype my <sup>5</sup>#in Canada. What time’s the <sup>6</sup>#?</div>
								</div>
								<div style='display:flex'>
                   <b>Mike</b>
                   <div style='margin-left:27px'>It’s at four o’clock.</div>
								</div>
								<div style='display:flex'>
                  <b>Anna</b>
                  <div style='margin-left:20px'>Oh, that’s OK. I can make it at four.
                  That’s lots of time to Skype and then
                  get the <sup>7</sup>#into town.</div>
								</div>
								<div style='display:flex'>
                   <b>Mike</b>
                   <div style='margin-left:27px'>Cool! Text me when you’re on the bus.</div>
								</div>
                <div style='display:flex'>
                  <b>Anna</b>
                  <div style='margin-left:20px'>OK. See you later.</div>
								</div>
								
							</div>
							<img style='width:10cm;height:10cm'  src='img/FriendsPlus/Page26/E1/1.jpg'>

					</div>
        `,
        answer: [
          "at home",
          "having coffee",
          "cinema",
          "are you doing",
          "cousin",
          "film",
          "bus",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB7-U2-P26-E2",
    audio: "Audios/1-22 Friends Plus 7.mp3",
    video: "Videos/Speaking/U2-1.32-Trang 26.mp4",
    component: T6,
    inputSize: 100,
    maxLength: 4,
    checkUppercase: true,
    stylesTextInput: {
      border: "2px solid",
      height: 45,
      // width: "100px",
      borderBottom: "dash",
      background: "white",
      // backgroundColor: "transparent",
    },
    textAlign: "center",
    // maxLength: 4,
    exerciseKey: "img/FriendsPlus/Page26/E2/Key/answerKey.png",
    titleImage: "",
    questionImage: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "<videoimage></videoimage><audioimage name='1-22'></audioimage> Read the Key Phrases. Cover the dialogue and try to remember who says the phrases, Anna or Mike. Which key phrases are not used? Watch or listen again and check.",
        color: "#cf2370",
      },
    ],
    questions: [
      {
        title: `
					<b style='color:white;border-radius:15px;background:rgb(229 113 149);padding:10px 360px 10px 10px; font-size: 27px'>KEY PHRASES</b>
					<div style='background: rgb(246 211 218); border-radius: 15px; padding: 10px;margin:-10px 0px'>
						<b style='color: rgb(211 34 116)'>Making plans</b>
							<div style='line-height:55px'>
									<b>1.</b> We’re thinking of going to the cinema.
										<input id='0' width='85px' /><br>
									<b>2.</b> Are you interested?
										<input id='1' width='85px' /><br>
									<b>3.</b> I can’t right now.
										<input id='2' width='85px' /><br>
									<b>4.</b> I can’t make it.
										<input id='3' width='85px' /><br>
									<b>5.</b> I’m not sure. 
										<input id='4' width='85px' /><br>
									<b>6.</b> Nothing special.
										<input id='5' width='85px' /> <br>
									<b>7.</b> I can make it at four. 
										<input id='6' width='85px' /><br>
									<b>8.</b> Text me when you’re on the bus.
										<input id='7' width='85px' />
							</div>
					</div>
        `,
        answer: ["Mike", "Mike", "Anna", "none", "none", "none", "Anna", "Mike"],
      },
    ],
  },
  3: {
    unit: "Unit 2",
    id: "SB7-U2-P26-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 200,
    textAlign: "center",
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page30/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Work in pairs. Practise the dialogue.",
        color: "#cf2370",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display: flex; background:rgb(218 229 233); padding:10px; border-radius:10px'>
            <div style='width: 18cm'>
              <div style='display:flex'>
                <b>Anna</b>
                <div style='margin-left:20px'>Hello?</div>
              </div>
              <div style='display:flex'>
                 <b>Mike</b>
                 <div style='margin-left:27px'>Hi, Anna. It’s Mike.</div>
              </div>
              <div style='display:flex'>
                <b>Anna</b>
                <div style='margin-left:20px'>Hi there. How are things?</div>
              </div>
              <div style='display:flex'>
                 <b>Mike</b>
                 <div style='margin-left:27px'>Good. Are you <sup>1</sup>#?</div>
              </div>
              <div style='display:flex'>
                <b>Anna</b>
                <div style='margin-left:20px'>Yes. Why?</div>
              </div>
              <div style='display:flex'>
                 <b>Mike</b>
                 <div style='margin-left:27px'>Well, I’m  <sup>2</sup>#with Sally
                 in town. We’re thinking of going to the <sup>3</sup>#. Are you interested?</div>
              </div>
              <div style='display:flex'>
                <b>Anna</b>
                <div style='margin-left:20px'>I’d like to come, but I can’t right now.</div>
              </div>
              <div style='display:flex'>
                 <b>Mike</b>
                 <div style='margin-left:27px'>What <sup>4</sup>#?</div>
              </div>
              <div style='display:flex'>
                <b>Anna</b>
                <div style='margin-left:20px'>I’m waiting to Skype my <sup>5</sup>#in Canada. What time’s the <sup>6</sup>#?</div>
              </div>
              <div style='display:flex'>
                 <b>Mike</b>
                 <div style='margin-left:27px'>It’s at four o’clock.</div>
              </div>
              <div style='display:flex'>
                <b>Anna</b>
                <div style='margin-left:20px'>Oh, that’s OK. I can make it at four.
                That’s lots of time to Skype and then
                get the <sup>7</sup>#into town.</div>
              </div>
              <div style='display:flex'>
                 <b>Mike</b>
                 <div style='margin-left:27px'>Cool! Text me when you’re on the bus.</div>
              </div>
              <div style='display:flex'>
                <b>Anna</b>
                <div style='margin-left:20px'>OK. See you later.</div>
              </div>
              
            </div>
            <img style='height:10cm' src='img/FriendsPlus/Page26/E1/1.jpg'>
        </div>
        `,
        answer: [
          "doesn't play",
          "play",
          "studies",
          "don't study",
          "finishes",
          "don't finish",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 2",
    id: "SB7-U2-P26-E4",
    audio: "Audios/1-23 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 100,
    maxLength: 1,
    isHiddenCheck: true,
    checkUppercase: true,
    stylesTextInput: {
      border: "2px solid",
      height: 40,
      // width: "100px",
      borderBottom: "dash",
      // backgroundColor: "transparent",
    },
    textAlign: "center",
    // maxLength: 4,
    exerciseKey: "img/FriendsPlus/Page26/E4/Key/answerKey.png",
    titleImage: "",
    questionImage: [],
    titleQuestion: [
      {
        num: "4",
        title:
          "Put the dialogue in the correct order. Listen and check. then practise it with your partner.",
        color: "#cf2370",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questions: [
      {
        title: `
        <div style='display:flex; margin-bottom:10px'>
          <input id='0' width='45px' />
          <b>A</b> 
          <div style='margin-left: 20px'>Oh, right. Text me later when you’re free,
          OK?</div>
        </div>
        <div style='display:flex; margin-bottom:10px'>
          <input id='0' width='45px' />
          <b>A</b> 
          <div style='margin-left: 20px'>Bye</div>
        </div>
        <div style='display:flex; margin-bottom:10px'>
          <input id='0' width='45px' />
          <b>A</b> 
          <div style='margin-left: 20px'>We’re thinking of playing football. Are you interested?
          OK?</div>
        </div>
        <div style='display:flex; margin-bottom:10px'>
          <input id='0' width='45px' />
          <b>A</b> 
          <div style='margin-left: 20px'>Why? What are you doing?          </div>
        </div>
        
        <div style='display:flex; margin-bottom:10px'>
          <input id='0' width='45px' />
          <b>B</b> 
          <div style='margin-left: 20px'>I can’t right now. Maybe later</div>
        </div>
        <div style='display:flex; margin-bottom:10px'>
          <input id='0' width='45px' />
          <b>B</b> 
          <div style='margin-left: 20px'>OK then. Bye</div>
        </div>
        <div style='display:flex; margin-bottom:10px'>
          <input id='0' width='45px' />
          <b>B</b> 
          <div style='margin-left: 20px'>Nothing special. I’m doing my homework</div>
        </div>
        

        `,
        answer: ["5", "7", "1", "3", "2", "6", "4"],
      },
    ],
  },
  5: {
    unit: "Unit 2",
    id: "SB7-U2-P26-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Work in pairs. Read the situation. Practise a new dialogue using the key phrases and the dialogue in exercise 1 to help you.",
        color: "#cf2370",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page26/E5/1.jpg" }]],
  },
};

export default json;
