import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';



const json = {



  5: { // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P79-E5',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page79/E5/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '5',
        title:
          'Complete the sentences using <i>can, can’t, could</i> or <i>couldn’t</i>.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 120,
    //hiện icon đáp án đúng
    // isHiddenCheck:true,
    // chữ hoa khác chữ thường
    // checkUppercase: true,
    
    questions: [
      {
        title: `
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div><b>#</b> that child prodigy play the piano when she was two?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>The boy on TV now <b>#</b>remember 1,000 different numbers – he’s amazing!</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>My little sister <b>#</b> write any words at the moment, but she can read easy sentences.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>We <b>#</b> play tennis when we were ten, but we’re good at it now.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>That famous artist <b>#</b> paint well at an early age.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>How many languages <b>#</b> your cousin speak now?</div>
        </div>
        `,
        answer: [
          "Could",
          "can",
          "can’t",
          "couldn’t",
          "could",
          "can",
        ],
      },
    ],
  },

  6:{ // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P79-E6',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page79/E6/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '6',
        title:
          'Complete the questions with <i>How</i> and the words in the box.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 150,
    checkUppercase: true,
    hintBox:[
      {
        src: [
            "far",
            "many",
            "much",
            "old",
            "rich",
            "tall",
        ],
        borderColor: "#333",
        width: 600,
        },
    ],
    questions: [
      {
        title: `
        <div style='margin: 20px 0 20px 0;'><hintbox id= '0' ></hintbox></div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>
            <div>‘ <b>#</b> books do you read every year?’ </div>
            <div>‘Over forty. I’m a child prodigy!’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>
            <div>‘ <b>#</b> can you run in an hour?’</div>
            <div>‘About six kilometres.’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>
            <div>‘ <b>#</b> are you now?’</div>
            <div> ‘I’m one metre seventy.’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>
            <div>‘ <b>#</b> homework do you do?’</div>
            <div>‘Quite a lot!’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>
            <div>‘<b>#</b> is Adam?’</div>
            <div> ‘He’s fourteen.’</div>
          </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>
            <div>‘<b>#</b> is that young film star?’</div>
            <div>‘I don’t know, but he’s got more money than me!’</div>
          </div>
        </div>

        `,
        answer: [
          "How many",
          "How far",
          "How tall",
          "How much",
          "How old",
          "How rich",
        ],
      },
    ],
  },
  7:{ // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P79-E7',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page79/E7/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '7',
        title:
          'Complete the dialogue and practise with your partner.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 280,
    checkUppercase: true,
    hintBox:[
      {
        src: [
            "That’s a good idea.",
            "What’s the best way",
            "It’s probably best to",
            "Well, you can",
            "Even better!",
            "Yeah, but",
        ],
        borderColor: "#333",
        width: 600,
        },
    ],
    questions: [
      {
        title: `
        <div style='margin: 20px 0 20px 70px;'><hintbox id= '0' ></hintbox></div>
        <div style='display: flex;'>
          <div style='margin-right: 20px;'><b> Tina </b></div>
          <div>This <i>phở</i> is amazing, Mai! When did you learn how to cook like that? </div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 28px;'><b> Mai </b></div>
          <div>My mum showed me how to make <i>phở</i> about six months ago.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 20px;'><b> Tina </b></div>
          <div>I love Vietnamese cooking, but I’m no good. <b><sup>1</sup>#</b> to learn?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 28px;'><b> Mai </b></div>
          <div><b><sup>2</sup>#</b> learn from somebody in your family.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 20px;'><b> Tina </b></div>
          <div><b><sup>3</sup>#</b> nobody in my family can make Vietnamese food.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 28px;'><b> Mai </b></div>
          <div><b><sup>4</sup>#</b> watch videos online.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 20px;'><b> Tina </b></div>
          <div>I suppose so.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 28px;'><b> Mai </b></div>
          <div>Or you can have some cooking lessons.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 20px;'><b> Tina </b></div>
          <div><b><sup>5</sup>#</b></div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 28px;'><b> Mai </b></div>
          <div>Or perhaps I can teach you and we can eat phở together!</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 20px;'><b> Tina </b></div>
          <div><b><sup>6</sup>#</b></div>
        </div>
        

        `,
        answer: [
          "What’s the best way",
          "It’s probably best to",
          "Yeah, but",
          "Well, you can",
          "That’s a good idea.",
          "Even better!",
        ],
      },
    ],
  },
  8:{
    unit: 'Progress review 3',
    id: "SB7-PR3-P79-E8",
    audio: "",
    video: "",
    component: T6,
    inputSize: 40,
    stylesTextInput: {
      border: "2px solid",
      borderBottom: "dash",
      height: 40,
      // fontSize: 30,
    },
    maxLength: 1,
    textAlign: "center",
    textareaStyle: { width: 950 },
    titleQuestion: [
      {
        num: "8",
        title:
          "Order the words to make sentences. Then match the sentences with the questions below.",
        color: "#50419c",
      },
    ],
    exerciseKey: "img/FriendsPlus/Page79/E8/Key/answerKey.png",
    hideBtnFooter: false,
    questionImage: [],
    hintBox: [],
    questions: [
      {
        title: `
        <div style='display: flex ; flex-direction: column; '>
            <div>
              <div><b style = "margin-right:15px">1</b> <i>British / writer / was / Enid Blyton / a</i></div>
              <textarea id="5" rows="1"></textarea>
            </div>
            <div>
              <div><b style = "margin-right:15px">2</b> <i>born / was / 1897 / England / in / She / in </i></div>
              <textarea id="6" rows="1"></textarea>
            </div>
            <div>
              <div><b style = "margin-right:15px">3</b> <i>sixteen / when / was / she / writing / She first / started</i></div>
              <textarea id="7" rows="1"></textarea>
            </div>
            <div>
              <div><b style = "margin-right:15px">4</b> <i> became / Enid Blyton / eventually / the / famous / most of writer / children's stories</i></div>
              <textarea id="8" rows="1"></textarea>
            </div>
            <div>
              <div><b style = "margin-right:15px">5</b> <i>loved / People / her work / were / her mysteries / because / exciting / so</i> </div>
              <textarea id="9" rows="1"></textarea>
            </div>
          </div>
        <div style='line-height:50px'>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>A</b> Who was Enid Blyton?</div>
            <div><span style='margin-left:10px'>#</span></div>
          </div>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>B</b> What did she become ?</div>
            <div><span style='margin-left:10px'>#</span></div>
          </div>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>C</b> Where was she born ?</div>
            <div><span style='margin-left:10px'>#</span></div>
          </div>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>D</b> Why were her books popular ?</div>
            <div><span style='margin-left:10px'>#</span></div>
          </div>
          <div style='display: flex; justify-content: space-between;'>
            <div><b style='margin-right:10px;'>E</b> When did she first start writing ?</div>
            <div><span style='margin-left:10px'>#</span></div>
          </div>
        </div>
        
        `,
        answer: [
          "1","4","2","5","3",
          "Enid Blyton was a British writer.",
          "She was born in England in 1897.",
          "She first started writing when she was sixteen.",
          "Enid Blyton eventually became the most famous writer of children’s stories.",
          "People loved her work because her mysteries were so exciting.",
        ],
      },
    ],
  },
  
}

export default json;