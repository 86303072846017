import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB7-U5-P60-E1",
    audio: "",
    video: "",
    component: T6,
    // recorder: true,
    questionImage: [],
    textareaStyle: { width: 700 },
    hideBtnFooter: true,

    titleQuestion: [
      {
        num: "1",
        title:
          "Read the text quickly. The following words may be new to you. What do you think they mean? Compare your ideas with a partner's.",
        color: "#dc2b36",
        // prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    hintBox: [
      {
        src: [
          "bodybuilder",
          "calories ",
          "carbohydrates",
          "average",
          "contains",
          "muscle",
        ],
        width: "600px",
        borderColor: "#dc2b36",
      },
    ],
    questions: [
      {
        title: `
          <hintBox id='0'></hintBox>
          <img style='width:90%' src='img/FriendsPlus/Page60/E1/1.jpg'>

        `,
        answer: [],
      },
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB7-U5-P60-E2",
    audio: "Audios/1-54 Friends Plus 7.mp3",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 1070,
    // hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page60/E2/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "<audioimage name='1.54'></audioimage> Read and listen to the text and answer the questions.",
        color: "#db2c37",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
				<img style='width:90%' src='img/FriendsPlus/Page60/E1/1.jpg'>
        <div>
          <div><b>1</b>&emsp;What should you do to become a bodybuilder?<br> #</div>
          <div><b>2</b>&emsp;What can you eat most when you practise bodybuilding?<br> #</div>
          <div><b>3</b>&emsp;How many calories does a basketball player need a day?<br> #</div>
          <div><b>4</b>&emsp;Why are bananas good for basketball players?<br> #</div>
          <div><b>5</b>&emsp;What do you think about the title of the text – Meals for medals?<br> #</div>
        </div>
          `,
        answer: [
          "To become a bodybuilder, you should practise bodybuilding regularly and have a good diet.",
          "You can eat eggs most when you practise bodybuilding.",
          "A basketball player needs between 2,500 and 5,000 calories a day.",
          "Because bananas contain helpful nutrients that make the body feel full of energy.",
          "The title shows the importance of a good diet. Your meals can help you achieve medals.",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 5",
    id: "SB7-U5-P60-E3",
    audio: "",
    video: "",
    component: T6,
    // recorder: true,
    questionImage: [],
    textareaStyle: { width: 700 },
    hideBtnFooter: true,

    titleQuestion: [
      {
        num: "3",
        title:
          "Use a dictionary to check the meaning of the words in <span style='color:rgb(37 181 223)'>blue</span> in the text.",
        color: "#dc2b36",
        prefix: { icons: [""], text: "VOCABULARY PLUS" },
      },
    ],
    // hintBox: [
    //   {
    //     src: [
    //       "bodybuilder",
    //       "calories ",
    //       "carbohydrates",
    //       "average",
    //       "contains",
    //       "muscle",
    //     ],
    //     width: "600px",
    //     color: "#dc2b36",
    //   },
    // ],
    questions: [
      {
        title: `
        <img style='width:90%' src='img/FriendsPlus/Page60/E1/1.jpg'>
        `,
        answer: [],
      },
    ],
  },

  4: {
    // Exercise num
    unit: "Unit 5",
    id: "SB7-U5-P60-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: "Work in pairs. Do you think a good diet can bring you medals?",
        color: "#dc2b36",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [],
  },
};

export default json;
