// TEMPLATE
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
    1: {
        unit: "Option 5",
        id: "SB7-O5-P110-E1",
        exerciseKey: "img/FriendsPlus/Page110/E1/Key/answerKey.png",
        audio: "",
        video: "",
        component: T6,
        inputSize: 210,
        maxLength: 15,
        // checkDuplicated: true,
        hintBox: [{
            src: [
                'battery charger',
                'camera',
                'e-book',
                'headphones',
                'laptop',
                'MP3 player',
                'smartphone',
                'radio',
            ],
            borderColor: "#f77e1d",
            width: 525,
        },],

        titleQuestion: [
            {
                num: "1",
                title: `
                Match the words in the box with devices A–H
                in the pictures.
            `,
                color: "#f77e1d",
            },
        ],
        questions: [
            {
                title: `
                    <div style="margin-top: 20px; width: 600px">
                        <hintbox id='0'></hintbox>

                        <div style="margin-top:30px;"><img src="img/FriendsPlus/Page110/E1/1.jpg" style="max-width: 90%" /></div>

                        <div style="display: flex; margin-top: 30px;">
                            <p>A. #</p>
                            <p style="margin-left: 20px;">B. #</p>
                        </div>
                        <div style="display: flex;">
                            <p>C. #</p>
                            <p style="margin-left: 20px;">D. #</p>
                        </div>
                        <div style="display: flex;">
                            <p>E. #</p>
                            <p style="margin-left: 20px;">F. #</p>
                        </div>
                        <div style="display: flex;">
                            <p>G. #</p>
                            <p style="margin-left: 20px;">H. #</p>
                        </div>
                    </div>
                `,

                answer: [
                    'ebook',
                    'radio',
                    'battery charger',
                    'headphones',
                    'MP3 player',
                    'camera',
                    'laptop',
                    'smartphone',
                ],
            },
        ],
    },

    2: {
        unit: "Option 5",
        id: "SB7-O5-P110-E2",
        exerciseKey: "img/FriendsPlus/Page110/E2/Key/answerKey.png",
        audio: "Audios/3-15 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 115,
        maxLength: 8,
        // checkDuplicated: true,
        // textAlign: 'center',

        titleQuestion: [
            {
                num: "2",
                title: `
                <audioimage name='3.15'></audioimage>
                Listen to a conversation in a shop.
                What device is Tim complaining about?
            `,
                color: "#f77e1d",
            },
        ],

        questions: [
            {
                title: `
                <p style="margin-top: 20px"><strong>Answer: </strong>#</p>
            `,
                answer: ['a laptop'],
            },
        ],

    },

    3: {
        unit: "Option 5",
        id: "SB7-O5-P110-E3",
        exerciseKey: "img/FriendsPlus/Page110/E3/Key/answerKey.png",
        audio: "Audios/3-15 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 85,
        maxLength: 5,

        titleQuestion: [
            {
                num: "3",
                title: `
                <audioimage name='3.15'></audioimage>
                Listen to the conversation again and write True or False. 
                Correct the false sentences.
              `,
                color: "#f77e1d",
            },
        ],

        questions: [
            {
                title: `
                    <ol style='margin-top: 20px; width: 570px;'>
                        <li>Tim isn’t happy with his new laptop. #</li>
                        <li>It’s faster than his old laptop. #</li>
                        <li>He can’t watch videos on it. #</li>
                        <li>He wants to change the battery. #</li>
                        <li>The manager can’t change the laptop. #</li>
                        <li>She sells Tim a new battery. #</li>
                    </ol>
                `,
                answer: [
                    'True',
                    'False',
                    'True',
                    'False',
                    'True',
                    'False',
                ],
            },
        ],

    },

    4: {
        unit: "Option 5",
        id: "SB7-O5-P110-E4",
        exerciseKey: "img/FriendsPlus/Page110/E4/Key/answerKey.png",
        audio: "Audios/3-16 Friends Plus 7.mp3",
        video: "",
        component: T6,
        inputSize: 340,
        maxLength: 31,
        stylesTextInput: {
            fontSize: 23,
        },

        titleQuestion: [
            {
                num: "4",
                title: `
                <audioimage name='3.16'></audioimage>
                Complete the dialogue with the key
                phrases. Listen and check. Then listen again
                and repeat the dialogue.
              `,
                color: "#f77e1d",
            },
        ],

        questions: [
            {
                title: `
                    <div style="width: 600px">
                        <div style="margin-top: 20px; width: 450px; padding-bottom: 20px;border: 1px solid rgb(247, 126, 29); border-radius: 10px; overflow: hidden">
                            <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(247, 126, 29); color: white; padding: 5px; border-radius: 0px 0px 10px 10px; text-transform: uppercase;">
                                Key Phrases
                            </div>
                            
                            <div style="margin-top: 10px; padding-left: 20px;">
                                <div style="color: rgb(247, 126, 29); font-weight: bold; font-size: 1.6rem">
                                    Complaining and apologising
                                </div>

                                <div>
                                    How can I help you?<br/>
                                    I’d like to make a complaint.<br/>
                                    I’m afraid we can’t change it.<br/>
                                    It doesn’t work.<br/>
                                    I’m terribly sorry about that.<br/>
                                    There’s something wrong with it.<br/>
                                </div>
                            </div>
                        </div>

                        <div style="margin-top: 20px">
                            <div style="display: flex;">
                                <span style="margin-right: 20px">Manager</span>
                                <span>Good afternoon. <br/><sup>1</sup>#</span>
                            </div>

                            <div style="display: flex;">
                            <span style="margin-right: 60px">Molly</span>
                                <span>
                                    <sup>2</sup>#. I bought this mobile phone here last week and <br/><sup>3</sup>#. 
                                    I can’t make phone calls.
                                </span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 20px">Manager</span>
                                <span>I see. <sup>4</sup>#</span>
                            </div>
                        
                            <div style="display: flex;">
                            <span style="margin-right: 60px">Molly</span>
                                <span>I would like to change it for a different one.</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 20px">Manager</span>
                                <span><sup>5</sup>#</span>
                            </div>
                        
                            <div style="display: flex;">
                            <span style="margin-right: 60px">Molly</span>
                                <span>But <sup>6</sup>#!</span>
                            </div>
                        
                            <div style="display: flex;">
                                <span style="margin-right: 20px">Manager</span>
                                <span>OK, I’ll see what we can do.</span>
                            </div>
                        </div>
                    </div>
                `,
                answer: [
                    "How can I help you?",
                    "I'd like to make a complaint",
                    "there's something wrong with it",
                    "I'm terribly sorry about that",
                    "I'm afraid we can't change it",
                    "it doesn't work.",
                ],
            },
        ],

    },

    5: {
        unit: "Option 5",
        id: "SB7-O5-P110-E5",
        exerciseKey: "",
        audio: "",
        video: "",
        textareaStyle: { width: 1000 },
        component: T6,
        inputSize: 550,
        maxLength: 100,
        hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
        titleQuestion: [
            {
                num: "5",
                title: `
                Work in pairs. You have a
                problem with a device and you are making
                a complaint. Prepare and practise a new
                dialogue using the ideas below or your own
                ideas. Use the model dialogue and the key
                phrases.
            `,
                color: "#f77e1d",
                prefix: "default",
            },
        ],

        questions: [
            {
                title: `
                <div style="margin-top: 20px; width: 600px">
                    <img src="img/FriendsPlus/Page110/E5/1.jpg" />
                    <div style="margin-top: 20px;"><textarea id="0" rows="7" ></textarea></div>
                </div>
            `,

                answer: [],
            },
        ],
    },

};

export default json;