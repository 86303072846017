import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  9: { // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P80-E9',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page80/E9/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '9',
        title:
          'Complete the sentences with the words in the box.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 110,
    checkUppercase: true,
    hintBox:[
      {
        src: [
            "avoid",
            "build",
            "climb",
            "find",
            "pick",
            "run",
            "stand",
            "use",
        ],
        borderColor: "#333",
        width: 600,
        },
    ],
    questions: [
      {
        title: `
        <div style='margin: 20px 0 20px 0;'><hintbox id= '0' ></hintbox></div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>Before it’s dark, you need to <b>#</b> a new shelter with branches.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>If you see a big bear, try to<b>#</b> still.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>If you’re afraid, you can <b>#</b> up a tree.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>Look for a river if you want to<b>#</b> some drinking water.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>You can always<b>#</b> away quickly from animals.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>Don't <b>#</b> strange fruit.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 7 </b></div>
          <div>If you <b>#</b> the sun, you can find out where you are. You don’t need a map!</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 8 </b></div>
          <div>Remember to <b>#</b> all dangerous plants.</div>
        </div>

        `,
        answer: [
          "build",
          "stand",
          "climb",
          "find",
          "run",
          "pick",
          "use",
          "avoid",
        ],
      },
    ],
  },
  10:{ // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P80-E10',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page80/E10/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '10',
        title:
          'Complete the personality adjectives in the text.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 150,
    checkUppercase: true,
    questions: [
      {
        title: `
        <div>If you want to do well on a TV survival 
        programme, you need the right personal 
        qualities. First of all, it’s important to want to 
        win – you need to be <b><sup>1</sup>#</b> .</div>
        <div>It also helps if you’re <b><sup>2</sup>#</b> – 
        because you need to climb trees and run 
        away from crocodiles! It’s useful to be 
        intelligent, creative and <b><sup>3</sup>#</b> .</div>
        <div>Don’t forget to work in a team and listen 
        to others – nobody likes a <b><sup>4</sup>#</b>
        person</div>
        <div>If people like you, they will help you, so don’t 
        be too quiet and <b><sup>5</sup>#</b> . It's always 
        important to be <b><sup>6</sup>#</b> !</div>
        

        `,
        answer: [
          "competitive",
          "fit",
          "clever",
          "bossy",
          "reserved",
          "friendly",
        ],
      },
    ],
  },
  11: { // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P80-E11',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page80/E11/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '11',
        title:
          'Complete the sentences with the correct form of the verbs.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 180,
    checkUppercase: true,
    questions: [
      {
        title: ` 
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>If she <b>#</b> (eat) that dangerous fruit, she <b>#</b> (feel) very ill.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>I <b>#</b> (stay) in the shelter if it <b>#</b> (rain).</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>If they <b>#</b> (not work) hard, they <b>#</b> (not win) the survival competition.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>We <b>#</b> (not get) cold if we <b>#</b> (take) warm coats.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>If he <b>#</b> (see) a lion, he <b>#</b> (be) afraid.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>Charlie <b>#</b> (get) better if he <b>#</b> (drink) some water.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 7 </b></div>
          <div>If you <b>#</b> (finish) your homework in time, <b>#</b> (you / watch) that adventure programme on TV?</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 8 </b></div>
          <div>What <b>#</b> (we / do) if we <b>#</b> (lose) our map?</div>
        </div>

        `,
        answer: [
          "eats","'ll feel",
          "'ll stay","rains",
          "don't work"," won't win",
          "won't get","take",
          "sees"," 'll be",
          "will get,","drinks",
          "finish","will you watch",
          "will we do","lose",
        ],
      },
    ],
  },
  12: { // Exercise num
    unit: 'Progress review 3',
    id: 'SB7-PR3-P80-E12',
    audio: 'Audios/2-13 Friends Plus 7.mp3',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page80/E12/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '12',
        title:
          '<AudioImage name="2.13"></AudioImage>Listen to the conversation between Alex and his dad. Complete the sentences below.',
        color: '#50419c',
      },
    ],
    component: T6,
    inputSize: 180,
    checkUppercase: true,
    questions: [
      {
        title: ` 
        <div>Alex …</div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 1 </b></div>
          <div>has got two <b>#</b> in his bag.</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 2 </b></div>
          <div>has got a new <b>#</b> .</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 3 </b></div>
          <div>hasn’t got a <b>#</b> .</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 4 </b></div>
          <div>needs to buy a <b>#</b> .</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 5 </b></div>
          <div>doesn’t want to take a <b>#</b> .</div>
        </div>
        <div style='display: flex;'>
          <div style='margin-right: 10px;'><b> 6 </b></div>
          <div>can’t put the big <b>#</b> in his bag.</div>
        </div>
        `,
        answer: [
          "water bottles",
          "sleeping bag ",
          "knife",
          "compass",
          "first-aid kit",
          "torch",
        ],
      },
    ],
  },

}

export default json;