import T6 from '../../components/ExcerciseTypes/TypeIn/T6';



const json = {
  1: {
    unit: "Unit 6",
    id: "SB7-U6-P75-E1",
    audio: "",
    video: "",
    component: T6,
    textAlign: "left",
    // exerciseKey: "img/FriendsPlus/Page75/E1/Key/answerKey.png",
    titleImage: "",
    textareaStyle: { width: 1000 },
    hideBtnFooter: true,
    questionImage: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Read Thanh’s blog. Who is the information in this blog for? What advice does Thanh give about school work?",
        color: "#01a84e",
      },
    ],
    questions: [
      {
        title: `
            <div><img style='width: 80%' src='img/FriendsPlus/Page75/E1/1.png' alt=''/></div>
            <div style='margin-left: 50px; margin-top: 50px;'>
              <b><i>Write:</i></b>
              <textarea id="0" rows="4"></textarea>
            </div>
        `,
        // answer: ["The information is for other students. Thanh advises not to get behind with your work, to pay attention in class and do your homework."],
        answer: [],
      },
    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB7-U6-P75-E2',
    audio: "",
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page75/E2/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '2',
        title:
          'Complete the Key Phrases with words from the blog.',
        color: '#01a84e',
      },
    ],
    component: T6,
    inputSize: 340,
    checkUppercase: true,
    hintBox: [],
    questions: [
      {
        title: `
        <div><img style='width: 80%' src='img/FriendsPlus/Page75/E1/1.png' alt=''/></div>
        <div style='background-color: rgb(207, 232, 211); margin-top: 20px; margin-left:150px; width:650px; border-radius: 15px'>
          <div style='background-color: rgb(79, 188, 121); border-radius: 15px;'><h1 style='color: rgb(255, 255, 255); padding: 5px 5px 5px 25px;'>KEY PHRASES</h1></div>
          <div style='padding: 15px 25px 25px 25px;' >
            <div ><b style='color:rgb(1, 168, 78) ;'>Giving advice</b></div>
            <div>It’s a good idea to <sup>1</sup># .</div>
            <div>You should <sup>2</sup># .</div>
            <div>It’s important to<sup>3</sup># .</div>
            <div>If you need help, <sup>4</sup># .</div>
          </div>
      </div>
        `,
        answer: [
          "join clubs",
          "pay attention in class",
          "arrive on time for lessons",
          "ask your teachers",
        ],
      },
    ],
  },

  3: {
    unit: "Unit 6",
    id: "SB7-U6-P75-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page75/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Cover the text. Can you remember the advice? Complete the sentences. <br/>Then read the text and check your answers.",
        color: "#01a84e",
      },
    ], inputSize: 100,
    stylesTextInput: { borderBottom: "dotted 1px" },
    maxLength: 7,
    textAlign: "center",
    questions: [
      {
        title: `
      <div style='margin:10px;border-radius:20px; border:4px solid rgb(1, 168, 78);padding: 5px; width: 15cm'>
        <div style='font-weight:900;color:white;background:rgb(1, 168, 78);padding-left:20px;border-radius:15px'>Affirmative ✓</div>
        <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'> # confident.</div>
        </div>
        <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'> # well.</div>
        </div>
        <div style='font-weight:900;color:white;background:rgb(1, 168, 78);padding-left:20px;border-radius:15px'>Negative ✗</div>
        <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:20px'>Don’t # behind with work.</div>
        </div>
        <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:20px'>Don’t # ! If you need help, ask your teacher!</div>
        </div>
      </div>
        `,
        answer: ["Stay", "Dress", "get", "panic"],
      },
    ],
  },


  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB7-U6-P75-E4',
    audio: '',
    video: '',
    padding: 0,
    textAlign: 'center',
    maxLength: 40,
    exerciseKey: 'img/FriendsPlus/Page75/E4/Key/answerKey.png',
    questionImage: [],
    titleQuestion: [
      {
        num: '4',
        title:
          'Complete the imperative sentences with the verbs in the box. ',
        color: '#01a84e',
      },
    ],
    component: T6,
    inputSize: 150,
    // checkUppercase: true,
    hintBox: [
      {
        src: [
          "arrive",
          "be",
          "organise",
          "worry",
        ],
        borderColor: "#01a84e",
        width: 600,
      },
    ],
    questions: [
      {
        title: `
        <div><hintbox id= '0' ></hintbox></div>
        <div><b>1 </b> Don’t # afraid to say what you think.</div>
        <div><b>2 </b> Don’t # about ‘problem people’.</div>
        <div><b>3 </b> # your study time.</div>
        <div><b>4 </b> Don’t # late for lessons.</div>
        `,
        answer: [
          "be",
          "worry",
          "Organise",
          "arrive",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB7-U6-P75-E5",
    audio: "",
    video: "",
    component: T6,
    recorder: false,
    titleQuestion: [
      {
        num: "5",
        title:
          " follow the steps in the Writting Guide. Ask and answer the questions for part B with your partner",

        color: "#01a84e",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questionImage: [],
    textareaStyle: { width: 650 },
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display: flex;'>
        <textarea id="0" rows="8"></textarea>
        <img src='img/FriendsPlus/Page75/E5/1.png' alt=''/>
        </div>
        `,
        answer: [],
      },
    ],
  },
}

export default json;