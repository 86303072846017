import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Unit 1',
		id: 'SB7-U1-P85-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page85/E1/Key/answerKey.png',
		component: Circle_Write,
		component: Circle_Write,
		titleQuestion: [
			{
				num: '1',
				title: `
          Complete the sentences and check your answers on page 84. 
          Then choose the correct words in the rule.
        `,
				color: '#1ebfd7',
			},
		],
		question: {
			Write: {
				inputStyle: { borderBottom: 'none', fontSize: 24, width: 75 },
				answers: ["I'm", 'not', 'to', "We're", 'have'],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					borderRadius: '50%',
					borderColor: 'transparent',
					color: '#00aeef',
					fontSize: 24,
				},
				selectWordStyle: {
					padding: 8,
					border: 'solid 2px',
					borderColor: '#000000',
				},
				limitSelect: 1,
				listWords: ['make_predictions / talk_about_plans_and_intentions.'],
				answers: ['0-4'],
				initialValue: [],
			},
			Layout: `
			<div style="margin-top: 20px;">
				<div style="padding-left: 10px; margin-bottom: 16px;">
					<div>
						<strong style="padding-right: 10px;">1</strong>
						<input id='0' /> going to be famous.
						
					</div>
					<div>
						<strong style="padding-right: 10px;">2</strong>
						I'm <input id='1' /> going <input id='2' /> think about the wrongs and the rights.
					</div>
					<div>
						<strong style="padding-right: 10px;">3</strong>
						<input id='3' /> going to <input id='4' /> cool keyboard and bass.
					</div>
				</div>

				<div style="margin-top: 30px; width: 600px; padding-bottom: 10px; border-radius: 20px; overflow: hidden; border: 1px solid rgb(93,200,220); margin-left: 20px;">
					<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(93,200,220); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
						RULES
					</div>
							
					<div style="padding: 20px;">
						We use be going to when we <sup>4</sup><input id='0' type='Circle' />
					</div>
				</div>
			</div>
		  `,
		},
	},
	2: {
		unit: 'Language Focus',
		id: 'SB7-L-P85-E2',
		exerciseKey: 'img/FriendsPlus/Page85/E2/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 220,
		maxLength: 20,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
          Study the information and complete the sentences.
        `,
				color: '#1ebfd7',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 600px; margin-top: 20px;">
            <div style="display: flex; justify-content: center; align-items: center;">
              <img src="img/FriendsPlus/Page85/E2/1.jpg" style="max-width: 100%;" />
            </div>

            <div style="margin-top: 20px; margin-left: 50px;">
              Laila <i style=" color: rgb(147,147,147);"><strong>isn't going to change</strong></i> the lyrics. <br/>
              Sam <i style=" color: rgb(147,147,147);"><strong>is going to change</strong></i> the lyrics.
            </div>

			<div style="padding-left: 10px; margin-bottom: 16px;">
				<div>
					<strong style="padding-right: 10px;">1</strong>
					Sam # the music.
				</div>
				<div>
					<strong style="padding-right: 10px;">2</strong>
					Laila # the music.		
				</div>
				<div>
					<strong style="padding-right: 10px;">3</strong>
					She # on TV.		
				</div>
				<div>
					<strong style="padding-right: 10px;">4</strong>
					Sam and Laila # a video.		
				</div>
				<div>
					<strong style="padding-right: 10px;">5</strong>
					They # a pop version.		
				</div>
			</div>
		</div>
                `,
				answer: [
					"isn't going to write",
					'is going to write',
					"isn't going to go",
					'are going to make',
					"aren't going to do",
				],
			},
		],
	},
	3: {
		unit: 'Language Focus',
		id: 'SB7-L-P85-E3',
		exerciseKey: 'img/FriendsPlus/Page85/E3/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		textareaStyle: { width: 600 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '3',
				title: `
          Write sentences about your plans and your friends' plans. 
          Use affirmative and negative forms of <i>be going</i> to and 
          the ideas in the table.
        `,
				color: '#1ebfd7',
			},
		],

		questions: [
			{
				title: `
					<div style="width: 600px; margin-top: 20px;">
            <i style="margin-left: 50px; color: rgb(147,147,147);"><strong>I'm not going to sing in class today.</strong></i><br/>

            <div style="display: flex; margin-top: 10px;">
              <div style="background-color: rgb(207,236,244); border: 1px solid rgb(30,192,217); padding: 10px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                  <span>
                    I <br/>
                    My friends and I <br/>
                    One of my friends
                  </span>
                </div>

                <div style="background-color: rgb(207,236,244); border: 1px solid rgb(30,192,217); padding: 10px">
                  <span>sing in class today</span> <br/>
                  <span>become a DJ</span> <br/>
                  <span>go to music class this week</span> <br/>
                  <span>be in a band one day</span> <br/>
                  <span>become rich and famous</span> <br/>
                  <span>listen to some music this evening</span> <br/>
                  <span>learn to play the drums</span> <br/>
                </div>
            </div>

            <textarea id="8" rows="10"></textarea>
					</div>
        `,

				answer: [],
			},
		],
	},
	4: {
		unit: 'Language Focus',
		id: 'SB7-L-P85-E4',
		exerciseKey: 'img/FriendsPlus/Page85/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 140,
		maxLength: 12,
		stylesTextInput: {
			fontSize: 23,
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '4',
				title: `
          Look at the examples. 
          Which sentence is a plan and which is a prediction?
        `,
				color: '#1ebfd7',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 670px; margin-top: 20px;">
						<div>
							<strong>Examples:</strong>
							<div style="padding-left: 10px; margin-bottom: 16px;">
								<div style="display: flex;">
									<span>We're going to have cool keyboard and bass.</span>
								</div>
								<div style="display: flex;">
									<span>It'll be a big hit!</span>
								</div>
							</div>
            			</div>
						<div>
							<strong>Answer:</strong><br/>
							The first sentence is #. <br/>
							The second sentence is #. 
						</div>
					</div>
                `,
				answer: ['a plan', 'a prediction'],
			},
		],
	},
	5: {
		unit: 'Language Focus',
		id: 'SB7-L-P85-E5',
		exerciseKey: 'img/FriendsPlus/Page85/E5/Key/answerKey.png',
		audio: '',
		video: '',
		isHiddenCheck: true,
		component: T6,
		inputSize: 210,
		maxLength: 50,
		stylesTextInput: {
			fontSize: 20,
			textAlign: 'center',
		},
		titleQuestion: [
			{
				num: '5',
				title: `
          Complete sentences with <i>be going to</i> (for plans and intentions) <br/>or <i>will</i> (for predictions) and the verbs in brackets.
        `,
				color: '#1ebfd7',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px; width: 700px; position: relative;">
            <img src="img/FriendsPlus/Page85/E5/1.jpg" style="max-width: 100%;" />

            <div style="width: 630px; position: absolute; top: 150px; left: 34px; font-size: 20px;">
              So, here's the good news. The concert is on Saturday, 
              so we <br/><sup>1</sup># (practise) on Monday 
              and Tuesday. I think it <br/><sup>2</sup># (be) fun. 
              People <sup>3</sup># (like) the show because 
              we <sup>4</sup># (spend) a lot of money on 
              lights and musicians. The band is from New York. I'm 
              sure that they <sup>5</sup># (enjoy) England. 
              <br/>I <sup>6</sup># (take) them to London. 
              <br/>We <sup>7</sup># (stay) in a nice hotel. 
            </div>
					</div>
				`,

				answer: [
					'are going to practise',
					'will be',
					'will like',
					'are going to spend',
					'will enjoy',
					"'m going to take",
					'are going to stay',
				],
			},
		],
	},
	6: {
		unit: 'Language Focus',
		id: 'SB7-L-P85-E6',
		exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		textareaStyle: { width: 600 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '6',
				title: `
          Work in pairs. Read the Study Strategy. Then write examples of two <br/>plans and two predictions. Tell your partner about them.
        `,
				color: '#1ebfd7',
			},
		],

		questions: [
			{
				title: `
					<div style="margin-top: 10px; width: 700px;">
            			<div style="margin-top: 20px; width: 600px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgba(214,238,248);">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(0,126,140); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
								STUDY STRATEGY
							</div>
              
							<div style="margin-top: 10px; padding: 0 20px">
								<div style="color: rgb(0,126,140); font-weight: bold; font-size: 1.6rem">
									Remembering grammar
								</div>
								<div style="padding-left: 10px; margin-bottom: 16px;">
									<div style="display: flex;">
										<strong style="padding-right: 10px;">1</strong>
										<span>When you learn a new grammar rule, write example sentences in your notebook.</span>
									</div>
									<div style="display: flex;">
										<strong style="padding-right: 10px;">2</strong>
										<span>Memorise your sentences and test yourself every week.</span>
									</div>
								</div>
							</div>
            			</div>
            			<textarea id="0" rows="7"></textarea>
					</div>
				`,
				answer: [],
			},
		],
	},
	7: {
		unit: 'Language Focus',
		id: 'SB7-L-P85-E7',
		exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		hintBox: [
			{
				src: ['Khang is going to play the drums.'],
				borderColor: '#1ebfd7',
				arrow: true,
				position: 1,
				width: 430,
			},
			{
				src: [
					`We aren't going to practise every day, 
          but we'll definitely have a lot of fans.`,
				],
				borderColor: '#1ebfd7',
				arrow: true,
				position: 2,
				width: 500,
			},
		],
		textareaStyle: { width: 1000 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')

		titleQuestion: [
			{
				num: '7',
				title: `
          Imagine that you are forming a band with some friends. 
          Make notes about your plans and predictions for the band. 
          Then tell the class. Use ideas from exercise 3 and your own ideas.
        `,
				color: '#1ebfd7',
				prefix: 'default',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px;">
						<div style="margin-top: 20px;  margin-left: 200px;">
							<div>
								<hintbox id='0'></hintbox>
							</div>
							<div style="margin-top:20px; margin-left: 150px">
								<hintbox id='1'></hintbox>
							</div>
						</div>
						<div style="margin-top: 40px;"><textarea id="8" rows="7"></textarea></div>
					</div>
				`,
				answer: [],
			},
		],
	},
	8: {
		unit: 'Language Focus',
		id: 'SB7-L-P85-E8',
		exerciseKey: '',
		audio: 'Audios/2-27 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		finished: {
			text: `
        Write some predictions and plans or intentions for next year.
			`,
		},
		textareaStyle: { width: 750 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '#1ebfd7',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 10px; width: 700px;">
						<finished></finished>
						<textarea id="0" rows="9"></textarea>
					</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
