import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import TB2 from '../../components/ExcerciseTypes/Table/TB2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 1',
    id: 'SB7-U1-P13-E1',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page13/E1/Key/answerKey.png',
    inputSize: 150,
    maxLength: 7,
    textAlign: 'center',
    checkUppercase: true,
    titleQuestion: [
      {
        num: '1',
        title: 'Complete the sentences with the words in the box. Then check your answers in the forum on page 12.',
        color: '#1ec0d9',
      },
    ],
    hintBox: [
      {
        src: ["doesn't", "<s>don't<s>", "don't", 'studies', 'want', 'watches'],
        borderColor: '#1bb7d0',
        width: 350,
      },
    ],
    questions: [
      {
        title: `
          <div style='margin:10px 250px'><hintbox id='0'></hintbox></div>
          <div style=" display: flex; flex-direction: row; ">
            <div>
              <div> We <i style='text-decoration: underline'>don’t</i> use our phone.</div>
              <div><b>1</b> She's older and she # more.</div>
              <div><b>2</b> But she # study!</div> 
              <div><b>3</b> I # watch much TV.</div>
              <div><b>4</b> I just # to sleep in my bedroom!</div>
              <div><b>5</b> My nan # TV a lot.</div> 
            </div>
          </div>
        `,
        answer: ['studies', "doesn't", "don't", 'want', 'watches'],
      },
    ],
  },
  2: {
    unit: 'Unit 1',
    id: 'SB7-U1-P13-E2',
    audio: '',
    video: '',
    component: T6,
    isHiddenCheck: true,
    exerciseKey: 'img/FriendsPlus/Page13/E2/Key/answerKey.png',
    inputSize: 150,
    stylesTextInput: { borderBottom: 'dotted 1px' },
    maxLength: 8,
    textAlign: 'center',
    checkUppercase: true,
    checkDuplicated: true,
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the Rules with five words from the box.',
        color: '#1ec0d9',
      },
    ],
    hintBox: [
      {
        src: ["doesn't", "don't", 'end', 'habits', 'routines', 'start'],
        borderColor: '#1bb7d0',

        width: 350,
      },
    ],
    questions: [
      {
        title: `
        <div style="display:flex; justify-content:center"><hintBox id='0'></hintBox></div>
        <div style='margin:10px;border-radius:20px; border:4px solid rgb(93 200 220);padding: 5px; width: 15cm'>
        <div style='font-weight:900;color:white;background:rgb(93 200 220);padding-left:20px;border-radius:15px'>RULES</div>
        <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'>We use the present simple to talk about facts # and #.</div>
        </div>
        <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'>Affirmative verbs # with <i>-s / es</i> in <i>he / she</i> forms. </div>
        </div>
        <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:20px'>Negative forms use # + infinitive without to after <i>I / you / we / they</i>.</div>
        </div>
        <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:20px'>Negative forms use # + infinitive without to after <i>he / she / it</i>.</div>
        </div>
      </div>
        `,
        answer: ['habits / routines', 'habits / routines', 'end', "don't", "doesn't"],
      },
    ],
  },
  // 6: {
  //   unit: "Unit 1",
  //   id: "SB7-U1-P13-E2",
  //   audio: "",
  //   video: "",
  //   component: TB1,
  //   exerciseKey: "img/FriendsPlus/Page13/E2/Key/answerKey.png",
  //   hintBox: [
  //     {
  //       src: ["doesn't", "don't", "end", "habits", "routines", "start"],
  //       borderColor: "#1bb7d0",
  //       width: 350,
  //     },
  //   ],
  //   titleQuestion: [
  //     {
  //       num: "2",
  //       title: "Complete the Rules with five words from the box.",
  //       color: "#1dbfd7",
  //     },
  //   ],
  //   questions: [
  //     {
  //       title: `<div>doesn’t&emsp;&emsp;don’t&emsp;&emsp;end&emsp;&emsp;habits&emsp;&emsp;routines&emsp;&emsp;start</div>`,
  //       answer: [],
  //     },
  //   ],

  //   data: [
  //     {
  //       title: "RULES",
  //       content: `

  //         <div style='display: flex;'><div style='margin-right: 10px;'><b>1</b></div><div>We use the present simple to talk about facts, <input id='0' /> and <input id='1' />.</div></div>
  //         <div style='display: flex;'><div style='margin-right: 10px;'><b>2</b></div><div>Affirmative verbs <input id='2' /> with <i>-s / es</i> in <i>he / she / it</i> forms.</div></div>
  //         <div style='display: flex;'><div style='margin-right: 10px;'><b>3</b></div><div>Negative forms use <input id='3' /> + infinitive without <i>to</i> after <i>I / you / we / they.</i></div></div>
  //         <div style='display: flex;'><div style='margin-right: 10px;'><b>4</b></div><div>Negative forms use <input id='4' /> + infinitive without <i>to</i> after <i>he / she / it.</i></div></div>
  //       `,
  //       type: "input",
  //       answers: [
  //         "habits / routines",
  //         "routines / habits",
  //         "end",
  //         "don't",
  //         "doesn't",
  //       ],
  //     },
  //     // {
  //     //   title: 'Affirmative ✔',
  //     //   content: "I'm the one...",
  //     //   type: 'textarea',
  //     //   answers: ['these are 1'],
  //     // },
  //   ],
  //   outterStyle: {},
  //   innerStyle: { color: "white" },
  // },
  3: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB7-U1-P13-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page13/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Choose the correct words.', color: '#2CBAEA' }],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: [
          "don't / doesn't", //1
          "don't / doesn't", //2
          'study / studies', //3
          "don't / doesn't",
          'watch / watches',
        ],
        answers: ['0-4', '1-4', '2-4', '3-0', '4-0'],
        initialValue: [],
      },
      Layout: `
      <div  style="display: flex;flex-direction: row;line-height: 48px;justify-content: space-between;">
        <div>
          <div> Some students <span style=" border: solid 2px #000000; border-radius: 50%; padding: 5px; ">finish</span> / finishes school at 5 p.m.</div>
          <div><b>1</b> My mum <b><input id='0' type='Circle' /></b> like games.</div>
          <div><b>2</b> She <b><input id='1' type='Circle' /></b> play much.</div>
          <div><b>3</b> Mark <b><input id='2' type='Circle' /></b> a lot.</div>
          <div><b>4</b> His friends <b><input id='3' type='Circle' /></b> work much.</div>
          <div><b>5</b> My friends and I <b><input id='4' type='Circle' /></b>  </div>
          <div>&ensp; films on my computer.</div>
        </div>
      </div>
      `,
    },
  },

  4: {
    // Exercise num
    unit: 'Starter',
    id: 'SB7-S-P13-E4',
    audio: 'Audios/1-07 Friends Plus 7.mp3',
    video: '',
    component: TB2,
    titleQuestion: [
      {
        num: '4',
        title:
          '<audioimage name="1.07"></audioimage> <span style="color: rgb(24 192 220);"><b>PRONUNCIATION</b> third person-s</span> Listen. Then practise the examples.',
        color: '#1dbfd7',
      },
    ],
    tb2Style: { width: 700 },
    exerciseKey: 'img/FriendsPlus/Page7/E5/Key/answerKey.png',
    type: 1,
    data: [
      {
        title: '/s/',
        content: ['sleeps', ' ', ' ', ' ', ' ', ' '],
        answers: ['sleeps', ' ', ' ', ' ', ' ', ' '],
        commonStyle: {
          backgroundColor: '#77cedf',
          color: 'black',
          fontSize: 26,
          border: '1px solid rgb(24 192 220)',
          fontStyle: 'oblique',
          // justifyContent: "flex-start",
        },
        titleStyle: {},
        contentStyle: { fontSize: 25, backgroundColor: '#cfecf4' },
      },
      {
        title: '/z/',
        content: ['says'],
        answers: ['says', '', '', '', '', ''],
        commonStyle: {
          backgroundColor: '#77cedf',
          color: 'black',
          fontSize: 26,
          border: '1px solid rgb(24 192 220)',
          fontStyle: 'oblique',
        },
        titleStyle: {},
        contentStyle: { fontSize: 25, backgroundColor: '#cfecf4' },
      },
      {
        title: '/iz/',
        content: ['finishes'],
        answers: ['finishes'],
        commonStyle: {
          backgroundColor: '#77cedf',
          color: 'black',
          fontSize: 26,
          border: '1px solid rgb(24 192 220)',
          fontStyle: 'oblique',
        },
        titleStyle: {},
        contentStyle: { fontSize: 25, backgroundColor: '#cfecf4' },
      },
    ],
    questions: [],
  },

  5: {
    // Exercise num
    unit: 'Unit 1',
    id: 'SB7-U1-P13-E5',
    audio: 'Audios/1-08 Friends Plus 7.mp3',
    video: '',
    component: TB2,
    titleQuestion: [
      {
        num: '5',
        title:
          '<audioimage name="1.08"></audioimage> <span style="color: rgb(24 192 220);"><b>PRONUNCIATION</b> third person-s</span> Listen to eight more verbs and add them to table in exercise 4. Practise saying them.',
        color: '#1dbfd7',
      },
    ],
    tb2Style: { width: 700 },
    exerciseKey: 'img/FriendsPlus/Page13/E5/Key/answerKey.png',
    type: 1,
    data: [
      {
        title: '/s/',
        content: ['#', '#', '#', '#', '#'],
        answers: ['lets', 'works', '', '', ''],
        commonStyle: {
          backgroundColor: '#77cedf',
          color: 'black',
          fontSize: 26,
          border: '1px solid rgb(24 192 220)',
          fontStyle: 'oblique',
          // justifyContent: "flex-start",
        },
        titleStyle: {},
        contentStyle: { fontSize: 25, backgroundColor: '#cfecf4' },
      },
      {
        title: '/z/',
        content: ['#', '#', '#', '#', '#'],
        answers: ['spends', 'lives', 'plays', 'allows', 'studies'],
        commonStyle: {
          backgroundColor: '#77cedf',
          color: 'black',
          fontSize: 26,
          border: '1px solid rgb(24 192 220)',
          fontStyle: 'oblique',
        },
        titleStyle: {},
        contentStyle: { fontSize: 25, backgroundColor: '#cfecf4' },
      },
      {
        title: '/iz/',
        content: ['#', '#', '#', '#', '#'],
        answers: ['watches', '', '', '', ''],
        commonStyle: {
          backgroundColor: '#77cedf',
          color: 'black',
          fontSize: 26,
          border: '1px solid rgb(24 192 220)',
          fontStyle: 'oblique',
        },
        titleStyle: {},
        contentStyle: { fontSize: 25, backgroundColor: '#cfecf4' },
      },
    ],
    questions: [],
  },

  6: {
    unit: 'Unit 1',
    id: 'SB7-S-P13-E6',
    audio: '',
    video: '',
    isHiddenCheck: true,
    component: T6,
    titleQuestion: [
      {
        num: '6',
        title: 'Complete the text with the correct form of the verbs in brackets.',
        color: '#1dbfd7',
      },
    ],
    stylesTextInput: {
      fontSize: 25,
      textAlign: 'center',
      // borderBottom: "none",
      backgroundColor: 'transparent',
    },
    inputSize: 170,
    maxLength: 13,
    // fontSize: 30,
    exerciseKey: 'img/FriendsPlus/Page13/E6/Key/answerKey.png',
    questionImage: [],
    questions: [
      {
        title: `
          <div style='width: 20cm; border-radius: 15px; background: rgb(212 221 236); padding: 20px'>
					    <div style='display: flex;  '>
                <img src='img/FriendsPlus/Page13/E6/1.jpg' />
                <div style='margin-left:20px'>
                    I <span style='color:gray; text-decoration:underline'> &emsp;like&emsp;</span>(like)
                    my brother, but I <sup>1</sup>#(think) he has a problem. He <sup>2</sup>#(not sleep)
                    much at weekends because he <sup>3</sup>#(play) video games all day and <sup>4</sup>#
                    (watch) TV
                </div>
              </div>
              <div>
              all  night. His friends <sup>5</sup>#
                  (not see) him very often because he <sup>6</sup>#(stay) in his room and he<br>
                  <sup>7</sup>#(not go) outside. He <sup>8</sup>#(speak) to us on his mobile
                  phone when he’s hungry. I <sup>9</sup>#(not know) if this is normal. Please help!
                  

              </div>
          </div>
				`,
        answer: [
          'think',
          "doesn't sleep",
          'plays',
          'watches',
          "don't see",
          'stays',
          "doesn't go",
          'speaks',
          "don't know",
        ],
      },
    ],
  },

  7: {
    unit: 'Unit 1',
    id: 'SB7-U1-P13-E7',
    audio: '',
    video: '',
    component: T6,
    // exerciseKey: "img/FriendsPlus/Page13/E7/Key/answerKey.png",
    inputSize: 240,
    checkUppercase: true,
    recorder: true,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '7',
        title:
          'Write true and false sentences using the words in the boxes. Use affirmative and negative forms. Compare with your partner.',
        color: '#1dbfd7',
        prefix: 'default',
        // prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex;margin: 10px 100px'>
            <div style='height: 65mm;border: 2px solid rgb(24 192 220);background:rgb(214 238 248); padding:5px 20px 5px 10px;border-radius:15px'>
              I<br>My mum<br>My dad<br>My friend<br>My teacher<br>Most people
            </div>
            <div style='height: 65mm;margin: 0px 30px;display: flex;border: 2px solid rgb(24 192 220);background:rgb(214 238 248); padding:5px 20px 5px 10px;border-radius:15px'>
              <div>study<br>use<br>play<br>go<br>watch<br>spend</div>
              <div style='margin-left:25px'>like<br>live<br>speak<br>sleep<br>let<br>eat</div>
            </div>
            <div style='border: 2px solid rgb(24 192 220);background:rgb(214 238 248); padding:5px 20px 5px 10px;border-radius:15px'>
              English<br>TV<br>video games<br>the phone<br>bedroom<br>outside<br>classroom<br>playground
            </div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  8: {
    unit: 'Unit 1',
    id: 'SB7-U1-P13-E8',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page13/E7/Key/answerKey.png',
    inputSize: 240,
    textareaStyle: { width: 600 },
    checkUppercase: true,
    recorder: true,
    finished: {
      text: 'Write sentences about your screen time: what your parents let you do, and what they don’t allow you to do.',
    },
    hideBtnFooter: true,
    // titleQuestion: [
    //   {
    //     num: "7",
    //     title:
    //       "Write true and false sentences using the words in the boxes. Use affirmative and negative forms. Compare with your partner.",
    //     color: "#1dbfd7",
    //     prefix: "default",
    //     // prefix: { icons: ['far fa-comment', 'fas fa-pencil-alt'], text: 'CULTURAL' },
    //   },
    // ],
    questions: [
      {
        title: `
          <finished></finished>
          <textarea id="0" rows="4"></textarea>
        `,
        answer: ['', ''],
      },
    ],
  },
};

export default json;
