import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	10: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P104-E10',
		exerciseKey: 'img/FriendsPlus/Page104/E10/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 200,
		maxLength: 16,
		stylesTextInput: {
			fontSize: 24,
		},
		titleQuestion: [
			{
				num: '10',
				title: `
                    Complete the sentences with the vehicles.
              `,
				color: '#4c3d9b',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <ol style="margin-top: 30px;">
                            <li>This # can help you walk and fly like a bird.</li>
                            <li>You can take this # from District 1 to Thu Duc city. It runs on water and land.</li>
                            <li>Look! Small cars are running safely under the #.</li>
                            <li># and  # may be your best choice if you want to fly.</li>
                            <li>A # can give you more time to work on the way to your office or to relax on the way home.</li>
                        <ol>

                        <div style="margin-top: 30px; display: flex; justify-content: center;">
                            <img src="img/FriendsPlus/Page104/E10/1.jpg" style="max-width: 100%" />
                        </div>
                    </div>
                `,
				answer: ['jetpack', 'amphibious bus', 'tunnel bus', 'Flying motorbike', 'jetpack', 'self-driving car'],
			},
		],
	},

	11: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P104-E11',
		exerciseKey: 'img/FriendsPlus/Page104/E11/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 160,
		maxLength: 12,
		stylesTextInput: {
			fontSize: 24,
		},
		titleQuestion: [
			{
				num: '11',
				title: `
                    Complete each sentence with two words.
              `,
				color: '#4c3d9b',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <ol>
                            <li># are limited and cause pollution.</li>
                            <li># is not new to us because we use it every day.</li>
                            <li># catch the sunrays and change them into electricity.</li>
                            <li>In the past, sailors used # to sail their ships to different areas.</li>
                            <li>Both solar energy and wind power are # sources of energy.</li>
                        <ol>

                        <div style="margin-top: 30px; display: flex; justify-content: center;">
                            <img src="img/FriendsPlus/Page104/E11/1.jpg" style="max-width: 100%" />
                        </div>
                    </div>
                `,
				answer: ['Fossil fuels', 'Solar energy', 'Solar panels', 'wind', 'renewable'],
			},
		],
	},

	12: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P104-E12',
		exerciseKey: 'img/FriendsPlus/Page104/E12/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 680,
		maxLength: 57,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 24,
		},
		titleQuestion: [
			{
				num: '12',
				title: `
                Write sentences using <i>because, and, but, or.</i>
              `,
				color: '#4c3d9b',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 680px">
                        <ol style='margin-top: 30px'>
                            <li>You / I / should use / solar energy. #</li>
                            <li>Olga / will visit / Egypt / Elsa won't. #</li>
                            <li>They / stayed at home / they wanted/ avoid Covid-19. #</li>
                            <li>'Would you like tea / coffee?' 'Either.' #</li>
                            <li>Study hard / you'll fail the exam. #</li>
                            <li>I saw / her brother / I not see her father. #</li>
                        </ol>

                        <div style="margin-top: 30px; display: flex; justify-content: center;">
                            <img src="img/FriendsPlus/Page104/E12/1.jpg" style="max-width: 100%" />
                        </div>
                    </div>  
                `,

				answer: [
					'You and I should use solar energy.',
					"Olga will visit Egypt but Elsa won't.",
					'They stayed at home because they wanted to avoid Covid-19.',
					'Would you like tea or coffee? - Either.',
					"Study hard or you'll fail the exam.",
					"I saw her brother but I didn't see her father.",
				],
			},
		],
	},

	13: {
		unit: 'Progress Review 4',
		id: 'SB7-PR-P104-E13',
		exerciseKey: 'img/FriendsPlus/Page104/E13/Key/answerKey.png',
		audio: 'Audios/2-33 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 140,
		maxLength: 10,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 24,
		},

		titleQuestion: [
			{
				num: '13',
				title: `
                    <audioimage name='2.33'></audioimage>
                    Listen to an announcement at the 
                    Noi Bai International Airport and fill in the 
                    blanks with pieces of specific information.
              `,
				color: '#4c3d9b',
			},
		],

		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <p>
                            This is the pre-boarding announcement for flight <sup>1</sup># to Ho Chi Minh City. We are now 
                            inviting those passengers with <sup>2</sup># and any passengers requiring special <sup>3</sup>#, to begin boarding at this time. 
                            Please have your boarding <sup>4</sup># and identification ready. Regular boarding will begin in approximately <sup>5</sup># minutes' time.
                            <br/>Thank you.
                        </p>

                        <div style="margin-top: 30px; display: flex; justify-content: center;">
                            <img src="img/FriendsPlus/Page104/E13/1.jpg" style="max-width: 100%" />
                        </div>
                    </div>  
                `,

				answer: ['VN-221', 'children', 'assistance', 'pass', 'ten'],
			},
		],
	},
};

export default json;
