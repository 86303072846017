import T6 from '../../components/ExcerciseTypes/TypeIn/T6';


const json = {
  4: {
    unit: 'Unit 3',
    id: 'SB7-U3-P35-E4',
    component: T6,
    titleImage: 'img/FriendsPlus/Page35/E4/1.jpg',
    textAlign: 'left',
    inputSize: '150px',
    exerciseKey: 'img/FriendsPlus/Page35/E4/Key/answerKey.png',
    questions: [
      {
        title: `
        <div>
          <div><b>1.</b>#</div>
          <div><b>2.</b>#</div>
          <div><b>3.</b>#</div>
          <div><b>4.</b>#</div>
          <div><b>5.</b>#</div>
        </div>`,
        answer: ['familiar', 'enormous', 'exciting', 'terrible', 'useful']
      }
    ]
  },

  5: {
    unit: 'Unit 3',
    id: 'SB7-U3-P35-E5',
    audio: 'Audios/1-28 Friends Plus 7.mp3',
    video: "Videos/Talking Heads/U3-1.28-Trang 35.mp4",
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page35/E5/Key/answerKey.png',
    titleQuestion: [
      {
        num: "5",
        title:
          "<videoimage></videoimage><audioimage name='1.28'></audioimage> Watch or listen to the people. Which question from the interview does each person answer?",
        color: "#0067b2",
      },
    ],
    questionImage: [],
    inputSize: 500,
    questions: [
      {
        title: `
          <div><b>1. &emsp;</b>#</div>
          <div><b>2. &emsp;</b>#</div>
          <div><b>3. &emsp;</b>#</div>
          <div><b>4. &emsp;</b>#</div>
          <div><b>5. &emsp;</b>#</div>
        
        `,
        answer: [`a person who you admire`, `the place where you live`,
          `your favourite invention`,
          `a game or activity you like`, `something you really don't like`,]
      }
    ]
  },
  6: {
    unit: 'Unit 3',
    id: 'SB7-U3-P35-E6',
    component: T6,
    audio: 'Audios/1-28 Friends Plus 7.mp3',
    video: "Videos/Talking Heads/U3-1.28-Trang 35.mp4",
    inputSize: '520px',
    exerciseKey: 'img/FriendsPlus/Page35/E6/Key/answerKey.png',
    titleQuestion: [
      {
        num: "6",
        title:
          " <videoimage></videoimage><audioimage name='1.28'></audioimage> Watch or listen again and complete the Key Phrases.",
        color: "#0067b2",

      },
    ],
    questions: [
      {
        title: `
        <div>
          <div style="padding: 10px 0px;"><img src='img/FriendsPlus/Page35/E6/2.jpg' /></div>
          <div><b>1.</b> I really love #</div>
          <div><b>2.</b> I really (don't) like #</div>
          <div><b>3.</b> I quite like #</div>
          <div><b>4.</b> I realy admire #</div>
          <div><b>5.</b> I'm really into #</div>
          <div style="margin-left: 25px">It's Ok/ exciting/ terrible!</div>
        </div>`,
        answer: ['my bike', 'getting up early in the mornings', 'living here', 'Lewis Hamilton', 'tennis']
      }
    ]
  },
  7: {
    unit: 'Unit 3',
    id: 'SB7-U3-P35-E7',
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title:
          "Read the interview questions again and think of your answers.Then ask your partner.Use some of the key phrases. Remember to ask ‘Why?’",
        color: "#0067b2",
        prefix: "default",

      },
    ],
    hintBox: [
      {
        src: ["Who do you admire ? "],
        borderColor: "#0067b2",
        arrow: true,
        position: 1,
        width: 300,
      },
      {
        src: ["I really like Taylor Swift. Her songs are great, and she does good things with her money."],
        borderColor: "#0067b2",
        arrow: true,
        position: 2,
        width: 550,
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex; justify-content: space-between; width: 600px;'>
            <div><hintbox id='0'></hintbox></div>
            <div style=" margin: 30px 0 0 40px"><hintbox id='1'></hintbox></div>
          </div>
        `,
        answer: [],
      },
    ],
  },
  8: {
    unit: 'Unit 3',
    id: 'SB7-U3-P35-E8',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 1000 },
    finished: { text: 'Write about events from history in your country.' },
    hideBtnFooter: true,
    questions: [
      {
        title: `
          <finished></finished>
          <div><textarea id="0" rows="7" ></textarea></div>
        `,
        answer: []
      },
    ]
  },

}

export default json;