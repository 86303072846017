import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Unit 6',
    id: 'SB7-U6-P71-E1',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page71/E1/Key/answerKey.png',
    component: MatchDots,
    stylesTextInput: { borderBottom: '1px dashed', textAlign: 'center' },
    inputSize: 50,
    // titleQuestion: [{ num: '1', title: 'Listen and draw lines. <headphone name="04" src="img/FriendsPlus/Page14/Audio/tieude-e1-p14.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '120px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '235px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // d
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '160px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '160px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // b
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '195px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 3
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '275px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // e
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '235px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 4
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '195px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // c
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '275px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 5
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '120px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // a
          // Nooi ten120px - 1148px = 1128
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '335px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '375px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '413px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 3
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '452px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 4
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '490px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 5
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '335px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // a
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '375px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // b
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '413px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // c
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '452px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // d
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '490px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // e
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '530px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // f
        ],
        answers: ['0-7', '1-8', '2-9', '3-6', '4-5', '10-20', '11-19', '12-16', '13-17', '14-18'],
        initialValue: [],
      },
      Layout: `
        <div style="font-size: 26px;">
          <b>
            <span style="color: #1dbfd7;">1</span> Match 1–5 with a–e to make sentences. Then match the sentences with the people in the text on page 70. 
          </b>
        </div>
        <div style="display: flex; margin-top: 20px; font-size: 26px;">
          <div style='margin-left: 20px;'>
            <div><b>1</b> If she changes her eating habits, …</div>
            <div><b>2</b> He won’t go far  …</div>
            <div><b>3</b> If she doesn’t do anything stupid, …</div>
            <div><b>4</b> He will need to work hard …</div>
            <div><b>5</b> Who will need luck …</div>
          </div>
         
          <div style='margin-left: 50px; margin-top: 200px;'>
            <input id='0' type= 'boxMatch' />
            <input id='1' type= 'boxMatch' />
            <input id='2' type= 'boxMatch' />
            <input id='3' type= 'boxMatch' />
            <input id='4' type= 'boxMatch' />
          </div>
         
          <div style='margin-left: 100px;'>
            <div><b>a.</b> … if he doesn’t take any risks</div>
            <div><b>b.</b> … if he wants to survive. </div>
            <div><b>c.</b> … she’ll go a long way. </div>
            <div><b>d.</b> … if she wants to get to the end? </div>
            <div><b>e.</b> … she’ll do very well. </div>
          </div>
         
          <div style='margin-left: 50px;'>
            <input id='5' type= 'boxMatch' />
            <input id='6' type= 'boxMatch' />
            <input id='7' type= 'boxMatch' />
            <input id='8' type= 'boxMatch' />
            <input id='9' type= 'boxMatch' />
          </div>
        </div>
        
        <div style="display: flex; margin-top: 20px; font-size: 26px; margin-left:415px">
          <div style='margin-left: 20px;'>
            <div><b>1</b></div>
            <div><b>2</b></div>
            <div><b>3</b></div>
            <div><b>4</b></div>
            <div><b>5</b></div>
          </div>
         
          <div style='margin-left: 50px; margin-top: 200px;'>
            <input id='10' type= 'boxMatch' />
            <input id='11' type= 'boxMatch' />
            <input id='12' type= 'boxMatch' />
            <input id='13' type= 'boxMatch' />
            <input id='14' type= 'boxMatch' />
          </div>
         
          <div style='margin-left: 100px;'>
            <div><b>Tom Woods</b></div>
            <div><b>Jenny Frome</b></div>
            <div><b>Peter Quinn</b></div>
            <div><b>Tina Brent</b></div>
            <div><b>Ted Wilson</b></div>
            <div><b>Sophie Jenkins</b></div>
          </div>
         
          <div style='margin-left: 50px;'>
            <input id='15' type= 'boxMatch' />
            <input id='16' type= 'boxMatch' />
            <input id='17' type= 'boxMatch' />
            <input id='18' type= 'boxMatch' />
            <input id='19' type= 'boxMatch' />
            <input id='20' type= 'boxMatch' />
          </div>
        </div>
        <div style='display:flex; width: 74%'>
          <img src='img/FriendsPlus/Page71/E1/1.png' alt=''/>
          <img src='img/FriendsPlus/Page71/E1/2.png' alt=''/>
        </div>
      `,
    },
  },
  2: {
    unit: 'Unit 6',
    id: 'SB7-U6-P71-E2',
    audio: '',
    video: '',
    exerciseKey: 'img/FriendsPlus/Page71/E2/Key/answerKey.png',
    titleQuestion: [
      {
        num: '2',
        title: 'Complete the Rules with <i>action</i> or <i>result</i>.',
        color: '#1dbfd7',
      },
    ],
    component: T6,
    inputSize: 150,
    stylesTextInput: { borderBottom: 'dotted 1px' },
    maxLength: 7,
    textAlign: 'center',
    checkUppercase: true,
    questions: [
      {
        title: `
      <div style='margin:10px;border-radius:20px; border:4px solid rgb(93 200 220);padding: 5px; width: 15cm'>
        <div style='font-weight:900;color:white;background:rgb(93 200 220);padding-left:20px;border-radius:15px'>RULES</div>
        <div style='display:flex'>
          <b>1</b>
          <div style='margin-left:20px'>We use the first conditional to talk about an action and the result of this action in the future.</div>
        </div>
        <div style='display:flex'>
          <b>2</b>
          <div style='margin-left:20px'>We describe the # with <i>if</i> + the present simple.</div>
        </div>
        <div style='display:flex'>
          <b>3</b>
          <div style='margin-left:20px'>We describe the # with <i>will</i> + base form.</div>
        </div>
        <div style='display:flex'>
          <b>4</b>
          <div style='margin-left:20px'>The sentence can start with the # or the result.</div>
        </div>
      </div>
        `,
        answer: ['action', 'result', 'action'],
      },
    ],
  },
  3: {
    // Exercise num
    unit: 'Unit 6',
    id: 'SB7-U6-P71-E3',
    audio: '',
    video: '',
    component: Circle_Write,
    exerciseKey: 'img/FriendsPlus/Page71/E3/Key/answerKey.png',
    titleQuestion: [{ num: '3', title: 'Choose the correct words.', color: '#2CBAEA' }],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: 'none',
          borderRadius: '50%',
          borderColor: 'transparent',
        },
        selectWordStyle: {
          padding: 5,
          border: 'solid 2px',
          borderColor: '#000000',
        },
        limitSelect: 1,
        listWords: [
          'use / ’ll_use ', //1
          'take / ’ll_take ', //2
          "don't_pick / won't_pick ", //3
          ' find / will_find ',
          ' will / does ',
          'get / will_get ',
          'drinks / ’ll_drink ',
          'is / ’ll_be',
        ],
        answers: ['0-4', '1-0', '2-4', '3-2', '4-2', '5-0', '6-0', '7-4'],
        initialValue: [],
      },
      Layout: `
      <div  style="display: flex;flex-direction: row;line-height: 48px;justify-content: space-between;">
        <div>
          <div><b style = "margin-right:15px">1</b>  I <b><input id='0' type='Circle' /></b> my phone to get directions if I get lost. </div>
          <div><b style = "margin-right:15px">2</b>  If you <b><input id='1' type='Circle' /></b> warm clothes, you won't get cold. </div>
          <div><b style = "margin-right:15px">3</b>  If we find mushrooms on our walk, we <b><input id='2' type='Circle' /></b> them. </div>
          <div><b style = "margin-right:15px">4</b>  Will you pick apples if you <b><input id='3' type='Circle' /></b> any on your walk? </div>
          <div><b style = "margin-right:15px">5</b>  What <b><input id='4' type='Circle' /></b> the team leader say if we  <b><input id='5' type='Circle' /></b> lost?  </div>
          <div><b style = "margin-right:15px">6</b>  If he <b><input id='6' type='Circle' /></b> that dirty water, he <b><input id='7' type='Circle' /></b> ill. </b>  </div>
        </div>
      </div>
      `,
    },
  },
  4: {
    unit: 'Unit 6',
    id: 'SB7-U6-P71-E4',
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page71/E4/Key/answerKey.png',
    stylesTextInput: { borderBottom: '1px dashed' },
    inputSize: 730,
    titleQuestion: [
      {
        num: '4',
        title: 'Order the words to make questions.',
        color: '#1dbfd7',
      },
    ],
    titleImage: '',
    questionImage: [],
    questions: [
      {
        title: `
					<div style='display: flex ; flex-direction: column; gap: 50px;'>
            <div>
              <div><b style = "margin-right:15px">1</b>if / is / you / Will / on Saturday / go out / sunny / it / ? </div>
              <div> # </div>
            </div>
            <div>
              <div><b style = "margin-right:15px">2</b>you / stay / rains / at home / it / tonight / Will / if / ? </div>
              <div> # </div>
            </div>
            <div>
              <div><b style = "margin-right:15px">3</b>  will / early / if / your homework / you / What / do / finish / you / ?</div>
              <div> # </div>
            </div>
            <div>
              <div><b style = "margin-right:15px">4</b>get / your parents / a bad school report / if / be / you / Will / angry / ?</div>
              <div> # </div>
            </div>
            <div>
              <div><b style = "margin-right:15px">5</b>will / you / money / get / What / buy / for your birthday / if / you / ? </div>
              <div> # </div>
           </div>
          </div>
        `,
        answer: [
          'Will you go out on Saturday if it is sunny? ',
          'Will you stay at home tonight if it rains? ',
          'What will you do if you finish your homework early? ',
          'Will your parents be angry if you get a bad school report? ',
          'What will you buy if you get money for your birthday? ',
        ],
      },
    ],
  },

  5: {
    unit: 'Unit 6',
    id: 'SB7-U6-P71-E5',
    audio: '',
    video: '',
    component: MatchDots,
    exerciseKey: 'img/FriendsPlus/Page71/E5/Key/answerKey.png',
    // titleQuestion: [{ num: '1', title: 'Listen and draw lines. <headphone name="04" src="img/FriendsPlus/Page14/Audio/tieude-e1-p14.mp3"></headphone>', color: "#5B5A5D" }],
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '522px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 1
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '639px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // d
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '562px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 2
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '562px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // b
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '599px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 3
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '680px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // e
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '639px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 4
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '599px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // c
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '680px',
              left: '470px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // 5
          {
            boxMatchStyle: {
              position: 'absolute',
              top: '522px',
              left: '580px',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #F58024',
              background: 'white',
            },
          }, // a
        ],
        answers: ['0-7', '2-5', '4-9', '1-6', '3-8'],
        initialValue: [],
      },
      Layout: `
        <div style="font-size: 26px;">
          <b>
            <span style="color: #1dbfd7;">5</span> Match 1–5 with a–e to make sentences. Then match the sentences with the people in the text on page 70. 
          </b>
        </div>
        <img src='img/FriendsPlus/Page71/E5/1.png' style='width: 300px; margin-left: 420px;'/>
        <div style="display: flex; margin-top: 20px; font-size: 26px;">
          <div style='margin-left: 20px;'>
            <div><b>1</b> If she brings her phone, …</div>
            <div><b>2</b> If she brings water, …</div>
            <div><b>3</b> If she brings sandals, …</div>
            <div><b>4</b> If she brings warm clothes, …</div>
            <div><b>5</b> If she eats fruit she  doesn't know, …</div>
          </div>
         
          <div style='margin-left: 50px; margin-top: 200px;'>
            <input id='0' type= 'boxMatch' />
            <input id='1' type= 'boxMatch' />
            <input id='2' type= 'boxMatch' />
            <input id='3' type= 'boxMatch' />
            <input id='4' type= 'boxMatch' />
          </div>
         
          <div style='margin-left: 100px;'>
            <div><b>a.</b> … insects will bite her feet.</div>
            <div><b>b.</b> … she'll be sick. </div>
            <div><b>c.</b> … it won't work. </div>
            <div><b>d.</b> … she'll be too hot. </div>
            <div><b>e.</b> … she won't be thirsty. </div>
          </div>
         
          <div style='margin-left: 50px;'>
            <input id='5' type= 'boxMatch' />
            <input id='6' type= 'boxMatch' />
            <input id='7' type= 'boxMatch' />
            <input id='8' type= 'boxMatch' />
            <input id='9' type= 'boxMatch' />
          </div>
        </div>
        <div>
        </div>
      `,
    },
  },
  6: {
    unit: 'Unit 6',
    id: 'SB7-U6-P71-E6',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '6',
        title: 'Read the Study Strategy. Work in pairs. Ask and answer the questions in exercise 4. Give some details.',
        color: '#1dbfd7',
        prefix: 'default',
      },
    ],
    exerciseKey: '',
    textareaStyle: { width: 1140 },
    hideBtnFooter: true,
    questionImage: [],
    hintBox: [
      {
        src: ["Will you go out if it's sunny on Saturday?"],
        borderColor: '#0066bf',
        arrow: true,
        position: 1,
        width: 400,
      },
      {
        src: ["Yes, I will. I'll go to the park. What about you?"],
        borderColor: '#0066bf',
        arrow: true,
        position: 2,
        width: 460,
      },
      {
        src: ["If it's sunny, I'll go to the beach."],
        borderColor: '#0066bf',
        arrow: true,
        position: 1,
        width: 400,
      },
    ],
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page71/E6/1.png' style='width:500px; margin: 10px 200px 20px 200px;'>
            <div >
            <div style='font-weight:600;'><hintbox id= '0'></hintbox></div>
            <div style="margin: -40px 0px 40px 430px; font-weight:600;" ><hintbox id= '1'></hintbox></div>
            <div style="margin-top: -40px; font-weight:600;"><hintbox id= '2'></hintbox></div>
          </div>
          <div style="margin-top: 50px">
            <div><b>1</b> Will you go out on Saturday if it is sunny?  </div>
            <div><b>2</b> Will you stay at home tonight if it rains? </div>
            <div><b>3</b>  What will you do if you finish your homework early?  </div>
            <div><b>4</b> Will your parents be angry if you get a bad school report? </div>
            <div><b>5</b> What will you buy if you get money for your birthday? </div>
          </div>
        `,
        answer: [],
      },
    ],
  },

  7: {
    unit: 'Unit 6',
    id: 'SB7-U6-P71-E7',
    audio: '',
    video: '',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 750 },
    hideBtnFooter: true,
    inputSize: 700,
    finished: {
      text: 'Invent a new team member for the Stay Alive programme',
    },
    questions: [
      {
        title: `
          <img src='img/FriendsPlus/Page71/E7/1.png' alt=''/>
        
        <div style='margin-left: 150px;'>
            <finished></finished>
            <textarea id="0" rows="7"></textarea>
        </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
