import { useState } from 'react';

const useCaseTextArea = () => {
  const [textArea, setTextArea] = useState([]);
  const [resultTextArea, setResultTextArea] = useState([]);

  const handleTextAreaChange = (value, index) => {
    const result = textArea.findIndex((x) => x && x.index === index);
    if (result === -1) {
      setTextArea([...textArea, { index, value }]);
    } else {
      setTextArea((prev) => {
        prev.splice(result, 1);
        return [...prev, { index, value }];
      });
    }
  };

  return { handleTextAreaChange, resultTextArea, setResultTextArea, setTextArea, textArea };
};

export default useCaseTextArea;
