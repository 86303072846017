import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Writing',
		id: 'SB7-W-P99-E1',
		exerciseKey: 'img/FriendsPlus/Page99/E1/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 430,
		maxLength: 44,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '1',
				title: `
                    Read the email. What happened to Clare? 
                    Why can't she go out?
                `,
				color: '#08a64f',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 10px;">
                        <div>
							<img src="img/FriendsPlus/Page99/E1/1.jpg" style="max-width: 100%;" />
						</div>

                        <div style="margin-top: 10px;">
							Answers: <br/>
							<ol>
								<li>#</li>
								<li>#</li>
							</ol>
                        </div>
                        
                    </div>
                    
                `,
				answer: [
					'She broke her leg while she was skiing.',
					'She is lying in bed with her leg in the air.',
				],
			},
		],
	},

	2: {
		unit: 'Writing',
		id: 'SB7-W-P99-E2',
		exerciseKey: 'img/FriendsPlus/Page99/E2/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 350,
		maxLength: 30,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
					Study the Key Phrases. Which phrases go at
					the beginning and which go at the end of an email?
            `,
				color: '#08a64f',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 600px; margin-top: 20px;">
						<div style="width: 450px; padding-bottom: 20px;border: 1px solid rgb(79, 188, 121); border-radius: 20px; overflow: hidden; background-color: rgba(207,232,211,1);">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(79, 188, 121); color: white; padding: 5px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
								Key Phrases
							</div>
							
							<div style="margin-top: 10px; padding-left: 20px;">
								<div style="color: rgb(79, 188, 121); font-weight: bold; font-size: 1.6rem">
									Informal expressions
								</div>
	
								<ol>
									<li>Thanks for your email.</li>
									<li>How's it going?</li>
									<li>Guess what?</li>
									<li>Take care and write back soon.</li>
								</ol>
							</div>
						</div>

						<div style="margin-top: 20px;">
								Answers: <br/>
								<div style="margin-left: 50px;">
									<strong>Beginning:</strong> <br/> # <br/> # <br/> # <br/>

									<strong>End:</strong> <br/> # <br/>
								</div>
								
						</div>
					</div>
                    
                    
                `,
				answer: [
					'Thanks for your email.',
					"How's it going?",
					'Guess what?',
					'Take care and write back soon.',
				],
			},
		],
	},

	3: {
		unit: 'Writing',
		id: 'SB7-W-P99-E1',
		exerciseKey: 'img/FriendsPlus/Page99/E3/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 120,
		maxLength: 7,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '3',
				title: `
					Look at the words in <span style="color: blue;">blue</span> in the text. Which
					word introduces a reason? Which one
					introduces a result?
                `,
				color: '#08a64f',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 10px;">
                        <div>
							<img src="img/FriendsPlus/Page99/E1/1.jpg" style="max-width: 100%;" />
						</div>

                        <div style="margin-top: 10px;">
							Answers: <br/>
								# introduces a reason. <br/>
								# introduces a result.
                        </div>
                        
                    </div>
                `,
				answer: ['because', 'so'],
			},
		],
	},

	4: {
		unit: 'Writing',
		id: 'SB7-W-P99-E4',
		exerciseKey: 'img/FriendsPlus/Page99/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 110,
		maxLength: 7,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '4',
				title: `
					Complete the sentences with <i>because</i> or <i>so</i>.
                `,
				color: '#08a64f',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 10px;">
						<ol>
							<li>
								I can't play football today # I've sprained my ankle.
							</li>
							
							<li>
								I cut my hand badly today # my mum took me to hospital. 
							</li>
								
							<li>
								I fell off my bike # now I have a big bruise on my arm.
							</li>
							
							<li>
								I couldn't see # I wasn't wearing my glasses.
							</li>
							
							<li>
								I'm bored # my computer isn't working.
							</li>
						</ol>
                    </div>
                `,
				answer: ['because', 'so', 'so', 'because', 'because'],
			},
		],
	},

	5: {
		unit: 'Writing',
		id: 'SB7-W-P99-E5',
		exerciseKey: 'img/FriendsPlus/Page99/E5/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 600,
		maxLength: 80,
		stylesTextInput: {
			fontSize: 23,
		},
		textareaStyle: { width: 610 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
					Follow the steps in the Writing Guide.	
				`,
				color: '#08a64f',
				prefix: { icons: ['fas fa-pencil-alt'], text: 'USE IT!' },
			},
		],

		questions: [
			{
				title: `
					<div style="width: 600px; margin-top: 20px;">
						<div style="padding-bottom: 20px;border: 1px solid rgb(79, 188, 121); border-radius: 20px; overflow: hidden; background-color:white;">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(79, 188, 121); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
								WRITING GUIDE
							</div>
							
							<div style="margin-top: 10px; padding: 0 20px">
								<div style="color: rgb(79, 188, 121); font-weight: bold; font-size: 1.6rem">
								<span style="text-transform: uppercase;">A</span> <span style="color: rgba(13,104,73,1);">TASK</span>
								</div>
	
								<p>
									Imagine that you had an accident while 
									you were camping by the sea. Write an 
									email (60 -80 words) to a friend.
								</p>
							</div>

							<div style="margin-top: 10px; padding: 0 20px">
								<div style="color: rgb(79, 188, 121); font-weight: bold; font-size: 1.6rem">
									<span style="text-transform: uppercase;">B</span> <span style="color: rgba(13,104,73,1); text-transform: uppercase">THINK AND PLAN</span>
								</div>
	
								<ol>
									<li>
										What has happened and where are you now?
									</li>
									<li>
										Where were you and who were you with?
									</li>
									<li>
										What were you doing when you had the accident?
									</li>
									<li>
										What is the result of the accident?
									</li>
									<li>
										How do you feel now?
									</li>
								</ol>
							</div>

							<div style="margin-top: 10px; padding: 0 20px">
								<div style="color: rgb(79, 188, 121); font-weight: bold; font-size: 1.6rem">
								<span style="text-transform: uppercase;">C</span> <span style="color: rgba(13,104,73,1);">WRITE</span>
								</div>
	
								<p>
									<span style="font-weight: bold;">Paragraph 1: Introduction</span> <br/>
									Greetings and a polite question <br/>
									<span style="font-weight: bold;">Paragraph 2: Description of the accident</span> <br/>
									<span style="font-weight: bold;">Paragraph 3: Conclusion</span> <br/>
									Ending your email politely <br/>
								</p>
							</div>

							<div style="margin-top: 10px; padding: 0 20px">
								<div style="color: rgb(79, 188, 121); font-weight: bold; font-size: 1.6rem">
									<span style="text-transform: uppercase;">D</span> <span style="color: rgba(13,104,73,1); text-transform: uppercase">CHECK</span>
								</div>
	
								<ul>
									<li>Informal expressions</li>
									<li><i>because</i> and <i>so</i></li>
									<li>Tenses</li>
								</ul>
							</div>
						</div>

						<div style="margin-top: 20px;">
							<textarea id="8" rows="5"></textarea>
						</div>
					</div>
                `,
				answer: [
				],
			},
		],
	},
};

export default json;
