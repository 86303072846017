import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";



const json = {
  1: {
    unit: "Unit 5",
    id: "SB7-U5-P65-E1",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 1000,
    maxLength: 78,
    exerciseKey: "img/FriendsPlus/Page65/E1/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Read the biography. How old was Agatha Christie when she published her first novel? Why are her books popular?",
        color: "#07a852",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style='display: flex; width: 37cm ; margin: 30px 0px; background:rgb(255 228 165); border-radius: 40px; border:20px solid rgb(249 166 26)'>
              <div style=' padding: 10px; margin: 10px '>
                <img src='img/FriendsPlus/Page65/E1/1.jpg' style=' margin-top: -10px;'> <br>
                <sup>1</sup><span style='color: rgb(17 205 239)'>Agatha  Christie was a British writer</span>.
                She was born in England in 1890 and died there in 1976.<br>
                <sup>2</sup><span style='color:rgb(17 205 239)'>She first started writing when she was sixteen</span>.
                Later, when she was a nurse, she had the idea for a detective story and she published her
                first novel in 1920. She was a very shy person, so she was happier to stay in and write than
                to meet people.
              </div>
             
              
              <div style=' padding: 10px; margin: 90px 10px 10px 10px'>
                <sup>3</sup><span style='color:rgb(17 205 239)'>Agatha Christie eventually became one of the most popular writers in history</span>.<br>
                She wrote more than sixty novels, as well as play and poetry, and sold more than two billion books. 
                There are translations of her books in more than 100 languages. People love her work because the characters and the mysteries are so interesting.
              </div>
              <img src='img/FriendsPlus/Page65/E1/2.jpg' style='margin-left: -20px;height:7cm'>
          </div>
          <div style='margin: 20px 5px'><b>1.</b>How old was Agatha Christie whe she published her first novel?<br>#<br>
          <b>2.</b>Why are her books popular? <br># </div>
				
          `,
        answer: [
          "She was 30 when she published her first novel.",
          "Her books are popular because the characters and mysteries are so interesting.",
        ],
      },
    ],
  },

  2: {
    unit: "Unit 5",
    id: "SB7-U5-P65-E2",
    audio: "",
    video: "",
    component: MatchDots,

    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    exerciseKey: "img/FriendsPlus/Page65/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          `Match the <span style="color: #018ed3">blue</span> first line of each paragraph (1–3) with the subject of the paragraph (a–c).`,
        color: "#07a852",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "484px",
              left: "205px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #07a852",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "484px",
              left: "360px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #07a852",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "543px",
              left: "205px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #07a852",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "543px",
              left: "360px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #07a852",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "602px",
              left: "205px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #07a852",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "602px",
              left: "360px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #07a852",
              background: "white",
            },
          }, // 5
        ],
        answers: ["1-4", "0-3", "2-5"],
        initialValue: [],
      },
      Layout: `
      <div style='display: flex; width: 30cm ; margin: 30px 0px; background:rgb(255 228 165); border-radius: 40px; border:20px solid rgb(249 166 26)'>
              <div style=' padding: 10px; margin: 10px '>
                <img src='img/FriendsPlus/Page65/E1/1.jpg' style='margin-top: -10px;'> <br>
                <sup>1</sup><span style='color: rgb(17 205 239)'>Agatha  Christie was a British writer</span>.
                She was born in England in 1890 and died there in 1976.<br>
                <sup>2</sup><span style='color:rgb(17 205 239)'>She first started writing when she was sixteen</span>.
                Later, when she was a nurse, she had the idea for a detective story and she published her
                first novel in 1920. She was a very shy person, so she was happier to stay in and write than
                to meet people.
              </div>
             
              
              <div style=' padding: 10px; margin: 90px 10px 10px 10px'>
                <sup>3</sup><span style='color:rgb(17 205 239)'>Agatha Christie eventually became one of the most popular writers in history</span>.<br>
                She wrote more than sixty novels, as well as play and poetry, and sold more than two billion books. 
                There are translations of her books in more than 100 languages. People love her work because the characters and the mysteries are so interesting.
              </div>
              <img src='img/FriendsPlus/Page65/E1/2.jpg' style='margin-left: -20px;height:7cm'>
          </div>
        <div style="font-size: 26px;">
					<div style='display: flex; font-weight: bold; padding-top: 5mm'>
						<div style='flex-grow: 2; margin-left: 100px;'>A</div>
						<div style='flex-grow: 2; margin-left: 80px'>B</div>
					</div>

          <div style='display: flex;padding-top: 5mm'>
            <div ><b>a.</b>Early career</div>
						<div><input id='0' type= 'boxMatch' /></div>
            <div><input id='1' type= 'boxMatch' /></div>
						<div style='margin-left: 217px;'><b>1.</b> Agatha Christie was British writer</div>
          </div>

					<div style='display: flex;padding-top: 5mm'>
            <div><b>b.</b>Achievements</div>
						<div><input id='2' type= 'boxMatch' /></div>
            <div><input id='3' type= 'boxMatch' /></div>
						<div style='margin-left: 195px;'><b>2.</b> She first started writing when she was sixteen.</div>
          </div>
					
					<div style='display: flex;padding-top: 5mm'>
            <div ><b>c.</b>life</div>
						<div><input id='4' type= 'boxMatch' /></div>
            <div><input id='5' type= 'boxMatch' /></div>
						<div style='margin-left: 327px;'><b>3.</b> Agatha Christie eventually became one of the most popular writers in history.</div>
          </div>
        </div>
        
					

      `,
    },
  },

  3: {
    unit: "Unit 5",
    id: "SB7-U5-P65-E3",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 530,
    maxLength: 90,
    exerciseKey: "img/FriendsPlus/Page65/E3/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the Study Strategy. Then read the list of questions. Read the biography again and add more questions to the list.",
        color: "#07a852",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display: flex; width: 37cm ; margin: 30px 0px; background:rgb(255 228 165); border-radius: 40px; border:20px solid rgb(249 166 26)'>
              <div style=' padding: 10px; margin: 10px '>
                <img src='img/FriendsPlus/Page65/E1/1.jpg' style=' margin-top: -10px;'> <br>
                <sup>1</sup><span style='color: rgb(17 205 239)'>Agatha  Christie was a British writer</span>.
                She was born in England in 1890 and died there in 1976.<br>
                <sup>2</sup><span style='color:rgb(17 205 239)'>She first started writing when she was sixteen</span>.
                Later, when she was a nurse, she had the idea for a detective story and she published her
                first novel in 1920. She was a very shy person, so she was happier to stay in and write than
                to meet people.
              </div>
             
              
              <div style=' padding: 10px; margin: 90px 10px 10px 10px'>
                <sup>3</sup><span style='color:rgb(17 205 239)'>Agatha Christie eventually became one of the most popular writers in history</span>.<br>
                She wrote more than sixty novels, as well as play and poetry, and sold more than two billion books. 
                There are translations of her books in more than 100 languages. People love her work because the characters and the mysteries are so interesting.
              </div>
              <img src='img/FriendsPlus/Page65/E1/2.jpg' style='margin-left: -20px;height:7cm'>
          </div>

          <div style='padding:8mm  3mm;width:20cm; margin-top: 10px; border-radius: 30px; background:rgb(209 232 212)'>
          <div style='color: white;font-size: 28px; background:rgb(1 110 69); padding: 10px; margin: -1cm -11px 5mm -11px; border-radius: 20px'><b> STUDY STRATEGY </b> </div>
          <b style='color:rgb(7 168 82)'>Selecting information</b>
          
          <div>There’a lot of information about people on the internet. You need to decide what information to use for a biopraphy
          <br><b>Tip:</b> Make a list of questions about the person you’re writing about.</div>
          </div>
          <img src='img/FriendsPlus/Page65/E3/1.jpg' style='height:95mm;width:2cm; margin: 0px 0px -390px -20px;' />
          <div style='display:flex; background:rgb(194 220 243); margin: 10px 0px 0px 40px;padding: 13px'>
              
              <div >
                <b>Life</b><br>What did Agatha Christie do?<br>Where was she born?<br>Where did she die?
                <div ><b>Early career</b><br>When did  she first start writing?<br>#<br>#</div>
              </div>
              <div style='margin-left: 30px'>
                <b>Achievements</b><br>#<br>#<br>#<br>#<br>
              </div>
          
          </div>


          `,
        answer: [
          "When did she publish her first novel?",
          "What was she like? / Was she shy or confident?",
          "How many novels did she write?",
          "How many books did she sell? ",
          "How many books did she sell?",
          "Why were her books popular? / Why do people love her works?",
        ],
      },
    ],
  },

  4: {
    unit: "Unit 5",
    id: "SB7-U5-P65-E4",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    textareaStyle: { width: 700 },
    inputSize: 100,
    maxLength: 50,
    // exerciseKey: "img/FriendsPlus/Page46/E3/Key/answerKey.png",
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Think of a famous or successful person from the past. Make a list of questions about them. Use the questions in exercise 3 to help you.",
        color: "#07a852",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          
          <img src='img/FriendsPlus/Page65/E3/1.jpg' style='height:95mm;width:2cm; margin: 0px 0px -390px -20px;' />
          <div style='display:flex; background:rgb(194 220 243); margin: 10px 0px 0px 40px;padding: 13px'>
              
              <div >
                <b>Life</b><br>What did Agatha Christie do?<br>Where was she born?<br>Where did she die?
                <div ><b>Early career</b><br>When did  she first start writing?<br>..........<br>..........</div>
              </div>
              <div style='margin-left: 30px'>
                <b>Achievements</b><br>..........<br>..........<br>..........<br>..........<br>
              </div>
          
          </div>

        <div style='padding-top: 40px'><textarea id="0" rows="6" ></textarea></div>
          `,
        answer: [],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB7-U5-P65-E5",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 620,
    maxLength: 100,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page65/E5/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "In your notebook, complete the Key Phrases with words the text.",
        color: "#07a852",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display: flex; width: 33cm ; margin: 10px 0px; background:rgb(255 228 165); border-radius: 40px; border:20px solid rgb(249 166 26)'>
            <div style=' padding: 10px; margin: 10px '>
              <img src='img/FriendsPlus/Page65/E1/1.jpg' style='margin-top: -10px;'> <br>
              <sup>1</sup><span style='color: rgb(17 205 239)'>Agatha  Christie was a British writer</span>.
              She was born in England in 1890 and died there in 1976.<br>
              <sup>2</sup><span style='color:rgb(17 205 239)'>She first started writing when she was sixteen</span>.
              Later, when she was a nurse, she had the idea for a detective story and she published her
              first novel in 1920. She was a very shy person, so she was happier to stay in and write than
              to meet people.
            </div>


            <div style=' padding: 10px; margin: 90px 10px 10px 10px'>
              <sup>3</sup><span style='color:rgb(17 205 239)'>Agatha Christie eventually became one of the most popular writers in history</span>.<br>
              She wrote more than sixty novels, as well as play and poetry, and sold more than two billion books. 
              There are translations of her books in more than 100 languages. People love her work because the characters and the mysteries are so interesting.
            </div>
            <img src='img/FriendsPlus/Page65/E1/2.jpg' style='margin-left: -20px;height:7cm'>
         </div>
          <div style='padding:8mm  3mm; margin-top: 10px; border-radius: 30px; background:rgb(209 232 212) ;width:27cm '>
           <div style='color: white; background:rgb(1 110 69); padding: 20px 20px 20px 20px; margin: -1cm -11px 5mm -11px; border-radius: 25px'><b> KEY PHRASES </b> </div>
            <b style='color:rgb(7 168 82)'>Staging information</b>
          
            <div>
              <div style='margin-bottom: 20px'>She first started <sup>1</sup>#</div>
              <div style='margin-bottom: 20px'><sup>2</sup>#eventually <br> <sup>3</sup>#</div>
              People love her work because <sup>4</sup>#
            </div>
          </div>
          


          `,
        answer: [
          "writing when she was sixteen.",
          "Agatha Christie",
          "became one of the most popular writers in history.",
          "the characters and the mysteries are so interesting.",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 5",
    id: "SB7-U6-P65-E6",
    component: T6,
    questionImage: [],
    textareaStyle: { width: 700 },
    // hideBtnFooter: true,
    titleQuestion: [
      {
        num: "6",
        title: " Follow the steps in the Writing Guide.",
        color: "#07a852",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" }
      },
    ],

    questions: [
      {
        title: `
        <div style='display: flex;margin-top: 10px;' >
          <div style='padding:8mm  4mm;border: 4px solid rgb(7 168 82); border-radius: 30px ; width: 15cm'>
          <div style='color: white; background: rgb(7 168 82); padding: 20px; margin: -1cm -11px 5mm -11px; border-radius: 20px'><b> WRITING GUIDE </b> </div>
            <div style='margin-bottom:15px'><b style='color:rgb(1 110 69)'>A TASK</b><br><div style='margin-left:20px'>Think about your person from exercise 4. Find infomation about the person and write a short biography (60 - 80 words) for an ‘Achievements’ web page.</div></div>
            
            <div style='margin-bottom:15px'><b style='color:rgb(1 110 69)'>B THINK AND PLAN</b><br><div style='margin-left:20px'> Find answers to your list of questions from exercise 4.</div></div>
            
            <div style='margin-bottom:15px'><b style='color:rgb(1 110 69)'>C WRITE</b><br><div style='margin-left:20px'>Look at your notes and the sections in the model text and decide what infomation you want to include in each section:<br> 
              • Life<br>• Early career<br>• Achievements</div></div>
            <div><b style='color:rgb(1 110 69)'>D CHECK</b><br><div style='margin-left:20px'>• Comparative and superlative adjectives
            </div></div>
        
        
          </div>
          <div style='padding-top: 40px'><textarea id="0" rows="20" ></textarea></div>
        </div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
