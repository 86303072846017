import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 1",
    id: "SB7-U1-P16-E1",
    audio: "Audios/1-11 Friends Plus 7.mp3",
    video: "Videos/Speaking/U1- 1.11-Trang 16.mp4",
    component: T6,
    inputSize: 400,
    maxLength: 36,
    checkUppercase: true,
    stylesTextInput: {
      // border: "2px solid",
      // height: 45,
      borderBottom: "dotted 1px",
      // backgroundColor: "transparent",
    },
    // textAlign: "center",
    // maxLength: 1,
    exerciseKey: "img/FriendsPlus/Page16/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "<videoimage></videoimage><audioimage name='1-11'></audioimage> Complete the dialogue with the key phrases. Then watch or listen and check. What do Lisa and Jamie decide to do?",
        color: "#cf2370",
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex'>
							<div style='width: 18cm'>
								<div style="display: flex">
									<b>Jamie</b>
									<div style='margin-left:30px'>What are you doing today?</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px;'>Nothing much. <sup>1</sup>#</div>
								</div>
								<div style="display: flex">
									<b>Jamie</b>
									<div style='margin-left:30px'>I don’t want to stay at home all day. It’s boring.</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px'>Well, what do you want to do?</div>
								</div>
								<div style="display: flex">
									<b>Jamie</b>
									<div style='margin-left:30px'>I don’t know. I just want to go out.</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px'><sup>2</sup># We can take a sandwich.</div>
								</div>
								<div style="display: flex">
									<b>Jamie</b>
									<div style='margin-left:30px'>It’s really cold. <sup>3</sup># when it’s cold.</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px'>Mmm. Well, I don’t want to play computer games all day.</div>
								</div>
								<div style="display: flex">
									<b>Jamie</b>
									<div style='margin-left:30px'><sup>4</sup>#There’s new café on the High Street. They say it’s very good.</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px'> <sup>5</sup># We can go to the shopping centre, too.</div>
								</div>
								<div style="display: flex">
									<b>Jamie</b>
									<div style='margin-left:30px'> I don’t know about the shopping centre, but the café sounds good. <sup>6</sup># and then get the bus into town.</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px'>Ok.</div>
								</div>
							</div>
							<img style='height:6cm' src='img/FriendsPlus/Page16/E1/1.jpg'>

					</div>
        `,
        answer: [
          "Shall we do something?",
          "Why don't we go for a bike ride?",
          "I don't feel like cycling",
          "How about going into town?",
          "That sounds like a good idea.",
          "Let's finish our homework now",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB7-U1-P16-E2",
    audio: "",
    video: "",
    component: T6,
    inputSize: 100,
    maxLength: 36,
    checkUppercase: true,
    stylesTextInput: {
      border: "2px solid",
      height: 45,
      width: 45,
      borderBottom: "dash",
      // backgroundColor: "transparent",
    },
    textAlign: "center",
    // maxLength: 1,
    exerciseKey: "img/FriendsPlus/Page16/E2/Key/answerKey.png",
    titleImage: "",
    questionImage: [],
    titleQuestion: [
      {
        num: "2",
        title:
          `Which key phrases are for making suggestions and which are for responding to suggestions? Write S(suggestion) or R(response).`,
        color: "#cf2370",
      },
    ],
    questions: [
      {
        title: `
					<b style='color:white;border-radius:15px;background:rgb(229 113 149);padding:10px 340px 10px 10px; font-size: 27px'>KEY PHRASES</b>
					<div style='background: rgb(246 211 218); border-radius: 15px; padding: 10px;margin:-10px 0px'>
						<b style='color: rgb(211 34 116)'>Making and responding to suggestions (1)</b>
							<div style='line-height:65px'>
									<b>1.</b> Shall we do something?
										<span style='margin-left:98px'><input id='0' width='45px' padding='0px' /></span><br>
									<b>2.</b> That sound like a good idea.
										<span style='margin-left:52px'><input id='1' width='45px' padding='0px' /></span><br>
									<b>3.</b> Let’s finish our homework now.
										<span style='margin-left:25px'><input id='2' width='45px' padding='0px' /></span><br>
									<b>4.</b> How about going into town?
										<span style='margin-left:56px'><input id='3' width='45px' padding='0px' /></span><br>
									<b>5.</b> Why don’t we go for a bike ride?
										<span style='margin-left:10px'><input id='4' width='45px' padding='0px' /></span><br>
									<b>6.</b> I don’t feel like cycling.
										<span style='margin-left:113px'><input id='5' width='45px' padding='0px' /></span>
							</div>
					</div>
        `,
        answer: ["S", "R", "S", "S", "S", "R"],
      },
    ],
  },
  3: {
    unit: "Unit 1",
    id: "SB7-U1-P16-E3",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "3",
        title: "Work in pairs. Practise the dialogue.",
        color: "#cf2370",
        prefix: { icons: ["far fa-comment"] },
      },
    ],
    questions: [
      {
        title: `
          <div style='display: flex'>
							<div style='width: 18cm'>
								<div style="display: flex">
									<b>Jamie</b>
									<div style='margin-left:30px'>What are you doing today?</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px;'>Nothing much. <sup>1</sup><input id='0' disabled/></div>
								</div>
								<div style="display: flex">
									<b>Jamie</b>+
									<div style='margin-left:30px'>I don’t want to stay at home all day. It’s boring.</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px'>Well, what do you want to do?</div>
								</div>
								<div style="display: flex">
									<b>Jamie</b>
									<div style='margin-left:30px'>I don’t know. I just want to go out.</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px'><sup>2</sup># We can take a sandwich.</div>
								</div>
								<div style="display: flex">
									<b>Jamie</b>
									<div style='margin-left:30px'>It’s really cold. <sup>3</sup># when it’s cold.</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px'>Mmm. Well, I don’t want to play computer games all day.</div>
								</div>
								<div style="display: flex">
									<b>Jamie</b>
									<div style='margin-left:30px'><sup>4</sup>#There’s new café on the High Street. They say it’s very good.</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px'> <sup>5</sup># We can go to the shopping centre, too.</div>
								</div>
								<div style="display: flex">
									<b>Jamie</b>
									<div style='margin-left:30px'> I don’t know about the shopping centre, but the café sounds good. <sup>6</sup># and then get the bus into town.</div>
								</div>
								<div style="display: flex">
									<b>Lisa</b>
									<div style='margin-left:50px'>Ok.</div>
								</div>
							</div>
							<img style='height:6cm' src='img/FriendsPlus/Page16/E1/1.jpg'>

					</div>
        `,
        answer: [],
      },
    ],
  },
  4: {
    unit: "Unit 1",
    id: "SB7-U1-P16-E4",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page16/E4/Key/answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title:
          "<audioimage name='1-12'></audioimage> Choose the correct phrases in the mini-dialogues. Listen and check. Then practise them with your partner.",
        color: "#cf2370",
        prefix: { icons: ["far fa-comment"] },
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 1,
          fontStyle: "italic",
          fontWeight: 700,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          color: "#000000",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "Let’s / Why / Hello", //1
          "want / feel", //2
          "Why_don’t_we / We’ll", //3
          "’s_like / sounds_like", //3
          "going / go",
          "like / feel_like",
          "doing / do",
        ],
        answers: ["0-0", "1-0", "2-0", "3-4", "4-0", "5-4", "6-4"],
        initialValue: [],
      },
      Layout: `
        <div>
					<b>1</b>
					<span style='margin-left:12px'> A&emsp; <input id='0'  type='Circle' />  do something different. </span><br>
					 &emsp;	B&emsp; OK. What do you <input id='1'  type='Circle' /> to do?<br>
					<b>2</b>
						<span style='margin-left:12px'> A&emsp; <input id='2'  type='Circle' /> go to the cinema?</span><br>
						<span style='margin-left:79px'> There’s a good film on this week.</span><br>
					 	&emsp;	B&emsp; That<input id='3'  type='Circle' /> a good idea.<br>
					<b>3</b>
						<span style='margin-left:12px'> A&emsp; How about <input id='4'  type='Circle' /> for a wall?</span><br>
					 	&emsp;	B&emsp; No thanks. I don’t really <input id='5'  type='Circle' /> going for a walk. <br>
					<b>4</b>
						<span style='margin-left:12px'> A&emsp; What shall we <input id='6'  type='Circle' />? </span><br>
					 	&emsp;	B&emsp; Nothing. I’m happy here on the sofa. <br>
				</div>
      `,
    },
  },
  5: {
    unit: "Unit 1",
    id: "SB7-U1-P16-E5",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Work in pairs. Practise a new dialogue using the key phrases and at least two ideas from pictures A-D below.",
        color: "#cf2370",
        prefix: { icons: ["far fa-comment"], text: "USE IT!" },
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page16/E5/1.jpg" }]],
  },
};

export default json;
