import TB2 from '../../components/ExcerciseTypes/Table/TB2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Unit 7',
		id: 'SB7-U7-P82-E1',
		exerciseKey: 'img/FriendsPlus/Page82/E1/Key/answerKey.png',
		audio: 'Audios/2-14 Friends Plus 7.mp3',
		video: '',
		component: TB2,
		titleQuestion: [
			{
				num: '1',
				title: `
					<audioimage name='2.14'></audioimage>
					Read the music quiz. 
					Then add the <span style="color: #03b2dd">blue</span> words to the categories in the table. 
					Listen and check.
       			 `,
				color: '#006ac0',
			},
		],
		questions: [
			{
				title: `
					<div>
						<div>
							<div style="display: flex; justify-content: center; align-items: center;">
								<div><img src="img/FriendsPlus/Page82/E1/1.jpg" style="max-width: 100%;" /></div>
								<div><img src="img/FriendsPlus/Page82/E1/2.jpg" style="max-width: 100%;" /></div>
							</div>
							<div style="margin-top: 10px; display: flex; justify-content: center; align-items: center;">
								<div><img src="img/FriendsPlus/Page82/E1/3.jpg" style="max-width: 100%;" /></div>
								<div><img src="img/FriendsPlus/Page82/E1/4.jpg" style="max-width: 100%;" /></div>
							</div>
							<div style="margin-top: 10px; display: flex; justify-content: center; align-items: center;">
								<div><img src="img/FriendsPlus/Page82/E1/5.jpg" style="max-width: 100%;" /></div>
								<div><img src="img/FriendsPlus/Page82/E1/6.jpg" style="max-width: 100%;" /></div>
							</div>
						</div>
					</div>
        		`,
				answer: [],
			},
		],
		tb2Style: { width: 550 },
		type: 1,
		data: [
			{
				title: 'Types of music',
				content: ['classical', '#', '#', '#', '#', '#', '#', '#', '#', '#'],
				answers: [
					'classical',
					'traditional',
					'salsa',
					'samba',
					'pop',
					'rock',
					'hard rock',
					'heavy metal',
					'hip-hop',
					'rap',
				],
				commonStyle: { backgroundColor: 'white' },
				titleStyle: { backgroundColor: 'rgb(77,32,123)', color: 'white' },
				contentStyle: {},
			},
			{
				title: 'Musical instruments',
				content: ['piano', '#', '#', '#', '#'],
				answers: ['piano', 'violin', 'drums', 'guitar', 'bass'],
				commonStyle: { backgroundColor: 'white' },
				titleStyle: { backgroundColor: 'rgb(77,32,123)', color: 'white' },
				contentStyle: {},
			},
			{
				title: 'Other words',
				content: ['fans', '#', '#', '#', '#', '#', '#'],
				answers: ['fans', 'concert', 'hits', 'band', 'lyrics', 'vocals', 'DJ'],
				commonStyle: { backgroundColor: 'white' },
				titleStyle: { backgroundColor: 'rgb(77,32,123)', color: 'white' },
				contentStyle: {},
			},
		],
	},
	2: {
		unit: 'Unit 7',
		id: 'SB7-U7-P82-E2',
		exerciseKey: 'img/FriendsPlus/Page82/E2/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
          Do the music quiz and compare your answers with your partner's.
        `,
				color: '#006ac0',
			},
		],
		questions: [
			{
				title: `
					<div>
						<div>
							<strong>Answer:</strong>
							1 # 2 # 3 # 4 # 5 # 6 #
						</div>

						<div>
						<div>
							<div style="display: flex; justify-content: center; align-items: center;">
								<div><img src="img/FriendsPlus/Page82/E1/1.jpg" style="max-width: 100%;" /></div>
								<div><img src="img/FriendsPlus/Page82/E1/2.jpg" style="max-width: 100%;" /></div>
							</div>
							<div style="margin-top: 10px; display: flex; justify-content: center; align-items: center;">
								<div><img src="img/FriendsPlus/Page82/E1/3.jpg" style="max-width: 100%;" /></div>
								<div><img src="img/FriendsPlus/Page82/E1/4.jpg" style="max-width: 100%;" /></div>
							</div>
							<div style="margin-top: 10px; display: flex; justify-content: center; align-items: center;">
								<div><img src="img/FriendsPlus/Page82/E1/5.jpg" style="max-width: 100%;" /></div>
								<div><img src="img/FriendsPlus/Page82/E1/6.jpg" style="max-width: 100%;" /></div>
							</div>
						</div>
					</div>
					</div>
        		`,
				answer: ['b', 'a', 'c', 'a', 'a', 'c'],
			},
		],
	},
};

export default json;
