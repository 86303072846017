import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Speaking',
		id: 'SB7-S-P98-E1',
		exerciseKey: 'img/FriendsPlus/Page98/E1/Key/answerKey.png',
		audio: 'Audios/2-30 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 170,
		maxLength: 14,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '1',
				title: `
                    <audioimage name='2.30'></audioimage>
                    Complete the dialogue with the correct words. 
                    Then listen and check.
                `,
				color: '#e55991',
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <div style="display: flex;">
                            <span style="margin-right: 26px">Agent</span>
                            <span>Good afternoon, where are you flying to?</span>
                        </div>
                    
                        <div style="display: flex;">
                            <span style="margin-right: 20px">Matsui</span>
                            <span>Good afternoon. I am flying to Da Nang, VietNam.</span>
                        </div>
                        
                        <div style="display: flex;">
                            <span style="margin-right: 26px">Agent</span>
                            <span>Can I see your ticket?</span>
                        </div>
                    
                        <div style="display: flex;">
                            <span style="margin-right: 20px">Matsui</span>
                            <span>Here you are.</span>
                        </div>

                        <div style="display: flex;">
                            <span style="margin-right: 26px">Agent</span>
                            <span>How many people are travelling?</span>
                        </div>
                    
                        <div style="display: flex;">
                            <span style="margin-right: 20px">Matsui</span>
                            <span>My son and I. He is under two.</span>
                        </div>

                        <div style="display: flex;">
                            <span style="margin-right: 26px">Agent</span>
                            <span>Can I have your <sup>1</sup># and his birth <sup>2</sup># ?</span>
                        </div>
                    
                        <div style="display: flex;">
                            <span style="margin-right: 20px">Matsui</span>
                            <span>Sure. Here they are.</span>
                        </div>

                        <div style="display: flex;">
                            <span style="margin-right: 26px">Agent</span>
                            <span>Would you like an <sup>3</sup># seat?</span>
                        </div>
                    
                        <div style="display: flex;">
                            <span style="margin-right: 20px">Matsui</span>
                            <span>I would be very happy.</span>
                        </div>

                        <div style="display: flex;">
                            <span style="margin-right: 26px">Agent</span>
                            <span>OK. Your seat is <sup>4</sup>#.</span>
                        </div>
                    
                        <div style="display: flex;">
                            <span style="margin-right: 20px">Matsui</span>
                            <span>Wonderful, thanks. Is it possible to check in the <sup>5</sup># ?</span>
                        </div>

                        <div style="display: flex;">
                            <span style="margin-right: 26px">Agent</span>
                            <span>Sure, what are you checking in?</span>
                        </div>
                    
                        <div style="display: flex;">
                            <span style="margin-right: 20px">Matsui</span>
                            <span>Just this suitcase and my backpack.</span>
                        </div>

                        <div style="display: flex;">
                            <span style="margin-right: 26px">Agent</span>
                            <span>Let's put them on the scale, one at a time, please.</span>
                        </div>
                    
                        <div style="display: flex;">
                            <span style="margin-right: 20px">Matsui</span>
                            <span>
                                Sure. And by the way, I will <sup>6</sup># in Singapore. 
                                Do I have to pick up my luggage there?
                            </span>
                        </div>

                        <div style="display: flex;">
                            <span style="margin-right: 26px">Agent</span>
                            <span>
                                No, you will pick them up at Da Nang International Airport. 
                                Here is your <br/><sup>7</sup># . Please be at Gate 21 at least 
                                45 minutes before the <br/><sup>8</sup># .
                            </span>
                        </div>
                    
                        <div style="display: flex;">
                            <span style="margin-right: 20px">Matsui</span>
                            <span>Thank you for your help.</span>
                        </div>

                        <div style="display: flex;">
                            <span style="margin-right: 26px">Agent</span>
                            <span>You’re welcome. Have a nice flight!</span>
                        </div>
                    </div>
                `,
				answer: [
					'passport',
					'certificate',
					'aisle',
					'22C',
					'luggage',
					'transit',
					'boarding pass',
					'departure time',
				],
			},
		],
	},

	2: {
		unit: 'Speaking',
		id: 'SB7-S-P98-E2',
		exerciseKey: 'img/FriendsPlus/Page98/E2/Key/answerKey.png',
		audio: 'Audios/2-30 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		stylesTextInput: {
			border: '1px solid',
			height: 25,
			fontSize: 20,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
                <audioimage name='2.30'></audioimage>
                Cover the dialogue. 
				Which phrases are for checking in at the airport? 
				Write Y or N. Listen again and check.
              `,
				color: '#e55991',
			},
		],

		questions: [
			{
				// title: `
				//     <div style="margin-top: 20px; width: 480px; padding-bottom: 20px;border: 1px solid rgb(247, 126, 29); border-radius: 10px; overflow: hidden">
				//         <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(247, 126, 29); color: white; padding: 5px; border-radius: 0px 0px 10px 10px; text-transform: uppercase;">
				//             Key Phrases
				//         </div>

				//         <div style="margin-top: 10px; padding-left: 20px;">
				//             <div style="color: rgb(247, 126, 29); font-weight: bold; font-size: 1.6rem">
				//                 Finding things
				//             </div>

				//             <div style="display: flex">
				//                 <ol style="list-style-type: lower-alpha;;">
				//                     <li>That’s the wrong one.</li>
				//                     <li>Look, here’s … !</li>
				//                     <li>Here it is!</li>
				//                     <li>Is this what you’re looking for?</li>
				//                 </ol>

				//                 <div style="margin-left: 10px"># <br/> # <br/> # <br/> # <br/> </div>
				//             </div>

				//         </div>

				//     </div>
				// `,

				title: `
					<div style="width: 600px; margin-top: 20px;">
						<div style="width: 580px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgba(246,211,218,1);">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(226,115,148); color: white; padding: 5px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
								Key Phrases
							</div>

							<div style="margin-top: 10px; padding-left: 20px;">
								<div style="color: rgb(226,115,148); font-weight: bold; font-size: 1.6rem">
				                    Check-in at the airport
								</div>

								<div style="display: flex;">
									<ol>
										<li>What's wrong with you?</li>
										<li>Where are you flying to?</li>
										<li>Can I have your passport(s)?</li>
										<li>What would you like?</li>
										<li>Would you like a window/ an aisle seat?</li>
										<li>What are you checking in?</li>
										<li>Have you got any luggage?</li>
										<li>Have a nice flight!</li>
										<li>Have a good plane!</li>
									</ol>

									<div style="margin-left: 10px"># <br/> # <br/> # <br/> # <br/> # <br/> # <br/> # <br/># <br/> # <br/></div>
								</div>
							</div>
						</div>

					</div>
				`,

				answer: ['N', 'Y', 'Y', 'N', 'Y', 'Y', 'Y', 'Y', 'N'],
			},
		],
	},

	3: {
		unit: 'Speaking',
		id: 'SB7-S-P98-E3',
		exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 120,
		maxLength: 7,
		stylesTextInput: {
			fontSize: 23,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '3',
				title: `
                    Work in pairs. Practise the dialogue.
                `,
				color: '#e55991',
				prefix: { icons: ['far fa-comment'], text: '' },
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 10px;">
                        <div>
							<img src="img/FriendsPlus/Page98/E3/1.jpg" style="max-width: 100%;" />
						</div>
                    </div>
                `,
				answer: ['because', 'so'],
			},
		],
	},

	4: {
		unit: 'Speaking',
		id: 'SB7-S-P98-E4',
		exerciseKey: 'img/FriendsPlus/Page98/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		stylesTextInput: {
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '4',
				title: `
                    Work in pairs. Rearrange the dialogue, then practise it.
                `,
				color: '#e55991',
				prefix: { icons: ['far fa-comment'], text: '' },
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 650px; margin-top: 10px;">
                        <ol>
                            <li>
                                Here is your boarding pass. 
                                Be at Gate 12 at least 30 minutes before the departure time.
                            </li>
                            <li>We're flying to Phu Quoc</li>
                            <li>Sure. Here it is.</li>
                            <li>Thanks for your help.</li>
                            <li>No. I've got only a small handbag.</li>
                            <li>Where are you flying to?</li>
                            <li>Are you checking in any bags?</li>
                            <li>It's my pleasure. Have a nice flight!</li>
                            <li>Can I have your passport, please?</li>
                        </ol>

                        <div>
                            Rerange the dialogue: <br/>
                            # - # - # - # - # - # - # - # - #
                        </div>
                    </div>
                `,
				answer: ['6', '2', '9', '3', '7', '5', '1', '4', '8'],
			},
		],
	},

	5: {
		unit: 'Speaking',
		id: 'SB7-S-P98-E5',
		exerciseKey: 'img/FriendsPlus/Page98/E5/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 590,
		maxLength: 100,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		textareaStyle: { width: 1000 },
		titleQuestion: [
			{
				num: '5',
				title: `
                    Work in pairs. Prepare a dialogue for the following situation. 
                    Use the key phrases. Then change roles.
				`,
				color: '#e55991',
				prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
			},
		],

		questions: [
			{
				title: `
                    <div style="width: 600px; margin-top: 20px;">
                        <div style="background-color: rgba(243,212,218,1); padding: 15px; border-radius: 20px;">
                            <div style="border: 2px solid rgba(199,56,118,1); padding: 10px;">
                                <p>
                                    <span style="font-weight: bold;">Student A</span> (a passenger): 
                                    You are going to fly, but you forget your passport.
                                </p>

                                <p>
                                    <span style="font-weight: bold;">Student B</span> (a check-in agent):  
                                    You will change another flight for him / her.
                                </p>
                            </div>
                        </div>
                        <div style="margin-top: 20px;"><textarea id="0" rows="7" ></textarea></div>
                    </div>
					
                `,
				answer: [],
			},
		],
	},
};

export default json;
