import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import TB2 from '../../components/ExcerciseTypes/Table/TB2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Vocabulary And Listening',
		id: 'SB7-V-P86-E1',
		exerciseKey: 'img/FriendsPlus/Page86/E1/Key/answerKey.png',
		audio: 'Audios/2-18 Friends Plus 7.mp3',
		video: '',
		component: Circle_Write,
		titleQuestion: [
			{
				num: '1',
				title: `
          <audioimage name='2.18'></audioimage>
          Check the meaning of the adjectives in <span style="color: #03b2dd">blue</span>. Then choose the correct word in each sentence. Listen and check.
        `,
				color: '#f57f22',
			},
		],
		question: {
			Write: {
				inputStyle: { width: 70 },
				answers: [],
				initialValue: [],
			},
			Circle: {
				initialWordStyle: {
					padding: 0,
					border: 'none',
					borderRadius: '50%',
					borderColor: 'transparent',
					color: '#00aeef',
				},
				selectWordStyle: {
					padding: 5,
					border: 'solid 2px',
					borderColor: '#00aeef',
				},
				limitSelect: 1,
				listWords: [
					'Ambitious / Strong',
					'talented / famous',
					'kind / successful',
					'Energetic / Charming',
					'Confident / Weak',
				],
				answers: ['0-0', '1-0', '2-0', '3-0', '4-0'],
				initialValue: [],
			},
			Layout: `
        		<div style="width: 700px">
					<div style="padding-left: 10px; margin-bottom: 16px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>
								<input id='0' type='Circle' /> people want to do well.
							</span>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>
								If you're good at something, you're <input id='1' type='Circle' />
							</span>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>
								When you're nice to people, you're <input id='2' type='Circle' />
							</span>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>
								<input id='3' type='Circle' /> people aren't usually tired.
							</span>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">5</strong>
							<span>
								<input id='4' type='Circle' /> people feel positive about their skills.
							</span>
						</div>
					</div>
				</div>
		      `,
		},
	},
	2: {
		unit: 'Vocabulary And Listening',
		id: 'SB7-V-P86-E2',
		exerciseKey: 'img/FriendsPlus/Page86/E2/Key/answerKey.png',
		audio: 'Audios/2-19 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 140,
		maxLength: 12,
		checkDuplicated: true,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 22,
		},
		hintBox: [
			{
				src: [
					'a|maz|ing',
					'ambitious',
					'charming',
					'confident',
					'energetic',
					'famous',
					'kind',
					'strong',
					'successful',
					'talented',
					'weak',
				],
				borderColor: '#f57f22',
				width: 525,
			},
		],
		titleQuestion: [
			{
				num: '2',
				title: `
          <audioimage name='2.19'></audioimage>
          <span style="color: #f57f22"><strong>PRONUNCIATION</strong> Syllables</span> 
          Listen to the adjectives and repeat. 
          How many syllables are there in each word? Mark the syllables.
        `,
				color: '#f57f22',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 20px;">
						<div style="display: flex; justify-content: center;">
							<hintbox id='0'></hintbox>
						</div>

						<div style="margin-top: 50px; padding-left: 10px; margin-bottom: 16px;">
							<div style="display: flex;">
								<strong style="padding-right: 23px;">1 syllable:</strong>
								<span># # #</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2 syllables:</strong>
								<span># #</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3 syllables:</strong>
								<span># # # # #</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4 syllables:</strong>
								<span>#</span>
							</div>
						</div>
					</div>
                `,
				answer: [
					'kind / strong / weak',
					'kind / strong / weak',
					'kind / strong / weak',
					'charm-ing / fa-mous',
					'charm-ing / fa-mous',
					'a-maz-ing / am-bi-tious / con-fi-dent / suc-cess-ful / tal-ent-ed',
					'a-maz-ing / am-bi-tious / con-fi-dent / suc-cess-ful / tal-ent-ed',
					'a-maz-ing / am-bi-tious / con-fi-dent / suc-cess-ful / tal-ent-ed',
					'a-maz-ing / am-bi-tious / con-fi-dent / suc-cess-ful / tal-ent-ed',
					'a-maz-ing / am-bi-tious / con-fi-dent / suc-cess-ful / tal-ent-ed',
					'en-er-get-ic',
				],
			},
		],
	},
	3: {
		unit: 'Vocabulary And Listening',
		id: 'SB7-V-P86-E3',
		exerciseKey: 'img/FriendsPlus/Page86/E3/Key/answerKey.png',
		audio: 'Audios/2-20 Friends Plus 7.mp3',
		video: '',
		component: TB2,
		titleQuestion: [
			{
				num: '3',
				title: `
          <audioimage name='2.20'></audioimage>
          Match the adjectives in <span style="color: #03b2dd">blue</span> from exercise 1 with 
          the nouns in the table. Listen and check.
        `,
				color: '#f57f22',
			},
		],
		questions: [
			{
				title: `
				<div style="width: 700px">
					<div style="padding-left: 10px; margin-bottom: 16px;">
						<div style="display: flex;">
							<strong style="padding-right: 10px;">1</strong>
							<span>
								<span style="color: #03b2dd">Ambitious</span> / <span style="color: #03b2dd">Strong</span> people want to do well.
							</span>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">2</strong>
							<span>
								If you're good at something, you're <span style="color: #03b2dd">talented</span> / <span style="color: #03b2dd">famous</span> 
							</span>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">3</strong>
							<span>
								When you're nice to people, you're <span style="color: #03b2dd">kind</span> / <span style="color: #03b2dd">succesful</span>
							</span>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">4</strong>
							<span>
							<span style="color: #03b2dd">Energetic</span> / <span style="color: #03b2dd">Charming</span> people aren't usually tired.
							</span>
						</div>
						<div style="display: flex;">
							<strong style="padding-right: 10px;">5</strong>
							<span>
							<span style="color: #03b2dd">Confident</span> / <span style="color: #03b2dd">Weak</span> people feel positive about their skills.
							</span>
						</div>
					</div>
				</div>`,
				answer: [],
			},
		],
		tb2Style: { width: 400 },
		type: 1,
		data: [
			{
				title: 'Noun',
				content: [
					'ambition',
					'charm',
					'confidence',
					'energy',
					'fame',
					'kindness',
					'strength',
					'success',
					'talent',
					'weakness',
				],
				answers: [],
				commonStyle: { backgroundColor: 'rgb(253,220,189)' },
				titleStyle: { backgroundColor: 'rgb(251,164,95)' },
				contentStyle: {},
			},
			{
				title: 'Adjective',
				content: ['ambitious', '#', '#', '#', '#', '#', '#', '#', '#', '#'],
				answers: [
					'ambitious',
					'charming',
					'confident',
					'energetic',
					'famous',
					'kind',
					'strong',
					'successful',
					'talented',
					'weak',
				],
				commonStyle: { backgroundColor: 'rgb(253,220,189)' },
				titleStyle: { backgroundColor: 'rgb(251,164,95)' },
				contentStyle: {},
			},
		],
	},
	4: {
		unit: 'Vocabulary And Listening',
		id: 'SB7-V-P86-E4',
		exerciseKey: 'img/FriendsPlus/Page86/E4/Key/answerKey.png',
		audio: 'Audios/2-21 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 5,
		stylesTextInput: {
			textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '4',
				title: `
          <audioimage name='2.21'></audioimage>
          Listen to an extract from <i>The You Factor.</i>  
          Which contestant gets the highest score, Jason or Kiera?
        `,
				color: '#f57f22',
			},
		],
		questions: [
			{
				title: `
          <div style="width: 650px; margin-top: 10px;">
              Answer: #
          </div>
        `,
				answer: ['Keira'],
			},
		],
	},
	5: {
		unit: 'Vocabulary And Listening',
		id: 'SB7-V-P86-E5',
		exerciseKey: 'img/FriendsPlus/Page86/E5/Key/answerKey.png',
		audio: 'Audios/2-21 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 170,
		maxLength: 13,
		stylesTextInput: {
			fontSize: 20,
			borderBottom: 'none',
		},
		isHiddenCheck: true, // Hide the tick sign after pressing the "Submit" button
		textareaStyle: { width: 620 },
		titleQuestion: [
			{
				num: '5',
				title: `
          <audioimage name='2.21'></audioimage>
          Listen again. Complete the judges' notes.
        `,
				color: '#f57f22',
			},
		],
		questions: [
			{
				title: `
					<div style="margin-top: 20px; width: 600px; position: relative;">
						<div style="display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page86/E5/1.jpg" style="width: 92%;" />
						</div>

						<div style="position: absolute; top: 87px; left: 188px; transform: rotate(-5deg)">#</div>
						<div style="position: absolute; top: 44px; left: 408px; transform: rotate(-5deg);">#</div>
						<div style="position: absolute; top: 73px; left: 385px; transform: rotate(-5deg)">#</div>
						<div style="position: absolute; top: 98px; left: 399px; transform: rotate(-5deg)">#</div>
						<div style="position: absolute; top: 159px; left: 120px; transform: rotate(-5deg)">#</div>
						<div style="position: absolute; top: 179px; left: 186px; transform: rotate(-5deg)">#</div>
						<div style="position: absolute; top: 236px; left: 124px; transform: rotate(-5deg)">#</div>
						<div style="position: absolute; top: 163px; left: 334px; transform: rotate(-5deg)">#</div>
					</div>
				`,
				answer: [
					'charming',
					'confidence',
					'ambitious',
					'successful',
					'Energy',
					'strength',
					'confidence',
					'a little weak',
				],
			},
		],
	},
	6: {
		unit: 'Vocabulary And Listening',
		id: 'SB7-V-P86-E6',
		exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		hintBox: [
			{
				src: [
					'<span style="font-size: 20px;">doctor</span>',
					'<span style="font-size: 20px;">drummer</span>',
					'<span style="font-size: 20px;">farmer</span>',
					'<span style="font-size: 20px;">judge</span>',
					'<span style="font-size: 20px;">teacher</span>',
					'<span style="font-size: 20px;">TV presenter</span>',
				],
				borderColor: '#f77e1d',
				width: 400,
			},
			{
				src: [
					'<span style="font-size: 20px; font-style: italic; color: #58585A; font-weight: 500;">I think you need to be kind to be a doctor.</span>',
				],
				borderColor: '#f77e1d',
				arrow: true,
				position: 1,
				width: 300,
			},
			{
				src: [
					'<span style="font-size: 20px; font-style: italic; color: #58585A"; font-weight: 500;>I don’t think that’s important. I think a doctor needs confidence.</span>',
				],

				borderColor: '#f77e1d',
				arrow: true,
				position: 2,
				width: 360,
			},
		],
		textareaStyle: { width: 700 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '6',
				title: `
				  Work in pairs. What qualities do you need to do different jobs?
        `,
				color: '#f57f22',
				prefix: 'default',
			},
		],
		questions: [
			{
				title: `
					<div style="position: relative;">
						<div style="display: flex; justify-content: center;">
							<hintbox id='0'></hintbox>
						</div>

						<div style="margin-top: 15px;">
							<hintbox id='1'></hintbox>
						</div>

						<div style="position: absolute; top: 197px; left: 350px;">
							<hintbox id='2'></hintbox>
						</div>

						<div style="margin-top: 30px; display: flex; justify-content: center; align-items: center;">
							<img src="img/FriendsPlus/Page86/E6/1.jpg" style="max-width: 100%;" />
						</div>

						<div style="margin-top: 30px;">
							<textarea id="0" rows="4"></textarea>
						</div>
					</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
