import Circle_Write from '../../components/ExcerciseTypes/Circle_Write';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB7-U3-P38-E1',
    component: Circle_Write,
    audio: 'Audios/1-30 Friends Plus 7.mp3',
    exerciseKey: 'img/FriendsPlus/Page38/E1/Key/answerKey.png',
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: { padding: '0 5px', fontWeight: 600, color: '#f87a16', fontSize: 24 },
        selectWordStyle: { border: '2px solid #f87a16' },
        limitSelect: 1,
        listWords: [
          'stayed / met',
          'helped / visited',
          'explored / came',
          'gave / saw',
          'travelled / saw',
          'visited / met',
          'met / stayed ',
          'gave / watched',
          'went / saw',
          'found / ate',
          'met / looked',
          'saw / took ',
          'came / felt',
          'had / got',
          'met / gave',
          'felt / had',
          'visited / left',
        ],
        answers: [
          '0-4',
          '1-0',
          '2-0',
          '3-4',
          '4-0',
          '5-0',
          '6-4',
          '7-4',
          '8-0',
          '9-0',
          '10-4',
          '11-4',
          '12-0',
          '13-0',
          '14-4',
          '15-0',
          '16-4',
        ],
        initialValue: [],
      },
      Layout: `
			<div style="position: relative; ">
				<img src="img/FriendsPlus/Page38/E1/1.jpg">
				<div style="position: absolute; top: 150px; left: 30px; width: 320px">I <input id='0' type='Circle' />met a boy and <input id='1' type='Circle' /> him with his homework.</div> 
				<div style="position: absolute; top: 300px; left: 30px; width: 320px">I  <input id='2' type='Circle' /> my town and <input id='3' type='Circle' /> my school.</div>
				<div style="position: absolute; top: 450px; left: 30px; width: 320px">I  <input id='4' type='Circle' /> to Egypt and <input id='5' type='Circle' /> the Pyramids.</div>
				<div style="position: absolute; top: 620px; left: 30px; width: 320px">I <input id='6' type='Circle' /> for three hours in Brazil and <input id='7' type='Circle' /> a football match.</div> 
				<div style="position: absolute; top: 100px; left: 610px; width: 270px"> I<input id='8' type='Circle' /> to the year 1990 and I  <input id='9' type='Circle' /> my grandparents</div> 
				<div style="position: absolute; top: 250px; left: 610px; width: 270px">The dinosaurs  <input id='10' type='Circle' /> unfriendly, so I  <input id='11' type='Circle' /> a photo and <input id='12' type='Circle' /> home quickly.</div> 
				<div style="position: absolute; top: 450px; left: 610px; width: 280px">I  <input id='13' type='Circle' /> lunch with Queen Victoria in London and I  <input id='14' type='Circle' /> her a photo.</div> 
				<div style="position: absolute; top: 620px; left: 610px; width: 270px">I  <input id='15' type='Circle' /> sick on the journey to the moon, so I  <input id='16' type='Circle' /> after an hour.</div> 
			</div>
		  `,
    },
  },
  2: {
    unit: 'Unit 3',
    id: 'SB7-U3-P38-E2',
    component: T6,
    audio: 'Audios/1-31 Friends Plus 7.mp3',
    inputSize: 120,
    exerciseKey: 'img/FriendsPlus/Page38/E2/Key/answerKey.png',
    checkUppercase: true,
    checkDuplicated: true,
    titleQuestion: [
      {
        num: '2',
        title:
          " <audioimage name='1.31'></audioimage> Find the past forms of these verbs in the diary. Which verbs are irregular? Listen and check.",
        color: 'rgb(247 129 36)',
      },
    ],
    questions: [
      {
        title: `
					<div style='border-width: 2px; border-style: solid; border-radius: 8px; border-color:rgb(247 129 36); text-align: center; padding: 0 20px; margin-top: 20px;'>come&emsp;&emsp;eat&emsp;&emsp;explore&emsp;&emsp;feel&emsp;&emsp;find&emsp;&emsp;get&emsp;&emsp;give&emsp;&emsp;go&emsp;&emsp;have&emsp;&emsp;help&emsp;&emsp;leave&emsp;&emsp;look&emsp;&emsp;meet&emsp;&emsp;see&emsp;&emsp;stay&emsp;&emsp;take&emsp;&emsp;travel&emsp;&emsp;visit&emsp;&emsp;watch&emsp;&emsp;</div>
					<div><b style='color: gray'><i>come</i></b> - came</div>
					<div style="display: flex; justify-content: space-between;">
					<div>
						<ul>
							<li>eat - #</li>
							<li>explore - #</li>
							<li>feel - #</li>
							<li>find - #</li>
							<li>get - #</li>
							<li>give - #</li>
						</ul>
					</div>
					<div>
						<ul>
							<li>go - #</li>
							<li>have - #</li>
							<li>help - #</li>
							<li>leave - #</li>
							<li>look - #</li>
							<li>meet - #</li>
						</ul>
					</div>
					<div>
						<ul>
							<li>see - #</li>
							<li>stay - #</li>
							<li>take - #</li>
							<li>travel - #</li>
							<li>visit- #</li>
							<li>watch - #</li>
						</ul>
					</div>
					</div>
					<div>
						<span><b>The irregular verbs are: </b></span>
						<span> # # # # # # # # # # # # </span>
					</div>
			`,
        answer: [
          ' ate ',
          'explored',
          'felt',
          'found',
          'got',
          'gave',
          'went',
          'had',
          'helped',
          'left',
          'looked',
          'met',
          'saw',
          'stayed',
          'took',
          'travelled',
          'visited',
          'watched',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
          'see/ go/ leave/ meet/ feel/ have/ find/ come/ give/ take/ get/ eat',
        ],
      },
    ],
  },
  3: {
    unit: 'Unit 3',
    id: 'SB7-U3-P38-E3',
    audio: 'Audios/1-32 Friends Plus 7.mp3',
    component: T6,
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: '3',
        title:
          "<audioimage name='1.32'></audioimage> <span style='color: rgb(247 129 36)'><b>PRONUNCIATION</b> Regular past simple verbs</span> Listen and repeat the verbs.",
        color: 'rgb(247 129 36)',
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
					<div><img src='img/FriendsPlus/Page38/E3/2.jpg'/></div>
				`,
        answer: [],
      },
    ],
  },

  4: {
    // Exercise num
    unit: 'Unit 3',
    id: 'SB7-U3-P38-E4',
    component: T6,
    audio: 'Audios/1-33 Friends Plus 7.mp3',
    inputSize: '120px',
    exerciseKey: 'img/FriendsPlus/Page38/E4/Key/answerKey.png',
    checkDuplicated: true,
    titleQuestion: [
      {
        num: '4',
        title:
          " <audioimage name='1.33'></audioimage> Listen to eight more verbs and add them to the table in exercise 3. Practise saying them.",
        color: 'rgb(247 129 36)',
      },
    ],
    questions: [
      {
        title: `
				<table style='margin-top: 20px;'>
					<tr style='border: 1px solid rgb(241 126 35); background-color: rgb(248 163 96); color: rgb(255 254 254);'>
					<th style='border: 1px solid rgb(241 126 35);'>&emsp;/t/</th>
					<th style='border: 1px solid rgb(241 126 35);'>&emsp;/d/</th>
					<th style='border: 1px solid rgb(241 126 35);'>&emsp;/id</th>
					</tr>
					<tr style='border: 1px solid rgb(241 126 35); background-color: rgb(254 219 191);'>
					<td style='border: 1px solid rgb(241 126 35); text-align: center; border-top-color: transparent; border-bottom-color: transparent;'>helped</td>
					<td style='border: 1px solid rgb(241 126 35); text-align: center;border-top-color: transparent; border-bottom-color: transparent;'>stayed</td>
					<td style='border: 1px solid rgb(241 126 35); text-align: center;border-top-color: transparent; border-bottom-color: transparent;'>visited</td>
					</tr>
					<tr style='border: 1px solid rgb(241 126 35); background-color: rgb(254 219 191);'>
					<td style='border: 1px solid rgb(241 126 35);border-top-color: transparent; border-bottom-color: transparent;''>#</td>
					<td style='border: 1px solid rgb(241 126 35);border-top-color: transparent; border-bottom-color: transparent;''>#</td>
					<td style='border: 1px solid rgb(241 126 35);border-top-color: transparent; border-bottom-color: transparent;''>#</td>
					</tr>
					<tr style='border: 1px solid rgb(241 126 35); background-color: rgb(254 219 191);'>
					<td style='border: 1px solid rgb(241 126 35);border-top-color: transparent; border-bottom-color: transparent;''>#</td>
					<td style='border: 1px solid rgb(241 126 35);border-top-color: transparent; border-bottom-color: transparent;''>#</td>
					<td style='border: 1px solid rgb(241 126 35);border-top-color: transparent; border-bottom-color: transparent;''>#</td>
					</tr>
					<tr style='border: 1px solid rgb(241 126 35); background-color: rgb(254 219 191);'>
					<td style='border: 1px solid rgb(241 126 35);'>#</td>
					<td style='border: 1px solid rgb(241 126 35);'>#</td>
					<td style='border: 1px solid rgb(241 126 35);'></td>
					</tr>
			  </table>`,
        answer: [
          'talked/ watched/ looked ',
          'explored/ travelled/ listened',
          'started/ wanted',
          'talked/ watched/ looked',
          'explored/ travelled/ listened',
          'started/ wanted',
          'talked/ watched/ looked',
          'explored/ travelled/ listened',
        ],
      },
    ],
  },
  5: {
    unit: 'Unit 3',
    id: 'SB7-U3-P38-E5',
    component: T6,
    titleQuestion: [
      {
        num: '5',
        title:
          " <audioimage name='1.34'></audioimage> Listen to Jade’s time travel diary. Put pictures A–H in the correct order.",
        color: 'rgb(247 129 36)',
      },
    ],
    audio: 'Audios/1-34 Friends Plus 7.mp3',
    inputSize: '50px',
    textTransform: 'uppercase',
    maxLength: 1,
    exerciseKey: 'img/FriendsPlus/Page38/E5/Key/answerKey.png',
    checkUppercase: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div>
						<img style="width: 74%" src="img/FriendsPlus/Page38/E5/1.jpg">
						<div><b>1.</b># <b>2.</b># <b>3.</b># <b>4.</b>#<b>5.</b>#<b>6.</b>#<b>7.</b>#<b>8.</b>#</div>
					</div>`,
        answer: ['C', 'F', 'E', 'B', 'A', 'D', 'G', 'H'],
      },
    ],
  },
  6: {
    unit: 'Unit 3',
    id: 'SB7-U3-P38-E6',
    component: T6,
    questionImage: [],
    textareaStyle: { width: 800 },
    hideBtnFooter: true,
    hintBox: [
      {
        src: ['I travelled to Phú Quôc with my parents last summer.We stayed …'],
        borderColor: 'rgb(247 129 36)	',
        width: 500,
        arrow: true,
        position: 1,
      },
    ],
    titleQuestion: [
      {
        num: '6',
        title: ' Work in pairs. Describe a journey using five of the verbs in exercise 2.',
        color: 'rgb(247 129 36)',
      },
    ],
    questions: [
      {
        title: `
				<div style="text-align: center; display: flex; justify-content: center"><hintBox id='0'></hintBox></div>
			  <div style="text-align:center; margin-top: 40px"><img style="width: 74%" src='img/FriendsPlus/Page38/E6/1.jpg' /></div>
			  <div><textarea id="0" rows="5" ></textarea></div>
			`,
        answer: [],
      },
    ],
  },
};

export default json;
