import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
	1: {
		unit: 'Language Focus',
		id: 'SB7-L-P87-E1',
		exerciseKey: 'img/FriendsPlus/Page87/E1/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 485,
		maxLength: 38,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '1',
				title: `
          Order the words to make questions. 
          Then match questions 1-4 with four answers from a-f. 
        `,
				color: '#1ebfd7',
			},
		],
		questions: [
			{
				title: `
					<div>
						<div style="padding-left: 10px; margin-bottom: 16px;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>
									are / what / we / do / going / to / ? <br/ > # <span style="font-style: italic; font-weight: 400; color: rgba(0,0,0,0.5)">Answer:</span> <input width='35px'>
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>
									you / are / work / with me / to / going / ? <br/ > # <span style="font-style: italic; font-weight: 400; color: rgba(0,0,0,0.5)">Answer:</span> <input width='35px'>
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>
									to / give her / we / are / what score / going / ? <br/ > # <span style="font-style: italic; font-weight: 400; color: rgba(0,0,0,0.5)">Answer:</span> <input width='35px'>
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>
									to the final / go / she / is / to / going / ? <br/ > # <span style="font-style: italic; font-weight: 400; color: rgba(0,0,0,0.5)">Answer:</span> <input width='35px'>
								</span>
							</div>
						</div>

						<div style="margin-top: 30px; width: 500px; display: flex; justify-content: space-between;">
							<div>
								<div style="padding-left: 10px;">
									<div style="display: flex;">
										<strong style="padding-right: 10px;">a.</strong>
										<span>
											Nine.
										</span>
									</div>
								</div>
								<div style="padding-left: 10px;">
									<div style="display: flex;">
										<strong style="padding-right: 10px;">b.</strong>
										<span>
											We're going to work.
										</span>
									</div>
								</div>
								<div style="padding-left: 10px;">
									<div style="display: flex;">
										<strong style="padding-right: 10px;">c.</strong>
										<span>
											Yes, she is.
										</span>
									</div>
								</div>
							</div>

							<div>
								<div style="padding-left: 10px;">
									<div style="display: flex;">
										<strong style="padding-right: 10px;">d.</strong>
										<span>
											Yes, I'm going.
										</span>
									</div>
								</div>
								<div style="padding-left: 10px;">
									<div style="display: flex;">
										<strong style="padding-right: 10px;">e.</strong>
										<span>
											No, we aren't.
										</span>
									</div>
								</div>
								<div style="padding-left: 10px;">
									<div style="display: flex;">
										<strong style="padding-right: 10px;">f.</strong>
										<span>
											Yes, I am.
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				`,
				answer: [
					'What are we going to do? ',
					'b',
					'Are you going to work with me? ',
					'f',
					'What score are we going to give her? ',
					'a',
					'Is she going to go to the final? ',
					'c',
				],
			},
		],
	},
	2: {
		unit: 'Language Focus',
		id: 'SB7-L-P87-E2',
		exerciseKey: 'img/FriendsPlus/Page87/E2/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 450,
		maxLength: 37,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '2',
				title: `
          Imagine you and a friend are organising a trip 
          to the final of <i>The You Factor</i>. 
          Write questions for 1-6. Then ask your partner.
        `,
				color: '#1ebfd7',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 600px; margin-top: 20px;">
						<div style="padding-left: 10px; margin-bottom: 16px;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>
									How / we / travel ? <br/> #		
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>
									Where / we / stay ? <br/> # 		
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>
									we/ be / on TV ? <br/> # 		
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>
									How much money / you / take ? <br/> # 		
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">5</strong>
								<span>
									your parents / go ? <br/> # 		
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">6</strong>
								<span>
									the final / be / on TV ? <br/> # 		
								</span>
							</div>
						</div>
					</div>
                `,
				answer: [
					'How are we going to travel?',
					'Where are we going to stay?',
					'Are we going to be on TV?',
					'How much money are you going to take?',
					'Are your parents going to go?',
					'Is the final going to be on TV?',
				],
			},
		],
	},
	3: {
		unit: 'Language Focus',
		id: 'SB7-L-P87-E3',
		exerciseKey: 'img/FriendsPlus/Page87/E3/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		titleQuestion: [
			{
				num: '3',
				title: `
          Match sentences 1-5 with rules a-c.
        `,
				color: '#1ebfd7',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 600px;">
						<div>
							<strong>Answers:</strong> <br/><span style="margin-left: 20px;"></span> 1 # 2 # 3 # 4 # 5 #
						</div>

						<div style="margin-top: 10px; padding-left: 10px; margin-bottom: 16px;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>
									We<strong>'re</strong> on the train and were travelling to Dublin.									
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>
									You<strong>'re coming</strong> to the final next week.
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>
									What <strong>are</strong> you <strong>doing?</strong>
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>
									What <strong>are</strong> you <strong>going</strong> to do when you leave school?
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">5</strong>
								<span>
									What <strong>are</strong> you <strong>doing</strong> after school?
								</span>
							</div>
						</div>

						<div style="margin-top: 10px; width: 500px; padding-bottom: 10px; border-radius: 20px; overflow: hidden; border: 1px solid rgb(93,200,220);">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(93,200,220); color: white; padding: 5px 10px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
								RULES
							</div>

							<div style="padding-left: 10px; margin-bottom: 16px;">
								<div style="display: flex;">
									<strong style="padding-right: 10px;">a.</strong>
									<span>
										We use the present continuous when we talk about actions in progress. (See page 23.)
									</span>
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">b.</strong>
									<span>
										We also use the present continuous when we 
										talk about arrangements in the future. We 
										often use a time expression, e.g. tomorrow, at 
										the weekend, etc.
									</span>
								</div>
								<div style="display: flex;">
									<strong style="padding-right: 10px;">c.</strong>
									<span>
										We use be going to when we talk about 
										future plans and intentions.
									</span>
								</div>
							</div>
						</div>
					</div>
        `,
				answer: ['a', 'b', 'a', 'c', 'b'],
			},
		],
	},
	4: {
		unit: 'Language Focus',
		id: 'SB7-L-P87-E4',
		exerciseKey: 'img/FriendsPlus/Page87/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 140,
		maxLength: 12,
		stylesTextInput: {
			fontSize: 23,
			textAlign: 'center',
		},
		hintBox: [
			{
				src: ['ask', 'do', 'feel', 'go', 'revise', '<s>sing</s>', 'think'],
				borderColor: '#1ebfd7',
				width: 525,
			},
		],
		titleQuestion: [
			{
				num: '4',
				title: `
          Complete the dialogue with the present continuous form of the verbs 
          in the box. Which sentence is an action in progress and which 
          is an arrangement for the future?
        `,
				color: '#1ebfd7',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 700px;">
						<div style="color: rgba(0,0,0,0.5); font-size: 20px; font-style: italic; font-weight: bold;"><span>1</span> Action in progress</div>
						<hintbox id='0'></hintbox>

						<div style="margin-top: 10px;">
							<div style="display: flex;">
								<span style="margin-right: 26px">Son</span>
								<span>You sound happy, Mum. Why <i style="color:rgb(93,93,93)">are you singing</i>?</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 21px">Mum</span>
								<span>I <sup>1</sup># very happy.</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Son</span>
								<span>That's nice. <sup>2</sup># we <sup>3</sup># anything on Saturday?</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 21px">Mum</span>
								<span>
									Yes! You <sup>4</sup># for your exams, remember? 
									Why <sup>5</sup># you <sup>6</sup># ?
								</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Son</span>
								<span>
									Because Rob's <sup>7</sup># to a concert on Saturday and he invited me. 
									Is that OK? Mum? You're very quiet.
								</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 21px">Mum</span>
								<span>Mmm. I <sup>8</sup># .</span>
							</div>

							<div style="display: flex;">
								<span style="margin-right: 26px">Son</span>
								<span>Oh no - don't think! Just say yes… please!</span>
							</div>
						</div>
					</div>
                `,
				answer: [
					"'m feeling",
					'Are',
					'doing',
					"'re revising",
					'are',
					'asking',
					'going',
					"'m thinking",
				],
			},
		],
	},
	5: {
		unit: 'Language Focus',
		id: 'SB7-L-P87-E5',
		exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		hintBox: [
			{
				src: ['Are you doing anything this evening?'],
				borderColor: '#1ebfd7',
				arrow: true,
				position: 1,
				width: 290,
			},
			{
				src: [
					"No, not much. I'm doing my homework and then just watching TV. What about you?",
				],
				borderColor: '#1ebfd7',
				arrow: true,
				position: 2,
				width: 320,
			},
		],
		textareaStyle: { width: 800 },
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
					Work in pairs. Ask and answer questions using phrases <br/>from the boxes. Use the present continuous.
        `,
				color: '#1ebfd7',
				prefix: 'default',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 700px;">
						<div style="display: flex; justify-content: space-between; align-items: flex-start;">
							<div style="width: 330px;border: 2px solid rgb(27, 191, 215); background-color: rgb(214,238,248); border-radius: 20px; padding: 20px;">
								Are you doing anything … <br/>
								What's happening …<br/>
								Are you busy …
							</div>
							<div style="width: 280px; border: 2px solid rgb(27, 191, 215); background-color: rgb(214,238,248); border-radius: 20px; padding: 20px;">
								… tomorrow? <br/>
								… in the holidays? <br/>
								… after this lesson? <br/>
								… next weekend? <br/>
								… this evening?
							</div>
						</div>
						<div style="margin-top: 10px; display: flex; justify-content: space-around;">
							<div><hintbox id='0'></hintbox></div>
							<div><hintbox id='1'></hintbox></div>
						</div>
						<div style="margin-top: 30px;"><textarea id="0" rows="7"></textarea></div>
					</div>
				`,
				answer: [],
			},
		],
	},
	6: {
		unit: 'Language Focus',
		id: 'SB7-L-P87-E6',
		exerciseKey: '',
		audio: 'Audios/2-27 Friends Plus 7.mp3',
		video: '',
		component: T6,
		inputSize: 100,
		maxLength: 8,
		stylesTextInput: {
			fontSize: 23,
		},
		textareaStyle: { width: 750 },
		finished: {
			text: `
				Imagine you're in a band and you're going on 
				The You Factor in two weeks. 
				Write sentences about your future arrangements to prepare.
			`,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '',
				title: ``,
				color: '#1ebfd7',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 700px;">
						<finished></finished>
						<div style="margin-top: 30px;"><textarea id="0" rows="10"></textarea></div>
					</div>
				`,
				answer: [],
			},
		],
	},
};

export default json;
