import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  5: {
    unit: "Unit 4",
    id: "SB7-U4-P55-E5",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px", textAlign: "center" },
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page55/E5/Key/answerKey.png",
    checkUppercase: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Complete the sentences using the affirmative, negative or question form of the past simple.",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          
              <div >
                <div style='margin-top:5mm'><b>1.</b> I #(go) to the cinema on Saturday, but I #(not enjoy) the film.</div>
                <div style='margin-top:5mm'><b>2.</b> #you #(take) any good photos on holiday?</div>
                <div style='margin-top:5mm'><b>3.</b> We #(play) volleyball yesterday, but we #(not win) the match</div>
                <div style='margin-top:5mm'><b>4.</b> Thanh #(travel) to New York, but he  #(not visit) the Statue of Liberty.</div>
                <div style='margin-top:5mm'><b>5.</b> What time #David #(get up) this morning?</div>
                <div style='margin-top:5mm'><b>6.</b> They #(come) to the party, but they #(not see) any of their friends</div>
                <div style='margin-top:5mm'><b>7.</b>  # Ann and Emma #  (find) their money?</div>
                <div style='margin-top:5mm'><b>8.</b> Why # she # (leave) the class early?</div>
              </div>
          `,
        answer: [
          "went", "didn't enjoy",
          "Did", "take ",
          "played", "didn't win ",
          "travelled", "didn't visit ",
          "did", "get up ",
          "came", "didn't see ",
          "Did", "find",
          "did", "leave",
        ],
      },
    ],
  },
  6: {
    unit: "Unit 4",
    id: "SB7-U4-P55-E6",
    audio: "",
    video: "",
    component: Circle_Write,
    inputSize: 180,
    exerciseKey: "img/FriendsPlus/Page55/E6/Key/answerKey.png",
    titleQuestion: [
      {
        num: "6",
        title: "Choose the correct words",
        color: "#4d3c97",
      },
    ],
    questionImage: [],
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          marginLeft: 40,
          border: "none",
          borderRadius: "49%",
          borderColor: "transparent",
          fontSize: 22,
          display: 'inline-block',
          width: 120
        },
        selectWordStyle: {
          padding: 1,
          border: "solid 2px",
          borderColor: "#000000",
          textAlign: "center",
        },
        limitSelect: 1,
        listWords: [
          "a._What  b._How | c._Why", //0
          "a._Isn't | b._No | c._Not", //1
          "a._thinking | b._think | c._thinks", //2
          "a._looks | b._look | c._looked", //3
          "a._before | b._ago | c._early", //4
          "a._go | b._happen | c._do", //5
          "a._was | b._were | c._is", //6
          "a._horrible | b._cruel | c._brilliant", //7
        ],
        answers: ['0-4', '1-12', '2-6', '3-0', '4-6', '5-12', '6-0', '7-12'],
        // initialValue: ['0-0', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
            <div style='margin: 20px'>
              <div style='display: flex;'>
                <div><b>George</b></div>
                <div style='margin-left: 15px;'>Hey, Peter. <sup>1</sup>.............. was your weekend?</div>
              </div>
              <div style='display: flex;'>
                <div><b>Peter</b></div>
                <div style='margin-left: 40px;'><sup>2</sup>.............. bad, thanks. I played volleyball and then I went shopping. I bought this red sports bag. What do you <sup>3</sup>..............?</div>
              </div>
              <div style='display: flex;'>
                <div><b>George</b></div>
                <div style='margin-left: 15px;'>It <sup>4</sup>.............. really cool. I got a new bag, too.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Peter</b></div>
                <div style='margin-left: 40px;'>When was that?</div>
              </div>
              <div style='display: flex;'>
                <div><b>George</b></div>
                <div style='margin-left: 15px;'>These week <sup>5</sup>.............. But nobody noticed.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Peter</b></div>
                <div style='margin-left: 40px;'>Oh no! What about you? What did you <sup>6</sup>.............. at the weekend?</div>
              </div>
              <div style='display: flex;'>
                <div><b>George</b></div>
                <div style='margin-left: 15px;'>I went to a football match.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Peter</b></div>
                <div style='margin-left: 40px;'>Oh, right. What <sup>7</sup>.............. it like?</div>
              </div>
              <div style='display: flex;'>
                <div><b>George</b></div>
                <div style='margin-left: 15px;'>It wasn’t <sup>8</sup>............... My team lost 7–0 and I missed the bus on the way home.</div>
              </div>
              <div style='display: flex;'>
                <div><b>Peter</b></div>
                <div style='margin-left: 40px;'>Oh dear!</div>
              </div>
              <div style='display: flex;'>
                <div><b>George</b></div>
                <div style='margin-left: 15px;'>Yeah. I think you had a much better weekend</div>
              </div>
              <div style='display: flex;'>
                <div><b>Peter</b></div>
                <div style='margin-left: 40px;'>That’s really kind of you. Thanks .</div>
              </div>
            </div>
            <div>
              <div><b>1</b><input id='0' type='Circle' /></div>
              <div><b>2</b><input id='1' type='Circle' /></div> 
              <div><b>3</b><input id='2' type='Circle' /></div> 
              <div><b>4</b><input id='3' type='Circle' /></div> 
              <div><b>5</b><input id='4' type='Circle' /></div> 
              <div><b>6</b><input id='5' type='Circle' /></div>
              <div><b>7</b><input id='6' type='Circle' /></div>
              <div><b>8</b><input id='7' type='Circle' /></div>
            </div>
            `,
      answer: [],
    },
  },
  7: {
    unit: "Unit 4",
    id: "SB7-U4-P55-E7",
    audio: "",
    video: "",
    component: T6,
    stylesTextInput: { borderBottom: "dotted 1px" },
    inputSize: 100,
    maxLength: 50,
    exerciseKey: "img/FriendsPlus/Page55/E7/Key/answerKey.png",
    checkUppercase: true,
    titleQuestion: [
      {
        num: "7",
        title: "Complete the text with the words. There are two extra words.",
        color: "#4d3c97",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='width:60%; margin: 10px 0 20px 100px; border-radius: 30px; border: 2px solid rgb(77 60 151)'>
           <div style='text-align:center'>At&emsp;&emsp;Finally&emsp;&emsp;forget&emsp;&emsp;fun&emsp;&emsp;remember <br> that&emsp;&emsp;Then&emsp;&emsp;time&emsp;&emsp;was&emsp;&emsp;were&emsp;&emsp;weren't</div>
        </div> 
        <div style=' border: 3px solid rgb(77 60 151); border-radius: 80px; padding: 0px 0px 50px 20px ;background: rgb(228 227 241); width: 60%'>  
              <div style='text-align: center'><img src='img/FriendsPlus/Page55/E7/1.jpg' style='width:50%'/>   </div>
              <div style='margin-top :5mm'>  <span style='margin-left:50px'> I will</span> never <sup>1</sup># my older sister’s eighteenth birthday barbecue at our house.
              <br><sup>2</sup># , my mum prepared a lot of nice food. <sup>3</sup># 6 o’clock all the guests arrived for the
              party.</div>
            <div style='display: flex;'>
              <div>
                <sup>4</sup># my dad lit the
                barbecue in the garden and after
                <sup>5</sup># he started to cook
                the food.<br>
                <span style='margin-left:50px'>At</span> 7 o’clock, we all ate in the
                garden. It <sup>6</sup># a nice,
                warm evening, so we <sup>7</sup>#
                cold. We all laughed and talked for a
                long time. <sup>8</sup># , at about
                10 o’clock, everyone went home.<br>
                <span style='margin-left:50px'>There</span> <sup>9</sup># thirty
                people at the barbecue and we all had a
                great <sup>10</sup># . It was a lovely
                party and we all felt very happy
              </div>
              <img src='img/FriendsPlus/Page55/E7/2.jpg' style='margin-right: -50px; width:40%' />   
            </div>              
        </div>    
          `,
        answer: [
          "forget ",
          "First, ",
          "At ",
          "Then ",
          "that ",
          "was ",
          "weren't ",
          "Finally ",
          "were ",
          "time",
        ],
      },
    ],
  },
};
export default json;
