import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "SB7-U6-P68-E1",
    audio: "Audios/2-02 Friends Plus 7.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page68/E1/Key/answerKey.png",
    stylesTextInput: { borderBottom: "1px dashed", textAlign: "center" },
    inputSize: 120,
    titleQuestion: [
      {
        num: "1",
        title:
          '<audioimage name= "2.02"></audioimage> Read the <i>Survival Game </i>and complete phrases 1–14.Then listen and check.',
        color: "#0067b4",
      },
    ],
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
          <div style='margin: 20px -50px;'><img src='/img/FriendsPlus/Page68/E1/1.png' alt=''/></div>
					<div style=' width: 100%; display: flex; justify-content: space-around ; gap: 100px'>
            <div>
                <div><b>1</b> # all plants </div>
                <div><b>2</b> # still </div>
                <div><b>3</b> # a shelter </div>
                <div><b>4</b> # a tree </div>
                <div><b>5</b> # the sun </div>
                <div><b>6</b> # the river </div>
                <div><b>7</b> # cool </div>
            </div>
            <div>
                <div>&nbsp;&nbsp;<b>8</b> # a fire </div>
                <div>&nbsp;&nbsp;<b>9</b> # a noise </div>
                <div><b>10</b> # at night </div>
                <div><b>11</b> # fruit </div>
                <div><b>12</b> # away </div>
                <div><b>13</b> # where you are </div>
                <div><b>14</b> # drinking water </div>
            </div>
          </div>
        `,
        answer: [
          "avoid",
          "stand",
          "build",
          "climb",
          "use",
          "follow",
          "keep",
          "light",
          "make",
          "move",
          "pick",
          "run",
          "stay",
          "find",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 6",
    id: "SB7-U6-P68-E2",
    audio: "Audios/2-03 Friends Plus 7.mp3",
    video: "",
    component: DesignUnderLine,
    totalInput: 8,
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page68/E2/Key/answerKey.png",
    titleImage: "",
    isAllowSubmit: true,
    titleQuestion: [
      {
        num: "2",
        title:
          '<audioimage name= "2.03"></audioimage> Read the rules. Then play the Survival Game in teams. Listen and check after each question.',
        color: "#0067b4",
      },
    ],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/1.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/2.jpg" },
        { url: "img/FriendsPlus/Page68/E1/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page68/E1/4.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/5.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/6.jpg" },
        { url: "img/FriendsPlus/Page68/E1/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page68/E1/8.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/9.jpg" },
        { url: "img/FriendsPlus/Page68/E1/10.jpg", input: 1 },
        { url: "img/FriendsPlus/Page68/E1/11.jpg" },
        { url: "img/FriendsPlus/Page68/E1/12.jpg", input: 2 },
        { url: "img/FriendsPlus/Page68/E1/13.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/14.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/15.jpg" },
        { url: "img/FriendsPlus/Page68/E1/16.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page68/E1/17.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/18.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/19.jpg" },
        { url: "img/FriendsPlus/Page68/E1/20.jpg", input: 2 },
        { url: "img/FriendsPlus/Page68/E1/21.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/22.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/23.jpg" },
        { url: "img/FriendsPlus/Page68/E1/24.jpg", input: 3 },
        { url: "img/FriendsPlus/Page68/E1/25.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/26.jpg" },
        { url: "img/FriendsPlus/Page68/E1/27.jpg", input: 3 },
        { url: "img/FriendsPlus/Page68/E1/28.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/29.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/30.jpg" },
        { url: "img/FriendsPlus/Page68/E1/31.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page68/E1/32.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/33.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/34.jpg" },
        { url: "img/FriendsPlus/Page68/E1/35.jpg", input: 4 },
        { url: "img/FriendsPlus/Page68/E1/36.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/37.jpg" },
        { url: "img/FriendsPlus/Page68/E1/38.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page68/E1/39.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/40.jpg" },
        { url: "img/FriendsPlus/Page68/E1/41.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page68/E1/42.jpg" },
        { url: "img/FriendsPlus/Page68/E1/43.jpg", input: 4 },
        { url: "img/FriendsPlus/Page68/E1/44.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/45.jpg" },
        { url: "img/FriendsPlus/Page68/E1/46.jpg", input: 5 },
        { url: "img/FriendsPlus/Page68/E1/47.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/48.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/49.jpg" },
        { url: "img/FriendsPlus/Page68/E1/50.jpg", input: 5 },
        { url: "img/FriendsPlus/Page68/E1/51.jpg" },
        { url: "img/FriendsPlus/Page68/E1/52.jpg", input: 6 },
        { url: "img/FriendsPlus/Page68/E1/53.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/54.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/55.jpg" },
        { url: "img/FriendsPlus/Page68/E1/56.jpg", input: 6 },
        { url: "img/FriendsPlus/Page68/E1/57.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/58.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/59.jpg" },
        { url: "img/FriendsPlus/Page68/E1/60.jpg", input: 6, isCorrect: true },
        { url: "img/FriendsPlus/Page68/E1/61.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/62.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/63.jpg" },
        { url: "img/FriendsPlus/Page68/E1/64.jpg", input: 7, isCorrect: true },
        { url: "img/FriendsPlus/Page68/E1/65.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/66.jpg" },
        { url: "img/FriendsPlus/Page68/E1/67.jpg", input: 7 },
        { url: "img/FriendsPlus/Page68/E1/68.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/69.jpg" },
        { url: "img/FriendsPlus/Page68/E1/70.jpg", input: 7 },
        { url: "img/FriendsPlus/Page68/E1/71.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/72.jpg" },
        { url: "img/FriendsPlus/Page68/E1/73.jpg", input: 8 },
        { url: "img/FriendsPlus/Page68/E1/74.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/75.jpg" },
      ],

      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/76.jpg" },
        { url: "img/FriendsPlus/Page68/E1/77.jpg", input: 8, isCorrect: true },
        { url: "img/FriendsPlus/Page68/E1/78.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/79.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/80.jpg" },
        { url: "img/FriendsPlus/Page68/E1/81.jpg", input: 8 },
        { url: "img/FriendsPlus/Page68/E1/82.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/83.jpg" },
      ],
    ],
  },

  3: {
    // Exercise num
    unit: "Unit 9",
    id: "SB5-U9-P68-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: false,
    // titleQuestion: [{ num: '3', title: 'Listen to the story and repeat. Act.', color: "#2E479B" }],
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E3/1.jpg" },
      ],
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E1/1.jpg" },
      ],
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 9",
    id: "SB5-U9-P68-E4",
    audio: "",
    video: "",
    component: D1,
    padding: 0,
    textAlign: "center",
    maxLength: 1,
    exerciseKey: "img/FriendsPlus/Page68/E4/Key/answerKey.png",
    questionImage: [
      // Row
      [
        // Column1
        { url: "img/FriendsPlus/Page68/E4/1.jpg" },
      ],
      [
        // Column2
        { url: "img/FriendsPlus/Page68/E4/2.jpg" },
      ],
      [
        // Column3
        { url: "img/FriendsPlus/Page68/E4/3.jpg" },
        { url: "img/FriendsPlus/Page68/E4/4.jpg", input: true, answer: "c" },
        { url: "img/FriendsPlus/Page68/E4/5.jpg" },
      ],
      [
        // Column4
        { url: "img/FriendsPlus/Page68/E4/6.jpg" },
        { url: "img/FriendsPlus/Page68/E4/7.jpg", input: true, answer: "a" },
        { url: "img/FriendsPlus/Page68/E4/8.jpg" },
      ],
      [
        // Column5
        { url: "img/FriendsPlus/Page68/E4/9.jpg" },
        { url: "img/FriendsPlus/Page68/E4/10.jpg", input: true, answer: "b" },
        { url: "img/FriendsPlus/Page68/E4/11.jpg" },
      ],
    ],
  },
};

export default json;
