import UI from '../../components/ExcerciseTypes/Design/UserInterface';
import MC2 from '../../components/ExcerciseTypes/MultipleChoice/MC2';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    // check lại types question
    unit: 'Unit 6',
    id: 'SB7-U6-P70-E1',
    audio: '',
    video: '',
    component: MC2,
    exerciseKey: 'img/FriendsPlus/Page70/E1/Key/answerKey.png',
    isQuestionVerticalToImage: true,
    isQuestionVertical: true,
    titleQuestion: [
      {
        num: '',
        title: 'Look at the text.Then read and check. ',
        color: '#f58024',
      },
    ],
    titleImage_2: 'img/FriendsPlus/Page70/E1/2.png',
    imgSize: 1100,
    questions: [
      {
        title: ' What type of text is it? What type of information do you think will be in the text? ',
        answers: [
          { text: ' a personal blog ', isCorrect: false },
          { text: ' an advertisement for a TV programme ', isCorrect: true },
          { text: ' a newspaper article', isCorrect: false },
        ],
        // selectedItem: 0, // Lựa chọn mặc định
      },
    ],
  },

  2: {
    unit: 'Unit 6',
    id: 'SB7-U6-P70-E2',
    audio: 'Audios/2-05 Friends Plus 7.mp3',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page70/E2/Key/answerKey.png',
    stylesTextInput: { borderBottom: '1px dashed', textAlign: 'center' },
    inputSize: 160,
    titleQuestion: [
      {
        num: '2',
        title:
          '<audioimage name= "2.05"></audioimage> Read and listen to the text and complete the sentences with the name of a team member',
        color: '#0067b4',
      },
    ],
    titleImage: 'img/FriendsPlus/Page70/E2/1.png',
    questionImage: [],
    questions: [
      {
        title: `
            <div style='margin-left: 100px;'>
                <div><b>1</b> # has the best survival rating. </div>
                <div><b>2</b> # and # are the weakest contestants. </div>
                <div><b>3</b> # needs to face his fear of snakes. </div>
                <div><b>4</b> # probably won’t hunt animals for food.</div>
                <div><b>5</b> # needs to listen to other people. </div>
                <div><b>6</b> # will decide which team wins. </div>
            </div>
        `,
        answer: ['Jenny ', 'Tom', 'Peter', 'Ted', 'Sophie', 'Tina', 'Steve Grant'],
      },
    ],
  },
  3: {
    unit: 'Unit 6',
    id: 'SB7-U6-P70-E3',
    video: '',
    audio: '',
    component: UI,
    titleQuestion: [
      {
        num: '3',
        title:
          "Use a dictionary to check the meaning of the words in <span style='color:#1dbfd7'>blue</span> in the text.",
        color: '#dd2c3b',
        prefix: {
          icons: [],
          text: 'VOCABULARY PLUS',
        },
      },
    ],

    questionImage: [[{ url: 'img/FriendsPlus/Page70/E3/1.png' }, { url: 'img/FriendsPlus/Page70/E3/2.png' }]],
  },
  4: {
    unit: 'Unit 6',
    id: 'SB7-U6-P70-E4',
    audio: '',
    video: '',
    component: T6,
    titleQuestion: [
      {
        num: '4',
        title: 'Work in pairs. Which team do you think will win the challenge?',
        color: 'rgba(209, 23, 23, 0.89)',
        prefix: 'default',
      },
    ],
    textareaStyle: { width: 1000 },
    hideBtnFooter: true,
    questionImage: [],
    hintBox: [],
    questions: [
      {
        title: `
        <textarea id="0" rows="7"></textarea>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
