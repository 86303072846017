import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Unit 1",
    id: "SB7-U1-P18-E1",
    audio: "",
    video: "",
    component: T6,
    inputSize: 150,
    stylesTextInput: {
      // border: "1px solid",
      // height: 35,
      borderBottom: "dotted 1px",
    },
    hintBox: [
      {
        src: ["bar chart", "pie chart", "data chart", "y-axis", "x-axis"],
        borderColor: "",
        // arrow: true,
        width: 500,
        borderColor: "#d92e38",
      },
    ],
    titleQuestion: [
      {
        num: "1",
        title:
          "Check the meaning of the words in the box and match them with A-E in the charts.",
        color: "#d92e38",
      },
    ],
    textAlign: "center",
    maxLength: 10,
    exerciseKey: "img/FriendsPlus/Page18/E1/Key/answerKey.png",
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
        <div style='display:flex;'>
          <div>
            <hintBox id='0'></hintBox>
            <div>
              <div><b>A.</b>#</div> 
              <div><b>B.</b>#</div> 
              <div><b>C.</b>#</div> 
            </div>
            <div>
              <div><b>D.</b>#</div> 
              <div><b>E.</b>#</div> 
            </div>
          </div>
          <div>
            <img style='width:74%' src='img/FriendsPlus/Page18/E1/1.jpg'>
          </div>
        </div>
          `,
        answer: ["data chart", "y axis", "bar chart", "x-axis", "pie chart"],
      },
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB7-U1-P18-E2",
    audio: "Audios/1-13 Friends Plus 7.mp3",
    video: "",
    component: T6,
    inputSize: 850,
    maxLength: 72,
    stylesTextInput: {
      // border: "1px solid",
      // height: 35,
      borderBottom: "dotted 1px",
    },
    hintBox: [
      {
        src: ["bar chart", "pie chart", "data chart", "y-axis", "x-axis"],
        // borderColor: "",
        // arrow: true,
        width: 500,
        borderColor: "#d92e38",
      },
    ],
    titleQuestion: [
      {
        num: "2",
        title:
          "<audioimage name='1.13'></audioimage> Read and listen to the text. What information does the pie chart NOT give?",
        color: "#d92e38",
      },
    ],
    // textAlign: "center",
    // maxLength: 10,
    exerciseKey: "img/FriendsPlus/Page18/E2/Key/answerKey.png",
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
          <div style=';margin-left:50px'>
            <img style='width:16cm' src='img/FriendsPlus/Page18/E2/1.jpg'>
            <div style=' margin-top:40px'>What information does the pie chart Not give?<br>#</div>
          </div>
          `,
        answer: [
          "It doesn’t give information about the numbers of students in each group.",
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "SB7-U1-P18-E3",
    audio: "",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page18/E3/Key/answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title:
          "Study the charts. Then choose the correct words in the reports.",
        color: "#d92e38",
      },
    ],
    question: {
      Write: {
        inputStyle: { width: 70 },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: 700,
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#000000",
        },
        limitSelect: 1,
        listWords: [
          "Most / A_small_number_of", //1
          "a_quarter / half_of", //2
          "three / four", //3
          "Under / Over",
          "three / four",
          "one_hundred / two_hundred",
        ],
        answers: ["0-4", "1-0", "2-0", "3-4", "4-4", "5-4"],
        initialValue: [],
      },
      Layout: `
        <div style='margin: 0px 70px'>
          <img src='img/FriendsPlus/Page18/E3/1.jpg'>
          <div style='width: 12cm;margin-left:20px'>
            <b><sup>1</sup></b><input id='0'  type='Circle' /> students do
            no homework at all. Nearly <b><sup>2</sup></b><input id='1'  type='Circle' /> the students do between two
            and three hours of homework. More
            than half of the students do more than <b><sup>3</sup></b><input id='2'  type='Circle' />  hours of homework a week.
          </div>
          <img src='img/FriendsPlus/Page18/E3/2.jpg'>
          <div style='width: 12cm;margin-left:20px'>
            <b><sup>4</sup></b><input id='3'  type='Circle' />  30 students never play video games. More than half of the students spend over <b><sup>5</sup></b><input id='4'  type='Circle' /> hours playing video games. Nearly <b><sup>6</sup></b><input id='5'  type='Circle' />  students spend between two and three hours playing video games every week. 
          </div>
        </div>
      `,
    },
  },
  4: {
    unit: "Unit 1",
    id: "SB7-U1-P18-E4",
    audio: "",
    video: "",
    component: UI,
    // recorder: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Draw a bar chart for the data in the chart below.",
        color: "#d92e38",
        prefix: { icons: ["fas fa-pencil-alt"], text: "USE IT!" },
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page18/E4/1.jpg" }]],
  },
};

export default json;
