// TEMPLATE
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';

const json = {
  1: {
    unit: 'Option 3',
    id: 'SB7-O3-P108-E1',
    exerciseKey: 'img/FriendsPlus/Page108/E1/Key/answerKey.png',
    audio: 'Audios/3-08 Friends Plus 7.mp3',
    video: '',
    component: T6,
    inputSize: 50,
    maxLength: 1,
    titleQuestion: [
      {
        num: '1',
        title: `
                <audioimage name='3.08'></audioimage>
                Listen to people talking about a wedding. 
                Match speakers 1–4 with the things in photos A–D.
            `,
        color: '#f77e1d',
      },
    ],
    questions: [
      {
        title: `
                    <div style="margin-top: 20px; width: 800px">
                        <div style="margin-top:30px;"><img src="img/FriendsPlus/Page108/E1/1.jpg" style="max-width: 100%" /></div>
                        <ol style="display: flex; margin-top: 30px;">
                            <li>Liam #</li>
                            <li style="margin-left: 100px">Emma#</li>
                        </ol>
                        <ol style="display: flex" start="3">
                            <li>Harry #</li>
                            <li style="margin-left: 100px">Ruby #</li>
                        </ol>
                    </div>
                `,

        answer: ['D', 'B', 'C', 'A'],
      },
    ],
  },

  2: {
    unit: 'Option 3',
    id: 'SB7-O3-P108-E2',
    exerciseKey: 'img/FriendsPlus/Page108/E2/Key/answerKey.png',
    audio: 'Audios/3-09 Friends Plus 7.mp3',
    video: '',
    component: T6,
    inputSize: 120,
    maxLength: 8,
    checkDuplicated: true,
    hintBox: [
      {
        src: ['clothes', 'place', 'Lily’s parents', 'music', 'presents', 'food', 'people', 'drinks'],
        borderColor: '#f77e1d',
        width: 525,
      },
    ],
    titleQuestion: [
      {
        num: '2',
        title: `
                <audioimage name='3.09'></audioimage>
                Listen to Megan talking about a wedding. 
                Which of the things in the box does she talk about?
            `,
        color: '#f77e1d',
      },
    ],
    questions: [
      {
        title: `
                <div style="margin-top: 20px; width: 700px">
                    <hintbox id='0'></hintbox>
                    
                    <div style="margin-top: 30px">
                        <p>Answer:</p>

                        <div style="display: flex;">
                            <span>#</span>
                            <span style="margin-left: 70px;">#</span>
                            <span style="margin-left: 40px;">#</span>
                        </div>

                        <div style="display: flex; margin-top: 30px">
                            <span>#</span>
                            <span style="margin-left: 70px;">#</span>
                            <span style="margin-left: 40px;">#</span>
                        </div>
                    </div>
                </div>
            `,

        answer: [
          'clothes / people / place / food / music / presents',
          'clothes / people / place / food / music / presents',
          'clothes / people / place / food / music / presents',
          'clothes / people / place / food / music / presents',
          'clothes / people / place / food / music / presents',
          'clothes / people / place / food / music / presents',
        ],
      },
    ],
  },

  3: {
    unit: 'Option 3',
    id: 'SB7-O3-P108-E3',
    exerciseKey: 'img/FriendsPlus/Page108/E3/Key/answerKey.png',
    audio: 'Audios/3-09 Friends Plus 7.mp3',
    video: '',
    component: T6,
    inputSize: 90,
    maxLength: 5,
    // inputSize: 630,
    // maxLength: 51,

    stylesTextInput: {
      // textAlign: 'center',
      fontSize: 24,
    },

    titleQuestion: [
      {
        num: '3',
        title: `
                <audioimage name='3.09'></audioimage> 
                Listen to the conversation again and write <i>True</i> or <i>False</i>. 
                Correct the false sentences.
              `,
        color: '#f77e1d',
      },
    ],

    questions: [
      {
        title: `
                    <div style="width: 680px">
                        <ol style='margin-top: 40px'>
                            <li>The wedding was last Saturday. #</li>
                            <li>There were a hundred people at the wedding. #</li>
                            <li>Megan sat next to Dan at dinner. #</li>
                            <li>The wedding was in a room in a big hotel. #</li>
                            <li>Megan gave Lily and Dan some money as a wedding present. #</li>
                        </ol>
                    </div>  
                `,

        // answer: [
        //     'True',
        //     'False (There were about 150 people there.)',
        //     "False (She sat next to Dan's brother.)",
        //     'False (It was in the garden of a big hotel.)',
        //     "False (Megan's parents gave Lily and Dan some money.)",
        // ],

        answer: ['True / true', 'False / false', 'False / false', 'False / false', 'False / false'],
      },
    ],
  },

  4: {
    unit: 'Option 3',
    id: 'SB7-O3-P108-E4',
    exerciseKey: 'img/FriendsPlus/Page108/E4/Key/answerKey.png',
    audio: 'Audios/3-10 Friends Plus 7.mp3',
    video: '',
    component: T6,
    inputSize: 400,
    maxLength: 36,
    stylesTextInput: {
      fontSize: 23,
    },
    titleQuestion: [
      {
        num: '4',
        title: `
                <audioimage name='3.10'></audioimage>
                Complete the dialogue with the key phrases. Listen and check. 
                Then listen again and repeat the dialogue.
              `,
        color: '#f77e1d',
      },
    ],
    questions: [
      {
        title: `
                    <div style="width: 600px">
                        <div style="margin-top: 20px; width: 450px; padding-bottom: 20px;border: 1px solid rgb(247, 126, 29); border-radius: 10px; overflow: hidden">
                            <div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(247, 126, 29); color: white; padding: 5px; border-radius: 0px 0px 10px 10px; text-transform: uppercase;">
                                Key Phrases
                            </div>
                            
                            <div style="margin-top: 10px; padding-left: 20px;">
                                <div style="color: rgb(247, 126, 29); font-weight: bold; font-size: 1.6rem">
                                    Asking about an event
                                </div>

                                <div>
                                    Did you have a good time?<br/>
                                    Were there many people?<br/>
                                    What did you give them for a present?<br/>
                                    What was (the food) like?<br/>
                                    Why didn't you go to … ?<br/>
                                </div>
                            </div>
                        </div>

                        <div style="margin-top: 20px">
                            <div style="display: flex;">
                                <span style="margin-right: 20px">Pete</span>
                                <span>Hi, Sam. <br/><sup>1</sup># Jack's party?</span>
                            </div>
                        
                            <div style="display: flex;">
                                <span style="margin-right: 20px">Sam</span>
                                <span>I couldn't come. It was my brother's wedding.</span>
                                
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 20px">Pete</span>
                                <span>Oh, I forgot about that. <sup>2</sup>#?</span>
                            </div>
                        
                            <div style="display: flex;">
                                <span style="margin-right: 60px">Sam</span>
                                <span>Yeah. I had a great time!</span>
                            </div>
                        
                            <div style="display: flex;">
                                <span style="margin-right: 20px">Pete</span>
                                <span><sup>3</sup>#?</span>
                            </div>
                            
                            <div style="display: flex;">
                                <span style="margin-right: 20px">Sam</span>
                                <span>
                                    Yes, there were about 100 people. 
                                    I met Jack's sister, Ruby. She's cool.
                                </span>
                                
                            </div>
                        
                            <div style="display: flex;">
                                <span style="margin-right: 20px">Pete</span>
                                <span>I don't know her. <sup>4</sup>#?</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 20px">Sam</span>
                                <span>Really good. There were lots of different types of food.</span>
                            </div>
                        
                            <div style="display: flex;">
                                <span style="margin-right: 20px">Pete</span>
                                <span><sup>5</sup>#?</span>
                            </div>

                            <div style="display: flex;">
                                <span style="margin-right: 20px">Sam</span>
                                <span>My mum and dad gave them a coffee machine. They really liked it!</span>
                            </div>
                        </div>
                    </div>
                `,
        answer: [
          "Why didn't you go to",
          'Did you have a good time',
          'Were there many people',
          'What was the food like',
          'What did you give them for a present',
        ],
      },
    ],
  },

  5: {
    unit: 'Option 3',
    id: 'SB7-O3-P108-E5',
    exerciseKey: '',
    audio: '',
    video: '',
    component: T6,
    inputSize: 550,
    maxLength: 100,
    textareaStyle: { width: 1000 },
    hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
    titleQuestion: [
      {
        num: '5',
        title: `
                Work in pairs. Look at the diary extract. 
                Prepare and practise a new dialogue using the information in the diary, 
                and the dialogue in exercise 4. Use the Key Phrases.
            `,
        color: '#f77e1d',
        prefix: 'default',
      },
    ],

    questions: [
      {
        title: `
                <div style="margin-top: 20px; width: 600px;">
                    <img src="img/FriendsPlus/Page108/E5/1.jpg" style="max-width: 100%;" />
                    <div style="margin-top: 20px;"><textarea id="0" rows="7" ></textarea></div>
                </div>
            `,

        answer: [],
      },
    ],
  },
};

export default json;
