import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Speaking',
		id: 'SB7-S-P88-E1',
		exerciseKey: 'img/FriendsPlus/Page88/E1/Key/answerKey.png',
		audio: 'Audios/2-22 Friends Plus 7.mp3',
		video: 'Videos/Speaking/U7-2.22-Trang 88.mp4',
		component: T6,
		inputSize: 420,
		maxLength: 36,
		stylesTextInput: {
			fontSize: 22,
		},
		titleQuestion: [
			{
				num: '1',
				title: `
          <audioimage name='2.22'></audioimage>
          Complete the dialogue with the missing words or phrases. 
          Then watch or listen and check. What two things does Dan offer to do?
        `,
				color: '#e55991',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 780px; font-size: 22px;">
						<div>
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>Hey, Dan.</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>Hi, Amelia. You look busy. What <sup>1</sup><input id='0' width='156px' /> ?</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>
								There's going to be a talent competition in 
								school and I'm helping to organise it.
								</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>Really? When <sup>2</sup></sup><input id='1' width='56px' /> ?</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>
								At the end of the month. 
								We've got lots of singers and <sup>3</sup></sup><input id='2' width='122px' /> ?
								</span>
								
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>Oh, great!</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>
								<sup>4</sup></sup><input id='3' width='117px' />, but it's hard work.  
								I've got all these posters to put up, and ...
								</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>
								Hold on! Do you want me to do that? 
								I can do it after school tomorrow.
								</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>That would be great, <sup>5</sup></sup><input id='4' width='92px' /> .</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>Can I do anything else to help?</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>
								Well, we still need help with the food
								and drink. If I give you a list, can you
								get some <sup>6</sup></sup><input id='5' width='80px' /> from the <sup>7</sup></sup><input id='6' width='141px' /> ?
								</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>Sure, no problem. I can do that.</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>
								Great. We're meeting tomorrow after school to check everything, 
								if you want to come along.
								</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>OK. See you then.</span>
							</div>
						</div>

						<div style="margin-top: 10px;">
							<strong>What two things does Dan offer to do?</strong><br/>						
							<i>Dan offer to:<span>
							<ol>
								<li>#</li>
								<li>#</li>
							</ol>
						</div>
					</div>
        `,
				answer: [
					'are you doing',
					'is it',
					'musicians',
					"It'll be fun",
					'thanks',
					'things',
					'supermarket',
					'put up posters',
					'get some things from the supermarket',
				],
			},
		],
	},
	2: {
		unit: 'Speaking',
		id: 'SB7-S-P88-E2',
		exerciseKey: 'img/FriendsPlus/Page88/E2/Key/answerKey.png',
		audio: 'Audios/2-22 Friends Plus 7.mp3',
		video: 'Videos/Speaking/U7-2.22-Trang 88.mp4',
		component: T6,
		inputSize: 480,
		maxLength: 39,
		titleQuestion: [
			{
				num: '2',
				title: `
          <audioimage name='2.22'></audioimage>
          Complete the dialogue with the missing words or phrases. 
          Then watch or listen and check. What two things does Dan offer to do?
        `,
				color: '#e55991',
			},
		],
		questions: [
			{
				title: `
					<div style="width: 600px; margin-top: 20px;">
						<div style="width: 580px; padding-bottom: 20px; border-radius: 20px; overflow: hidden; background-color: rgba(246,211,218,1);">
							<div style="font-weight: bold; font-size: 1.6rem; background-color: rgb(226,115,148); color: white; padding: 5px; border-radius: 0px 0px 20px 20px; text-transform: uppercase;">
								Key Phrases
							</div>

							<div style="margin-top: 10px; padding-left: 20px;">
								<div style="color: rgb(226,115,148); font-weight: bold; font-size: 1.6rem">
                  Offering to help
								</div>

								<div style="display: flex;">
                  We need help with (the food and drink). <br/>
                  Do you want me to do that? <br/>
                  I can do that. <br/>
                  Can I do anything else to help? <br/>
                  Sure, no problem. <br/>
                  That would be great. <br/>
								</div>
							</div>
						</div>

            <div style="margin-top: 30px;">
              <span style="background-color: rgb(55,182,236); color: white; padding: 10px 20px; border-radius: 20px 20px 0 0 ; text-transform: uppercase;">Answers</span>

              <div style="margin-top: 10px;">
                <div style="display: flex;">
                  <span style="margin-right: 56px">Dan</span>
                  <span>#</span>
                </div>

                <div style="display: flex;">
                  <span style="margin-right: 26px">Amelia</span>
                  <span>#</span>
                </div>
                
                <div style="display: flex;">
                  <span style="margin-right: 56px">Dan</span>
                  <span>#</span>
                </div>

                <div style="display: flex;">
                  <span style="margin-right: 26px">Amelia</span>
                  <span>#</span>
                </div>
                
                <div style="display: flex;">
                  <span style="margin-right: 56px">Dan</span>
                  <span>#</span>
                </div>
              </div>
              
            </div>
					</div>
				`,

				answer: [
					'Do you want me to do that?',
					'That would be great.',
					'Can I do anything else to help?',
					'We need help with (the food and drink).',
					'Sure, no problem. I can do that.',
				],
			},
		],
	},
	3: {
		unit: 'Speaking',
		id: 'SB7-S-P88-E3',
		exerciseKey: '',
		audio: '',
		video: '',
		component: T6,
		inputSize: 120,
		maxLength: 20,
		stylesTextInput: {
			fontSize: 23,
			textAlign: 'center',
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '3',
				title: `
          <span style='margin-left: -15px;'>Work in pairs. Practise the dialogue.</span>
        `,
				color: '#e55991',
				prefix: { icons: ['far fa-comment'], text: '' },
			},
		],
		questions: [
			{
				title: `
					<div style="width: 780px; font-size: 22px;">
						<div>
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>Hey, Dan.</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>Hi, Amelia. You look busy. What <sup>1</sup># ?</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>
								There's going to be a talent competition in 
								school and I'm helping to organise it.
								</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>Really? When <sup>2</sup># ?</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>
								At the end of the month. 
								We've got lots of singers and <sup>3</sup># ?
								</span>
								
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>Oh, great!</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>
								<sup>4</sup>#, but it's hard work.  
								I've got all these posters to put up, and ...
								</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>
								Hold on! Do you want me to do that? 
								I can do it after school tomorrow.
								</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>That would be great, <sup>5</sup># .</span>
							</div>
						
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>Can I do anything else to help?</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>
								Well, we still need help with the food
								and drink. If I give you a list, can you
								get some <sup>6</sup># from the <sup>7</sup># ?
								</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>Sure, no problem. I can do that.</span>
							</div>
							
							<div style="display: flex;">
								<span style="margin-right: 26px">Amelia</span>
								<span>
								Great. We're meeting tomorrow after school to check everything, 
								if you want to come along.
								</span>
							</div>
							<div style="display: flex;">
								<span style="margin-right: 56px">Dan</span>
								<span>OK. See you then.</span>
							</div>
						</div>
					</div>
        `,
				answer: [],
			},
		],
	},
	4: {
		unit: 'Speaking',
		id: 'SB7-S-P88-E4',
		exerciseKey: 'img/FriendsPlus/Page88/E4/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 40,
		maxLength: 1,
		stylesTextInput: {
			fontSize: 23,
		},
		hintBox: [
			{
				src: ["Look - I've got all of these tickets to sell."],
				borderColor: '#e55991',
				arrow: true,
				position: 1,
				width: 480,
			},
			{
				src: ["I'll sell some tickets if you want."],

				borderColor: '#e55991',
				arrow: true,
				position: 2,
				width: 400,
			},
			{
				src: ['That would be great, thanks.'],

				borderColor: '#e55991',
				arrow: true,
				position: 1,
				width: 380,
			},
		],
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '4',
				title: `
					<span style='margin-left: -15px;'>Work in pairs. Look at situations 1-5. 
          Take turns offering to help in each situation. 
          Use the Key Phrases.</span>
          
        `,

				color: '#e55991',
				prefix: { icons: ['far fa-comment'], text: '' },
			},
		],
		questions: [
			{
				title: `
                    <div style="width: 650px; margin-top: 10px;">
						<div style="padding-left: 10px; margin-bottom: 16px;">
							<div style="display: flex;">
								<strong style="padding-right: 10px;">1</strong>
								<span>
									You must sell fifty music concert tickets.
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">2</strong>
								<span>
									You're having problems with your homework.
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">3</strong>
								<span>
									You can't play chess.
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">4</strong>
								<span>
									Sophie didn't invite me to her party.
								</span>
							</div>
							<div style="display: flex;">
								<strong style="padding-right: 10px;">5</strong>
								<span>
									It's really hot in here.
								</span>
							</div>
						</div>
                      <div style="">
                        <hintbox id='0'></hintbox>
                      </div>

                      <div style="margin-top: 30px; margin-left: 280px; ">
                        <hintbox id='1'></hintbox>
                      </div>

                      <div style="margin-top: 40px;">
                        <hintbox id='2'></hintbox>
                      </div>
                    </div>
                `,
				answer: [],
			},
		],
	},
	5: {
		unit: 'Speaking',
		id: 'SB7-S-P88-E5',
		exerciseKey: 'img/FriendsPlus/Page88/E5/Key/answerKey.png',
		audio: '',
		video: '',
		component: T6,
		inputSize: 590,
		maxLength: 100,
		// checkDuplicated: true,
		stylesTextInput: {
			// textAlign: 'center',
			fontSize: 23,
		},
		hideBtnFooter: true, // Not answer (Hide two buttons: 'Try again' and ' Submit')
		titleQuestion: [
			{
				num: '5',
				title: `
          Work in pairs. Read the situations. 
          Practise a new dialogue using the Key Prases 
          and the dialogue in exercise 1 to help you.                              
				`,
				color: '#e55991',
				prefix: { icons: ['far fa-comment'], text: 'USE IT!' },
			},
		],
		questions: [
			{
				title: `
          <div style="width: 600px; margin-top: 20px;">
            <div style="background-color: rgba(243,212,218,1); padding: 15px; border-radius: 20px;">
              <div style="border: 2px solid rgba(199,56,118,1); padding: 10px;">
                <span style="font-weight: bold;">Student A</span> (a passenger): 
                You're helping to organise a barbecue and cooking competition 
                at your school and you have a lot to do: music, foodand posters.
                
                <br/>

                <span style="font-weight: bold;">Student B</span> (a check-in agent):  
                Ask what you can do to help. 
                Offer to do something else, too.
              </div>

              <div style="border: 2px solid rgba(199,56,118,1); padding: 10px; margin-top: 20px">
                <span style="font-weight: bold;">Student A</span> (a passenger): 
                You're helping to organise a mini sports tournament 
                at your school and you have a lot to do: tickets, posters and snacks.

                <br/>

                <span style="font-weight: bold;">Student B</span> (a check-in agent):  
                Ask what you can do to help. 
                Offer to do something else, too.
              </div>
            </div>
          </div>
        `,
				answer: [],
			},
		],
	},
}

export default json
