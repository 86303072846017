import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import Animate from '../../../../../Animate';
import Fireworks from '../../../../../ResultsAndEffects/fireworks';
import styles from './starModal.module.css';

const StarModal = ({ modal, star }) => {
  return (
    <Modal centered isOpen={modal} style={{ maxWidth: 640 }}>
      <ModalBody>
        {star >= 1 && <Fireworks />}
        <div className={styles.modalBody_container}>
          <Animate resultString={star} />
        </div>
      </ModalBody>
    </Modal>
  );
};

StarModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  star: PropTypes.number.isRequired,
};

export default StarModal;
