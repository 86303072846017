import TB1 from "../../components/ExcerciseTypes/Table/TB1";
import TB2 from "../../components/ExcerciseTypes/Table/TB2";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Starter",
    id: "SB7-S-P9-E1",
    audio: "",
    video: "",
    component: TB1,
    exerciseKey: "img/FriendsPlus/Page9/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Look at the examples from the dialogue on page 8. What are the <i>he / she / it</i> forms of the words in bold?",
        color: "#1dbfd7",
      },
    ],
    data: [
      {
        title: "Affirmative",
        content: "1 We’<b>ve got</b> maths now.",
        type: "textarea",
        answers: ["He has got", "She has got", "It has got"],
      },
      // {
      //   title: "Negative ✘",
      //   content: "Tony isn't very happy.",
      //   type: "text",
      // },
      {
        title: "Negative",
        content: "2 I <b>haven’t got</b> my timetable.",
        type: "textarea",
        answers: ["He hasn't got", "She hasn't got", "It hasn't got"],
      },
      {
        title: "Questions",
        content: "3 What <b>have</b> we <b>got</b> now?",
        type: "textarea",
        answers: ["What has he got?", "What has she got?", "What has it got?"],
      },
    ],
    outterStyle: {},
    innerStyle: {},
  },
  2: {
    unit: "Starter",
    id: "SB7-S-P9-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page9/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Look at the photos. Complete the sentences with the correct forms of <i>have got.</i>",
        color: "#1dbfd7",
      },
    ],
    inputSize: 170,
    textAlign: "center",
    titleImage: "",
    questions: [
      {
        title: `
          <div>May and Orla <span style='color: darkgrey;'>have got</span> school ties. ✔</div>
          <div><b>1</b> Conor # a school tie. ✘</div>
          <div><b>2</b> He # a laptop. ✔</div>
          <div><b>3</b> May and Orla # backpacks. ✔</div>
          <div><b>4</b> They # laptops. ✘</div>
          <div><b>5</b> They # good marks. ✘</div>
          <div><b>6</b> Conor # a very good mark. ✔</div>
          <div style='margin-top: 20px;'><img src='img/FriendsPlus/Page9/E2/1.jpg' width='34%'/></div>
        `,
        answer: [
          "hasn't got",
          "has got",
          "have got",
          "haven't got",
          "haven't got",
          "has got",
        ],
      },
    ],
  },
  3: {
    unit: "Starter",
    id: "SB7-S-P9-E3",
    audio: "",
    video: "",
    component: TB2,
    exerciseKey: "",
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Write six questions with the correct forms of <i>have got</i> and the words in the boxes. Then work in pairs. Ask and answer your questions.",
        color: "#1dbfd7",
        prefix: { icons: ["far fa-comment"] },
      },
    ],
    questions: [],
    tb2Style: { width: 700 },
    type: 3,
    data: [
      {
        // title: "",
        content: [
          "you",
          "your friends",
          "your teacher",
          "this class",
          "the school",
          "this book",
        ],
        answers: [],
        commonStyle: {
          backgroundColor: "#d7eff6",
          border: "solid 2px #1dbfd7",
          borderRadius: 25,
          marginRight: 25,
        },
        titleStyle: {},
        contentStyle: {},
      },
      {
        // title: "",
        content: ["interesting", "nice", "good", "difficult", "modern", "old"],
        answers: [],
        commonStyle: {
          backgroundColor: "#d7eff6",
          border: "solid 2px #1dbfd7",
          borderRadius: 25,
          marginRight: 25,
        },
        titleStyle: {},
        contentStyle: {},
      },
      {
        // title: "",
        content: [
          "science lab",
          "marks",
          "furniture",
          "classrooms",
          "exercises",
          "teachers",
          "posters",
        ],
        answers: [],
        commonStyle: {
          backgroundColor: "#d7eff6",
          border: "solid 2px #1dbfd7",
          borderRadius: 25,
          marginRight: 25,
        },
        titleStyle: {},
        contentStyle: {},
      },
    ],
  },
  4: {
    unit: "Starter",
    id: "SB7-S-P9-E4",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "4",
        title:
          "Complete the sentences. Then check your answers in the dialogue on page 8. When do we use <i>any</i>?",
        color: "#1dbfd7",
      },
    ],
    stylesTextInput: {
      fontSize: 24,
      textAlign: "center",
      borderBottom: "dotted 1px",
    },
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page9/E4/Key/answerKey.png",
    questionImage: [],
    questions: [
      {
        title: `
					<div><b>1</b> There # a new history teacher.</div>
          <div><b>2</b> There # a thousand students here.</div>
          <div><b>3</b> How many teachers # there here?</div>
          <div><b>4</b> There # science labs in this block.</div>
				`,
        answer: ["is", "are", "are", "aren't any"],
      },
    ],
  },

  5: {
    unit: "Starter",
    id: "SB7-S-P9-E5",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "5",
        title:
          "Complete the quiz with <i>there are, is there</i> or <i>are there</i>. Then answer the questions.",
        color: "#1dbfd7",
      },
    ],
    stylesTextInput: {
      fontSize: 24,
      textAlign: "center",
      borderBottom: "transparent",
    },
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page9/E5/Key/answerKey.png",
    isHiddenCheck: true,
    checkUppercase: true,
    maxLength: 9,
    questions: [
      {
        title: `
          <div style=' position: relative; '>
            <div><img src='img/FriendsPlus/Page9/E5/1.jpg' style='width: 69%' /></div>
            <div style=" position: absolute; top: 159px; left: 382px;"><input id='0' width='105px' padding='0px' /></div>
            <div style=" position: absolute; top: 252px; left: 123px;">#</div>
            <div style=" position: absolute; top: 348px; left: 124px;">#</div>
            <div style=" position: absolute; top: 468px; left: 124px;">#</div>
            <div style=" position: absolute; top: 594px; left: 124px;">#</div>
					</div>
				`,
        answer: ["are there", "Is there", "There are", "Are there", "Is there"],
      },
    ],
  },
  6: {
    unit: "Starter",
    id: "SB7-S-P9-E6",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "6",
        title:
          "Complete the text with the correct forms of <i>be</i> and <i>have got</i>.",
        color: "#1dbfd7",
      },
    ],
    stylesTextInput: {
      fontSize: 26,
      textAlign: "center",
      borderBottom: "dotted 1px",
    },
    inputSize: 150,
    exerciseKey: "img/FriendsPlus/Page9/E6/Key/answerKey.png",
    questionImage: [],
    checkUppercase: true,
    maxLength: 11,
    questions: [
      {
        title: `
          <div><b>Our school</b></div>
          <div>
            In our school there are about a thousand <br />
            students. There <sup>1</sup> # thirty <br />
            classrooms and there <sup>2</sup> # <br />
            a big sports field next to the school. <br />
            There <sup>3</sup> # only boys here. <br />
            There <sup>4</sup> # any girls. The school <br />
            <sup>5</sup> # a new science lab, but we <br />
            <sup>6</sup> # a computer lab and there <br />
            <sup>7</sup> # many computers in the classes. <br />
            I like the school because there <sup>8</sup> # a <br />
            good atmosphere.
          </div>
				`,
        answer: [
          "are",
          "is",
          "are",
          "aren't",
          "has got",
          "haven't got",
          "aren't",
          "is",
        ],
      },
    ],
  },
  7: {
    unit: "Starter",
    id: "SB7-S-P9-E7",
    audio: "",
    video: "",
    component: T6,
    titleQuestion: [
      {
        num: "7",
        title:
          "Write a short paragraph (60 – 80 words) about your school using <i>there’s, there are, has got</i> and <i>have got.</i>",
        color: "#1dbfd7",
        prefix: "default",
      },
    ],
    exerciseKey: "",
    textareaStyle: { width: 1140 },
    hideBtnFooter: true,
    questionImage: [],
    questions: [
      {
        title: `
          <div><textarea id="0" rows="10" ></textarea></div>
        `,
        answer: [],
      },
    ],
  },
};

export default json;
